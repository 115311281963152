import { CommunicationState } from '../communicationState';
import { WeWashApiErrorTag } from '../../http/errors';
import { createReducer } from 'redux-starter-kit';
import { RESET_VIEWS } from '../genericActions';
import {
    ADD_VIP_USERS_TO_LAUNDRY_ROOM,
    ADD_VIP_USERS_TO_LAUNDRY_ROOM_FAILURE,
    ADD_VIP_USERS_TO_LAUNDRY_ROOM_SUCCESS,
    LAUNDRY_ROOM_FOR_VIP_FETCH,
    LAUNDRY_ROOM_FOR_VIP_FETCH_FAILURE,
    LAUNDRY_ROOM_FOR_VIP_FETCH_SUCCESS,
    LAUNDRY_ROOM_FOR_VIP_UPDATE_COST,
    LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_FAILURE,
    LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_SUCCESS,
    LaundryRoomForVipUsers,
    REMOVE_VIP_USERS,
    REMOVE_VIP_USERS_FAILURE,
    REMOVE_VIP_USERS_SUCCESS,
    VIP_USERS_FETCH,
    VIP_USERS_FETCH_FAILURE,
    VIP_USERS_FETCH_RESET,
    VIP_USERS_FETCH_SUCCESS,
    VipUserAddingReportDto,
    VipUsersInLaundryRoomDto,
    VipUsersRemovalReportDto,
} from './manageVipUsersActions';

export interface ManageVipUsersStore {
    laundryRoom?: LaundryRoomForVipUsers;
    vipUsers?: VipUsersInLaundryRoomDto;
    getLaundryRoomStatus: CommunicationState;
    getVipUsersStatus: CommunicationState;
    errors: WeWashApiErrorTag[];
    updateLaundryRoomVipCostStatus: CommunicationState;
    addVipUsersReport?: VipUserAddingReportDto;
    addVipUsersStatus: CommunicationState;
    removeVipUsersReport?: VipUsersRemovalReportDto;
    removeVipUsersStatus: CommunicationState;
    updateErrors: WeWashApiErrorTag[];
}

const initialStore: ManageVipUsersStore = {
    laundryRoom: undefined,
    vipUsers: undefined,
    getLaundryRoomStatus: CommunicationState.INITIAL,
    getVipUsersStatus: CommunicationState.INITIAL,
    errors: [],
    updateLaundryRoomVipCostStatus: CommunicationState.INITIAL,
    addVipUsersReport: undefined,
    addVipUsersStatus: CommunicationState.INITIAL,
    removeVipUsersReport: undefined,
    removeVipUsersStatus: CommunicationState.INITIAL,
    updateErrors: [],
};

export const manageVipUsersReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LAUNDRY_ROOM_FOR_VIP_FETCH]: (state, action) => {
        state.getLaundryRoomStatus = CommunicationState.LOADING;
        state.errors = [];
    },
    [LAUNDRY_ROOM_FOR_VIP_FETCH_SUCCESS]: (state, action) => {
        state.laundryRoom = action.payload.data;
        state.getLaundryRoomStatus = CommunicationState.SUCCESS;
        state.errors = [];
    },
    [LAUNDRY_ROOM_FOR_VIP_FETCH_FAILURE]: (state, action) => {
        state.laundryRoom = undefined;
        state.getLaundryRoomStatus = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
    [VIP_USERS_FETCH]: (state, action) => {
        state.getVipUsersStatus = CommunicationState.LOADING;
        state.errors = [];
    },
    [VIP_USERS_FETCH_SUCCESS]: (state, action) => {
        state.vipUsers = action.payload.data;
        state.getVipUsersStatus = CommunicationState.SUCCESS;
        state.errors = [];
    },
    [VIP_USERS_FETCH_FAILURE]: (state, action) => {
        state.vipUsers = undefined;
        state.getVipUsersStatus = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
    [VIP_USERS_FETCH_RESET]: (state, action) => {
        state.vipUsers = undefined;
        state.getVipUsersStatus = CommunicationState.INITIAL;
        state.errors = [];
    },
    [LAUNDRY_ROOM_FOR_VIP_UPDATE_COST]: (state, action) => {
        state.updateLaundryRoomVipCostStatus = CommunicationState.LOADING;
        state.updateErrors = [];
    },
    [LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_SUCCESS]: (state, action) => {
        state.updateLaundryRoomVipCostStatus = CommunicationState.SUCCESS;
        state.updateErrors = [];
    },
    [LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_FAILURE]: (state, action) => {
        state.updateLaundryRoomVipCostStatus = CommunicationState.FAILURE;
        state.updateErrors = action.payload.errors;
    },
    [ADD_VIP_USERS_TO_LAUNDRY_ROOM]: (state, action) => {
        state.addVipUsersStatus = CommunicationState.LOADING;
        state.addVipUsersReport = undefined;
        state.updateErrors = [];
    },
    [ADD_VIP_USERS_TO_LAUNDRY_ROOM_SUCCESS]: (state, action) => {
        state.addVipUsersStatus = CommunicationState.SUCCESS;
        state.addVipUsersReport = action.payload.data;
    },
    [ADD_VIP_USERS_TO_LAUNDRY_ROOM_FAILURE]: (state, action) => {
        state.addVipUsersStatus = CommunicationState.FAILURE;
        state.updateErrors = action.payload.errors;
    },
    [REMOVE_VIP_USERS]: (state, action) => {
        state.removeVipUsersStatus = CommunicationState.LOADING;
        state.removeVipUsersReport = undefined;
        state.updateErrors = [];
    },
    [REMOVE_VIP_USERS_SUCCESS]: (state, action) => {
        state.removeVipUsersStatus = CommunicationState.SUCCESS;
        state.removeVipUsersReport = action.payload.data;
    },
    [REMOVE_VIP_USERS_FAILURE]: (state, action) => {
        state.removeVipUsersReport = undefined;
        state.removeVipUsersStatus = CommunicationState.FAILURE;
        state.updateErrors = action.payload.errors;
    },
});
