import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    ManageUserStore,
    PaymentMethod,
} from '../../store/manage-user/manageUserReducer';
import { UserExtendedInformation } from '../../store/search-user/searchUserReducer';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';
import { Button, Row } from 'react-bootstrap';
import {
    loadInvoiceById,
    manuallyPayInvoice,
    resetPayInvoiceRequests,
    triggerDefaultInvoicePayment,
} from '../../store/pay-invoice/payInvoiceActions';
import styles from './UserPaysDebt.module.css';
import genericStyles from '../../App/Generic.module.css';
import classnames from 'classnames';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { formatMonetaryAmount } from '../../store/amountAndCurrency';
import { PayInvoiceStore } from '../../store/pay-invoice/payInvoiceReducer';
import { RouterStore } from '../../store/router/routerReducer';
import { CommunicationState } from '../../store/communicationState';
import SuccessFeedback from '../../forms/SuccessFeedback';
import UserInvoiceDetails from './elements/UserInvoiceDetails';
import { useParams } from 'react-router-dom';

interface Props {
    user: ManageUserStore;
    searchResultsById: UserExtendedInformation[];
    routerStore: RouterStore;
    userPayInvoice: PayInvoiceStore;
    isAdmin: boolean;
    goTo: (route: Route, param?: string | number) => void;
    triggerDefaultInvoicePayment: (invoiceId: number, userId: number) => void;
    manuallyPayInvoice: (invoiceId: number, userId: number) => void;
    resetPayInvoiceRequests: () => void;
    loadInvoiceById: (invoiceId: number) => void;
}

interface Params {
    invoiceId?: string;
}

const UserPaysInvoice: FunctionComponent<Props> = ({
    routerStore,
    user,
    isAdmin,
    goTo,
    searchResultsById,
    userPayInvoice,
    triggerDefaultInvoicePayment,
    manuallyPayInvoice,
    resetPayInvoiceRequests,
    loadInvoiceById,
}) => {
    useEffect(() => {
        resetPayInvoiceRequests && resetPayInvoiceRequests();
    }, [resetPayInvoiceRequests]);
    const params = useParams<Params>();
    const [showDetails, setShowDetails] = useState(true);

    const invoiceId = Number(params.invoiceId || routerStore.param);
    const isValidInvoiceId = !Number.isNaN(invoiceId);
    const isUserDataAvailable =
        searchResultsById.length > 0 && user && user.details;

    useEffect(() => {
        loadInvoiceById(invoiceId);
    }, [invoiceId, loadInvoiceById]);

    function renderEmpty() {
        return (
            <Row
                className={classnames(
                    'flex-column justify-content-between',
                    styles.container
                )}
            >
                {userPayInvoice.invoiceById &&
                    userPayInvoice.invoiceById.invoice_id === invoiceId && [
                        <Row key="invoiceNumber">
                            <strong>Rechnung</strong>
                            <span className={styles.value}>
                                {userPayInvoice.invoiceById.invoice_number}
                            </span>
                        </Row>,
                        <Row key={'invoiceStatus'}>
                            <strong>Status</strong>
                            <span className={styles.value}>
                                {userPayInvoice.invoiceById.status}
                            </span>
                        </Row>,
                    ]}
                <Row className={styles.spacer}>
                    <Button
                        onClick={() =>
                            goTo(
                                Route.MANAGE_USER,
                                (user &&
                                    user.details &&
                                    user.details.user_id) ||
                                    undefined
                            )
                        }
                    >
                        Zurück
                    </Button>
                </Row>
                <Row className={styles.spacer}>
                    <Button onClick={() => goTo(Route.SEARCH_USER)}>
                        Nutzer suchen
                    </Button>
                </Row>
            </Row>
        );
    }

    if (!isAdmin) {
        return renderEmpty();
    }

    if (!isValidInvoiceId || !isUserDataAvailable) {
        return renderEmpty();
    }
    if (!user || !user.details) {
        return renderEmpty();
    }

    const { user_id } = user.details;
    const information = searchResultsById
        .filter((it) => it.user_id === user_id)
        .reduce((info) => info);
    const selectedPaymentMethods = user.details.payment_methods.filter(
        (it) => it.selected
    );
    const paymentMethod =
        selectedPaymentMethods.length > 0
            ? selectedPaymentMethods[0]
            : undefined;

    const invoice = user.details.outstanding_invoices.find(
        (it) => it.invoice_id === invoiceId
    );
    if (!invoice) {
        return renderEmpty();
    }
    const paymentInProgress =
        userPayInvoice.communicationState === CommunicationState.LOADING;
    const paymentSuccess =
        userPayInvoice.communicationState === CommunicationState.SUCCESS;

    return (
        <Row
            className={classnames(
                'flex-column justify-content-between',
                styles.container
            )}
        >
            <Row>
                <h3 className={genericStyles.wewashHeading}>Offene Rechnung</h3>
            </Row>
            <Row className={styles.spacer}>
                <strong>Nutzer:</strong>{' '}
                <span className={styles.value}>{information.name}</span>
            </Row>
            <Row>
                <strong>E-Mail:</strong>{' '}
                <span className={styles.value}>{information.email}</span>
            </Row>
            <Row className={styles.spacer}>
                <strong>Rechnung</strong>
                <span className={styles.value}>{invoice.invoice_number}</span>
            </Row>
            <Row>
                <strong>Status</strong>
                <span className={styles.value}>{invoice.payment_status}</span>
            </Row>
            <Row>
                <strong>Land</strong>
                <span className={styles.value}>{invoice.country_code}</span>
            </Row>
            <Row>
                <strong>Betrag</strong>
                <span className={styles.value}>
                    {formatMonetaryAmount(invoice.amount)}
                </span>
            </Row>
            <Row>
                <Button
                    type={'button'}
                    variant={showDetails ? 'secondary' : 'outline-secondary'}
                    onClick={() => setShowDetails(!showDetails)}
                >
                    {showDetails ? 'Details verstecken' : 'Details anzeigen'}
                </Button>
            </Row>
            {maybeRenderDetails()}

            <Row className={styles.spacer}>
                <strong>Rechnung stornieren</strong>
                <span className={styles.value}>
                    Die Rechnung aufheben. Dabei können einzelne Reservierungen
                    ausgewählt werden.
                </span>
            </Row>
            <Row>
                <Button
                    onClick={handleCancelInvoice}
                    disabled={userPayInvoice.loading}
                >
                    WEITER ZUR STORNIERUNG
                </Button>
                <ErrorFeedback apiErrors={userPayInvoice.errors} />
            </Row>

            <Row className={styles.spacer}>
                {showPaymentMethod(paymentMethod)}
            </Row>
            <Row>
                <Button
                    disabled={paymentInProgress || !paymentMethod}
                    onClick={handleTriggerAutomaticPayment}
                >
                    Zahlung von {formatMonetaryAmount(invoice.amount)} anstoßen
                </Button>
            </Row>
            <Row className={styles.spacer}>
                {paymentInProgress && (
                    <p>
                        Zahlung von {formatMonetaryAmount(invoice.amount)} wird
                        bearbeitet
                    </p>
                )}
                {paymentSuccess && (
                    <SuccessFeedback
                        message={
                            'Erfolgreich! Zahlung von ' +
                            formatMonetaryAmount(invoice.amount) +
                            ' wurde angestoßen.'
                        }
                    />
                )}
                <ErrorFeedback apiErrors={userPayInvoice.errors} />
            </Row>
            {invoice.manual_payment_allowed && (
                <Row className={styles.spacer}>
                    <strong>Betrag erhalten</strong>
                    <span className={styles.value}>
                        Die Rechnung als bezahlt markieren.
                    </span>
                </Row>
            )}
            {invoice.manual_payment_allowed && (
                <Row>
                    <Button
                        onClick={handleManualPayment}
                        disabled={userPayInvoice.loading}
                    >
                        KUNDE HAT BETRAG ÜBERWIESEN
                    </Button>
                </Row>
            )}
            <Row className={styles.spacer}>
                {userPayInvoice.loading && (
                    <p>Rechnungsstatus wird aktualisiert...</p>
                )}
                <ErrorFeedback apiErrors={userPayInvoice.errors} />
            </Row>
            <Row className={styles.spacer}>
                <Button onClick={() => goTo(Route.MANAGE_USER, user_id)}>
                    Zurück
                </Button>
            </Row>
        </Row>
    );

    function handleManualPayment() {
        manuallyPayInvoice(invoiceId, user_id);
    }

    function handleTriggerAutomaticPayment() {
        triggerDefaultInvoicePayment(invoiceId, user_id);
    }

    function handleCancelInvoice() {
        goTo(Route.CANCEL_USER_INVOICE, invoiceId);
        // cancelInvoiceForUser(invoiceId, user_id);
    }

    function showPaymentMethod(paymentMethod: PaymentMethod | undefined) {
        if (!paymentMethod) {
            return (
                <span>
                    <strong>
                        Keine automatische Zahlungsmethode ausgewählt.
                    </strong>
                    <span className={styles.value}>
                        Die Zahlung kann nur angestoßen werden, wenn der Kunde
                        eine gültige, automatische Zahlungsmethode ausgewählt
                        hat.
                    </span>
                </span>
            );
        }
        return (
            <span>
                <strong>Ausgewählte Zahlungsmethode:</strong>
                <span className={styles.value}>
                    {paymentMethod.type} {paymentMethod.payment_info}
                    {paymentMethod.valid_until && (
                        <span> Gültig bis {paymentMethod.valid_until} </span>
                    )}
                </span>
            </span>
        );
    }

    function maybeRenderDetails() {
        if (!showDetails) {
            return null;
        }
        if (
            userPayInvoice.getCommunicationState !==
                CommunicationState.SUCCESS ||
            !userPayInvoice.invoiceById
        ) {
            return (
                <Row>
                    {userPayInvoice.getCommunicationState ===
                    CommunicationState.LOADING ? (
                        'Lade...'
                    ) : (
                        <ErrorFeedback apiErrors={userPayInvoice.getErrors} />
                    )}
                </Row>
            );
        }
        return (
            <Row>
                <UserInvoiceDetails invoice={userPayInvoice.invoiceById} />
            </Row>
        );
    }
};

export default connect(
    (store: RootStore) => ({
        routerStore: store.router,
        user: store.manageUser,
        searchResultsById: store.searchUser.resultsById,
        userPayInvoice: store.payInvoice,
        isAdmin: store.user.userIsAdmin,
    }),
    {
        goTo,
        triggerDefaultInvoicePayment,
        manuallyPayInvoice,
        resetPayInvoiceRequests,
        loadInvoiceById,
    }
)(UserPaysInvoice);
