import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import { loadUserDetails } from '../manage-user/manageUserActions';
import { searchUserById } from '../search-user/searchUserActions';
import { goTo } from '../router/routerActions';
import { Route } from '../../Router';

export const MANUALLY_PAY_INVOICE = 'MANUALLY_PAY_INVOICE';
export const MANUALLY_PAY_INVOICE_SUCCESS = 'MANUALLY_PAY_INVOICE_SUCCESS';
export const MANUALLY_PAY_INVOICE_FAILURE = 'MANUALLY_PAY_INVOICE_FAILURE';

export const TRIGGER_DEFAULT_INVOICE_PAYMENT =
    'TRIGGER_DEFAULT_INVOICE_PAYMENT';
export const TRIGGER_DEFAULT_INVOICE_PAYMENT_SUCCESS =
    'TRIGGER_DEFAULT_INVOICE_PAYMENT_SUCCESS';
export const TRIGGER_DEFAULT_INVOICE_PAYMENT_FAILURE =
    'TRIGGER_DEFAULT_INVOICE_PAYMENT_FAILURE';

export const RESET_PAY_INVOICE_REQUESTS = 'RESET_PAY_INVOICE_REQUESTS';

export const GET_INVOICE_BY_ID = 'GET_INVOICE_BY_ID';
export const GET_INVOICE_BY_ID_SUCCESS = 'GET_INVOICE_BY_ID_SUCCESS';
export const GET_INVOICE_BY_ID_FAILURE = 'GET_INVOICE_BY_ID_FAILURE';

export const CANCEL_INVOICE = 'CANCEL_INVOICE';
export const CANCEL_INVOICE_SUCCESS = 'CANCEL_INVOICE_SUCCESS';
export const CANCEL_INVOICE_FAILURE = 'CANCEL_INVOICE_FAILURE';

export const RESET_CANCEL_INVOICE_PERFORMED_ACTION =
    'RESET_CANCEL_INVOICE_PERFORMED_ACTION';

export const SEARCH_INVOICES_BY_SEARCH_TEXT = 'SEARCH_INVOICE_BY_SEARCH_TEXT';

export const RESET_SEARCH_INVOICES_BY_SEARCH_TEXT =
    'RESET_SEARCH_INVOICES_BY_SEARCH_TEXT';

export const SEARCH_INVOICES_BY_SEARCH_TEXT_SUCCESS =
    'SEARCH_INVOICE_BY_SEARCH_TEXT_SUCCESS';

export const SEARCH_INVOICES_BY_SEARCH_TEXT_FAILURE =
    'SEARCH_INVOICE_BY_SEARCH_TEXT_FAILURE';

export function manuallyPayInvoice(invoiceId: number, userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: MANUALLY_PAY_INVOICE });
        try {
            const { data } = await RestClient.post(
                `/v1/callcenter/invoices/${invoiceId}/manually-pay`
            );
            dispatch({ type: MANUALLY_PAY_INVOICE_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: MANUALLY_PAY_INVOICE_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
        await dispatch(loadUserDetails(userId));
        await dispatch(searchUserById(userId));
        await dispatch(loadInvoiceById(invoiceId));
    };
}

export function triggerDefaultInvoicePayment(
    invoiceId: number,
    userId: number
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({
            type: TRIGGER_DEFAULT_INVOICE_PAYMENT,
            payload: { userId, invoiceId },
        });
        try {
            const { data } = await RestClient.post(
                `/v1/callcenter/invoices/${invoiceId}/trigger-default-pay`
            );
            dispatch({
                type: TRIGGER_DEFAULT_INVOICE_PAYMENT_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: TRIGGER_DEFAULT_INVOICE_PAYMENT_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
        await dispatch(loadUserDetails(userId));
        await dispatch(searchUserById(userId));
        await dispatch(loadInvoiceById(invoiceId));
    };
}

export function resetPayInvoiceRequests(): AnyAction {
    return { type: RESET_PAY_INVOICE_REQUESTS };
}

export function loadInvoiceById(invoiceId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: GET_INVOICE_BY_ID });
        try {
            const { data } = await RestClient.get(
                `/v1/callcenter/invoices/${invoiceId}`
            );
            dispatch({ type: GET_INVOICE_BY_ID_SUCCESS, payload: { data } });
        } catch (e) {
            dispatch({
                type: GET_INVOICE_BY_ID_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function searchInvoices(searchText: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: RESET_SEARCH_INVOICES_BY_SEARCH_TEXT });
        dispatch({ type: SEARCH_INVOICES_BY_SEARCH_TEXT });
        try {
            const { data } = await RestClient.get(
                `/v1/callcenter/invoices?searchText=${searchText}`
            );
            dispatch({
                type: SEARCH_INVOICES_BY_SEARCH_TEXT_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: SEARCH_INVOICES_BY_SEARCH_TEXT_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function cancelInvoiceForUser(
    invoiceId: number,
    userId: number,
    invoiceItemIds: number[]
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CANCEL_INVOICE });
        try {
            const { data } = await RestClient.post(
                `/v1/callcenter/invoices/${invoiceId}/cancel`,
                { invoice_item_ids: invoiceItemIds }
            );
            dispatch({ type: CANCEL_INVOICE_SUCCESS, payload: { data } });
            await dispatch(loadUserDetails(userId));
            await dispatch(searchUserById(userId));
            await dispatch(loadInvoiceById(invoiceId));
        } catch (error) {
            dispatch({
                type: CANCEL_INVOICE_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function resetCancelInvoicePerformedAction() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: RESET_CANCEL_INVOICE_PERFORMED_ACTION });
    };
}
