import React, { Fragment, useEffect, useState } from 'react';
import {
    UserExtendedInformation,
    UserParallelReservationsState,
} from '../../store/search-user/searchUserReducer';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Button, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikProps } from 'formik';
import { changeUserParallelReservations } from '../../store/manage-user/manageUserActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    data: {
        userId: number;
    };

    searchResultsById: UserExtendedInformation[];
    errorTags: WeWashApiErrorTag[];
    isPatching: boolean;
    setUserParallelReservations: (
        userId: number,
        state: UserParallelReservationsState,
        limit: number
    ) => void;
    close: () => void;
}

interface Data {
    userId: number;
    parallelReservationsState: UserParallelReservationsState;
    parallelReservationsLimit: string;
}

const TRANSLATIONS_FOR_PARALLEL_RESERVATIONS_STATE: {
    [key in UserParallelReservationsState]: string;
} = {
    [UserParallelReservationsState.RESTRICTED]:
        'Eingeschränkt (nur 1 Reservierung)',
    [UserParallelReservationsState.NORMAL]: 'Normal (bis zum Limit)',
    [UserParallelReservationsState.UNLIMITED]: 'Uneingeschränkt (kein Limit)',
};

export function translateParallelReservationsState(
    value: UserParallelReservationsState
): string {
    if (value == null) {
        return 'Daten konnten nicht geladen werden';
    }
    return TRANSLATIONS_FOR_PARALLEL_RESERVATIONS_STATE[value];
}

function ChangeUserParallelReservationsModal(props: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const information =
        props.searchResultsById
            .filter((it) => it.user_id === props.data.userId)
            .reduce((info) => info) || {};

    const initialValues: Data = {
        userId: props.data.userId,
        parallelReservationsState: information.parallel_reservations_state,
        parallelReservationsLimit: String(
            information.parallel_reservations_limit
        ),
    };

    useEffect(() => {
        if (patchTriggered && !props.isPatching) {
            if (props.errorTags.length > 0) {
                setPatchTriggered(false);
            } else {
                props.close();
            }
        }
    }, [patchTriggered, props.isPatching, props.errorTags, props.close]);

    return (
        <Fragment>
            <Modal.Header closeButton onHide={handleCancel}>
                <Modal.Title>Gleichzeitige Reservierungen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<Data>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className={'ml-3 mb-3 mr-3'}>
                    Die Anzahl der gleichzeitigen Reservierungen für Nutzer{' '}
                    <strong>{information.email}</strong> (#{props.data.userId})
                    anpassen. Das beschränkt die Anzahl der Reservierungen in
                    der Warteschlange und vor dem Start der Maschine.
                </Row>
                <Row className={'ml-3 mb-3 mr-3'}>
                    <Form.Group controlId={'parallelReservationsState'}>
                        <Form.Label>
                            Gleichzeitige Reservierungs Modus:
                        </Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={formProps.handleChange}
                            value={formProps.values.parallelReservationsState}
                        >
                            {Object.values(UserParallelReservationsState).map(
                                (value) => (
                                    <option key={value} value={value}>
                                        {
                                            TRANSLATIONS_FOR_PARALLEL_RESERVATIONS_STATE[
                                                value
                                            ]
                                        }
                                    </option>
                                )
                            )}
                        </Form.Control>
                        {formProps.values.parallelReservationsState ===
                            UserParallelReservationsState.RESTRICTED && (
                            <div>
                                <small>
                                    'Eingeschränkt' wird nach dem Abschluß einer
                                    bezahlten Reservierung automatisch auf
                                    'Normal' geändert.
                                </small>
                            </div>
                        )}
                    </Form.Group>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>
                                Limit (für Normal)
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type={'number'}
                            name={'parallelReservationsLimit'}
                            value={formProps.values.parallelReservationsLimit}
                            onChange={formProps.handleChange}
                            placeholder={'Limit'}
                        />
                    </InputGroup>
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Button
                        className={'mr-2'}
                        type="button"
                        onClick={handleCancel}
                        variant={'outline-secondary'}
                    >
                        ABBRECHEN
                    </Button>
                    <Button type={'submit'} disabled={patchTriggered}>
                        EINSTELLUNGEN ÜBERNEHMEN
                    </Button>
                </Row>
                <ErrorFeedback apiErrors={props.errorTags} />
            </Form>
        );
    }

    async function handleSubmit(values: Data): Promise<void> {
        props.setUserParallelReservations(
            values.userId,
            values.parallelReservationsState,
            Number(values.parallelReservationsLimit)
        );
        setPatchTriggered(true);
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({
        searchResultsById: store.searchUser.resultsById,
        errorTags: store.manageUser.patchErrors,
        isPatching: store.manageUser.patching,
    }),
    {
        close: closeModal,
        setUserParallelReservations: changeUserParallelReservations,
    }
)(ChangeUserParallelReservationsModal);
