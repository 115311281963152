import React from 'react';
import Table from 'react-bootstrap/Table';
import {
    HubState,
    UserAccountState,
    UserExtendedInformation,
    UserType,
} from '../../store/search-user/searchUserReducer';
import styles from './UserInformationTable.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobile, faPhone } from '@fortawesome/free-solid-svg-icons';
import { PaymentMethodStatus } from '../../store/manage-user/manageUserReducer';
import { LifecycleStatus } from '../../store/location/locationReducer';

interface Props {
    users: UserExtendedInformation[];
    onSelect: (information: UserExtendedInformation) => any;
    isAdmin: boolean;
    emailEditable?: boolean;
    onEmailUpdate?: (email: string) => void;
}

export function translatePaymentMethodStatus(status: PaymentMethodStatus) {
    switch (status) {
        case PaymentMethodStatus.EXPIRED:
            return 'Abgelaufen';
        case PaymentMethodStatus.VERIFIED:
        case PaymentMethodStatus.TRUSTED:
            return 'Verifiziert';
        case PaymentMethodStatus.INVALID:
            return 'Ungültig';
        case PaymentMethodStatus.TRIAL:
        case PaymentMethodStatus.UNVERIFIED:
            return 'In Verifizierung';
        case PaymentMethodStatus.EXPIRED_TRIAL:
            return 'Verifizierung abgelaufen';
        case PaymentMethodStatus.NEW:
            return 'Neu';
        default:
            return status;
    }
}

export function translateUserAccountState(status: UserAccountState) {
    switch (status) {
        case UserAccountState.ACTIVATING:
            return 'Aktivierungscode noch nicht eingegeben';
        case UserAccountState.ACTIVE:
            return 'Aktiv';
        case UserAccountState.RESET_PW:
            return 'Passwort reset angefordert';
        case UserAccountState.BLOCKED:
            return 'Gesperrt';
        case UserAccountState.DELETE:
            return 'Löschung beauftragt';
        default:
            return status;
    }
}

export function translateLocationLifecycleAndHubStatus(
    lifecycleStatus?: LifecycleStatus,
    hubState?: HubState
) {
    return LifecycleStatus.ACTIVE !== lifecycleStatus
        ? translateLifecycleStatus(lifecycleStatus)
        : translateHubStatus(hubState);
}

export function translateLifecycleStatus(status?: LifecycleStatus) {
    switch (status) {
        case LifecycleStatus.ACTIVE:
            return 'aktiv';
        case LifecycleStatus.DELETED:
            return 'gelöscht';
        case LifecycleStatus.DELETE_REQUESTED:
            return 'Löschung beantragt';
        default:
            return 'Unbekannt';
    }
}
function translateHubStatus(status?: HubState) {
    switch (status) {
        case HubState.UP:
            return 'Online';
        case HubState.CRITICAL:
            return 'Kritisch';
        case HubState.DOWN:
            return 'Offline';
        case HubState.INITIAL:
            return 'Neu';
        case HubState.WARNING:
            return 'Warnung';
        default:
            return 'Unbekannt';
    }
}

export function iconForType(type: UserType) {
    switch (type) {
        case UserType.APP:
            return (
                <FontAwesomeIcon size={'lg'} color="#00B3E6" icon={faMobile} />
            );
        case UserType.PHONE:
        default:
            return (
                <FontAwesomeIcon size={'lg'} color="#00B3E6" icon={faPhone} />
            );
    }
}

export function getPaymentMethodStatusClassName(
    paymentMethodStatus: PaymentMethodStatus
) {
    switch (paymentMethodStatus) {
        case PaymentMethodStatus.EXPIRED:
        case PaymentMethodStatus.INVALID:
        case PaymentMethodStatus.EXPIRED_TRIAL:
            return styles.alarm;
        case PaymentMethodStatus.TRIAL:
        case PaymentMethodStatus.UNVERIFIED:
        case PaymentMethodStatus.NEW:
            return styles.warning;
        case PaymentMethodStatus.VERIFIED:
        case PaymentMethodStatus.TRUSTED:
        default:
            return '';
    }
}

const UserInformationTable = (props: Props) => {
    function renderPhoneNumberAndName(userInfo: UserExtendedInformation) {
        if (userInfo.user_type === UserType.PHONE) {
            return (
                <td>
                    {userInfo.name} <br />
                    <i>{userInfo.phone_number}</i>
                </td>
            );
        } else {
            return <td>{userInfo.name}</td>;
        }
    }

    return (
        <Table bordered hover className={styles.table}>
            <thead>
                <tr className={styles.header}>
                    <th />
                    <th>
                        Name <br /> <i>Telefonnummer</i>
                    </th>
                    <th>
                        Email <br /> <i>Customer ID</i>
                    </th>
                    <th>Account Status</th>
                    <th>Schulden</th>
                    <th>Waschraum</th>
                </tr>
            </thead>
            <tbody className={styles.clickable}>
                {props.users.map((it) => {
                    return (
                        <tr
                            key={it.user_id}
                            onClick={() => props.onSelect(it)}
                            className={styles.body}
                        >
                            <td>{iconForType(it.user_type)}</td>
                            {renderPhoneNumberAndName(it)}
                            <td>
                                {it.email} <br />
                                <i>{it.customer_id}</i>
                            </td>
                            <td>
                                {translateUserAccountState(it.account_state)}
                            </td>
                            <td className={it.has_debt ? styles.alarm : ''}>
                                {it.b2b_user
                                    ? '--'
                                    : it.has_debt
                                    ? 'ja'
                                    : 'nein'}
                            </td>
                            <td>
                                {it.location.name}
                                <br />
                                {it.location.address}
                            </td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default UserInformationTable;
