import React, { ReactText } from 'react';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';

interface Props {
    locationId?: string;
    handleLaundryRoomSearch: (params: LaundryRoomParameters) => void;
    isLoading: boolean;
}

export interface LaundryRoomParameters {
    locationId: ReactText;
}

export function LaundryRoomSearchForm(props: Props) {
    const laundryRoomParams: LaundryRoomParameters = {
        locationId: props.locationId ? props.locationId : '',
    };

    return (
        <Formik
            onSubmit={props.handleLaundryRoomSearch}
            initialValues={laundryRoomParams}
            children={renderLaundryRoomSearchForm}
        />
    );

    function renderLaundryRoomSearchForm(
        formProps: FormikProps<LaundryRoomParameters>
    ) {
        const isLoading = props.isLoading;
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'locationId'}>
                    <Form.Label>Laundry Room ID</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={handleLaundryRoomChange}
                        value={String(formProps.values.locationId)}
                    />

                    <Button type={'submit'} disabled={isLoading}>
                        {isLoading ? 'Wird geladen...' : 'Karte ansehen'}
                    </Button>
                </Form.Group>
            </Form>
        );

        function handleLaundryRoomChange(event: React.ChangeEvent<any>) {
            formProps.handleChange(event);
        }
    }
}
