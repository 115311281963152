import React, { FunctionComponent } from 'react';
import Alert from 'react-bootstrap/Alert';
import { WeWashApiErrorTag } from '../http/errors';
import { FormikErrors } from 'formik';
import { PhoneUserRegistrationData } from '../types/phoneUserRegistrationTypes';

const GENERAL_FORM_ERRORS_MESSAGE = 'Das Formular enthält Fehler';

interface Props {
    apiErrors?: WeWashApiErrorTag[];
    formikErrors?: FormikErrors<PhoneUserRegistrationData>;
    additionalInfoConstructor?: (error: string) => JSX.Element;
}

const ErrorFeedback: FunctionComponent<Props> = ({
    apiErrors,
    formikErrors,
    additionalInfoConstructor,
}) => {
    let errors: string[] = [];

    if (apiErrors) {
        errors = apiErrors;
    } else if (formikErrors && Object.keys(formikErrors).length > 0) {
        errors = [GENERAL_FORM_ERRORS_MESSAGE];
    }

    function additionalInfo(tag: string) {
        if (additionalInfoConstructor) {
            return additionalInfoConstructor(tag);
        }
        return <></>;
    }

    return errors.length > 0 ? (
        <div>
            {errors.map((error) => (
                <Alert key={error} show={!!error} variant="danger">
                    {error}
                    <br />
                    {additionalInfo(error)}
                </Alert>
            ))}
        </div>
    ) : null;
};

export default ErrorFeedback;
