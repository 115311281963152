import { AnyAction } from 'redux-starter-kit';
import RestClient from '../../http/RestClient';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import { Route } from '../../Router';
import { goTo } from '../router/routerActions';
import { PhoneUserRegistrationData } from '../../types/phoneUserRegistrationTypes';

export const REGISTER = 'REGISTER';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_ERROR = 'REGISTER_ERROR';

function registerSuccessAction() {
    return { type: REGISTER_SUCCESS };
}

function registerErrorAction(error: WeWashApiError) {
    return {
        type: REGISTER_ERROR,
        payload: { errors: extractErrorTags(error) },
    };
}

export function registerAction(data: PhoneUserRegistrationData) {
    const registrationData = {
        ...data,
        first_name: data.firstName,
        last_name: data.lastName,
        lang: data.language,
        phone: data.phoneCountryCode + data.phoneNumber,
        country_id: data.country.valueOf(),
        selected_location_id: data.locationId,
        account_holder: data.accountHolder,
        email: data.email,
    };

    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REGISTER });
        try {
            await RestClient.post(
                '/v1/callcenter/register_user',
                registrationData
            );
            dispatch(registerSuccessAction());
            dispatch(goTo(Route.REGISTRATION_SUCCESS));
        } catch (error) {
            console.error('error', error);
            dispatch(registerErrorAction(error));
        }
    };
}
