import { Reservation } from './manageUserReducer';
import { createReducer } from 'redux-starter-kit';
import { RESET_VIEWS } from '../genericActions';
import {
    LOAD_RESERVATIONS_OF_USER,
    LOAD_RESERVATIONS_OF_USER_FAILURE,
    LOAD_RESERVATIONS_OF_USER_SUCCESS,
    ReservationsOfUser,
    UNLOCK_RESERVATION_OF_USER, UNLOCK_RESERVATION_OF_USER_FAILURE, UNLOCK_RESERVATION_OF_USER_SUCCESS
} from "./manageReservationsOfUserActions";
import { WeWashApiErrorTag } from '../../http/errors';
import { CommunicationState } from '../communicationState';

export interface UnlockActionForReservations {
    state: CommunicationState;
    errors: WeWashApiErrorTag[];
    applianceId?: number;
    reservationId?: number|null;
    data?: { duration_millis: number };
}

export interface ManageReservationsOfUserStore {
    loading: boolean;
    errors: WeWashApiErrorTag[];
    userId?: number;
    reservations: Reservation[];
    unlockAction: UnlockActionForReservations;
}

const initialStore: ManageReservationsOfUserStore = {
    loading: false,
    errors: [],
    userId: undefined,
    reservations: [],
    unlockAction: {
        state: CommunicationState.INITIAL,
        errors: [],
    },
};

export const manageReservationsOfUserReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_RESERVATIONS_OF_USER]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_RESERVATIONS_OF_USER_SUCCESS]: (state, action) => {
        state.loading = false;
        const newList: ReservationsOfUser = action.payload.data;
        state.userId = newList.user_id;
        state.reservations = [...newList.reservations];
    },
    [LOAD_RESERVATIONS_OF_USER_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UNLOCK_RESERVATION_OF_USER]: (state, action) => {
        state.unlockAction.state = CommunicationState.LOADING;
        state.unlockAction.applianceId = action.payload.applianceId;
        state.unlockAction.reservationId = action.payload.reservationId;
    },
    [UNLOCK_RESERVATION_OF_USER_SUCCESS]: (state, action) => {
        state.unlockAction.state = CommunicationState.SUCCESS;
        state.unlockAction.data = action.payload;
    },
    [UNLOCK_RESERVATION_OF_USER_FAILURE]: (state, action) => {
        state.unlockAction.state = CommunicationState.FAILURE;
        state.unlockAction.errors = action.payload.errors;
    },
});
