import React, { FunctionComponent } from 'react';
import {
    closeModal,
    ModalParameters,
    openModal,
} from '../../store/modal/modalActions';
import DeletePaymentConfirmation from './DeletePaymentConfirmation';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import FailedPaymentSelectionInformation from './FailedPaymentSelectionInformation';
import FailedPaymentDeletionInformation from './FailedPaymentDeletionInformation';
import FailedPaymentVerificationInformation from './FailedPaymentVerificationInformation';
import ChangeLaundryRoom from '../manage-user/ChangeLaundryRoom';
import ChangeLaundryRoomWithList from '../manage-user/ChangeLaundryRoomWithList';
import ChangeInvoiceDelivery from '../manage-user/ChangeInvoiceDelivery';
import ChangeLocationMetaInformation from '../manage-laundry-room/ChangeLocationMetaInformation';
import UpdatePhoneUserEmailModal from './UpatePhoneUserEmailModal';
import PartnerManagerSendActivationEmailCard from '../manage-partner-manager/PartnerManagerSendActivationEmailCard';
import SelectLaundryRoomModal from './SelectLaundryRoomModal';
import ChangeMonitoringFlagInformation from '../manage-laundry-room/ChangeMonitoringFlagInformation';
import ChangeLocationVisibilityDistance from '../manage-laundry-room/ChangeLocationVisibilityDistance';
import SelectFeedbackModeQuestionModal from './SelectFeedbackModeQuestionModal';
import ChangeAllowPlainSepaMandateCreation from '../manage-user/ChangeAllowPlainSepaMandateCreation';
import SetPrepaymentForLocationModal from './SetPrepaymentForLocationModal';
import UpsertMaintenanceSchedule from '../../forms/manage-appliance/UpsertMaintenanceSchedule';
import SetSupportsScan2WashForLaundryRoomModal from './SetSupportsScan2WashForLaundryRoomModal';
import ChangeUserParallelReservationsModal from '../manage-user/ChangeUserParallelReservationsModal';

interface Props {
    modal: ModalParameters<any>;
    openModal: (params: ModalParameters<any>) => any;
    closeModal: () => any;
}

export enum ModalType {
    NONE = 'NONE',
    DELETE_PAYMENT_CONFIRMATION = 'DELETE_PAYMENT_CONFIRMATION',
    FAILED_PAYMENT_SELECTION_INFORMATION = 'FAILED_PAYMENT_SELECTION_INFORMATION',
    FAILED_PAYMENT_DELETION_INFORMATION = 'FAILED_PAYMENT_DELETION_INFORMATION',
    FAILED_PAYMENT_VERIFICATION_INFORMATION = 'FAILED_PAYMENT_VERIFICATION_INFORMATION',
    CHANGE_LAUNDRY_ROOM_ACTION = 'CHANGE_LAUNDRY_ROOM_ACTION',
    CHANGE_LAUNDRY_ROOM_WITH_LIST_ACTION = 'CHANGE_LAUNDRY_ROOM_WITH_LIST_ACTION',
    CHANGE_LOCATION_META_INFORMATION = 'CHANGE_LOCATION_META_INFORMATION',
    CHANGE_MONITORING_FLAG_INFORMATION = 'CHANGE_MONITORING_FLAG_META_INFORMATION',
    CHANGE_LOCATION_VISIBILITY_DISTANCE = 'CHANGE_LOCATION_VISIBILITY_DISTANCE',
    CHANGE_PHONE_USER_EMAIL = 'CHANGE_PHONE_USER_EMAIL',
    CHANGE_INVOICE_DELIVERY = 'CHANGE_INVOICE_DELIVERY',
    CHANGE_ALLOW_SEPA_MANDATE_CREATION = 'CHANGE_ALLOW_SEPA_MANDATE_CREATION',
    CHANGE_USER_PARALLEL_RESERVATIONS = 'CHANGE_USER_PARALLEL_RESERVATIONS',
    SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL',
    SELECT_LAUNDRY_ROOM = 'SELECT_LAUNDRY_ROOM',
    SELECT_FEEDBACK_MODE_QUESTION = 'SELECT_FEEDBACK_MODE_QUESTION',
    CHANGE_PREPAYMENT_ALLOWED = 'CHANGE_PREPAYMENT_ALLOWED',
    UPSERT_MAINTENANCE_SCHEDULE = 'UPSERT_MAINTENANCE_SCHEDULE',
    CHANGE_SCAN_2_WASH_SUPPORTED = 'CHANGE_SCAN_2_WASH_SUPPORTED',
}

const modalMapping = {
    [ModalType.NONE]: () => <span />,
    [ModalType.DELETE_PAYMENT_CONFIRMATION]: DeletePaymentConfirmation,
    [ModalType.FAILED_PAYMENT_SELECTION_INFORMATION]:
        FailedPaymentSelectionInformation,
    [ModalType.FAILED_PAYMENT_DELETION_INFORMATION]:
        FailedPaymentDeletionInformation,
    [ModalType.FAILED_PAYMENT_VERIFICATION_INFORMATION]:
        FailedPaymentVerificationInformation,
    [ModalType.CHANGE_LAUNDRY_ROOM_ACTION]: ChangeLaundryRoom,
    [ModalType.CHANGE_LOCATION_META_INFORMATION]: ChangeLocationMetaInformation,
    [ModalType.CHANGE_MONITORING_FLAG_INFORMATION]:
        ChangeMonitoringFlagInformation,
    [ModalType.CHANGE_LOCATION_VISIBILITY_DISTANCE]:
        ChangeLocationVisibilityDistance,
    [ModalType.CHANGE_LAUNDRY_ROOM_WITH_LIST_ACTION]: ChangeLaundryRoomWithList,
    [ModalType.CHANGE_PHONE_USER_EMAIL]: UpdatePhoneUserEmailModal,
    [ModalType.CHANGE_INVOICE_DELIVERY]: ChangeInvoiceDelivery,
    [ModalType.SEND_ACTIVATION_EMAIL]: PartnerManagerSendActivationEmailCard,
    [ModalType.CHANGE_ALLOW_SEPA_MANDATE_CREATION]:
        ChangeAllowPlainSepaMandateCreation,
    [ModalType.CHANGE_USER_PARALLEL_RESERVATIONS]:
        ChangeUserParallelReservationsModal,
    [ModalType.SELECT_LAUNDRY_ROOM]: SelectLaundryRoomModal,
    [ModalType.SELECT_FEEDBACK_MODE_QUESTION]: SelectFeedbackModeQuestionModal,
    [ModalType.CHANGE_PREPAYMENT_ALLOWED]: SetPrepaymentForLocationModal,
    [ModalType.UPSERT_MAINTENANCE_SCHEDULE]: UpsertMaintenanceSchedule,
    [ModalType.CHANGE_SCAN_2_WASH_SUPPORTED]:
        SetSupportsScan2WashForLaundryRoomModal,
};
const RootModal: FunctionComponent<Props> = ({
    modal,
    openModal,
    closeModal,
}) => {
    const show = modal.type !== ModalType.NONE;
    const ModalComponent = modalMapping[modal.type];

    return (
        <Modal show={show} onHide={closeModal}>
            <ModalComponent data={modal.data} />
        </Modal>
    );
};

export default connect((store: RootStore) => ({ modal: store.modal.modal }), {
    openModal,
    closeModal,
})(RootModal);
