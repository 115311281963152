import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikActions, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { CommunicationState } from '../../store/communicationState';
import {
    addLaundryRoomVipUsers,
    LaundryRoomForVipUsers,
    VipUserAddingReportDto,
    VipUserReport,
    VipUserResult,
} from '../../store/manage-vip-users/manageVipUsersActions';
import SuccessFeedback from '../../forms/SuccessFeedback';

interface Props {
    laundryRoom: LaundryRoomForVipUsers;
    updateStatus: CommunicationState;
    addVipReport?: VipUserAddingReportDto;

    addLaundryRoomVipUsers: (
        laundryRoomId: number,
        customerIdsOrEmails: string[]
    ) => void;
}

interface VipInputFields {
    customerIds: string;
}

interface AddReportForDisplay {
    successCount: number;
    alreadyExistingEntries: VipUserReport[];
    errorEntries: VipUserReport[];
}

function AddVipUsersForm(props: Props) {
    const [addingReport, setAddingReport] = useState<
        AddReportForDisplay | undefined
    >();
    useEffect(() => {
        setAddingReport(transformReport(props.addVipReport));
    }, [props.addVipReport]);

    const initialValues: VipInputFields = {
        customerIds: '',
    };

    return (
        <div>
            <Formik
                onSubmit={handleInputSubmit}
                initialValues={initialValues}
                children={renderForm}
            />

            {addingReport && renderReport(addingReport)}
        </div>
    );

    function renderForm(formProps: FormikProps<VipInputFields>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'customerIds'}>
                    <Form.Label>Customer IDs oder E-Mail Adressen</Form.Label>
                    <Form.Control
                        as={'textarea'}
                        rows={10}
                        onChange={formProps.handleChange}
                        value={formProps.values.customerIds}
                    />
                </Form.Group>
                <Button
                    type={'submit'}
                    disabled={
                        formProps.isSubmitting ||
                        props.updateStatus === CommunicationState.LOADING
                    }
                >
                    VIP Nutzer hinzufügen
                </Button>
            </Form>
        );
    }

    function handleInputSubmit(
        formValues: VipInputFields,
        formActions: FormikActions<VipInputFields>
    ) {
        const laundryRoomId = props.laundryRoom.id;
        const asList: string[] = formValues.customerIds
            .trim()
            .split(/[\s,]+/)
            .filter((it) => it);
        if (new Set(asList).size !== asList.length) {
            window.alert('Die Eingabe enthält Duplikate. Bitte prüfen.');
            formActions.setSubmitting(false);
            return;
        }

        const confirmationMessage = `Diesen ${
            asList.length
        } Nutzern VIP Status für Waschraum ID ${laundryRoomId} zuweisen?\n\n${asList.join(
            '\n'
        )}`;
        if (window.confirm(confirmationMessage)) {
            props.addLaundryRoomVipUsers(laundryRoomId, asList);
        }
        formActions.setSubmitting(false);
    }

    function renderReport(report: AddReportForDisplay) {
        return (
            <div>
                <br />
                <SuccessFeedback message={'Übermittelt'} />
                <h4>Als VIP hinzugefügt</h4>
                {report.successCount} erfolgreich zum VIP ernannt
                {maybeRenderAlreadyExists()}
                {maybeRenderError()}
                <br />
            </div>
        );

        function maybeRenderAlreadyExists() {
            if (report.alreadyExistingEntries.length === 0) {
                return null;
            }
            return (
                <>
                    <br />
                    {report.alreadyExistingEntries.length} waren bereits VIP:{' '}
                    {report.alreadyExistingEntries
                        .map((it) => it.email_or_customer_id)
                        .join(', ')}
                </>
            );
        }

        function maybeRenderError() {
            if (report.errorEntries.length === 0) {
                return null;
            }
            return (
                <>
                    <br />
                    {report.errorEntries.length} Fehler:
                    <ul>
                        {report.errorEntries.map((it) => (
                            <li
                                key={`entry-${encodeURIComponent(
                                    it.email_or_customer_id
                                )}`}
                            >
                                {it.email_or_customer_id}:{' '}
                                {translateAddResult(it.result)}
                            </li>
                        ))}
                    </ul>
                </>
            );
        }
    }
}

function translateAddResult(addResult: VipUserResult): string {
    switch (addResult) {
        case VipUserResult.OTHER_ERROR:
            return 'Unbekannter Fehler.';
        case VipUserResult.UNKNOWN_USER:
            return 'Customer ID / E-Mail-Adresse nicht gefunden.';
        case VipUserResult.SUCCESS:
        case VipUserResult.ALREADY_EXISTING:
            return 'Erfolgreich';
    }
    return addResult;
}

function transformReport(
    fullReport?: VipUserAddingReportDto
): AddReportForDisplay | undefined {
    if (!fullReport) {
        return undefined;
    }
    const result: AddReportForDisplay = {
        successCount: 0,
        alreadyExistingEntries: [],
        errorEntries: [],
    };

    fullReport.reports.forEach((it) => {
        if (it.result === VipUserResult.SUCCESS) {
            result.successCount++;
        } else if (it.result === VipUserResult.ALREADY_EXISTING) {
            result.alreadyExistingEntries.push(it);
        } else {
            result.errorEntries.push(it);
        }
    });

    return result;
}

export default connect(
    (store: RootStore) => ({
        updateStatus: store.manageVipUsers.addVipUsersStatus,
        addVipReport: store.manageVipUsers.addVipUsersReport,
    }),
    { addLaundryRoomVipUsers }
)(AddVipUsersForm);
