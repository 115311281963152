import React, { FunctionComponent, useEffect } from 'react';
import { Route } from '../../Router';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { goTo } from '../../store/router/routerActions';
import { LocationStore } from '../../store/location/locationReducer';
import { fetchLaundryRoom } from '../../store/location/locationActions';
import { useLocation } from 'react-router-dom';
import { laundryRoomIdReqParam } from '../../util/constants';
import ManageLaundryRoom from './ManageLaundryRoom';
import SearchBarComponent from '../common/SearchBarGeneric';

interface Props {
    goTo: (route: Route) => void;
    locationStore: LocationStore;
    onSearchForLaundryRoomId: (searchTerm: string) => any;
}

interface SearchLaundryRoomIdTerm {
    searchLaundryRoomIdTerm: string;
}

const SearchLaundryRoomById: FunctionComponent<Props> = (props) => {
    const location = useLocation();
    const laundryRoomId = new URLSearchParams(location.search).get(
        laundryRoomIdReqParam
    );

    useEffect(() => {
        if (laundryRoomId) {
            props.onSearchForLaundryRoomId(laundryRoomId);
        }
    }, [laundryRoomId]);

    return (
        <>
            <div id="SearchLaundryRoomById" className="p-1">
                <SearchBarComponent
                    errors={props.locationStore.errors}
                    onFormSubmit={props.onSearchForLaundryRoomId}
                    searchBarTitle="Waschraumsuche"
                    searchPlaceHolderText="Suche Waschraum mit ID"
                />
            </div>
            {props.locationStore.selectedLocation && <ManageLaundryRoom />}
        </>
    );
};

export default connect(
    (store: RootStore) => ({
        locationStore: store.location,
    }),
    { goTo, onSearchForLaundryRoomId: fetchLaundryRoom }
)(SearchLaundryRoomById);
