import React, { FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import Row from 'react-bootstrap/Row';
import { Route } from '../Router';
import { goTo } from '../store/router/routerActions';
import Alert from 'react-bootstrap/Alert';
import Checkmark from './checkmark.png';
import styles from './RegistrationSuccess.module.css';

interface Props {
    goTo: (route: Route) => void;
}

const RegistrationSuccess: FunctionComponent<Props> = (props) => {
    function goToRegistrationForm() {
        props.goTo(Route.REGISTRATION_FORM);
    }

    return (
        <Row className="justify-content-center" id="RegistrationSuccess">
            <Alert variant="light">
                <Alert.Heading>
                    Registrierung erfolgreich
                    <img
                        alt="checkmark"
                        src={Checkmark}
                        className={styles.checkmark}
                    />
                </Alert.Heading>
                <p className="mt-3">
                    Der Telefonnutzer wurde erfolgreich im WeWash System
                    registriert.
                </p>
                <Button className="mt-4" onClick={goToRegistrationForm}>
                    Weiteren Nutzer anlegen
                </Button>
            </Alert>
        </Row>
    );
};

export default connect(null, { goTo })(RegistrationSuccess);
