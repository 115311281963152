import { CreditNoteData } from '../../store/manage-credit-notes/manageCreditNotesActions';
import { Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import styles from '../manage-mansion/SearchMansionById.module.css';
import React, { MouseEvent } from 'react';
import { BASE_URL } from '../../http/RestClient';

interface Props {
    creditNotes: CreditNoteData[];
    onSelect?: (creditNoteId: number) => void;
    onDownloadCreditNoteXml?: (creditNoteNumber: string) => void;
}

export function CreditNoteTable(props: Props) {
    return (
        <Table bordered>
            <thead>
                <tr>
                    <th>Customer ID</th>
                    <th>Credit Note Number (Accounting Status)</th>
                    <th>Jahr/Monat</th>
                    <th>Land</th>
                    <th>Summe (Partner Anteil)</th>
                    <th>PDF</th>
                    <th>E-Mail</th>
                    <th>XML</th>
                </tr>
            </thead>
            <tbody>{props.creditNotes.map(renderEntry)}</tbody>
        </Table>
    );

    function renderEntry(creditNote: CreditNoteData) {
        return (
            <tr key={creditNote.credit_note_number}>
                <td onClick={handleEntrySelect}>
                    {creditNote.user_customer_id}
                </td>
                <td onClick={handleEntrySelect}>{showCreditNoteNumber()}</td>
                <td onClick={handleEntrySelect}>{`${creditNote.year}/${String(
                    creditNote.month
                ).padStart(2, '0')}`}</td>
                <td onClick={handleEntrySelect}>{`${creditNote.country}`}</td>
                <td
                    onClick={handleEntrySelect}
                >{`${creditNote.total_gross.toFixed(2)} (${
                    creditNote.partner_gross
                })`}</td>
                <td>
                    {creditNote.pdf_status}
                    {creditNote.download_token && (
                        <a href={getHref()} target={'_blank'}>
                            <FontAwesomeIcon
                                icon={faDownload}
                                className={styles.icon}
                            />
                        </a>
                    )}
                </td>
                <td>{creditNote.email_status}</td>
                <td>
                    {creditNote.xml_status}
                    {creditNote.xml_status === 'SUCCESS' && (
                        <a
                            href={'#'}
                            target={'_blank'}
                            onClick={handleDownloadDatev}
                        >
                            <FontAwesomeIcon
                                icon={faDownload}
                                className={styles.icon}
                            />
                        </a>
                    )}
                </td>
            </tr>
        );

        function showCreditNoteNumber() {
            const isInternalCreditNote: boolean =
                creditNote.accounting_status === 'INTERNAL';
            if (isInternalCreditNote) {
                return `Wird generiert (${creditNote.accounting_status})`;
            }

            if (creditNote.accounting_status === 'CORRECTED') {
                return `${creditNote.credit_note_number} (${creditNote.accounting_status})`;
            }
            return `${creditNote.credit_note_number}`;
        }

        function handleEntrySelect() {
            props.onSelect && props.onSelect(creditNote.credit_note_id);
        }

        function getHref() {
            return `${BASE_URL}/v1/turnover/${creditNote.credit_note_number}/pdf?downloadToken=${creditNote.download_token}`;
        }

        function handleDownloadDatev(event: MouseEvent) {
            event.preventDefault();

            if (!creditNote.credit_note_number) {
                return;
            }
            props.onDownloadCreditNoteXml &&
                props.onDownloadCreditNoteXml(creditNote.credit_note_number);
        }
    }
}
