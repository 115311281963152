import { createReducer } from 'redux-starter-kit';
import {
    CLEAR_MODAL_RESPONSE,
    CLOSE_MODAL,
    ModalParameters,
    OPEN_MODAL,
} from './modalActions';
import { ModalType } from '../../components/modal/RootModal';

export interface ModalStore {
    modal: ModalParameters<any>;
    response: ModalParameters<any>;
}

const initialStore: ModalStore = {
    modal: {
        type: ModalType.NONE,
        data: null,
    },
    response: {
        type: ModalType.NONE,
        data: undefined,
    },
};

export const modalReducer = createReducer(initialStore, {
    [OPEN_MODAL]: (state, action) => {
        state.modal = action.payload.params;
    },
    [CLOSE_MODAL]: (state, action) => {
        state.modal = initialStore.modal;
        if (action.payload && action.payload.params) {
            state.response = action.payload.params;
        } else {
            state.response = initialStore.response;
        }
    },
    [CLEAR_MODAL_RESPONSE]: (state) => {
        state.response = initialStore.response;
    },
});
