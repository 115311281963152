import {createReducer} from 'redux-starter-kit';
import {RESET_VIEWS} from '../genericActions';
import {
    Invoice,
    InvoicesOfUser,
    LOAD_INVOICES_OF_USER,
    LOAD_INVOICES_OF_USER_FAILURE,
    LOAD_INVOICES_OF_USER_SUCCESS,
} from './manageInvoicesOfUserActions';
import {WeWashApiErrorTag} from '../../http/errors';

export interface ManageInvoicesOfUserStore {
    loading: boolean;
    errors: WeWashApiErrorTag[];
    userId?: number;
    invoices: Invoice[];
}

const initialStore: ManageInvoicesOfUserStore = {
    loading: false,
    errors: [],
    userId: undefined,
    invoices: [],
};

export const manageInvoicesOfUserReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_INVOICES_OF_USER]: (state) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_INVOICES_OF_USER_SUCCESS]: (state, action) => {
        state.loading = false;
        const newList: InvoicesOfUser = action.payload.data;
        state.userId = newList.user_id;
        state.invoices = [...newList.invoices];
    },
    [LOAD_INVOICES_OF_USER_FAILURE]: (state, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
});
