import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootStore } from '../../store/rootStore';
import { loadLaundryRoomForVipScreen } from '../../store/manage-vip-users/manageVipUsersActions';
import { ManageVipUsersStore } from '../../store/manage-vip-users/manageVipUsersReducer';
import { LAUNDRY_ROOM_VIP_SEARCH_QUERY } from '../../util/constants';
import { useUrlSearchParam } from '../common/useUrlSearchParam';
import { CommunicationState } from '../../store/communicationState';
import ManageVipUsersDisplay from './ManageVipUsersDisplay';
import {
    clearModalResponse,
    ModalParameters,
    openModal,
} from '../../store/modal/modalActions';
import { SelectLaundryRoomModalParams } from '../modal/SelectLaundryRoomModal';
import Button from 'react-bootstrap/Button';
import { ModalType } from '../modal/RootModal';
import SearchBarGeneric from '../common/SearchBarGeneric';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Props {
    loadLaundryRoomForVipScreen: (laundryRoomId: number) => void;
    manageVipUsers: ManageVipUsersStore;
    modalResult: ModalParameters<any>;

    openModal: (params: ModalParameters<SelectLaundryRoomModalParams>) => void;
    clearModalResponse: () => void;
}

function ManageVipUsers({ loadLaundryRoomForVipScreen, ...props }: Props) {
    const urlParam = useUrlSearchParam({ name: LAUNDRY_ROOM_VIP_SEARCH_QUERY });
    const laundryRoomId = Number(urlParam.currentValue);
    const laundryRoomIdFromRequest =
        props.manageVipUsers.laundryRoom && props.manageVipUsers.laundryRoom.id;
    const { getLaundryRoomStatus } = props.manageVipUsers;

    useEffect(() => {
        if (
            ![CommunicationState.INITIAL, CommunicationState.SUCCESS].includes(
                getLaundryRoomStatus
            )
        ) {
            return;
        }
        if (laundryRoomId && laundryRoomIdFromRequest !== laundryRoomId) {
            loadLaundryRoomForVipScreen(laundryRoomId);
        }
    }, [
        laundryRoomId,
        laundryRoomIdFromRequest,
        getLaundryRoomStatus,
        loadLaundryRoomForVipScreen,
    ]);

    useEffect(() => {
        if (
            props.modalResult.type !== ModalType.SELECT_LAUNDRY_ROOM ||
            !props.modalResult.data
        ) {
            return;
        }
        const response =
            props.modalResult as ModalParameters<SelectLaundryRoomModalParams>;
        if (response.data && response.data.laundryRoomId) {
            handleSelect(response.data.laundryRoomId);
            props.clearModalResponse();
        }
    }, [props.modalResult, props.clearModalResponse]);

    return (
        <div>
            <SearchBarGeneric
                searchBarTitle={'VIP Nutzer Konfiguration - Waschraumsuche'}
                searchPlaceHolderText={'Waschraum ID'}
                errors={props.manageVipUsers.errors}
                onFormSubmit={handleSubmit}
                prefilledText={String(laundryRoomId || '')}
            />
            <Row>
                <Col xs={3} />
                <Col xs={6}>
                    <Button
                        onClick={handleOpenModalSearch}
                        variant={'outline-primary'}
                    >
                        Waschraum aus Liste wählen
                    </Button>
                </Col>
            </Row>
            {props.manageVipUsers.laundryRoom && (
                <ManageVipUsersDisplay
                    laundryRoom={props.manageVipUsers.laundryRoom}
                />
            )}
        </div>
    );

    function handleOpenModalSearch() {
        props.openModal({
            type: ModalType.SELECT_LAUNDRY_ROOM,
            data: { laundryRoomId },
        });
    }

    function handleSubmit(search: string) {
        const value = Number(search);

        if (!Number.isNaN(value)) {
            handleSelect(value);
        }
    }

    function handleSelect(laundryRoomId: number) {
        loadLaundryRoomForVipScreen(laundryRoomId);
        urlParam.updateValue(String(laundryRoomId));
    }
}

export default connect(
    (store: RootStore) => ({
        manageVipUsers: store.manageVipUsers,
        modalResult: store.modal.response,
    }),
    { loadLaundryRoomForVipScreen, openModal, clearModalResponse }
)(ManageVipUsers);
