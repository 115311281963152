import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchMansionById.module.css';
import { searchMansion } from '../../store/manage-mansion/manageMansionActions';
import {
    ManageMansionStore,
    Mansion,
} from '../../store/manage-mansion/manageMansionReducer';
import Table from 'react-bootstrap/Table';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';

export interface SearchTerm {
    searchTerm: string;
}

interface Props {
    manageMansion: ManageMansionStore;
    searchMansion: (searchTerm: number) => any;
    goTo: (route: Route) => void;
}

const SearchMansionById: FunctionComponent<Props> = (props) => {
    const initialValues: SearchTerm = { searchTerm: '' };

    async function handleFormSubmit(
        values: SearchTerm,
        actions: FormikActions<SearchTerm>
    ) {
        await props.searchMansion(Number(values.searchTerm));
        actions.setSubmitting(false);
    }

    function handleSelect() {
        props.goTo(Route.MANAGE_MANSION);
    }

    function renderMansionTable(mansion: Mansion) {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Objektadresse</th>
                        <th>Vor-/Nachname</th>
                        <th>Firma</th>
                    </tr>
                </thead>
                <tbody>
                    <tr onClick={handleSelect}>
                        <td>{mansion.address}</td>
                        <td>{mansion.partner.name}</td>
                        <td>{mansion.partner.company_name}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    return (
        <div id="SearchMansionId" className="p-1">
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {(formProps: FormikProps<SearchTerm>) => (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <ErrorFeedback
                                apiErrors={
                                    props.manageMansion.searchMansionErrors
                                }
                            />

                            <Form.Group
                                controlId="formGroupSearch"
                                className={styles.searchMansionIdBar}
                            >
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        name="searchTerm"
                                        className={styles.input}
                                        value={formProps.values.searchTerm}
                                        placeholder={
                                            'Objektsuche mit Mansion ID'
                                        }
                                        onChange={formProps.handleChange}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-dark"
                                            type="submit"
                                            disabled={
                                                formProps.isSubmitting ||
                                                formProps.values.searchTerm
                                                    .length === 0
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className={styles.icon}
                                            />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <div className="mt-5">
                                {props.manageMansion.mansion &&
                                    renderMansionTable(
                                        props.manageMansion.mansion
                                    )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        manageMansion: store.manageMansion,
    }),
    { searchMansion, goTo }
)(SearchMansionById);
