import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { RootStore } from '../../store/rootStore';
import { ManagePartnerManagerStore } from '../../store/manage-partner-manager/managePartnerManagerReducer';
import { searchB2BUser } from '../../store/manage-partner-manager/managePartnerManagerActions';
import B2BUserDisplay from './PartnerManagerDisplay';
import { CommunicationState } from '../../store/communicationState';
import SearchBarGeneric from '../common/SearchBarGeneric';
import { URL_SEARCH_QUERY } from '../../util/constants';
import { goTo } from '../../store/router/routerActions';
import { Route } from '../../Router';

interface Props {
    partnerManagerStore: ManagePartnerManagerStore;
    searchB2BUser: (searchStr: string) => void;
    goTo: (route: Route, param?: string | number) => void;
}

function ManageB2BUser({ searchB2BUser, ...props }: Props) {
    const location = useLocation();
    const history = useHistory();
    const b2BUserId = new URLSearchParams(location.search).get(
        URL_SEARCH_QUERY
    );

    const b2bUser = props.partnerManagerStore.partnerManager;
    const loadingState = props.partnerManagerStore.fetchState;

    useEffect(() => {
        if (!b2BUserId) {
            return;
        }
        if (loadingState !== CommunicationState.INITIAL) {
            return;
        }
        searchB2BUser(b2BUserId);
    }, [b2BUserId, loadingState, searchB2BUser]);

    useEffect(() => {
        if (loadingState !== CommunicationState.SUCCESS) {
            return;
        }
        if (b2bUser && Number(b2BUserId) !== b2bUser.id) {
            const searchParams = new URLSearchParams(location.search);
            searchParams.set(URL_SEARCH_QUERY, String(b2bUser.id));
            history.replace({
                ...location,
                search: '?' + searchParams.toString(),
            });
        }
    }, [history, loadingState, location, b2bUser, b2BUserId]);

    return (
        <div>
            <SearchBarGeneric
                searchBarTitle={'B2B Benutzer Suche'}
                searchPlaceHolderText={
                    'Salesforce: Partner Board Kontakt ID, E-Mail-Adresse oder Salesforce ID'
                }
                errors={props.partnerManagerStore.errors}
                onFormSubmit={handleSearch}
                prefilledText={b2BUserId}
            />

            {b2bUser && <B2BUserDisplay goTo={props.goTo} b2bUser={b2bUser} />}
        </div>
    );

    function handleSearch(searchStr: string) {
        searchB2BUser(searchStr);
    }
}

export default connect(
    (store: RootStore) => ({
        partnerManagerStore: store.managePartnerManager,
    }),
    { searchB2BUser, goTo }
)(ManageB2BUser);
