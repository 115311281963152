import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import { RESET_VIEWS } from '../genericActions';
import {
    HUBS_INSPECT,
    HUBS_INSPECT_FAILURE,
    HUBS_INSPECT_SUCCESS,
    HUBS_MARK_SHIPPED,
    HUBS_MARK_SHIPPED_FAILURE,
    HUBS_MARK_SHIPPED_SUCCESS,
    HUBS_START_INSTALLATION_REPORT,
    HUBS_START_INSTALLATION_REPORT_FAILURE,
    HUBS_START_INSTALLATION_REPORT_SUCCESS,
} from './manageHubsActions';

export enum RouterInstallationLifecycle {
    PRODUCED = 'PRODUCED',
    INSTALLATION = 'INSTALLATION',
    LIVE = 'LIVE',
}

export interface RouterInstallationReport {
    gateway_firmware?: string;
    switch_firmware?: string;
    default_antenna?: string;
    antenna_mode?: string;
    count_hub_state_history_down?: number;
    count_hub_state_history_warning?: number;
    count_hub_state_history_critical?: number;
    count_antenna_violations?: number;
    average_rssi?: number;
    overall_result: string;
    status: string;
    status_updated_time: number;
    due_time: number;
}

export interface RouterLiveData {
    serial_number: string;
    location_id?: number;
    ip_address: string;
    installation_lifecycle: RouterInstallationLifecycle;
    hub_state: string;
    go_live_time?: number;
    installation_report?: RouterInstallationReport;
}

export interface ManageHubsStore {
    router?: RouterLiveData;
    loading: boolean;
    hubRestErrors: WeWashApiErrorTag[];
}

const initialStore: ManageHubsStore = {
    loading: false,
    router: undefined,
    hubRestErrors: [],
};

export const manageHubsReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [HUBS_INSPECT]: (state: ManageHubsStore) => {
        state.loading = true;
        state.hubRestErrors = [];
    },
    [HUBS_INSPECT_SUCCESS]: (state: ManageHubsStore, action) => {
        state.router = action.payload.data;
        state.loading = false;
        state.hubRestErrors = [];
    },
    [HUBS_INSPECT_FAILURE]: (state: ManageHubsStore, action) => {
        state.loading = false;
        state.hubRestErrors = action.payload.errors;
    },
    [HUBS_MARK_SHIPPED]: (state: ManageHubsStore) => {
        state.loading = true;
        state.hubRestErrors = [];
    },
    [HUBS_MARK_SHIPPED_SUCCESS]: (state: ManageHubsStore, action) => {
        state.router = action.payload.data;
        state.loading = false;
        state.hubRestErrors = [];
    },
    [HUBS_MARK_SHIPPED_FAILURE]: (state: ManageHubsStore, action) => {
        state.loading = false;
        state.hubRestErrors = action.payload.errors;
    },
    [HUBS_START_INSTALLATION_REPORT]: (state: ManageHubsStore) => {
        state.loading = true;
        state.hubRestErrors = [];
    },
    [HUBS_START_INSTALLATION_REPORT_SUCCESS]: (
        state: ManageHubsStore,
        action
    ) => {
        state.router = action.payload.data;
        state.loading = false;
        state.hubRestErrors = [];
    },
    [HUBS_START_INSTALLATION_REPORT_FAILURE]: (
        state: ManageHubsStore,
        action
    ) => {
        state.loading = false;
        state.hubRestErrors = action.payload.errors;
    },
});
