import { AxiosError, AxiosResponse } from 'axios';

export interface WeWashApiError extends AxiosError {
    response: AxiosResponse<{
        message: string;
        tags: string | string[];
    }>;
}

export enum WeWashApiErrorTag {
    WRONG_CREDENTIALS = 'Ungültige Anmeldedaten',
    PAYMENT_METHOD_INVALID = 'Ungültige Zahlungsdaten',
    DUPLICATE_PAYMENT_METHOD = 'Bezahlmethode existiert bereits',
    MOLLIE_DIRECT_DEBIT_MANDATE_CREATION_FAILED = 'Etwas ist schiefgelaufen. Daten prüfen und erneut versuchen',
    PHONE_INVALID = 'Ungültige Telefonnummer',
    USER_ALREADY_CREATED = 'Telefonnummer bereits registriert',
    USER_PHONE_NUMBER_ALREADY_EXISTS = 'Telefonnummer bereits registriert',
    USER_EMAIL_ALREADY_EXISTS = 'Email bereits registriert',
    INVOICE_SENDING_MODE_INVALID = 'Der Nutzer kann die Rechnungszustellung nicht nutzen.',
    UNKNOWN_ERROR = 'Technisches Problem',
    PAYMENT_METHOD_ALREADY_BLACKLISTED = 'Bezahlmethode schon geblacklisted',
    IBAN_DOES_NOT_MATCH = 'IBAN stimmt nicht mit der IBAN der Bezahlmethode überein',
    PAYMENT_METHOD_DOES_NOT_EXIST = 'Bezahlmethode existiert nicht',
    PAYMENT_METHOD_NOT_FOUND = 'Could not find Payment Method',
    FINANCIAL_DOCUMENT_NOT_FOUND = 'Could not find Invoice/Debt Collection',
    MULTIPLE_FINANCIAL_DOCUMENTS_FOUND = 'Multiple Invoices/Debt Collections found',
    FINANCIAL_TRANSACTION_NOT_FOUND = 'Could not find Financial Transaction',
    ALL_PARAMETERS_EMPTY = 'All Search Parameters empty',
    INCONSISTENT_PARAMETERS = 'Search parameters dont match',
    FINANCIAL_TRANSACTION_NOT_ADMISSIBLE = 'Not allowed for current payment status',
    USER_DOESNT_EXIST = 'User does not exist',
    USER_DOESNT_EXIST_OR_ALREADY_ACTIVATED = 'User already activated, or they do not exist.',
    TOPUP_NOT_SUPPORTED_FOR_USER_ROLE = 'Top up not supported because of user role.',
    APPLIANCE_NOT_FOUND = 'Die Maschine existiert nicht.',
    LOCATION_DOES_NOT_EXIST = 'Der gesuchte Waschraum existiert nicht',
    QUESTION_NOT_ALLOWED = 'Der gesuchte Frage ist nicht Erlaubt',
    QUESTION_DOESNT_EXIST = 'Der gesuchte Frage ist nicht gefunden',
    PARTNER_DOESNT_EXIST = 'Kein Partner gefunden.',
    PARTNER_BOARD_MANAGER_NOT_FOUND = 'Partner Board Manager nicht gefunden',
    TOO_SMALL = 'Betrag zu gering.',
    WRONG_FILE_TYPE = 'Die Abrechnung muss ein PDF sein.',
    ID_INVALID = 'Ungültige Eingaben, möglicherweise existiert bereits eine Abrechnung für das Jahr und den Monat.',
    SERIALNUMBER_ALREADY_ASSIGNED = 'Seriennummer bereits zugewiesen',
    SERIALNUMBER_UNAVAILABLE = 'Seriennummer nicht verfügbar',
    SERIALNUMBER_NOT_FOUND = 'Seriennummer nicht gefunden',
    SERIALNUMBER_IN_USE = 'Seriennummer in Benutzung',
    SERIALNUMBER_UNASSIGNED = 'Seriennummer ist nicht zugewiesen',
    SERIALNUMBER_INVALID = 'Seriennummer ungültig',
    SERIALNUMBER_MISSING = 'Seriennummer fehlt',
    SERIALNUMBER_WRONG_INSTALLATION_LIFECYCLE = 'Seriennummer hat den falschen Installation Lifecycle.',
    MANSION_DOESNT_EXIST = 'Objekt existiert nicht',
    LOCATION_NOT_ADMISSIBLE = 'Waschraum nicht erlaubt',
    LOCATION_NOT_ADMISSIBLE_FOR_PHONE_USERS = 'Waschraum nicht erlaubt für Telefonnutzer',
    UNCONFIRMED_PRIVACY_POLICY = 'Aktuelle Privacy Policy nicht akzeptiert',
    UNCONFIRMED_TERMS_AND_CONDITIONS = 'Aktuelle Terms and Conditions / AGB nicht akzeptiert',
    RESERVATION_NOT_COMPLETED = 'Reservierung ist noch nicht beendet (COMPLETED).',
    INVOICE_ITEM_WRONG_STATUS = 'Invoice Item hat den falschen Status.',
    PAYMENT_SUPPRESSED = 'Payment Suppressed für die gewählte Zahlungsmethode.',
    TECHNICAL_PAYMENT_ERROR = 'Momentan ist die Zahlung aufgrund eines technischen Fehlers unterdrück.',
    OPEN_DEBT = 'Der Nutzer hat unbezahlte Rechnungen / Buchungen.',
    INSTALLATION_VERIFICATION_NOT_FOUND = 'Der Verifizierungszyklus ist schon abgeschlossen.',
    INSTALLATION_VERIFICATION_NOT_FINISHED = 'Der vorige Verifizierungszyklus ist noch nicht abgeschlossen.',
    INSTALLATION_VERIFICATION_CYCLE_ALREADY_STARTED = 'Abbrechen nicht mehr möglich. Die Machine wurde bereits gestarted.',
    INSTALLATION_VERIFICATION_INVALID_LOADING_TIME = 'Ungültige Beladungszeit.',
    INSTALLATION_VERIFICATION_WRONG_OPERATIONAL_STATE = 'Nicht möglich während anderer Wartungsoperationen.',
    BLIND_MAINTENANCE_APPLIANCE_ENABLED = 'Die Machine muss deaktiviert sein und es dürfen keine anderen Wartungsoperationen ausgeführt werden.',
    BLIND_MAINTENANCE_WRONG_OPERATIONAL_STATE = 'Die Machine muss deaktiviert sein und es dürfen keine anderen Wartungsoperationen ausgeführt werden.',
    BLIND_MAINTENANCE_INVALID_DURATION = 'Ungültige Dauer.',
    BLIND_MAINTENANCE_NOT_FOUND = 'Die blinde Wartung ist schon abgeschlossen.',
    BLIND_MAINTENANCE_AWAITING_APPLIANCE_FINISHED = 'Wartungszeitraum ist schon abgelaufen. Ende des Zyklus muss noch abgewartet werden.',
    BLIND_MAINTENANCE_ALREADY_STARTED = 'Blinde Wartung hat schon begonnen. Startzeit kann nicht mehr geändert werden.',
    BLIND_MAINTENANCE_ALREADY_ENDING = 'Blinde Wartung endet gerade. Endzeit kann nicht mehr geändert werden.',
    BLIND_MAINTENANCE_END_BEFORE_START = 'Endzeit darf nicht vor Startzeit liegen.',
    BLIND_MAINTENANCE_LINT_FILTER_INVALID_FOR_WASHER = 'Tausch des Flusensiebs ist nur für Trockner möglich.',
    MAINTENANCE_SCHEDULE_NOT_FOUND = 'Wartungszeitplan nicht gefunden.',
    USER_MARKED_FOR_DELETION_NOT_FOUND = 'Dieser Nutzer oder die Löschanfrage existiert nicht.',
    INVOICE_ITEM_ALREADY_IN_REFUND_STATUS = 'Die Reservierung wurde bereits erstattet.',
    USER_TYPE_DOES_NOT_SUPPORT_FOR_REFUND = 'Usertyp wird nicht unterstützt',
    CYCLE_TYPE_OR_LOCATION_INADMISSABLE = 'Entweder war die Reservierung im Simulator oder der Zyklustyp war nicht kommerziell.',
    RESERVATION_NOT_IN_A_REFUNDABLE_STATUS = 'Die Reservierung kann nicht erstattet werden.',
    CLIENT_INSTALLATION_APPLIANCE_IS_HUB = 'Die Appliance ist an einem Hub angeschlossen. Sie benötigt keine Client Installation.',
    CLIENT_INSTALLATION_ONGOING = 'Eine vorherige Client Installation ist noch nicht abgeschlossen.',
    CLIENT_RECONNECTION_NO_HUB = 'Der Waschraum hat keinen Hub.',
    CLIENT_RECONNECTION_ONGOING = 'Eine vorherige Client Reconnection ist noch nicht abgeschlossen.',
    CLIENT_RECONNECTION_WLP_ERROR = 'WLP Verbindungsfehler',
    IBAN_PREFIX_BLOCKED = 'IBANs von diesem Land werden nicht unterstützt.',
    INVOICE_STATUS_NOT_ELIGIBLE = 'Rechnung kann nicht storniert werden.',
    INVOICE_PAYMENT_STATUS_NOT_ELIGIBLE = 'Rechnung kann nicht storniert werden. Die Rechnung darf noch nicht bezahlt sein um eine Stornierung durchzuführen.',
    INVOICE_ACCOUNTING_STATUS_NOT_ELIGIBLE = 'Rechnung kann nicht mehr storniert werden. Die Rechnung ist in einem ungültigen Accounting-Zustand.',
    INVOICE_IS_NOT_PAY_PER_USE = 'Der Rechnungstyp passt nicht - es ist keine PayPerUse Rechnung.',
    INVOICE_ITEM_DOES_NOT_BELONG_TO_INVOICE = 'Die Rechnung passt nicht zu den ausgewählten Reservierungen.',
    DUPLICATE_INVOICE_ITEM_IDS = 'Die Rechnung passt nicht zu den ausgewählten Reservierungen.',
    INVOICE_ITEMS_LIST_EMPTY = 'Die Rechnung kann nur stoniert werden wenn mindestens eine Reservierung storniert wird.',
    INVOICE_CANCEL_NOT_ALLOWED_FOR_COUNTRY = 'Stornierung von Rechnungen nicht erlaubt für dieses Land',
    PRE_PAYMENT_NOT_ALLOWED_IN_COUNTRY = 'Pre-Payment ist für das Land des Waschraums nicht verfügbar.',
    LOCATION_QR_ID_NULL = 'Eine QR-ID darf nicht null sein.',
    LOCATION_QR_ID_EMPTY = 'Eine QR-ID darf nicht leer sein.',
    LOCATION_QR_ID_DUPLICATE = 'Die QR-ID wurde bereits im Waschraum mit der ID {id} verwendet.',
    SCAN_2_WASH_NOT_ALLOWED = 'Für diesen Waschraum kann Scan2Wash nicht eingeschaltet werden - evt. wird das Land nicht unterstützt.',
    WRONG_USER_TYPE = 'Nutztertyp kann dieses Feature nicht verwenden.',
}

const ErrorMessageToTag: { [key: string]: WeWashApiErrorTag } = {
    WRONG_CREDENTIALS: WeWashApiErrorTag.WRONG_CREDENTIALS,
    PAYMENT_METHOD_INVALID: WeWashApiErrorTag.PAYMENT_METHOD_INVALID,
    DUPLICATE_PAYMENT_METHOD: WeWashApiErrorTag.DUPLICATE_PAYMENT_METHOD,
    MOLLIE_DIRECT_DEBIT_MANDATE_CREATION_FAILED:
        WeWashApiErrorTag.MOLLIE_DIRECT_DEBIT_MANDATE_CREATION_FAILED,
    PHONE_INVALID: WeWashApiErrorTag.PHONE_INVALID,
    USER_ALREADY_CREATED: WeWashApiErrorTag.USER_ALREADY_CREATED,
    UNKNOWN_ERROR: WeWashApiErrorTag.UNKNOWN_ERROR,
    USER_PHONE_NUMBER_ALREADY_EXISTS:
        WeWashApiErrorTag.USER_PHONE_NUMBER_ALREADY_EXISTS,
    USER_EMAIL_ALREADY_EXISTS: WeWashApiErrorTag.USER_EMAIL_ALREADY_EXISTS,
    INVOICE_SENDING_MODE_INVALID:
        WeWashApiErrorTag.INVOICE_SENDING_MODE_INVALID,
    PAYMENT_METHOD_ALREADY_BLACKLISTED:
        WeWashApiErrorTag.PAYMENT_METHOD_ALREADY_BLACKLISTED,
    IBAN_DOES_NOT_MATCH: WeWashApiErrorTag.IBAN_DOES_NOT_MATCH,
    PAYMENT_METHOD_DOES_NOT_EXIST:
        WeWashApiErrorTag.PAYMENT_METHOD_DOES_NOT_EXIST,
    PAYMENT_METHOD_NOT_FOUND: WeWashApiErrorTag.PAYMENT_METHOD_NOT_FOUND,
    FINANCIAL_DOCUMENT_NOT_FOUND:
        WeWashApiErrorTag.FINANCIAL_DOCUMENT_NOT_FOUND,
    MULTIPLE_FINANCIAL_DOCUMENTS_FOUND:
        WeWashApiErrorTag.MULTIPLE_FINANCIAL_DOCUMENTS_FOUND,
    FINANCIAL_TRANSACTION_NOT_FOUND:
        WeWashApiErrorTag.FINANCIAL_TRANSACTION_NOT_FOUND,
    ALL_PARAMETERS_EMPTY: WeWashApiErrorTag.ALL_PARAMETERS_EMPTY,
    INCONSISTENT_PARAMETERS: WeWashApiErrorTag.INCONSISTENT_PARAMETERS,
    FINANCIAL_TRANSACTION_NOT_ADMISSIBLE:
        WeWashApiErrorTag.FINANCIAL_TRANSACTION_NOT_ADMISSIBLE,
    USER_DOESNT_EXIST: WeWashApiErrorTag.USER_DOESNT_EXIST,
    USER_DOESNT_EXIST_OR_ALREADY_ACTIVATED:
        WeWashApiErrorTag.USER_DOESNT_EXIST_OR_ALREADY_ACTIVATED,
    TOPUP_NOT_SUPPORTED_FOR_USER_ROLE:
        WeWashApiErrorTag.TOPUP_NOT_SUPPORTED_FOR_USER_ROLE,
    APPLIANCE_NOT_FOUND: WeWashApiErrorTag.APPLIANCE_NOT_FOUND,
    LOCATION_DOES_NOT_EXIST: WeWashApiErrorTag.LOCATION_DOES_NOT_EXIST,
    QUESTION_NOT_ALLOWED: WeWashApiErrorTag.QUESTION_NOT_ALLOWED,
    QUESTION_DOESNT_EXIST: WeWashApiErrorTag.QUESTION_DOESNT_EXIST,
    PARTNER_DOESNT_EXIST: WeWashApiErrorTag.PARTNER_DOESNT_EXIST,
    PARTNER_BOARD_MANAGER_NOT_FOUND:
        WeWashApiErrorTag.PARTNER_BOARD_MANAGER_NOT_FOUND,
    TOO_SMALL: WeWashApiErrorTag.TOO_SMALL,
    WRONG_FILE_TYPE: WeWashApiErrorTag.WRONG_FILE_TYPE,
    ID_INVALID: WeWashApiErrorTag.ID_INVALID,
    SERIALNUMBER_ALREADY_ASSIGNED:
        WeWashApiErrorTag.SERIALNUMBER_ALREADY_ASSIGNED,
    SERIALNUMBER_UNAVAILABLE: WeWashApiErrorTag.SERIALNUMBER_UNAVAILABLE,
    SERIALNUMBER_IN_USE: WeWashApiErrorTag.SERIALNUMBER_IN_USE,
    SERIALNUMBER_UNASSIGNED: WeWashApiErrorTag.SERIALNUMBER_UNASSIGNED,
    SERIALNUMBER_NOT_FOUND: WeWashApiErrorTag.SERIALNUMBER_NOT_FOUND,
    SERIALNUMBER_INVALID: WeWashApiErrorTag.SERIALNUMBER_INVALID,
    SERIALNUMBER_MISSING: WeWashApiErrorTag.SERIALNUMBER_MISSING,
    SERIALNUMBER_WRONG_INSTALLATION_LIFECYCLE:
        WeWashApiErrorTag.SERIALNUMBER_WRONG_INSTALLATION_LIFECYCLE,
    MANSION_DOESNT_EXIST: WeWashApiErrorTag.MANSION_DOESNT_EXIST,
    LOCATION_NOT_ADMISSIBLE: WeWashApiErrorTag.LOCATION_NOT_ADMISSIBLE,
    LOCATION_NOT_ADMISSIBLE_FOR_PHONE_USERS:
        WeWashApiErrorTag.LOCATION_NOT_ADMISSIBLE_FOR_PHONE_USERS,
    UNCONFIRMED_PRIVACY_POLICY: WeWashApiErrorTag.UNCONFIRMED_PRIVACY_POLICY,
    UNCONFIRMED_TERMS_AND_CONDITIONS:
        WeWashApiErrorTag.UNCONFIRMED_TERMS_AND_CONDITIONS,
    RESERVATION_NOT_COMPLETED: WeWashApiErrorTag.RESERVATION_NOT_COMPLETED,
    INVOICE_ITEM_WRONG_STATUS: WeWashApiErrorTag.INVOICE_ITEM_WRONG_STATUS,
    PAYMENT_SUPPRESSED: WeWashApiErrorTag.PAYMENT_SUPPRESSED,
    TECHNICAL_PAYMENT_ERROR: WeWashApiErrorTag.TECHNICAL_PAYMENT_ERROR,
    OPEN_DEBT: WeWashApiErrorTag.OPEN_DEBT,
    INSTALLATION_VERIFICATION_NOT_FOUND:
        WeWashApiErrorTag.INSTALLATION_VERIFICATION_NOT_FOUND,
    INSTALLATION_VERIFICATION_NOT_FINISHED:
        WeWashApiErrorTag.INSTALLATION_VERIFICATION_NOT_FINISHED,
    INSTALLATION_VERIFICATION_CYCLE_ALREADY_STARTED:
        WeWashApiErrorTag.INSTALLATION_VERIFICATION_CYCLE_ALREADY_STARTED,
    INSTALLATION_VERIFICATION_INVALID_LOADING_TIME:
        WeWashApiErrorTag.INSTALLATION_VERIFICATION_INVALID_LOADING_TIME,
    INSTALLATION_VERIFICATION_WRONG_OPERATIONAL_STATE:
        WeWashApiErrorTag.INSTALLATION_VERIFICATION_WRONG_OPERATIONAL_STATE,
    BLIND_MAINTENANCE_APPLIANCE_ENABLED:
        WeWashApiErrorTag.BLIND_MAINTENANCE_APPLIANCE_ENABLED,
    BLIND_MAINTENANCE_WRONG_OPERATIONAL_STATE:
        WeWashApiErrorTag.BLIND_MAINTENANCE_WRONG_OPERATIONAL_STATE,
    BLIND_MAINTENANCE_INVALID_DURATION:
        WeWashApiErrorTag.BLIND_MAINTENANCE_INVALID_DURATION,
    BLIND_MAINTENANCE_NOT_FOUND: WeWashApiErrorTag.BLIND_MAINTENANCE_NOT_FOUND,
    BLIND_MAINTENANCE_AWAITING_APPLIANCE_FINISHED:
        WeWashApiErrorTag.BLIND_MAINTENANCE_AWAITING_APPLIANCE_FINISHED,
    BLIND_MAINTENANCE_ALREADY_STARTED:
        WeWashApiErrorTag.BLIND_MAINTENANCE_ALREADY_STARTED,
    BLIND_MAINTENANCE_ALREADY_ENDING:
        WeWashApiErrorTag.BLIND_MAINTENANCE_ALREADY_ENDING,
    BLIND_MAINTENANCE_END_BEFORE_START:
        WeWashApiErrorTag.BLIND_MAINTENANCE_END_BEFORE_START,
    BLIND_MAINTENANCE_LINT_FILTER_INVALID_FOR_WASHER:
        WeWashApiErrorTag.BLIND_MAINTENANCE_LINT_FILTER_INVALID_FOR_WASHER,
    MAINTENANCE_SCHEDULE_NOT_FOUND:
        WeWashApiErrorTag.MAINTENANCE_SCHEDULE_NOT_FOUND,
    USER_MARKED_FOR_DELETION_NOT_FOUND:
        WeWashApiErrorTag.USER_MARKED_FOR_DELETION_NOT_FOUND,
    INVOICE_ITEM_ALREADY_IN_REFUND_STATUS:
        WeWashApiErrorTag.INVOICE_ITEM_ALREADY_IN_REFUND_STATUS,
    USER_TYPE_DOES_NOT_SUPPORT_FOR_REFUND:
        WeWashApiErrorTag.USER_TYPE_DOES_NOT_SUPPORT_FOR_REFUND,
    CYCLE_TYPE_OR_LOCATION_INADMISSABLE:
        WeWashApiErrorTag.CYCLE_TYPE_OR_LOCATION_INADMISSABLE,
    RESERVATION_NOT_IN_A_REFUNDABLE_STATUS:
        WeWashApiErrorTag.RESERVATION_NOT_IN_A_REFUNDABLE_STATUS,
    CLIENT_INSTALLATION_APPLIANCE_IS_HUB:
        WeWashApiErrorTag.CLIENT_INSTALLATION_APPLIANCE_IS_HUB,
    CLIENT_INSTALLATION_ONGOING: WeWashApiErrorTag.CLIENT_INSTALLATION_ONGOING,
    CLIENT_RECONNECTION_NO_HUB: WeWashApiErrorTag.CLIENT_RECONNECTION_NO_HUB,
    CLIENT_RECONNECTION_ONGOING: WeWashApiErrorTag.CLIENT_RECONNECTION_ONGOING,
    CLIENT_RECONNECTION_WLP_ERROR:
        WeWashApiErrorTag.CLIENT_RECONNECTION_WLP_ERROR,
    IBAN_PREFIX_BLOCKED: WeWashApiErrorTag.IBAN_PREFIX_BLOCKED,
    INVOICE_STATUS_NOT_ELIGIBLE: WeWashApiErrorTag.INVOICE_STATUS_NOT_ELIGIBLE,
    INVOICE_PAYMENT_STATUS_NOT_ELIGIBLE:
        WeWashApiErrorTag.INVOICE_PAYMENT_STATUS_NOT_ELIGIBLE,
    INVOICE_ACCOUNTING_STATUS_NOT_ELIGIBLE:
        WeWashApiErrorTag.INVOICE_ACCOUNTING_STATUS_NOT_ELIGIBLE,
    INVOICE_IS_NOT_PAY_PER_USE: WeWashApiErrorTag.INVOICE_IS_NOT_PAY_PER_USE,
    INVOICE_ITEM_DOES_NOT_BELONG_TO_INVOICE:
        WeWashApiErrorTag.INVOICE_ITEM_DOES_NOT_BELONG_TO_INVOICE,
    DUPLICATE_INVOICE_ITEM_IDS: WeWashApiErrorTag.DUPLICATE_INVOICE_ITEM_IDS,
    INVOICE_ITEMS_LIST_EMPTY: WeWashApiErrorTag.INVOICE_ITEMS_LIST_EMPTY,
    COUNTRY_NOT_ALLOWED:
        WeWashApiErrorTag.INVOICE_CANCEL_NOT_ALLOWED_FOR_COUNTRY,
    PRE_PAYMENT_NOT_ALLOWED_IN_COUNTRY:
        WeWashApiErrorTag.PRE_PAYMENT_NOT_ALLOWED_IN_COUNTRY,
    LOCATION_QR_ID_NULL: WeWashApiErrorTag.LOCATION_QR_ID_NULL,
    LOCATION_QR_ID_EMPTY: WeWashApiErrorTag.LOCATION_QR_ID_EMPTY,
    LOCATION_QR_ID_DUPLICATE: WeWashApiErrorTag.LOCATION_QR_ID_DUPLICATE,
    SCAN_2_WASH_NOT_ALLOWED: WeWashApiErrorTag.SCAN_2_WASH_NOT_ALLOWED,
    WRONG_USER_TYPE: WeWashApiErrorTag.WRONG_USER_TYPE,
};

export function extractErrorMessage(error: WeWashApiError): string | undefined {
    if (error.response && error.response.data && error.response.data.message) {
        return error.response.data.message;
    }
    return undefined;
}

export function extractErrorTags(error: WeWashApiError): WeWashApiErrorTag[] {
    if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.tags
    ) {
        let tagStrings: string | string[] = error.response.data.tags;

        if (!Array.isArray(tagStrings)) {
            tagStrings = [tagStrings];
        } else if (tagStrings.length < 1) {
            return [WeWashApiErrorTag.UNKNOWN_ERROR];
        }

        const tags = tagStrings.map(
            (tag) => ErrorMessageToTag[tag] || WeWashApiErrorTag.UNKNOWN_ERROR
        );

        return removeDuplicates(tags);
    } else {
        return [WeWashApiErrorTag.UNKNOWN_ERROR];
    }
}

function removeDuplicates(tags: WeWashApiErrorTag[]): WeWashApiErrorTag[] {
    return tags.filter((el, i, a) => i === a.indexOf(el));
}

export function getErrorTagFromMessage(errorMessage: string): string {
    return ErrorMessageToTag[errorMessage];
}
