import { AnyAction } from 'redux-starter-kit';
import { LoginCredentials } from '../../forms/login/LoginForm';
import RestClient from '../../http/RestClient';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import {
    extractErrorTags,
    WeWashApiError,
    WeWashApiErrorTag,
} from '../../http/errors';
import { User } from './userReducer';
import { goTo } from '../router/routerActions';
import { Route } from '../../Router';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const REFRESH_ERROR = 'REFRESH_ERROR';
export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'LOGOUT_ERROR';

export const ME_ENDPOINT = '/v1/users/me';
export const CC_AGENT_ROLE = 4;
export const ADMIN_ROLE = 1;

function loginSuccessAction(data: User) {
    return { type: LOGIN_SUCCESS, payload: { data } };
}

function loginErrorAction(error?: WeWashApiError) {
    const errorTags = error
        ? extractErrorTags(error)
        : [WeWashApiErrorTag.WRONG_CREDENTIALS];
    return { type: LOGIN_ERROR, payload: { errors: errorTags } };
}

function refreshErrorAction(error: WeWashApiError) {
    return {
        type: REFRESH_ERROR,
        payload: { errors: extractErrorTags(error) },
    };
}

export function loginAction(credentials: LoginCredentials) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOGIN });
        try {
            await RestClient.post('auth', credentials);
            const { data }: { data: User } = await RestClient.get<User>(
                ME_ENDPOINT
            );
            dispatch(loginSuccessAction(data));
        } catch (error) {
            console.error('error', error);
            dispatch(loginErrorAction(error));
        }
    };
}

export function refreshAuthAction() {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: LOGIN });
        try {
            const { data } = await RestClient.get<User>(ME_ENDPOINT);
            dispatch(loginSuccessAction(data));
        } catch (error) {
            if (error && error.response && error.response.status === 401) {
                dispatch(logoutAction());
            }
            dispatch(refreshErrorAction(error));
        }
    };
}

export function logoutAction() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOGOUT });
        try {
            await RestClient.post('/v1/users/me/logout');
            RestClient.get('dummy');
            dispatch({ type: LOGOUT_SUCCESS });
            dispatch(goTo(Route.LOGIN));
        } catch (error) {
            console.error('error', error);
            dispatch({
                type: LOGOUT_ERROR,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
