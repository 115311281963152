import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux-starter-kit';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from "../../http/errors";
import { Reservation } from './manageUserReducer';

export const LOAD_RESERVATIONS_OF_USER = 'LOAD_RESERVATIONS_OF_USER';
export const LOAD_RESERVATIONS_OF_USER_SUCCESS =
    'LOAD_RESERVATIONS_OF_USER_SUCCESS';
export const LOAD_RESERVATIONS_OF_USER_FAILURE =
    'LOAD_RESERVATIONS_OF_USER_FAILURE';

export const UNLOCK_RESERVATION_OF_USER = 'UNLOCK_RESERVATION_OF_USER';
export const UNLOCK_RESERVATION_OF_USER_SUCCESS =
    'UNLOCK_RESERVATION_OF_USER_SUCCESS';
export const UNLOCK_RESERVATION_OF_USER_FAILURE =
    'UNLOCK_RESERVATION_OF_USER_FAILURE';

export interface ReservationsOfUser {
    user_id: number;
    reservations: Reservation[];
}

export function loadReservationsOfUserById(userId: number, limit?: number) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        const limitStr = limit ? `?size=${limit}` : '';
        dispatch({ type: LOAD_RESERVATIONS_OF_USER, payload: { userId } });
        try {
            const { data } = await RestClient.get(
                `/v1/callcenter/user_reservations/${userId}${limitStr}`
            );
            dispatch({
                type: LOAD_RESERVATIONS_OF_USER_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LOAD_RESERVATIONS_OF_USER_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function unlockApplianceOfReservation(
  applianceId: number,
    reservationId: number|null
) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({
            type: UNLOCK_RESERVATION_OF_USER,
            payload: { applianceId, reservationId },
        });
        try {
            const { data } = await RestClient.post(
                `/v1/callcenter/appliances/${applianceId}/repower`
            );
            dispatch({
                type: UNLOCK_RESERVATION_OF_USER_SUCCESS,
                payload: data,
            });
        } catch (e) {
            dispatch({
                type: UNLOCK_RESERVATION_OF_USER_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}
