import { createReducer } from 'redux-starter-kit';
import {
    REGISTER_PAYMENT,
    REGISTER_PAYMENT_ERROR,
    REGISTER_PAYMENT_SUCCESS,
} from './paymentActions';
import { WeWashApiErrorTag } from '../../http/errors';
import { DirectDebitData } from '../../forms/payment/PaymentFormStandalone';
import { RESET_VIEWS } from '../genericActions';

export interface PaymentStore {
    lastCreated: Map<number, DirectDebitData>;
    errors: WeWashApiErrorTag[];
}

const initialStore: PaymentStore = {
    lastCreated: new Map(),
    errors: [],
};

export const paymentReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [REGISTER_PAYMENT]: (state: PaymentStore) => {
        state.errors = [];
    },
    [REGISTER_PAYMENT_SUCCESS]: (state: PaymentStore, action) => {
        const { data } = action.payload;
        state.lastCreated.set(data.userId, {
            iban: data.iban,
            accountHolder: data.accountHolder,
        });
        state.errors = [];
    },
    [REGISTER_PAYMENT_ERROR]: (state: PaymentStore, action) => {
        state.errors = action.payload.errors;
    },
});
