import React, { FunctionComponent } from 'react';
import * as yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form, FormControl } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import styles from '../manage-mansion/SearchMansionById.module.css';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    CreditNoteData,
    CreditNoteForUpload,
    uploadCreditNote,
} from '../../store/manage-credit-notes/manageCreditNotesActions';
import { WeWashApiErrorTag } from '../../http/errors';
import Alert from 'react-bootstrap/Alert';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    uploading: boolean;
    uploadSuccess: boolean;
    creditNote: CreditNoteData;
    uploadCreditNote: (creditNote: CreditNoteForUpload) => void;
}

interface UploadCreditNoteData {
    creditNoteNumber: string;
    resetEmailStatus: boolean;
    fileName: string;
    file?: Blob;
    partnerGross: string;
    upload: boolean;
}

const schema: yup.ObjectSchema<UploadCreditNoteData> = yup.object({
    creditNoteNumber: yup.string().required(),
    resetEmailStatus: yup.boolean().required(),
    fileName: yup.string().when('upload', {
        is: true,
        then: yup.string().required('Bitte eine Datei auswählen'),
        otherwise: yup.string().notRequired(),
    }),
    file: yup.mixed().notRequired(),
    partnerGross: yup.string().required(),
    upload: yup.boolean().required(),
});

const WARNING_COLOR = '#D2691E';

const STYLE_INACTIVE: React.CSSProperties = {
    background: '#eee',
};

const UploadCreditNoteForm: FunctionComponent<Props> = (props) => {
    const partnerGrossIsDifferentNow =
        props.creditNote.partner_gross !==
        props.creditNote.calculated_partner_gross;

    if (!props.creditNote.credit_note_number) {
        return <div>Credit Note Number erforderlich</div>;
    }

    const initialValues: UploadCreditNoteData = {
        creditNoteNumber: props.creditNote.credit_note_number,
        resetEmailStatus: false,
        fileName: '',
        file: new Blob([]),
        partnerGross: String(props.creditNote.partner_gross),
        upload: false,
    };
    return (
        <div id={'uploadCreditNoteForm'}>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                validationSchema={schema}
                children={renderForm}
            />
        </div>
    );

    function renderForm(formProps: FormikProps<UploadCreditNoteData>) {
        const waiting = formProps.isSubmitting || props.uploading;
        const fileSizeKB = formProps.values.file
            ? formProps.values.file.size / 1000
            : 0;
        const fileSizeWarning = fileSizeKB > 1000;
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'partnerGross'}>
                    <Form.Label>Partner Anteil/Partner Gross</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={formProps.handleChange}
                        value={formProps.values.partnerGross}
                    />
                    {partnerGrossIsDifferentNow && (
                        <Button
                            type={'button'}
                            variant={'outline-dark'}
                            size={'sm'}
                            onClick={() =>
                                formProps.setFieldValue(
                                    'partnerGross',
                                    props.creditNote.calculated_partner_gross.toFixed(
                                        2
                                    )
                                )
                            }
                        >
                            Auf berechneten Partner Anteil (
                            {props.creditNote.calculated_partner_gross.toFixed(
                                2
                            )}
                            ) korrigieren
                        </Button>
                    )}
                </Form.Group>
                <Form.Group controlId={'creditNoteNumber'}>
                    <Form.Label>Credit Note Number</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={formProps.values.creditNoteNumber}
                        disabled
                    />
                </Form.Group>
                <Form.Check
                    type={'radio'}
                    id={'recreate'}
                    name={'upload-group'}
                    label={'Dokumente neu erstellen'}
                    checked={!formProps.values.upload}
                    onChange={handleRecreateChange}
                />
                <Form.Check
                    type={'radio'}
                    id={'upload'}
                    name={'upload-group'}
                    label={'Eigenes Dokument hochladen'}
                    checked={formProps.values.upload}
                    onChange={handleUploadChange}
                />
                {renderUpload()}
                <Form.Group
                    controlId={'resetEmailStatus'}
                    className={'mb-3 mt-2'}
                >
                    <Form.Check
                        checked={formProps.values.resetEmailStatus}
                        onChange={formProps.handleChange}
                        label={
                            'E-Mail Status zurücksetzen (E-Mail erneut senden)'
                        }
                    />
                </Form.Group>

                <ErrorFeedback apiErrors={props.apiErrors} />
                <Button type={'submit'} disabled={waiting} size={'lg'}>
                    {waiting ? 'Bitte warten...' : 'Upload / Neu Erstellen'}
                </Button>
                {props.uploadSuccess && (
                    <Alert variant="success" className={'mt-2'}>
                        Upload erfolgreich / Neu Erstellung angestoßen
                    </Alert>
                )}
            </Form>
        );

        function renderUpload() {
            const active = formProps.values.upload;

            return (
                <div
                    className={'ml-5'}
                    style={active ? undefined : STYLE_INACTIVE}
                >
                    <Form.Group controlId={'fileName'}>
                        <Form.Label>Abrechnung</Form.Label>
                        <Form.Control
                            type={'file'}
                            value={formProps.values.fileName}
                            onChange={handleFileUpload}
                            accept={'.pdf, application/pdf'}
                            isInvalid={
                                formProps.touched.fileName &&
                                !!formProps.errors.fileName
                            }
                            isValid={
                                formProps.touched.fileName &&
                                !formProps.errors.fileName
                            }
                            disabled={!active}
                        />
                        <FormControl.Feedback type={'invalid'}>
                            {formProps.errors.fileName}
                        </FormControl.Feedback>
                        {fileSizeWarning && (
                            <div style={{ color: WARNING_COLOR }}>
                                <FontAwesomeIcon
                                    icon={faExclamationTriangle}
                                    className={styles.icon}
                                    color={WARNING_COLOR}
                                />
                                Große Datei ausgewählt! (Dateigröße:{' '}
                                {fileSizeKB.toFixed(0)}kB)
                            </div>
                        )}
                    </Form.Group>
                </div>
            );
        }

        function handleFileUpload(event: React.ChangeEvent<any>) {
            formProps.setFieldValue('file', event.target.files[0]);
            formProps.handleChange(event);
        }

        function handleUploadChange() {
            formProps.setFieldValue('upload', true);
        }
        function handleRecreateChange() {
            formProps.setFieldValue('upload', false);
        }
    }

    async function handleFormSubmit(
        values: UploadCreditNoteData,
        actions: FormikActions<UploadCreditNoteData>
    ) {
        const userId = (props.creditNote && props.creditNote.user_id) || '';
        actions.setSubmitting(true);
        try {
            const creditNoteForUpload: CreditNoteForUpload = {
                user_id: userId,
                credit_note_number: values.creditNoteNumber,
                reset_email_status: values.resetEmailStatus,
                file: values.upload ? values.file : undefined,
                partnerGross: values.partnerGross,
                upload: values.upload,
            };
            props.uploadCreditNote(creditNoteForUpload);
        } catch (e) {
            console.error('Credit Note Upload failed', e);
        } finally {
            actions.setSubmitting(false);
        }
    }
};
export default connect(
    (store: RootStore) => ({
        uploading: store.manageCreditNotes.uploading,
        uploadSuccess: store.manageCreditNotes.uploadSuccess,
        apiErrors: store.manageCreditNotes.errors,
    }),
    { uploadCreditNote }
)(UploadCreditNoteForm);
