import React from 'react';
import {
    flattenCardInfo,
    renderForLanguage,
    renderInternalLink,
    TransposedCardInfo,
    transposeToCardInfo,
} from './LaundryRoomCardForm';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import { LaundryRoomCardWriteDto } from '../../../store/manage-laundry-room/manageLaundryRoomActions';

interface Props {
    laundryRoomId: number;
    onCreateNewCard: (
        laundryRoomId: number,
        dto: LaundryRoomCardWriteDto
    ) => void;
    defaultLanguage: string;
}

export function AddNewLaundryRoomCard(props: Props) {
    const initialValues: LaundryRoomCardWriteDto = {
        laundry_room_id: props.laundryRoomId,
        title: {},
        description: {},
        button_text: {},
        link: {},
        internal_link: false,
    };
    const configuredLanguages: string[] = [props.defaultLanguage];

    const initialValuesFlattened = flattenCardInfo(
        initialValues,
        configuredLanguages
    );

    function renderButton() {
        return (
            <div style={{ marginBottom: 10 }}>
                <Button type="submit">Karte Hinzufügen</Button>
            </div>
        );
    }

    function handleCreateNewCardSubmit(values: TransposedCardInfo) {
        const writeDto: LaundryRoomCardWriteDto = transposeToCardInfo(
            values,
            configuredLanguages,
            initialValues
        );
        props.onCreateNewCard(props.laundryRoomId, writeDto);
    }

    return (
        <Formik
            onSubmit={handleCreateNewCardSubmit}
            initialValues={initialValuesFlattened}
            enableReinitialize={true}
        >
            {(formProps: FormikProps<TransposedCardInfo>) => {
                return (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        <div>Waschraum ID: {props.laundryRoomId}</div>
                        {renderInternalLink(formProps)}
                        {renderButton()}
                        {configuredLanguages.map((language) =>
                            renderForLanguage(language, formProps)
                        )}
                        {renderButton()}
                    </Form>
                );
            }}
        </Formik>
    );
}
