import React, {
    FunctionComponent,
    ReactNode,
    useEffect,
    useState,
} from 'react';
import {
    CcReservationActions,
    Reservation,
} from '../../store/manage-user/manageUserReducer';
import { Route } from '../../Router';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import { connect } from 'react-redux';
import { goTo } from '../../store/router/routerActions';
import { InvoiceItemStatusForRefund } from '../../store/refund-reservation/refundReservationReducer';
import { RootStore } from '../../store/rootStore';
import { UnlockActionForReservations } from '../../store/manage-user/manageReservationsOfUserReducer';
import { CommunicationState } from '../../store/communicationState';
import { Spinner } from 'react-bootstrap';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { unlockApplianceOfReservation } from '../../store/manage-user/manageReservationsOfUserActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

interface Props {
    userId: number;
    reservations: Reservation[];
    loading: boolean;
}

interface PropsWithFunctions extends Props {
    unlockAction: UnlockActionForReservations;
    goTo: (route: Route, param?: string | number) => void;
    unlockApplianceOfReservation: (
        applianceId: number,
        reservationId: number
    ) => void;
}

enum EntryType {
    RESERVATION,
    DATE,
}

interface ReservationEntry {
    type: EntryType;
    reservation?: Reservation;
    date?: string;
}

const UserReservationList: FunctionComponent<PropsWithFunctions> = ({
    goTo,
    ...props
}) => {
    const [entries, setEntries] = useState<ReservationEntry[]>([]);
    useEffect(() => {
        setEntries(generateList(props.reservations));
    }, [props.reservations]);

    return (
        <Table bordered striped>
            <thead>
                <tr>
                    <th>Datum</th>
                    <th>Id der Buchung</th>
                    <th>Kurzbezeichnung</th>
                    <th>Status der Buchung</th>
                </tr>
            </thead>
            <tbody>{entries.map(renderEntry)}</tbody>
        </Table>
    );

    function renderEntry(entry: ReservationEntry) {
        if (entry.type === EntryType.RESERVATION && entry.reservation) {
            return renderReservation(entry.reservation);
        } else if (entry.type === EntryType.DATE && entry.date) {
            return renderDateBorder(entry.date);
        } else {
            return null;
        }
    }

    function renderReservation(it: Reservation) {
        return (
            <tr key={it.reservation_id}>
                <td>
                    {moment(it.booking_time || it.queue_time).format(
                        'DD.MM.YYYY HH:mm:ss'
                    )}
                </td>
                <td>{it.reservation_id}</td>
                <td>{it.short_name}</td>
                <td>
                    {translateReservationStatus(it)} {renderActions(it)}
                </td>
            </tr>
        );
    }

    function renderDateBorder(date: string) {
        return (
            <tr key={`date-${date}`}>
                <td colSpan={4} align={'left'}>
                    {date}
                </td>
            </tr>
        );
    }

    function translateReservationStatus(reservation: Reservation) {
        switch (reservation.reservation_status) {
            case 'QUEUED':
                return 'Der Nutzer befindet sich in der Warteschlange für die Maschine.';
            case 'BOOKED':
                return (
                    <div>
                        Die Maschine ist für den Zeitraum eines Wasch- oder
                        Trockenvorgangs kostenpflichtig für den Nutzer gebucht.{' '}
                        {renderRefundSection(reservation)}
                    </div>
                );
            case 'RESERVED':
                return 'Die Maschine ist für den Nutzer reserviert und kann beladen und gestartet werden.';
            case 'RESERVATION_CANCELED':
                return 'Die Reservierung der Maschine wurde vom Nutzer noch vor dem Start der Maschine kostenfrei storniert.';
            case 'RESERVATION_TIMED_OUT':
                return 'Die Maschine wurde nicht innerhalb des vorgesehenen Zeitfensters vom Nutzer gestartet. Es sind keine Kosten für diese Reservierung entstanden.';
            case 'COMPLETED':
                return (
                    <div>
                        Der Wasch- oder Trockengang ist abgeschlossen.{' '}
                        {renderRefundSection(reservation)}
                    </div>
                );
            case 'FAILED':
                return (
                    <span>
                        Die Reservierung der Maschine hat nicht geklappt. Es
                        sind keine Kosten für diesen Versuch entstanden. Bitte
                        in der Waschraum Info überprüfen, ob eine Maschine im
                        Waschraum{' '}
                        <span style={{ color: 'red' }}>vorhanden</span> ist.
                        Sofern Maschinen vorhanden sind, den Kunden bitten
                        erneut zu reservieren.
                    </span>
                );
            case 'INITIAL':
                return '';
            case 'NOT_MY_LAUNDRY':
                return 'Der Kunde hat gemeldet, dass diese Reservierung nicht seine Wäsche enthält. Der WeWash Support wird die Anfrage schnellstmöglich bearbeiten.';
            case 'CANCEL_REQUESTED':
                return 'Der Kunde möchte die Reservierung stornieren. Es wird geprüft ob die Machine schon gestarted wurde.';
            case 'LOADING_PERIOD_EXPIRED':
                return 'Die Zeit zum Beladen der Machine ist abgelaufen. Es wird geprüft ob die Machine schon gestarted wurde.';
            default:
                return reservation.reservation_status;
        }
    }

    function renderRefundSection(reservation: Reservation): ReactNode {
        switch (reservation.invoice_item_status) {
            case InvoiceItemStatusForRefund.CANCELED:
                return 'Die Reservierung wurde erstattet und wird nicht in Rechnung gestellt.';
            case InvoiceItemStatusForRefund.REFUNDED:
                return 'Die Reservierung wurde erstattet. Der Betrag wurde als Credits gutgeschrieben.';
            case InvoiceItemStatusForRefund.INVOICED:
                return 'Die Reservierung wurde in Rechnung gestellt';
            case InvoiceItemStatusForRefund.NEW:
                return '';
        }
        return '';
    }

    function renderActions(reservation: Reservation) {
        return [
            reservation.available_actions.includes(
                CcReservationActions.REFUND_INVOICE_ITEM
            ) && renderRefundButton(reservation.reservation_id),
            ' ',
            reservation.available_actions.includes(
                CcReservationActions.UNLOCK_APPLIANCE
            ) && renderUnlockButton(reservation),
        ];
    }

    function renderRefundButton(reservationId: number) {
        return (
            <Button
                key={`refund_${reservationId}`}
                disabled={props.loading}
                onClick={() => goTo(Route.REFUND_RESERVATION, reservationId)}
            >
                ERSTATTUNG
            </Button>
        );
    }

    function renderUnlockButton(reservation: Reservation) {
        const isMyUnlockAction =
            props.unlockAction.reservationId === reservation.reservation_id;

        if (isMyUnlockAction) {
            switch (props.unlockAction.state) {
                case CommunicationState.LOADING:
                    return <Spinner animation={'border'} />;
                case CommunicationState.SUCCESS:
                    const minutes =
                        props.unlockAction.data &&
                        props.unlockAction.data.duration_millis
                            ? moment
                                  .duration(
                                      props.unlockAction.data.duration_millis
                                  )
                                  .asMinutes()
                            : '?';
                    return (
                        <strong>
                            <FontAwesomeIcon icon={faCheckCircle} />{' '}
                            {reservation.short_name} entsperrt für {minutes}{' '}
                            Minuten.
                        </strong>
                    );
                case CommunicationState.FAILURE:
                    return (
                        <>
                            {reservation.short_name} konnte nicht entsperrt
                            werden.{' '}
                            <ErrorFeedback
                                apiErrors={props.unlockAction.errors}
                            />
                        </>
                    );
            }
        }

        return (
            <Button
                key={`unlock_${reservation.reservation_id}`}
                disabled={props.loading}
                onClick={() => triggerUnlock(reservation)}
            >
                {reservation.short_name} ENTSPERREN
            </Button>
        );
    }

    function triggerUnlock(reservation: Reservation) {
        if (!reservation.appliance_id) {
            return;
        }
        if (
            window.confirm(
                `Strom einschalten? Maschine ${reservation.short_name} für kurze Zeit mit Strom versorgen damit der Kunde seine Wäsche aus der Maschine nehmen kann?`
            )
        ) {
            props.unlockApplianceOfReservation(
                reservation.appliance_id,
                reservation.reservation_id
            );
        }
    }
};

function generateList(reservations: Reservation[]) {
    let date = getDateString(Date.now());
    let newList: ReservationEntry[] = [];

    reservations.forEach((reservation) => {
        const t = reservation.booking_time || reservation.queue_time;
        const newDate = t ? getDateString(t) : date;
        if (newDate !== date && newDate) {
            date = newDate;
            newList.push({ type: EntryType.DATE, date });
        }
        newList.push({ type: EntryType.RESERVATION, reservation });
    });

    return newList;
}

function getDateString(timestamp: number): string {
    const date = new Date(timestamp);
    return `${transformMonth(date.getMonth())} ${date.getFullYear()}`;
}

function transformMonth(month: number): string {
    const monthNames = [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
    ];
    if (0 <= month && month < monthNames.length) {
        return monthNames[month];
    } else {
        return String(month + 1);
    }
}

export default connect(
    (state: RootStore) => ({
        unlockAction: state.manageReservationsOfUser.unlockAction,
    }),
    { goTo, unlockApplianceOfReservation }
)(UserReservationList);
