import { extractErrorTags, WeWashApiError } from '../../http/errors';
import RestClient from '../../http/RestClient';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux-starter-kit';
import { AddressDto } from '../manage-appliances/manageApplianceReducer';
import { Currency } from '../amountAndCurrency';

export const LAUNDRY_ROOM_FOR_VIP_FETCH = 'LAUNDRY_ROOM_FOR_VIP_FETCH';
export const LAUNDRY_ROOM_FOR_VIP_FETCH_SUCCESS =
    'LAUNDRY_ROOM_FOR_VIP_FETCH_SUCCESS';
export const LAUNDRY_ROOM_FOR_VIP_FETCH_FAILURE =
    'LAUNDRY_ROOM_FOR_VIP_FETCH_FAILURE';

export const LAUNDRY_ROOM_FOR_VIP_UPDATE_COST =
    'LAUNDRY_ROOM_FOR_VIP_UPDATE_COST';
export const LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_SUCCESS =
    'LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_SUCCESS';
export const LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_FAILURE =
    'LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_FAILURE';

export const VIP_USERS_FETCH = 'VIP_USERS_FETCH';
export const VIP_USERS_FETCH_SUCCESS = 'VIP_USERS_FETCH_SUCCESS';
export const VIP_USERS_FETCH_FAILURE = 'VIP_USERS_FETCH_FAILURE';
export const VIP_USERS_FETCH_RESET = 'VIP_USERS_FETCH_RESET';

export const ADD_VIP_USERS_TO_LAUNDRY_ROOM = 'ADD_VIP_USERS_TO_LAUNDRY_ROOM';
export const ADD_VIP_USERS_TO_LAUNDRY_ROOM_SUCCESS =
    'ADD_VIP_USERS_TO_LAUNDRY_ROOM_SUCCESS';
export const ADD_VIP_USERS_TO_LAUNDRY_ROOM_FAILURE =
    'ADD_VIP_USERS_TO_LAUNDRY_ROOM_FAILURE';

export const REMOVE_VIP_USERS = 'REMOVE_VIP_USERS';
export const REMOVE_VIP_USERS_SUCCESS = 'REMOVE_VIP_USERS_SUCCESS';
export const REMOVE_VIP_USERS_FAILURE = 'REMOVE_VIP_USERS_FAILURE';

export interface LaundryRoomForVipUsers {
    id: number;
    name: string;
    address: AddressDto;
    costs: {
        currency_code: Currency;
        amount_washer: number;
        amount_washer_vip: number | null;
        amount_dryer: number;
        amount_dryer_vip: number | null;
    };
}

export interface VipUsersInLaundryRoomDto {
    location_id: number;
    vip_users: VipUserForCcui[];
}

export interface VipUserForCcui {
    user_id: number;
    customer_id: string;
    email: string;
    phone: string | null;
}

export interface UpdateVipCostsForLaundryRoomDto {
    cost_dryer_vip: number;
    cost_washing_machine_vip: number;
}

export interface VipUsersForAddingDto {
    customer_ids_or_emails: string[];
}

export interface VipUserAddingReportDto {
    location_id: number;
    reports: VipUserReport[];
}

export interface VipUserReport {
    email_or_customer_id: string;
    result: VipUserResult;
}

export enum VipUserResult {
    SUCCESS = 'SUCCESS',
    UNKNOWN_USER = 'UNKNOWN_USER',
    ALREADY_EXISTING = 'ALREADY_EXISTING',
    OTHER_ERROR = 'OTHER_ERROR',
}

interface VipUsersForRemovalDto {
    vip_user_ids: number[];
}

export interface VipUsersRemovalReportDto {
    location_id: number;
    deleted_count: number;
    requested_count: number;
}

export function loadLaundryRoomForVipScreen(laundryRoomId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LAUNDRY_ROOM_FOR_VIP_FETCH });
        try {
            const { data } = await RestClient.get<LaundryRoomForVipUsers>(
                `/v1/callcenter/laundry_room_vip/${laundryRoomId}`
            );
            dispatch({
                type: LAUNDRY_ROOM_FOR_VIP_FETCH_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: LAUNDRY_ROOM_FOR_VIP_FETCH_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function loadVipUsers(laundryRoomId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: VIP_USERS_FETCH });
        try {
            const { data } = await RestClient.get<VipUsersInLaundryRoomDto>(
                `/v1/callcenter/vip_users/${laundryRoomId}`
            );
            dispatch({ type: VIP_USERS_FETCH_SUCCESS, payload: { data } });
        } catch (e) {
            dispatch({
                type: VIP_USERS_FETCH_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function updateLaundryRoomVipCosts(
    laundryRoomId: number,
    vipPrices: UpdateVipCostsForLaundryRoomDto
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LAUNDRY_ROOM_FOR_VIP_UPDATE_COST });

        try {
            await RestClient.post(
                `/v1/callcenter/laundry_room_vip/${laundryRoomId}/set_vip_costs`,
                vipPrices
            );
            dispatch({ type: LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_SUCCESS });
        } catch (e) {
            dispatch({
                type: LAUNDRY_ROOM_FOR_VIP_UPDATE_COST_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }

        await loadLaundryRoomForVipScreen(laundryRoomId)(dispatch);
    };
}

export function addLaundryRoomVipUsers(
    laundryRoomId: number,
    customerIdsOrEmails: string[]
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: ADD_VIP_USERS_TO_LAUNDRY_ROOM });
        try {
            const requestData: VipUsersForAddingDto = {
                customer_ids_or_emails: customerIdsOrEmails,
            };
            const { data } = await RestClient.patch<VipUserAddingReportDto>(
                `/v1/callcenter/vip_users/${laundryRoomId}`,
                requestData
            );
            dispatch({
                type: ADD_VIP_USERS_TO_LAUNDRY_ROOM_SUCCESS,
                payload: { data },
            });
            dispatch({ type: VIP_USERS_FETCH_RESET });
        } catch (e) {
            dispatch({
                type: ADD_VIP_USERS_TO_LAUNDRY_ROOM_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function removeVipUsers(laundryRoomId: number, userIds: number[]) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REMOVE_VIP_USERS });
        try {
            const requestData: VipUsersForRemovalDto = {
                vip_user_ids: userIds,
            };
            const { data } = await RestClient.post<VipUsersRemovalReportDto>(
                `/v1/callcenter/vip_users/remove/${laundryRoomId}`,
                requestData
            );
            dispatch({ type: REMOVE_VIP_USERS_SUCCESS, payload: { data } });
            await dispatch(loadVipUsers(laundryRoomId));
        } catch (e) {
            dispatch({
                type: REMOVE_VIP_USERS_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}
