import { useEffect } from 'react';

interface WeightedUsers {
    userId: number;
    counter: number;
}

const LOCAL_STORAGE_KEY = 'WeightedUsersForFavorites';

const MAX_FAVORITE_SIZE = 10;

const favoriteUsers: WeightedUsers[] = [];

function useFavoriteUsers() {
    useEffect(() => {
        loadFromStorage();
    }, []);

    return { favoriteUsers, handleSelectUser, handleResetFavoriteUsers };
}

function handleSelectUser(userId: number) {
    const index = favoriteUsers.findIndex((it) => it.userId === userId);
    if (index >= 0) {
        favoriteUsers[index].counter++;
        favoriteUsers.sort((a, b) => b.counter - a.counter);
    } else {
        if (favoriteUsers.length >= MAX_FAVORITE_SIZE) {
            favoriteUsers.pop();
        }

        favoriteUsers.push({
            userId,
            counter: 1,
        });
    }
    saveToStorage();
}

function handleResetFavoriteUsers() {
    favoriteUsers.splice(0, favoriteUsers.length);
    saveToStorage();
}

function loadFromStorage() {
    const fromLocalStorage = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (fromLocalStorage) {
        const parsed = JSON.parse(fromLocalStorage);
        if (Array.isArray(parsed)) {
            favoriteUsers.splice(0, favoriteUsers.length, ...parsed);
        }
    }
}

function saveToStorage() {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(favoriteUsers));
}

export default useFavoriteUsers;
