import React, { FunctionComponent, ReactText } from 'react';
import { Route } from '../../Router';
import { RootStore } from '../../store/rootStore';
import { goTo } from '../../store/router/routerActions';
import {
    CreditNoteData,
    downloadCreditNoteDatev,
    getPartnerCreditNotesFor,
    selectPartnerCreditNote,
} from '../../store/manage-credit-notes/manageCreditNotesActions';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form, InputGroup } from 'react-bootstrap';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from '../manage-mansion/SearchMansionById.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CreditNoteTable } from './CreditNoteTable';
import { DownloadCallback, downloadFile } from '../../util/downloadFileAsBlob';

interface Props {
    creditNotes: CreditNoteData[];
    searchApiErrors: WeWashApiErrorTag[];
    selectPartnerCreditNote: (id: number) => void;
    getPartnerCreditNotesFor: (s: ReactText) => void;
    goTo: (route: Route) => void;
    downloadCreditNoteDatev: (
        creditNoteNumber: string,
        callback: DownloadCallback
    ) => void;
}

interface SearchByUserId {
    userId: string;
}

const SearchCreditNotes: FunctionComponent<Props> = (props) => {
    const initialValues: SearchByUserId = { userId: '' };
    return (
        <div id={'SearchCreditNotesId'} className={'p-1'}>
            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initialValues}
                children={renderForm}
            />
            <div className="mt-5">
                <CreditNoteTable
                    creditNotes={props.creditNotes}
                    onSelect={handleEntrySelect}
                    onDownloadCreditNoteXml={handleCreditNoteXml}
                />
            </div>
        </div>
    );

    function handleCreditNoteXml(creditNoteNumber: string) {
        props.downloadCreditNoteDatev(creditNoteNumber, downloadFile);
    }

    async function handleFormSubmit(
        values: SearchByUserId,
        actions: FormikActions<SearchByUserId>
    ) {
        await props.getPartnerCreditNotesFor(values.userId);
        actions.setSubmitting(false);
    }

    function renderForm(formProps: FormikProps<SearchByUserId>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <ErrorFeedback apiErrors={props.searchApiErrors} />
                <Form.Group controlId={'formGroupSearch'}>
                    <InputGroup>
                        <Form.Control
                            type={'text'}
                            name={'userId'}
                            value={formProps.values.userId}
                            placeholder={
                                'Suche mit Accounting Area User ID, Customer ID oder Credit Note Number'
                            }
                            onChange={formProps.handleChange}
                        />
                        <InputGroup.Append>
                            <Button
                                variant={'outline-dark'}
                                type={'submit'}
                                disabled={
                                    formProps.isSubmitting ||
                                    !formProps.values.userId
                                }
                            >
                                <FontAwesomeIcon
                                    icon={faSearch}
                                    className={styles.icon}
                                />
                            </Button>
                        </InputGroup.Append>
                    </InputGroup>
                </Form.Group>
            </Form>
        );
    }

    function handleEntrySelect(creditNoteId: number) {
        props.selectPartnerCreditNote(creditNoteId);
        props.goTo(Route.MANAGE_PARTNER_CREDIT_NOTE);
    }
};

export default connect(
    (store: RootStore) => ({
        creditNotes: store.manageCreditNotes.creditNotes,
        searchApiErrors: store.manageCreditNotes.errors,
    }),
    {
        getPartnerCreditNotesFor,
        goTo,
        selectPartnerCreditNote,
        downloadCreditNoteDatev,
    }
)(SearchCreditNotes);
