import React, { Fragment, FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';

interface Props {
    data: {
        status: string;
    };
    close: () => any;
}

const FailedPaymentSelectionInformation: FunctionComponent<Props> = ({
    data,
    close,
}) => {
    let reason;

    switch (data.status) {
        case 'EXPIRED':
            reason =
                'Die Zahlungsmethode kann nicht ausgewählt werden, da sie abgelaufen ist.';
            break;
        case 'INVALID':
        default:
            reason =
                'Die Zahlungsmethode kann nicht ausgewählt werden, da sie ungültig ist.';
            break;
    }

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Zahlungsmethode kann nicht ausgewählt werden
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{reason}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => close()}>Ok.</Button>
            </Modal.Footer>
        </Fragment>
    );
};

export default connect(null, { close: closeModal })(
    FailedPaymentSelectionInformation
);
