import { createReducer } from 'redux-starter-kit';
import {
    CreditNotesDownloadRequest,
    DOWNLOAD_CREDIT_NOTES,
    DOWNLOAD_CREDIT_NOTES_FAILURE,
    DOWNLOAD_CREDIT_NOTES_SUCCESS,
    FETCH_CREDIT_NOTES_SUMMARY,
    FETCH_CREDIT_NOTES_SUMMARY_FAILURE,
    FETCH_CREDIT_NOTES_SUMMARY_SUCCESS,
    RESET_GROUPED_CREDIT_NOTES,
} from './groupedCreditNotesAction';
import { WeWashApiErrorTag } from '../../http/errors';
import { CommunicationState } from '../communicationState';

export interface GroupedCreditNotesStore {
    loading: boolean;
    creditNotesSummary: CreditNotesSummary | null;
    errors: WeWashApiErrorTag[];
    downloadingCreditNotesZip: {
        state: CommunicationState;
        request?: CreditNotesDownloadRequest;
    };
}

export interface CreditNotesSummary {
    entries: CreditNotesSummaryEntry[];
}

export interface CreditNotesSummaryEntry {
    country_id: number;
    country_code: string;
    year: number;
    month: number;
    partner_gross_filter: PartnerGrossFilter;
    accountable: number;
    corrected: number;
    internal: number;
    pdf_success: number;
    pdf_initial: number;
    pdf_error: number;
    xml_success: number;
    xml_initial: number;
    xml_error: number;
}

export enum PartnerGrossFilter {
    ALL = 'ALL',
    POSITIVE = 'POSITIVE',
    NEGATIVE = 'NEGATIVE',
    ZERO = 'ZERO',
}

const initialStore: GroupedCreditNotesStore = {
    loading: false,
    creditNotesSummary: null,
    errors: [],
    downloadingCreditNotesZip: { state: CommunicationState.INITIAL },
};

export const groupedCreditNotesReducer = createReducer(initialStore, {
    [RESET_GROUPED_CREDIT_NOTES]: (state: GroupedCreditNotesStore) => {
        state.loading = false;
        state.errors = [];
        state.creditNotesSummary = null;
        state.downloadingCreditNotesZip = {
            state: CommunicationState.INITIAL,
            request: undefined,
        };
    },
    [FETCH_CREDIT_NOTES_SUMMARY]: (state: GroupedCreditNotesStore) => {
        state.loading = true;
        state.errors = [];
    },
    [FETCH_CREDIT_NOTES_SUMMARY_SUCCESS]: (
        state: GroupedCreditNotesStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.creditNotesSummary = action.payload.data;
    },
    [FETCH_CREDIT_NOTES_SUMMARY_FAILURE]: (
        state: GroupedCreditNotesStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [DOWNLOAD_CREDIT_NOTES]: (state: GroupedCreditNotesStore, action: any) => {
        state.downloadingCreditNotesZip = {
            request: action.payload,
            state: CommunicationState.LOADING,
        };
        state.errors = [];
    },
    [DOWNLOAD_CREDIT_NOTES_SUCCESS]: (state: GroupedCreditNotesStore) => {
        state.downloadingCreditNotesZip.state = CommunicationState.SUCCESS;
    },
    [DOWNLOAD_CREDIT_NOTES_FAILURE]: (
        state: GroupedCreditNotesStore,
        action
    ) => {
        state.downloadingCreditNotesZip.state = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
});
