import React, { FunctionComponent, ReactText } from 'react';
import { RootStore } from '../../store/rootStore';
import { connect } from 'react-redux';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import styles from '../manage-laundry-room/LaundryRoomConnectivity.module.css';
import { resetViews } from '../../store/genericActions';
import {
    checkLaundryRoomConnectivity,
    LaundryRoomConnectivityDto,
    loadLaundryRoomConnectivity,
    startClientReconnection,
} from '../../store/manage-laundry-room/manageLaundryRoomActions';
import ErrorFeedback from '../ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';
import moment from 'moment';

interface Props {
    connectivityInfo: LaundryRoomConnectivityDto | null;
    loading: boolean;
    errors: WeWashApiErrorTag[];
    clearView: () => void;
    loadLaundryRoomConnectivity: (laundryRoomId: ReactText) => void;
    checkLaundryRoomConnectivity: (laundryRoomId: ReactText) => void;
    startClientReconnection: (laundryRoomId: ReactText) => void;
}

interface LaundryRoomParameters {
    locationId: ReactText;
}

const LaundryRoomConnectivity: FunctionComponent<Props> = (props) => {
    const laundryRoomParams: LaundryRoomParameters = {
        locationId: '',
    };
    const connectivityInfo: LaundryRoomConnectivityDto | null =
        props.connectivityInfo;

    return (
        <div id={'LaundryRoomCard'}>
            <h2>Waschraum Konnektivität</h2>
            {renderLaundryRoomSearch()}
            {renderHubConnectivitySection()}
            {renderClientReconnectionSection()}
            <ErrorFeedback apiErrors={props.errors} />
        </div>
    );

    function renderLaundryRoomSearch() {
        return (
            <Formik
                onSubmit={handleLaundryRoomSearch}
                initialValues={laundryRoomParams}
                children={renderLaundryRoomSearchForm}
            />
        );
    }

    function handleLaundryRoomSearch(params: LaundryRoomParameters) {
        props.loadLaundryRoomConnectivity(params.locationId);
    }

    function renderLaundryRoomSearchForm(
        formProps: FormikProps<LaundryRoomParameters>
    ) {
        const isLoading = props.loading;
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'locationId'}>
                    <Form.Label>Location ID</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={handleLaundryRoomChange}
                        value={String(formProps.values.locationId)}
                    />

                    <Button type={'submit'} disabled={isLoading}>
                        {isLoading ? 'Wird geladen...' : 'Laden'}
                    </Button>
                </Form.Group>
            </Form>
        );

        function handleLaundryRoomChange(event: React.ChangeEvent<any>) {
            if (props.connectivityInfo) {
                props.clearView();
            }
            formProps.handleChange(event);
        }
    }

    function formatTimestamp(epochMillis: number) {
        return moment(epochMillis).format('DD.MM.YYYY, HH:mm');
    }

    function renderHubConnectivitySection() {
        if (!connectivityInfo) {
            return null;
        }
        return (
            <div className={styles.section}>
                <h3>Hub</h3>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <th>Waschraum</th>
                            <td>{connectivityInfo.location_id}</td>
                        </tr>
                        <tr>
                            <th>Verbindungsstatus</th>
                            <td>
                                {connectivityInfo.connectivity_state || '-'}
                            </td>
                        </tr>
                        <tr>
                            <th>Verbindungscheck</th>
                            <td>
                                {connectivityInfo.connectivity_check_state ||
                                    '-'}
                            </td>
                        </tr>
                        {connectivityInfo.connectivity_check_update_time && (
                            <tr>
                                <td />
                                <td>{`Seit ${formatTimestamp(
                                    connectivityInfo.connectivity_check_update_time
                                )}`}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Button
                    className={styles.actionButton}
                    onClick={handleConnectivityCheck}
                >
                    Verbindungscheck anstoßen
                </Button>
            </div>
        );
    }

    function handleConnectivityCheck() {
        if (!connectivityInfo) {
            return null;
        }
        props.checkLaundryRoomConnectivity(connectivityInfo.location_id);
    }

    function renderClientReconnectionSection() {
        if (!connectivityInfo) {
            return null;
        }
        return (
            <div className={styles.section}>
                <h3>Clients</h3>
                <table className={styles.infoTable}>
                    <tbody>
                        <tr>
                            <th>Client Reconnection</th>
                            <td>
                                {connectivityInfo.client_reconnection_status ||
                                    '-'}
                            </td>
                        </tr>
                        {connectivityInfo.client_reconnection_status_update_time && (
                            <tr>
                                <td />
                                <td>{`Seit ${formatTimestamp(
                                    connectivityInfo.client_reconnection_status_update_time
                                )}`}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <div className={styles.clientReconnectionInfoText}>
                    Setzt die NetworkId des Hubs auf 0 so dass die Clients sich
                    neu verbinden. Setzt nach kurzer Wartezeit die NetworkId
                    zurück auf ihren normal Wert und started eine Client
                    Installation für alle Clients.
                </div>
                <Button
                    className={styles.actionButton}
                    onClick={handleClientReconnection}
                >
                    Client Reconnection starten
                </Button>
            </div>
        );
    }

    function handleClientReconnection() {
        if (!connectivityInfo) {
            return null;
        }
        props.startClientReconnection(connectivityInfo.location_id);
    }
};

export default connect(
    (store: RootStore) => ({
        connectivityInfo: store.manageLaundryRooms.laundryRoomConnectivity,
        loading: store.manageLaundryRooms.loading,
        errors: store.manageLaundryRooms.errors,
    }),
    {
        clearView: resetViews,
        loadLaundryRoomConnectivity,
        checkLaundryRoomConnectivity,
        startClientReconnection,
    }
)(LaundryRoomConnectivity);
