import { ReactText } from 'react';
import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { TopUpUserDto } from './topUpReducer';
import { extractErrorTags } from '../../http/errors';
import { TopUpCreationDto } from '../../forms/topup/TopUpForm';

export const LOAD_TOP_UP_DATA = 'LOAD_TOP_UP_DATA';
export const LOAD_TOP_UP_DATA_SUCCESS = 'LOAD_TOP_UP_DATA_SUCCESS';
export const LOAD_TOP_UP_DATA_FAILURE = 'LOAD_TOP_UP_DATA_FAILURE';
export const CREATE_TOP_UP = 'CREATE_TOP_UP';
export const CREATE_TOP_UP_SUCCESS = 'CREATE_TOP_UP_SUCCESS';
export const CREATE_TOP_UP_FAILURE = 'CREATE_TOP_UP_FAILURE';

export function loadTopUpUserAndBalance(userId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_TOP_UP_DATA });
        try {
            const { data } = await RestClient.get<TopUpUserDto>(
                `v1/topup/info/${userId}`
            );
            dispatch({ type: LOAD_TOP_UP_DATA_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: LOAD_TOP_UP_DATA_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function createTopUp(data: TopUpCreationDto) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CREATE_TOP_UP });
        try {
            await RestClient.post('v1/topup', {
                user_id: data.userId,
                amount: data.amount.replace(',', '.'),
                comment: data.comment,
                country_id: parseInt(data.countryId),
            });
            dispatch({ type: CREATE_TOP_UP_SUCCESS, payload: { data } });
            await dispatch(loadTopUpUserAndBalance(data.userId));
        } catch (error) {
            dispatch({
                type: CREATE_TOP_UP_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
