import React, {FunctionComponent} from 'react';
import PhoneUserRegistrationForm from './forms/registration/PhoneUserRegistrationForm';
import RegistrationSuccess from './RegistrationSuccess/RegistrationSuccess';
import BlacklistIbanForm from './forms/blacklist-iban/BlacklistIbanForm';
import FailedPaymentForm from './forms/failed-payment/FailedPaymentForm';
import TopUpForm from './forms/topup/TopUpForm';
import RefundReservationForm from './forms/refund-reservation/RefundReservationForm';
import SearchUser from './forms/search-user/SearchUser';
import ManageUser from './components/manage-user/ManageUser';
import styles from './App/App.module.css';
import ManagePayments from './forms/manage-payments/ManagePayments';
import {Route as PublicRoute, Router as ReactRouter} from 'react-router-dom';
import PrivateRoute from './routing/PrivateRoute';
import Menu from './App/Menu';
import history from './App/history';
import UserPaysDebt from './components/manage-user/UserPaysInvoice';
import SearchUserId from './forms/search-user/SearchUserById';
import ManageApplianceForm from './forms/manage-appliance/ManageApplianceForm';
import SearchPartner from './forms/search-user/SearchPartner';
import ManageSerialnumbersForm from './forms/manage-serialnumbers/ManageSerialnumbersForm';
import SearchMansionById from './components/manage-mansion/SearchMansionById';
import ManageMansion from './components/manage-mansion/ManageMansion';
import ManageHub from './components/manage-hub/ManageHub';
import ManageLaundryRoomCardsForm from './forms/manage-laundry-room/LaundryRoomCardScreen';
import SearchCreditNotes from './components/manage-credit-notes/SearchCreditNotes';
import ManageCreditNote from './components/manage-credit-notes/ManageCreditNote';
import GroupedUserInvoices from './components/grouped-user-invoices/GroupedUserInvoices';
import FavoriteUsers from './forms/search-user/FavoriteUsers';
import ManageUserForId from './components/manage-user/ManageUserForId';
import LaundryRoomConnectivity from './forms/manage-laundry-room/LaundryRoomConnectivity';
import ManageReservationsOfUser from './components/manage-user/ManageReservationsOfUser';
import SearchLaundryRoomById from './components/manage-laundry-room/SearchLaundryRoomById';
import GroupedCreditNotes from './components/grouped-credit-notes/GroupedCreditNotes';
import ManageVipUsers from './components/manage-vip-users/ManageVipUsers';
import ManagePartnerManager from './components/manage-partner-manager/ManagePartnerManager';
import VipUsersList from './components/manage-vip-users/VipUsersList';
import CancelUserInvoiceScreen from "./components/manage-user/CancelUserInvoiceScreen";
import ManageInvoicesOfUser from "./components/manage-user/ManageInvoicesOfUser";
import SearchInvoicesScreen from "./components/manage-user/SearchInvoicesScreen";

export enum Route {
    LOGIN = '/login',
    RACCOON = '/raccoon',
    REGISTRATION_FORM = '/registration_form',
    REGISTRATION_SUCCESS = '/registration_success',
    BLACKLIST_IBAN = '/blacklist_iban',
    FAILED_PAYMENT = '/failed_payment',
    TOP_UP = '/top_up',
    REFUND_RESERVATION = '/refund_reservation',
    SEARCH_USER = '/search_user',
    MANAGE_USER = '/manage_user',
    MANAGE_RESERVATIONS_OF_USER = '/manage_reservations',
    MANAGE_INVOICES_OF_USER = '/manage_invoices',
    MANAGE_PAYMENTS = '/manage_payments',
    PAY_INVOICE = '/user_pays_invoice',
    CANCEL_USER_INVOICE = '/cancel_user_invoice',
    SEARCH_USER_ID = '/search_user_id',
    FAVORITE_USERS_BY_ID = '/favorite_users_by_id',
    MANAGE_APPLIANCE = '/manage_appliance',
    MANAGE_PARTNER = '/manage_partner',
    MANAGE_SERIALNUMBERS = '/manage_serialnumbers',
    SEARCH_MANSION = '/search_mansion',
    MANAGE_MANSION = '/manage_mansion',
    MANAGE_HUB = '/manage_hub',
    SEARCH_LAUNDRY_ROOM_BY_ID = '/search_laundry_room',
    MANAGE_LAUNDRY_ROOM_CARDS = '/manage_laundry_room_cards',
    MANAGE_LAUNDRY_ROOM_CONNECTIVITY = '/manage_laundry_room_connectivity',
    SEARCH_PARTNER_CREDIT_NOTES = '/search_partner_credit_notes',
    MANAGE_PARTNER_CREDIT_NOTE = '/manage_partner_credit_note',
    GET_USER_INVOICES_ZIP = '/user_invoices_zip',
    GET_CREDIT_NOTES_ZIP = '/credit_notes_zip',
    MANAGE_B2B_USER = '/manage_b2b_user',
    MANAGE_VIP_USERS = '/manage_vip_users',

    VIP_USERS_LIST = '/vip_users_list',
}

const Router: FunctionComponent = () => {
    return (
        <div id="Router" className={styles.router}>
            <ReactRouter history={history}>
                <Menu />
                <PublicRoute path={Route.LOGIN} exact component={SearchUser} />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.REGISTRATION_FORM}
                    exact
                    component={PhoneUserRegistrationForm}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.REGISTRATION_SUCCESS}
                    exact
                    component={RegistrationSuccess}
                />
                <PrivateRoute
                    path={Route.BLACKLIST_IBAN}
                    exact
                    component={BlacklistIbanForm}
                />
                <PrivateRoute
                    path={Route.FAILED_PAYMENT}
                    exact
                    component={FailedPaymentForm}
                />
                <PrivateRoute path={Route.TOP_UP} exact component={TopUpForm} />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.REFUND_RESERVATION}
                    exact
                    component={RefundReservationForm}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.REFUND_RESERVATION}/:reservationId`}
                    exact
                    component={RefundReservationForm}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.SEARCH_USER}
                    exact
                    component={SearchUser}
                />
                <PrivateRoute
                    path={Route.SEARCH_USER_ID}
                    exact
                    component={SearchUserId}
                />
                <PrivateRoute
                    path={Route.FAVORITE_USERS_BY_ID}
                    exact
                    component={FavoriteUsers}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.MANAGE_USER}
                    exact
                    component={ManageUser}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.MANAGE_USER}/:userId`}
                    exact
                    component={ManageUserForId}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.MANAGE_RESERVATIONS_OF_USER}/:userId`}
                    exact
                    component={ManageReservationsOfUser}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.MANAGE_INVOICES_OF_USER}/:userId`}
                    exact
                    component={ManageInvoicesOfUser}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.MANAGE_PAYMENTS}
                    exact
                    component={ManagePayments}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.PAY_INVOICE}/:invoiceId`}
                    exact
                    component={UserPaysDebt}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.CANCEL_USER_INVOICE}/:invoiceId`}
                    exact
                    component={CancelUserInvoiceScreen}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.CANCEL_USER_INVOICE}`}
                    exact
                    component={SearchInvoicesScreen}
                />
                <PrivateRoute
                    path={Route.MANAGE_APPLIANCE}
                    exact
                    component={ManageApplianceForm}
                />
                <PrivateRoute
                    path={Route.SEARCH_LAUNDRY_ROOM_BY_ID}
                    exact
                    component={SearchLaundryRoomById}
                />
                <PrivateRoute
                    path={Route.MANAGE_LAUNDRY_ROOM_CARDS}
                    exact
                    component={ManageLaundryRoomCardsForm}
                />
                <PrivateRoute
                    path={Route.MANAGE_LAUNDRY_ROOM_CONNECTIVITY}
                    exact
                    component={LaundryRoomConnectivity}
                />
                <PrivateRoute
                    path={Route.MANAGE_PARTNER}
                    exact
                    component={SearchPartner}
                />
                <PrivateRoute
                    path={Route.MANAGE_SERIALNUMBERS}
                    exact
                    component={ManageSerialnumbersForm}
                />
                <PrivateRoute
                    path={Route.MANAGE_B2B_USER}
                    exact
                    component={ManagePartnerManager}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={Route.MANAGE_VIP_USERS}
                    exact
                    component={ManageVipUsers}
                />
                <PrivateRoute
                    ccAgentsAllowed
                    path={`${Route.VIP_USERS_LIST}/:laundryRoomId`}
                    exact
                    component={VipUsersList}
                />
                <PrivateRoute
                    path={Route.SEARCH_MANSION}
                    exact
                    component={SearchMansionById}
                />
                <PrivateRoute
                    path={Route.MANAGE_MANSION}
                    exact
                    component={ManageMansion}
                />
                <PrivateRoute
                    path={Route.MANAGE_HUB}
                    exact
                    component={ManageHub}
                />
                <PrivateRoute
                    path={Route.SEARCH_PARTNER_CREDIT_NOTES}
                    exact
                    component={SearchCreditNotes}
                />
                <PrivateRoute
                    path={Route.MANAGE_PARTNER_CREDIT_NOTE}
                    exact
                    component={ManageCreditNote}
                />
                <PrivateRoute
                    path={Route.GET_USER_INVOICES_ZIP}
                    exact
                    component={GroupedUserInvoices}
                />
                <PrivateRoute
                    path={Route.GET_CREDIT_NOTES_ZIP}
                    exact
                    component={GroupedCreditNotes}
                />
                <PrivateRoute
                    path={Route.RACCOON}
                    component={() => {
                        window.location.replace(
                            process.env.REACT_APP_RACCOON_URL || ''
                        );
                        return null;
                    }}
                />
            </ReactRouter>
        </div>
    );
};

export default Router;
