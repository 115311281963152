import React, { useEffect, useState } from 'react';
import {
    VipUserForCcui,
    VipUsersInLaundryRoomDto,
    VipUsersRemovalReportDto,
} from '../../store/manage-vip-users/manageVipUsersActions';
import styles from './VipUsersList.module.css';
import Button from 'react-bootstrap/Button';
import SuccessFeedback from '../../forms/SuccessFeedback';
import { WeWashApiErrorTag } from '../../http/errors';
import ErrorFeedback from '../../forms/ErrorFeedback';
import Alert from 'react-bootstrap/Alert';

interface Props {
    vipUsers: VipUsersInLaundryRoomDto;
    onRemove: (laundryRoomId: number, userIds: number[]) => void;
    removeInProgress: boolean;
    removalReport?: VipUsersRemovalReportDto;
    errors: WeWashApiErrorTag[];
}

function ManageVipUsersDisplay(props: Props) {
    const [checkedIds, setCheckedIds] = useState(new Set<number>());
    const [filterValue, setFilterValue] = useState('');

    useEffect(() => {
        removeStaleCheckedIds();
    }, [props.vipUsers.vip_users]);

    return (
        <div>
            <h3>VIP Status entfernen</h3>

            <Button
                disabled={props.removeInProgress}
                onClick={() =>
                    props.onRemove(
                        props.vipUsers.location_id,
                        Array.from(checkedIds)
                    )
                }
            >
                {checkedIds.size} Ausgewählte entfernen
            </Button>

            {props.removalReport && renderRemovalReport(props.removalReport)}
            <ErrorFeedback apiErrors={props.errors} />

            <input
                type="text"
                onChange={(event) => setFilterValue(event.target.value.trim())}
                value={filterValue}
                placeholder="Filtern nach E-Mail oder CustomerId"
                className={styles.filterInputField}
            />

            {props.vipUsers.vip_users
                .filter(
                    (user) =>
                        user.email.includes(filterValue) ||
                        user.customer_id.includes(filterValue)
                )
                .map(renderUser)}
        </div>
    );

    function renderRemovalReport(report: VipUsersRemovalReportDto) {
        if (report.deleted_count === report.requested_count) {
            return (
                <SuccessFeedback
                    message={`VIP Status wurde für ${report.deleted_count} Nutzer entfernt`}
                />
            );
        } else {
            return (
                <Alert variant="warning">{`VIP Status wurde für ${report.deleted_count} statt ${report.requested_count} Nutzer entfernt`}</Alert>
            );
        }
    }

    function renderUser(user: VipUserForCcui) {
        const userId = user.user_id;
        return (
            <div key={userId} onClick={() => toggleUser(userId)}>
                <input type="checkbox" checked={checkedIds.has(userId)} />
                {` ${user.email} (${user.customer_id})`}
            </div>
        );
    }

    function toggleUser(userId: number) {
        const newCheckedIds = new Set(checkedIds);
        if (newCheckedIds.has(userId)) {
            newCheckedIds.delete(userId);
        } else {
            newCheckedIds.add(userId);
        }
        setCheckedIds(newCheckedIds);
    }

    function removeStaleCheckedIds() {
        const newCheckedIds = new Set<number>();
        props.vipUsers.vip_users.forEach((user) => {
            if (checkedIds.has(user.user_id)) {
                newCheckedIds.add(user.user_id);
            }
        });
        setCheckedIds(newCheckedIds);
    }
}

export default ManageVipUsersDisplay;
