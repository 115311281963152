import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, InputGroup, Row } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { updateHubMonitoringFlag } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';

interface Props {
    data: { needs_monitoring?: boolean; locationId: number };
    laundryRoomStore: ManageLaundryRoomStore;
    close: () => void;
    updateHubMonitoringFlag: (
        locationId: number,
        needs_monitoring: boolean
    ) => void;
}

interface InputFormData {
    needs_monitoring: boolean;
}

function ChangeMonitoringFlagInformation({
    data,
    close,
    updateHubMonitoringFlag,
    laundryRoomStore,
}: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const isLoading = patchTriggered && laundryRoomStore.loading;

    useEffect(() => {
        if (
            patchTriggered &&
            !isLoading &&
            laundryRoomStore.errors.length === 0
        ) {
            close();
        }
    }, [patchTriggered, isLoading, laundryRoomStore.errors, close]);

    const initialValues: InputFormData = {
        needs_monitoring: data.needs_monitoring ? data.needs_monitoring : false,
    };
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Hub-Monitoring ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Soll Hub-Monitoring für diesen Waschraum durchgeführt werden?
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<InputFormData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group
                    as={Row}
                    controlId={'formGroupServiceInformationId'}
                    className={'mx-0'}
                >
                    <ErrorFeedback apiErrors={laundryRoomStore.errors} />
                    <InputGroup>
                        {/*<InputGroup.Prepend>
                        <InputGroup.Text>
                            Hub-Monitoring
                        </InputGroup.Text>
                    </InputGroup.Prepend>*/}
                        <Form.Check
                            custom
                            label="Hub-Monitoring"
                            name={'needs_monitoring'}
                            onChange={formProps.handleChange}
                            checked={formProps.values.needs_monitoring}
                        ></Form.Check>
                    </InputGroup>
                    <Row className={'mx-0 mt-4'}>
                        <Button
                            variant="outline-primary"
                            onClick={handleCancel}
                            className={'mr-2'}
                        >
                            Abbrechen
                        </Button>
                        <Button
                            type={'submit'}
                            disabled={formProps.isSubmitting}
                        >
                            Speichern
                        </Button>
                    </Row>
                </Form.Group>
            </Form>
        );
    }

    function handleCancel() {
        close();
    }

    function handleSubmit(
        value: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        updateHubMonitoringFlag(data.locationId, value.needs_monitoring);
        setPatchTriggered(true);
        actions.setSubmitting(false);
    }
}

export default connect(
    (state: RootStore) => ({ laundryRoomStore: state.manageLaundryRooms }),
    {
        close: closeModal,
        updateHubMonitoringFlag,
    }
)(ChangeMonitoringFlagInformation);
