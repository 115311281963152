import React from 'react';
import styles from './LaundryRoomCardSelect.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { LaundryRoomCardDto } from '../../../store/manage-laundry-room/manageLaundryRoomActions';

const cardStyleCommon = {
    width: '8rem',
    height: '8rem',
    borderColor: 'grey',
    borderWidth: '2px',
    margin: '2px',
};

const cardStyleActive = {
    borderStyle: 'solid',
    color: '#fff',
    backgroundColor: '#00B3E6',
};

const cardStyleInactive = {
    borderStyle: 'dashed',
};

interface Props {
    onAddNewCard: () => void;
    onCardSelect: (card: LaundryRoomCardDto) => void;
    cards: LaundryRoomCardDto[];
    defaultLanguage: string;
    selectedLaundryRoomCard?: LaundryRoomCardDto;
    laundryRoomDefaultLanguage: string;
}

function LaundryRoomCardSelect(props: Props) {
    return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
            <span>
                {props.cards.length === 0 && (
                    <h3>Fügen Sie eine neue Waschraumkarte hinzu</h3>
                )}
                {props.cards.length > 0 && (
                    <h3>Wählen Sie eine neue Waschraumkarte aus</h3>
                )}
                <h6>
                    <i>Waschraum Standardsprache: {props.defaultLanguage}</i>
                </h6>
                <div className={styles.horizontalContainerFlex}>
                    <div className="row" style={{ paddingLeft: '1rem' }}>
                        {props.cards.map((card) => {
                            const selected = Boolean(
                                props.selectedLaundryRoomCard &&
                                    card.card_id ===
                                        props.selectedLaundryRoomCard.card_id
                            );
                            return (
                                <CardTile
                                    card={card}
                                    onCardSelect={props.onCardSelect}
                                    key={card.card_id}
                                    defaultLanguage={
                                        props.laundryRoomDefaultLanguage
                                    }
                                    selected={selected}
                                />
                            );
                        })}
                        <div
                            className={styles.card}
                            style={{ ...cardStyleCommon, ...cardStyleInactive }}
                            onClick={props.onAddNewCard}
                        >
                            <FontAwesomeIcon icon={faPlus} />
                        </div>
                    </div>
                </div>
            </span>
        </div>
    );
}

interface CardTileProps {
    card: LaundryRoomCardDto;
    onCardSelect: (card: LaundryRoomCardDto) => void;
    selected: boolean;
    defaultLanguage: string;
}

function CardTile(props: CardTileProps) {
    const style = props.selected
        ? { ...cardStyleCommon, ...cardStyleActive }
        : { ...cardStyleCommon, ...cardStyleInactive };

    return (
        <div
            className={styles.card}
            style={style}
            onClick={() => props.onCardSelect(props.card)}
        >
            <p>{props.card.title[props.defaultLanguage]}</p>
        </div>
    );
}

export default LaundryRoomCardSelect;
