import { connect } from 'react-redux';
import React, { Fragment, useEffect, useState } from 'react';
import { RootStore } from '../../store/rootStore';
import {
    fetchAllMansions,
    filterLocations,
    filterMansions,
} from '../../store/location/locationActions';
import {
    LocationForSelection,
    MansionWithLocations,
} from '../../store/location/locationReducer';
import Row from 'react-bootstrap/Row';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import { changeLaundryRoomMethod } from '../../store/manage-user/manageUserActions';
import Button from 'react-bootstrap/Button';
import { closeModal } from '../../store/modal/modalActions';
import { ManageUserStore } from '../../store/manage-user/manageUserReducer';
import Modal from 'react-bootstrap/Modal';
import ErrorFeedback from '../../forms/ErrorFeedback';
import Alert from 'react-bootstrap/Alert';

export interface ChangeLaundryRoomDto {
    userId: number;
    email: string;
    locationId: string;
}

interface ReduxProps {
    manageUserStore: ManageUserStore;
    allMansions: MansionWithLocations[];
    filteredMansions: MansionWithLocations[];
    filteredLocations: LocationForSelection[];
    fetchAllMansions: () => void;
    filterMansions: (postalcode: string) => void;
    filterLocations: (mansionId: string) => void;
    changeLaundryRoomMethod: (userId: number, laundryRoomId: number) => void;
    close: () => void;
}

interface Props extends ReduxProps {
    data: ChangeLaundryRoomDto;
}

interface MansionAndLaundryRoom {
    smartSearchValue: string;
    mansionId: string;
    locationId: string;
}

const schema: ObjectSchema<MansionAndLaundryRoom> = yup.object({
    smartSearchValue: yup.string(),
    mansionId: yup.string().required(),
    locationId: yup.string().required(),
});

function ChangeLaundryRoomWithList(props: Props) {
    const [initialValues, setInitialValues] = useState<MansionAndLaundryRoom>();
    const [patchTriggered, setPatchTriggered] = useState(false);
    const isPatching = patchTriggered && props.manageUserStore.patching;

    useEffect(() => {
        if (
            patchTriggered &&
            !isPatching &&
            props.manageUserStore.patchErrors.length === 0
        ) {
            props.close();
        }
    }, [
        patchTriggered,
        isPatching,
        props.manageUserStore.patchErrors,
        props.close,
    ]);

    useEffect(() => {
        if (props.allMansions && props.allMansions.length > 0) {
            return;
        }
        props.fetchAllMansions();
    }, []);

    useEffect(() => {
        if (initialValues) {
            return;
        }
        if (props.allMansions && props.allMansions.length > 0) {
            const mansion = props.allMansions.find(
                (m) =>
                    !!m.locations.find(
                        (l) => String(l.id) === props.data.locationId
                    )
            );
            const values: MansionAndLaundryRoom = {
                smartSearchValue: '',
                mansionId: mansion ? String(mansion.id) : '',
                locationId: mansion ? String(props.data.locationId) : '',
            };
            if (mansion) {
                props.filterLocations(String(mansion.id));
            }
            setInitialValues(values);
        }
    }, [props.allMansions]);

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Waschraum ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {initialValues && (
                    <Formik
                        validationSchema={schema}
                        initialValues={initialValues}
                        onSubmit={handleSubmit}
                        children={renderForm}
                    />
                )}
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<MansionAndLaundryRoom>) {
        if (props.filteredMansions.length > 0) {
            if (
                !props.filteredMansions.find(
                    (it) => it.id.toString() === formProps.values.mansionId
                )
            ) {
                formProps.setFieldValue(
                    'mansionId',
                    props.filteredMansions[0].id.toString()
                );
                props.filterLocations(props.filteredMansions[0].id.toString());
            }
        } else {
            if (formProps.values.mansionId !== '') {
                formProps.setFieldValue('mansionId', '');
                props.filterLocations('');
            }
        }

        let selectedLocation: LocationForSelection | undefined;

        if (props.filteredLocations.length > 0) {
            selectedLocation = props.filteredLocations.find(
                (it) => it.id.toString() === formProps.values.locationId
            );
            if (!selectedLocation) {
                selectedLocation = props.filteredLocations[0];
                formProps.setFieldValue(
                    'locationId',
                    selectedLocation.id.toString()
                );
            }
        } else {
            selectedLocation = undefined;
            if (formProps.values.locationId !== '') {
                formProps.setFieldValue('locationId', '');
            }
        }

        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className="ml-3 mb-3">
                    Den Waschraum für Nutzer {props.data.email} ändern:
                </Row>

                <Row className={'ml-3'}>
                    <Form.Group controlId="smartSearchValue">
                        <Form.Label column={false}>
                            Freie Waschraumsuche (Straße, Ort, PLZ)
                        </Form.Label>
                        <Form.Control
                            type="text"
                            onChange={handleChangeAndTriggerMansionSearch}
                            value={formProps.values.smartSearchValue}
                        />
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mr-3'}>
                    <Form.Group controlId="mansionId">
                        <Form.Label column={false}>Objekt</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleMansionChange}
                            value={formProps.values.mansionId}
                            isInvalid={
                                formProps.touched.mansionId &&
                                !!formProps.errors.mansionId
                            }
                            isValid={
                                formProps.touched.mansionId &&
                                !formProps.errors.mansionId
                            }
                        >
                            {props.filteredMansions.map((it) => (
                                <option
                                    key={it.id}
                                    value={it.id}
                                >{`${it.street} ${it.housenumber}, ${it.postalcode} ${it.city}`}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mr-3'}>
                    <Form.Group controlId="locationId">
                        <Form.Label column={false}>Waschraum</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleLaundryRoomChange}
                            value={formProps.values.locationId}
                            isInvalid={
                                formProps.touched.locationId &&
                                !!formProps.errors.locationId
                            }
                            isValid={
                                formProps.touched.locationId &&
                                !formProps.errors.locationId
                            }
                        >
                            {props.filteredLocations.map((it) => (
                                <option key={it.id} value={it.id}>
                                    {it.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                {selectedLocation && !selectedLocation.phone_users_allowed && (
                    <Row className={'ml-3 mr-3'}>
                        <Alert variant="warning">
                            {
                                'Der ausgewählte Waschraum unterstützt keine Telefonbuchung'
                            }
                        </Alert>
                    </Row>
                )}
                <Row className={'ml-3 mb-5'}>
                    <Button
                        className="mr-2"
                        type={'button'}
                        onClick={handleCancel}
                        variant={'outline-secondary'}
                    >
                        ABBRECHEN
                    </Button>
                    <Button type="submit" disabled={formProps.isSubmitting}>
                        {formProps.isSubmitting
                            ? 'Bitte warten...'
                            : 'WASCHRAUM ÄNDERN'}
                    </Button>
                </Row>
                <ErrorFeedback apiErrors={props.manageUserStore.patchErrors} />
            </Form>
        );

        function handleMansionChange(event: React.ChangeEvent<any>) {
            props.filterLocations(event.target.value);
            formProps.handleChange(event);
        }

        function handleLaundryRoomChange(event: React.ChangeEvent<any>) {
            formProps.handleChange(event);
        }

        function handleChangeAndTriggerMansionSearch(
            event: React.ChangeEvent<any>
        ) {
            props.filterMansions(event.target.value);
            formProps.handleChange(event);
        }
    }

    async function handleSubmit(
        values: MansionAndLaundryRoom,
        actions: FormikActions<MansionAndLaundryRoom>
    ) {
        try {
            await props.changeLaundryRoomMethod(
                props.data.userId,
                Number(values.locationId)
            );
            setPatchTriggered(true);
            actions.setSubmitting(false);
        } catch (e) {}
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({
        manageUserStore: store.manageUser,
        allMansions: store.location.allMansions,
        filteredMansions: store.location.filteredMansions,
        filteredLocations: store.location.filteredLocations,
        user: store.manageUser,
        searchResultsById: store.searchUser.resultsById,
    }),
    {
        fetchAllMansions,
        filterMansions,
        filterLocations,
        changeLaundryRoomMethod,
        close: closeModal,
    }
)(ChangeLaundryRoomWithList);
