import React, { Fragment, FunctionComponent, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { registerAction } from '../../store/registration/registrationActions';
import { RootStore } from '../../store/rootStore';
import {
    fetchAllMansions,
    filterLocations,
    filterMansions,
} from '../../store/location/locationActions';
import {
    LocationForSelection,
    MansionWithLocations,
} from '../../store/location/locationReducer';
import PaymentForm from '../payment/PaymentForm';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { RegistrationStore } from '../../store/registration/registrationReducer';
import ErrorFeedback from '../ErrorFeedback';
import { FormControl } from 'react-bootstrap';
import Alert from 'react-bootstrap/Alert';
import {
    Country,
    Language,
    PhoneUserRegistrationData,
    Salutation,
} from '../../types/phoneUserRegistrationTypes';
import {
    InvoiceDeliveryMethod,
    UserType,
} from '../../store/search-user/searchUserReducer';
import {
    ALLOWED_INVOICE_DELIVERY_BY_USER_TYPE,
    TRANSLATIONS_FOR_INVOICE_DELIVERY,
} from '../../components/manage-user/ChangeInvoiceDelivery';

const INVOICE_DELIVERY_WITH_EMAIL = [
    InvoiceDeliveryMethod.E_MAIL,
    InvoiceDeliveryMethod.LETTER,
    InvoiceDeliveryMethod.NONE,
];
const INVOICE_DELIVERY_WITHOUT_EMAIL = [
    InvoiceDeliveryMethod.LETTER,
    InvoiceDeliveryMethod.NONE,
];

const FIELD_INVOICE_DELIVERY = 'invoice_delivery_method';

const schema: ObjectSchema<PhoneUserRegistrationData> = yup.object({
    smartSearchValue: yup.string(),
    agbAccepted: yup.bool().required(),
    userInfoAvailable: yup.bool().required(),
    language: yup.mixed<Language>().oneOf(Object.values(Language)).required(),
    salutation: yup
        .mixed<Salutation>()
        .oneOf(Object.values(Salutation))
        .required(),
    firstName: yup.string().required('Bitte ausfüllen').min(2, 'Zu kurz'),
    lastName: yup.string().required('Bitte ausfüllen').min(2, 'Zu kurz'),
    phoneCountryCode: yup.string().required(),
    phoneNumber: yup.string().required('Bitte ausfüllen').min(4, 'Zu kurz'),
    email: yup.string(),
    user_provides_email: yup.boolean(),
    invoice_delivery_method: yup
        .mixed<InvoiceDeliveryMethod>()
        .required()
        .when('user_provides_email', (user_provides_email: boolean) => {
            if (user_provides_email) {
                return yup
                    .mixed<InvoiceDeliveryMethod>()
                    .oneOf(INVOICE_DELIVERY_WITH_EMAIL)
                    .required();
            } else {
                return yup
                    .mixed<InvoiceDeliveryMethod>()
                    .oneOf(
                        INVOICE_DELIVERY_WITHOUT_EMAIL,
                        'Rechnungszustellung via E-Mail benötigt eine hinterlegte E-Mail-Adresse'
                    )
                    .required();
            }
        }),
    street: yup.string().required(),
    housenumber: yup.string().required(),
    postalcode: yup.string().required(),
    city: yup.string().required(),
    country: yup.mixed<Country>().oneOf(Object.values(Country)).required(),
    mansionId: yup.string().required(),
    locationId: yup.string().required(),
    iban: yup.string(),
    accountHolder: yup.string(),
});

const initialValues: PhoneUserRegistrationData = {
    smartSearchValue: '',
    agbAccepted: false,
    userInfoAvailable: false,
    language: Language.GERMAN,
    salutation: Salutation.MALE,
    firstName: '',
    lastName: '',
    phoneCountryCode: '+49',
    phoneNumber: '',
    email: '',
    user_provides_email: true,
    invoice_delivery_method: InvoiceDeliveryMethod.E_MAIL,
    street: '',
    housenumber: '',
    postalcode: '',
    city: '',
    country: Country.GERMANY,
    mansionId: '',
    locationId: '',
    iban: '',
    accountHolder: '',
};

interface Props {
    filteredMansions: MansionWithLocations[];
    filteredLocations: LocationForSelection[];
    registerAction: (
        registrationData: PhoneUserRegistrationData
    ) => Promise<any>;
    registration: RegistrationStore;
    fetchAllMansions: () => void;
    filterMansions: (postalcode: string) => void;
    filterLocations: (mansionId: string) => void;
    ccAgentEmail: string;
}

const PhoneUserRegistrationForm: FunctionComponent<Props> = (props) => {
    async function handleFormSubmit(
        values: PhoneUserRegistrationData,
        actions: FormikActions<PhoneUserRegistrationData>
    ) {
        try {
            if (!values.user_provides_email) {
                values.email = '';
            }
            await props.registerAction(values);
            actions.setSubmitting(false);
        } catch (e) {
            console.error('Error during registration', e);
        }
    }

    useEffect(() => {
        props.fetchAllMansions();
    }, []);

    return (
        <div id="PhoneUserRegistrationForm">
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {(formProps: FormikProps<PhoneUserRegistrationData>) => {
                    function handleChangeAndTriggerMansionSearch(
                        event: React.ChangeEvent<any>
                    ) {
                        props.filterMansions(event.target.value);
                        formProps.handleChange(event);
                    }

                    function handleMansionChange(
                        event: React.ChangeEvent<any>
                    ) {
                        props.filterLocations(event.target.value);
                        formProps.handleChange(event);
                    }

                    function handleChangeAndSetTouched(
                        event: React.ChangeEvent<any>
                    ) {
                        formProps.handleChange(event);
                        formProps.handleBlur(event);
                    }

                    if (!formProps.touched.invoice_delivery_method) {
                        if (
                            formProps.values.user_provides_email &&
                            formProps.values.invoice_delivery_method !==
                                InvoiceDeliveryMethod.E_MAIL
                        ) {
                            formProps.setFieldValue(
                                FIELD_INVOICE_DELIVERY,
                                InvoiceDeliveryMethod.E_MAIL
                            );
                        } else if (
                            !formProps.values.user_provides_email &&
                            formProps.values.invoice_delivery_method !==
                                InvoiceDeliveryMethod.LETTER
                        ) {
                            formProps.setFieldValue(
                                FIELD_INVOICE_DELIVERY,
                                InvoiceDeliveryMethod.LETTER
                            );
                        }
                    } else if (
                        !formProps.values.user_provides_email &&
                        formProps.values.invoice_delivery_method ===
                            InvoiceDeliveryMethod.E_MAIL
                    ) {
                        formProps.setFieldValue(
                            FIELD_INVOICE_DELIVERY,
                            InvoiceDeliveryMethod.LETTER
                        );
                    }

                    if (props.filteredMansions.length > 0) {
                        if (
                            !props.filteredMansions.find(
                                (it) =>
                                    it.id.toString() ===
                                    formProps.values.mansionId
                            )
                        ) {
                            formProps.setFieldValue(
                                'mansionId',
                                props.filteredMansions[0].id.toString()
                            );
                            props.filterLocations(
                                props.filteredMansions[0].id.toString()
                            );
                        }
                    } else {
                        if (formProps.values.mansionId !== '') {
                            formProps.setFieldValue('mansionId', '');
                            props.filterLocations('');
                        }
                    }

                    let selectedLocation: LocationForSelection | undefined;

                    if (props.filteredLocations.length > 0) {
                        selectedLocation = props.filteredLocations.find(
                            (it) =>
                                it.id.toString() === formProps.values.locationId
                        );
                        if (!selectedLocation) {
                            selectedLocation = props.filteredLocations[0];
                            formProps.setFieldValue(
                                'locationId',
                                selectedLocation.id.toString()
                            );
                        }
                    } else {
                        selectedLocation = undefined;
                        if (formProps.values.locationId !== '') {
                            formProps.setFieldValue('locationId', '');
                        }
                    }

                    const invoiceDeliveryOptions = formProps.values
                        .user_provides_email
                        ? INVOICE_DELIVERY_WITH_EMAIL
                        : INVOICE_DELIVERY_WITHOUT_EMAIL;

                    return (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <h3 className="mt-5 mb-4">Vorbereitende Fragen</h3>
                            <Row className="ml-3">
                                <Form.Group controlId="agbAccepted">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        label="AGB akzeptiert?"
                                        checked={formProps.values.agbAccepted}
                                        onChange={formProps.handleChange}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="ml-3">
                                <Form.Group controlId="userInfoAvailable">
                                    <Form.Check
                                        custom
                                        type="checkbox"
                                        label="Liegt Zahlmethode und Rechnungsadresse vor?"
                                        checked={
                                            formProps.values.userInfoAvailable
                                        }
                                        onChange={formProps.handleChange}
                                    />
                                </Form.Group>
                            </Row>
                            {formProps.values.agbAccepted &&
                                formProps.values.userInfoAvailable && (
                                    <Fragment>
                                        <h3 className="mt-5 mb-4">Waschraum</h3>
                                        <Row className="ml-1">
                                            <Col sm={8}>
                                                <Form.Group controlId="smartSearchValue">
                                                    <Form.Label column={false}>
                                                        Freie Waschraumsuche
                                                        (Straße, Ort, PLZ)
                                                    </Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        onChange={
                                                            handleChangeAndTriggerMansionSearch
                                                        }
                                                        value={
                                                            formProps.values
                                                                .smartSearchValue
                                                        }
                                                    />
                                                </Form.Group>
                                            </Col>
                                        </Row>
                                        <Form.Group
                                            className="ml-3"
                                            controlId="mansionId"
                                        >
                                            <Form.Label column={false}>
                                                Objekt
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={handleMansionChange}
                                                value={
                                                    formProps.values.mansionId
                                                }
                                                isInvalid={
                                                    formProps.touched
                                                        .mansionId &&
                                                    !!formProps.errors.mansionId
                                                }
                                                isValid={
                                                    formProps.touched
                                                        .mansionId &&
                                                    !formProps.errors.mansionId
                                                }
                                            >
                                                {props.filteredMansions.map(
                                                    (it) => (
                                                        <option
                                                            key={it.id}
                                                            value={it.id}
                                                        >{`${it.street} ${it.housenumber}, ${it.postalcode} ${it.city}`}</option>
                                                    )
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group
                                            className="ml-3"
                                            controlId="locationId"
                                        >
                                            <Form.Label column={false}>
                                                Waschraum
                                            </Form.Label>
                                            <Form.Control
                                                as="select"
                                                onChange={
                                                    formProps.handleChange
                                                }
                                                value={
                                                    formProps.values.locationId
                                                }
                                                isInvalid={
                                                    formProps.touched
                                                        .locationId &&
                                                    !!formProps.errors
                                                        .locationId
                                                }
                                                isValid={
                                                    formProps.touched
                                                        .locationId &&
                                                    !formProps.errors.locationId
                                                }
                                            >
                                                {props.filteredLocations.map(
                                                    (it) => (
                                                        <option
                                                            key={it.id}
                                                            value={it.id}
                                                        >
                                                            {it.name}
                                                        </option>
                                                    )
                                                )}
                                            </Form.Control>
                                        </Form.Group>
                                        {selectedLocation &&
                                            !selectedLocation.phone_users_allowed && (
                                                <Alert
                                                    className="ml-3"
                                                    variant="danger"
                                                >
                                                    {
                                                        'Der ausgewählte Waschraum unterstützt keine Telefonbuchung'
                                                    }
                                                </Alert>
                                            )}
                                        {selectedLocation &&
                                            selectedLocation.phone_users_allowed && (
                                                <Fragment>
                                                    <h3 className="mt-5 mb-4">
                                                        Name
                                                    </h3>
                                                    <Row className="ml-3">
                                                        <Col sm={3}>
                                                            <Form.Group controlId="language">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Sprache
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .language
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            Language.GERMAN
                                                                        }
                                                                    >
                                                                        Deutsch
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            Language.ENGLISH
                                                                        }
                                                                    >
                                                                        Englisch
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={3}>
                                                            <Form.Group controlId="salutation">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Anrede
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .salutation
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            Salutation.MALE
                                                                        }
                                                                    >
                                                                        Herr
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            Salutation.FEMALE
                                                                        }
                                                                    >
                                                                        Frau
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-3">
                                                        <Col>
                                                            <Form.Group controlId="firstName">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Vorname
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .firstName
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .firstName &&
                                                                        !!formProps
                                                                            .errors
                                                                            .firstName
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .firstName &&
                                                                        !formProps
                                                                            .errors
                                                                            .firstName
                                                                    }
                                                                />
                                                                <FormControl.Feedback type="invalid">
                                                                    {
                                                                        formProps
                                                                            .errors
                                                                            .firstName
                                                                    }
                                                                </FormControl.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <Form.Group controlId="lastName">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Nachname
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .lastName
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .lastName &&
                                                                        !!formProps
                                                                            .errors
                                                                            .lastName
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .lastName &&
                                                                        !formProps
                                                                            .errors
                                                                            .lastName
                                                                    }
                                                                />
                                                                <FormControl.Feedback type="invalid">
                                                                    {
                                                                        formProps
                                                                            .errors
                                                                            .lastName
                                                                    }
                                                                </FormControl.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h3 className="mt-5 mb-4">
                                                        Telefonnummer (für
                                                        Buchung)
                                                    </h3>
                                                    <Row className="ml-3">
                                                        <Col sm={2}>
                                                            <Form.Group controlId="phoneCountryCode">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Ländervorwahl
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .phoneCountryCode
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .phoneCountryCode &&
                                                                        !!formProps
                                                                            .errors
                                                                            .phoneCountryCode
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .phoneCountryCode &&
                                                                        !formProps
                                                                            .errors
                                                                            .phoneCountryCode
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={5}>
                                                            <Form.Group controlId="phoneNumber">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                    className="text-nowrap"
                                                                >
                                                                    Telefonnummer
                                                                    (z.B. 089
                                                                    120 350 im
                                                                    Format
                                                                    89120350
                                                                    eingeben)
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .phoneNumber
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .phoneNumber &&
                                                                        !!formProps
                                                                            .errors
                                                                            .phoneNumber
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .phoneNumber &&
                                                                        !formProps
                                                                            .errors
                                                                            .phoneNumber
                                                                    }
                                                                />
                                                                <FormControl.Feedback type="invalid">
                                                                    {
                                                                        formProps
                                                                            .errors
                                                                            .phoneNumber
                                                                    }
                                                                </FormControl.Feedback>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h3 className="mt-5 mb-4">
                                                        Kontaktinformationen
                                                    </h3>
                                                    <Row className="ml-3">
                                                        <Col sm={8}>
                                                            <Form.Group controlId="user_provides_email">
                                                                <Form.Check
                                                                    custom
                                                                    type="checkbox"
                                                                    label="Der Nutzer stellt WeWash seine E-Mail zur Verfügung"
                                                                    checked={
                                                                        formProps
                                                                            .values
                                                                            .user_provides_email
                                                                    }
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-3">
                                                        <Col
                                                            sm={5}
                                                            style={{
                                                                marginLeft:
                                                                    '1rem',
                                                            }}
                                                        >
                                                            {formProps.values
                                                                .user_provides_email && (
                                                                <Form.Group controlId="email">
                                                                    <Form.Label
                                                                        column={
                                                                            false
                                                                        }
                                                                        className="text-nowrap"
                                                                    >
                                                                        E-Mail
                                                                    </Form.Label>
                                                                    <Form.Control
                                                                        type="text"
                                                                        onChange={
                                                                            formProps.handleChange
                                                                        }
                                                                        value={
                                                                            formProps
                                                                                .values
                                                                                .email
                                                                        }
                                                                        isInvalid={
                                                                            formProps
                                                                                .touched
                                                                                .email &&
                                                                            !!formProps
                                                                                .errors
                                                                                .email
                                                                        }
                                                                        isValid={
                                                                            formProps
                                                                                .touched
                                                                                .email &&
                                                                            !formProps
                                                                                .errors
                                                                                .email
                                                                        }
                                                                    />
                                                                    <FormControl.Feedback type="invalid">
                                                                        {
                                                                            formProps
                                                                                .errors
                                                                                .email
                                                                        }
                                                                    </FormControl.Feedback>
                                                                </Form.Group>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                    <h3 className="mt-5 mb-4">
                                                        Rechnungsadresse
                                                    </h3>
                                                    <Row className="ml-3">
                                                        <Col sm={4}>
                                                            <Form.Group controlId="invoice_delivery_method">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Rechnungszustellung
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={
                                                                        handleChangeAndSetTouched
                                                                    }
                                                                    onBlur={
                                                                        formProps.handleBlur
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .invoice_delivery_method
                                                                    }
                                                                >
                                                                    {ALLOWED_INVOICE_DELIVERY_BY_USER_TYPE[
                                                                        UserType
                                                                            .PHONE
                                                                    ].map(
                                                                        (
                                                                            it
                                                                        ) => (
                                                                            <option
                                                                                key={
                                                                                    it
                                                                                }
                                                                                value={
                                                                                    it
                                                                                }
                                                                                disabled={
                                                                                    !invoiceDeliveryOptions.includes(
                                                                                        it
                                                                                    )
                                                                                }
                                                                            >
                                                                                {
                                                                                    TRANSLATIONS_FOR_INVOICE_DELIVERY[
                                                                                        it
                                                                                    ]
                                                                                }
                                                                            </option>
                                                                        )
                                                                    )}
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-3">
                                                        <Col sm={8}>
                                                            <Form.Group controlId="street">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Straße
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .street
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .street &&
                                                                        !!formProps
                                                                            .errors
                                                                            .street
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .street &&
                                                                        !formProps
                                                                            .errors
                                                                            .street
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={2}>
                                                            <Form.Group controlId="housenumber">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Hausnummer
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .housenumber
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .housenumber &&
                                                                        !!formProps
                                                                            .errors
                                                                            .housenumber
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .housenumber &&
                                                                        !formProps
                                                                            .errors
                                                                            .housenumber
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-3">
                                                        <Col sm={3}>
                                                            <Form.Group controlId="postalcode">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    PLZ
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .postalcode
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .postalcode &&
                                                                        !!formProps
                                                                            .errors
                                                                            .postalcode
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .postalcode &&
                                                                        !formProps
                                                                            .errors
                                                                            .postalcode
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col sm={6}>
                                                            <Form.Group controlId="city">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Ort
                                                                </Form.Label>
                                                                <Form.Control
                                                                    type="text"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .city
                                                                    }
                                                                    isInvalid={
                                                                        formProps
                                                                            .touched
                                                                            .city &&
                                                                        !!formProps
                                                                            .errors
                                                                            .city
                                                                    }
                                                                    isValid={
                                                                        formProps
                                                                            .touched
                                                                            .city &&
                                                                        !formProps
                                                                            .errors
                                                                            .city
                                                                    }
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <Row className="ml-3">
                                                        <Col sm={4}>
                                                            <Form.Group controlId="country">
                                                                <Form.Label
                                                                    column={
                                                                        false
                                                                    }
                                                                >
                                                                    Land
                                                                </Form.Label>
                                                                <Form.Control
                                                                    as="select"
                                                                    onChange={
                                                                        formProps.handleChange
                                                                    }
                                                                    value={
                                                                        formProps
                                                                            .values
                                                                            .country
                                                                    }
                                                                >
                                                                    <option
                                                                        value={
                                                                            Country.GERMANY
                                                                        }
                                                                    >
                                                                        Deutschland
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            Country.AUSTRIA
                                                                        }
                                                                    >
                                                                        Österreich
                                                                    </option>
                                                                    <option
                                                                        value={
                                                                            Country.SINGAPORE
                                                                        }
                                                                    >
                                                                        Singapur
                                                                    </option>
                                                                </Form.Control>
                                                            </Form.Group>
                                                        </Col>
                                                    </Row>
                                                    <h3 className="mt-5 mb-4">
                                                        Zahlungsmethode
                                                    </h3>
                                                    <div className="my-5 ml-3 justify-content-center">
                                                        <PaymentForm
                                                            handleChange={
                                                                formProps.handleChange
                                                            }
                                                            iban={
                                                                formProps.values
                                                                    .iban
                                                            }
                                                            accountHolder={
                                                                formProps.values
                                                                    .accountHolder
                                                            }
                                                        />
                                                    </div>
                                                    <ErrorFeedback
                                                        apiErrors={
                                                            props.registration
                                                                .errors
                                                        }
                                                    />
                                                    {formProps.submitCount >
                                                        0 && (
                                                        <ErrorFeedback
                                                            formikErrors={
                                                                formProps.errors
                                                            }
                                                        />
                                                    )}
                                                    <Button
                                                        type="submit"
                                                        disabled={
                                                            formProps.isSubmitting
                                                        }
                                                        size="lg"
                                                    >
                                                        {formProps.isSubmitting
                                                            ? 'Bitte warten...'
                                                            : 'Absenden'}
                                                    </Button>
                                                </Fragment>
                                            )}
                                    </Fragment>
                                )}
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        filteredMansions: store.location.filteredMansions,
        filteredLocations: store.location.filteredLocations,
        ccAgentEmail: store.user.data
            ? store.user.data.email
            : 'ccagent@we-wash.com',
        registration: store.registration,
    }),
    { registerAction, fetchAllMansions, filterMansions, filterLocations }
)(PhoneUserRegistrationForm);
