import { AnyAction } from 'redux-starter-kit';
import RestClient from '../../http/RestClient';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { DirectDebitData } from '../../forms/payment/PaymentFormStandalone';
import { extractErrorTags } from '../../http/errors';
import { loadUserDetails } from '../manage-user/manageUserActions';

export const REGISTER_PAYMENT = 'REGISTER_PAYMENT';
export const REGISTER_PAYMENT_SUCCESS = 'REGISTER_PAYMENT_SUCCESS';
export const REGISTER_PAYMENT_ERROR = 'REGISTER_PAYMENT_ERROR';

export function addPaymentAction(userId: number, data: DirectDebitData) {
    const paymentData = {
        iban: data.iban,
        account_owner: data.accountHolder,
    };

    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REGISTER_PAYMENT });
        try {
            await RestClient.post(
                `/v1/callcenter/payments/${userId}`,
                paymentData
            );
            dispatch({
                type: REGISTER_PAYMENT_SUCCESS,
                payload: { data: { ...data, userId } },
            });
            dispatch(loadUserDetails(userId));
        } catch (error) {
            console.error('error', error);
            dispatch({
                type: REGISTER_PAYMENT_ERROR,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
