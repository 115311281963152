import React, { FunctionComponent, useEffect, useState } from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import ErrorFeedback from '../../forms/ErrorFeedback';
import styles from '../../forms/search-user/SearchUser.module.css';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    searchBarTitle: string;
    searchPlaceHolderText: string;
    errors: WeWashApiErrorTag[];
    onFormSubmit: (searchTerm: string) => void;
    prefilledText?: string | null;
}

interface SearchTerm {
    searchTerm: string;
}

const SearchBarGeneric: FunctionComponent<Props> = (props) => {
    const initValues: SearchTerm = { searchTerm: props.prefilledText || '' };

    const [previousText, setPreviousText] = useState<string>(
        initValues.searchTerm
    );
    useEffect(() => {
        setPreviousText(props.prefilledText || '');
    }, [props.prefilledText]);

    function handleFormSubmit(
        values: SearchTerm,
        actions: FormikActions<SearchTerm>
    ) {
        props.onFormSubmit(values.searchTerm);
        actions.setSubmitting(false);
    }

    return (
        <div>
            <h3 className={styles.wewashHeading}>{props.searchBarTitle}</h3>

            <Formik
                onSubmit={handleFormSubmit}
                initialValues={initValues}
                enableReinitialize={previousText !== initValues.searchTerm}
            >
                {(formProps: FormikProps<SearchTerm>) => (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        <Form.Group
                            controlId="formGroupSearch"
                            className={styles.searchBar}
                        >
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    name="searchTerm"
                                    className={styles.input}
                                    value={formProps.values.searchTerm}
                                    placeholder={props.searchPlaceHolderText}
                                    onChange={formProps.handleChange}
                                />
                                <InputGroup.Append>
                                    <Button
                                        variant="outline-dark"
                                        type="submit"
                                        disabled={
                                            formProps.isSubmitting ||
                                            formProps.values.searchTerm
                                                .length === 0
                                        }
                                    >
                                        <FontAwesomeIcon
                                            icon={faSearch}
                                            className={styles.icon}
                                        />
                                    </Button>
                                </InputGroup.Append>
                            </InputGroup>
                            <ErrorFeedback apiErrors={props.errors} />
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SearchBarGeneric;
