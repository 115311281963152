export interface AmountAndCurrency {
    amount: number;
    currency: Currency;
}

export enum Currency {
    EUR = 'EUR',
    SGD = 'SGD',
    GBP = 'GBP',
    CHF = 'CHF',
}

export function formatMonetaryAmount(amountAndCurrency: AmountAndCurrency) {
    if (!amountAndCurrency) {
        return null;
    }
    const language = 'de';
    const amount = amountAndCurrency.amount;
    const currency = amountAndCurrency.currency;

    return Number(amount).toLocaleString(language, {
        style: 'currency',
        currency,
        minimumFractionDigits: 2,
    });
}
