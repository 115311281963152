import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    LOAD_PAYMENT_DATA,
    LOAD_PAYMENT_DATA_SUCCESS,
    LOAD_PAYMENT_DATA_FAILURE,
    SET_PAYMENT_FAILED,
    SET_PAYMENT_FAILED_SUCCESS,
    SET_PAYMENT_FAILED_FAILURE,
} from './failedPaymentActions';
import { RESET_VIEWS } from '../genericActions';

export enum FailedPaymentRequestState {
    INITIAL = 'initial',
    LOADING = 'loading',
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export interface FailedPaymentStore {
    loadingState: FailedPaymentRequestState;
    setPaymentFailedState: FailedPaymentRequestState;
    loadingErrors: WeWashApiErrorTag[];
    setPaymentFailedErrors: WeWashApiErrorTag[];
    data: FinancialTransactionDto | null;
}

export interface FinancialTransactionDto {
    financial_document_number: string;
    financial_document_type: string;
    financial_document_payment_status: string;
    financial_transaction_id: number;
    timestamp: number;
    user_id: number;
    user_name: string;
    payment_method_id: number;
    payment_method_info: string;
    payment_method_status: string;
}

const initialStore: FailedPaymentStore = {
    loadingState: FailedPaymentRequestState.INITIAL,
    setPaymentFailedState: FailedPaymentRequestState.INITIAL,
    loadingErrors: [],
    setPaymentFailedErrors: [],
    data: null,
};

export const failedPaymentReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_PAYMENT_DATA]: (state: FailedPaymentStore, action) => {
        state.loadingState = FailedPaymentRequestState.LOADING;
        state.setPaymentFailedState = FailedPaymentRequestState.INITIAL;
        state.loadingErrors = [];
    },
    [LOAD_PAYMENT_DATA_SUCCESS]: (state: FailedPaymentStore, action) => {
        state.loadingState = FailedPaymentRequestState.SUCCESS;
        state.data = action.payload.data;
        state.loadingErrors = [];
    },
    [LOAD_PAYMENT_DATA_FAILURE]: (state: FailedPaymentStore, action) => {
        state.loadingState = FailedPaymentRequestState.FAILURE;
        state.loadingErrors = action.payload.errors;
    },
    [SET_PAYMENT_FAILED]: (state: FailedPaymentStore, action) => {
        state.setPaymentFailedState = FailedPaymentRequestState.LOADING;
        state.setPaymentFailedErrors = [];
    },
    [SET_PAYMENT_FAILED_SUCCESS]: (state: FailedPaymentStore, action) => {
        state.setPaymentFailedState = FailedPaymentRequestState.SUCCESS;
        state.setPaymentFailedErrors = [];
    },
    [SET_PAYMENT_FAILED_FAILURE]: (state: FailedPaymentStore, action) => {
        state.setPaymentFailedState = FailedPaymentRequestState.FAILURE;
        state.setPaymentFailedErrors = action.payload.errors;
    },
});
