import React from 'react';
import { LaundryRoomForVipUsers } from '../../store/manage-vip-users/manageVipUsersActions';
import VipUsersLaundryRoomCard from './VipUsersLaundryRoomCard';
import VipPricingForm from './VipPricingForm';
import AddVipUsersForm from './AddVipUsersForm';
import { Link } from 'react-router-dom';
import { Route } from '../../Router';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

interface Props {
    laundryRoom: LaundryRoomForVipUsers;
}

function ManageVipUsersDisplay(props: Props) {
    return (
        <div>
            <Row className={'mt-3'}>
                <Col>
                    <h3>Informationen zum Waschraum</h3>
                    <VipUsersLaundryRoomCard laundryRoom={props.laundryRoom} />
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col>
                    <h3>Preise für VIP Nutzer</h3>
                    <VipPricingForm laundryRoom={props.laundryRoom} />
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col>
                    <h3>VIP Nutzer entfernen</h3>
                    <Link
                        to={`${Route.VIP_USERS_LIST}/${props.laundryRoom.id}`}
                    >
                        Bestehende VIP Nutzer
                    </Link>
                </Col>
            </Row>
            <Row className={'mt-3'}>
                <Col>
                    <h3>VIP Nutzer hinzufügen</h3>
                    <AddVipUsersForm laundryRoom={props.laundryRoom} />
                </Col>
            </Row>
        </div>
    );
}

export default ManageVipUsersDisplay;
