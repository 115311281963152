import { createReducer } from 'redux-starter-kit';
import {
    CREATE_LAUNDRY_ROOM_QR_ID,
    CREATE_LAUNDRY_ROOM_QR_ID_FAILURE,
    CREATE_LAUNDRY_ROOM_QR_ID_SUCCESS,
    DELETE_LAUNDRY_ROOM_QR_ID,
    DELETE_LAUNDRY_ROOM_QR_ID_FAILURE,
    DELETE_LAUNDRY_ROOM_QR_ID_SUCCESS,
    FETCH_ALL_LOCATIONS,
    FETCH_ALL_LOCATIONS_FAILURE,
    FETCH_ALL_LOCATIONS_SUCCESS,
    FETCH_LAUNDRY_ROOM,
    FETCH_LAUNDRY_ROOM_FAILURE,
    FETCH_LAUNDRY_ROOM_QR_IDS,
    FETCH_LAUNDRY_ROOM_QR_IDS_FAILURE,
    FETCH_LAUNDRY_ROOM_QR_IDS_SUCCESS,
    FETCH_LAUNDRY_ROOM_SUCCESS,
    FILTER_LOCATIONS,
    FILTER_MANSIONS,
} from './locationActions';
import { WeWashApiErrorTag } from '../../http/errors';
import { AddressDto } from '../manage-appliances/manageApplianceReducer';
import { LaundryRoomWithApplianceStates } from '../manage-laundry-room/manageLaundryRoomUtils';
import { LocationMetadata } from '../../forms/manage-appliance/LaundryRoomAnchorCard';

export enum LaundryLessonFrequency {
    OFF = 'OFF',
    DEFAULT = 'DEFAULT',
    HIGH = 'HIGH',
}

export interface LaundryRoomOverviewDto extends LaundryRoomWithApplianceStates {
    id: number;
    name: string;
    address: AddressDto;
    note: string;
    has_laundry_room_card: boolean;
    has_critical_note: boolean;
    show_critical_note: boolean;
    needs_monitoring: boolean;
    visibility_distance: number;
    critical_note: string;
    appliances: ApplianceOverviewDto[];
    laundry_lesson_frequency: LaundryLessonFrequency;
    location_meta_data_dto: LocationMetadata;
    lifecycle_status: LifecycleStatus;
    feedback_question_id?: number;
    feedback_question_response_count?: number;
    desired_feedback_count?: number;
    pre_payment_required?: boolean;
    supports_scan2_wash?: boolean;
}

export interface ApplianceOverviewDto {
    appliance_id: number;
    enabled: boolean;
    appliance_short_name: string;
    control_type: string;
    status_since: number;
    source: string;
    reason: string;
}

export enum LocationQrIdStatus {
    VERIFIED = 'VERIFIED',
    UNVERIFIED = 'UNVERIFIED',
}

export interface LaundryRoomQrIdOverview {
    id: number;
    qr_id: string;
    verification_count: number;
    verification_status: LocationQrIdStatus;
}

export enum LifecycleStatus {
    DELETE_REQUESTED = 'DELETE_REQUESTED',
    DELETED = 'DELETED',
    ACTIVE = 'ACTIVE',
}

export interface Location {
    id: number;
    name: string;
    address: string;
    note: string;
    lifecycle_status: LifecycleStatus;
}

export interface LocationForSelection {
    id: number;
    name: string;
    phone_users_allowed: boolean;
}

export interface MansionWithLocations {
    id: number;
    locations: LocationForSelection[];
    street: string;
    housenumber: string;
    city: string;
    postalcode: string;
}

export interface LocationStore {
    allMansions: MansionWithLocations[];
    filteredMansions: MansionWithLocations[];
    filteredLocations: LocationForSelection[];
    locationQrIds: LaundryRoomQrIdOverview[];
    loading: boolean;
    loadingQrIds: boolean;
    createLocationQrIdErrorMessage: string;
    qrIdsErrors: WeWashApiErrorTag[];
    errors: WeWashApiErrorTag[];
    selectedLocation?: LaundryRoomOverviewDto;
}

const initialStore: LocationStore = {
    allMansions: [],
    filteredMansions: [],
    filteredLocations: [],
    locationQrIds: [],
    loading: false,
    loadingQrIds: false,
    createLocationQrIdErrorMessage: '',
    qrIdsErrors: [],
    errors: [],
    selectedLocation: undefined,
};

export const locationReducer = createReducer(initialStore, {
    [FETCH_ALL_LOCATIONS]: (state: LocationStore) => {
        state.loading = true;
        state.errors = [];
    },
    [FETCH_ALL_LOCATIONS_SUCCESS]: (state: LocationStore, action) => {
        const sortedMansions: MansionWithLocations[] = sortMansionsByStreet(
            action.payload.data
        );
        state.loading = false;
        state.errors = [];
        state.allMansions = sortedMansions;
        state.filteredMansions = sortedMansions;
    },
    [FETCH_ALL_LOCATIONS_FAILURE]: (state: LocationStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [FETCH_LAUNDRY_ROOM]: (state: LocationStore) => {
        state.loading = false;
        state.errors = [];
    },
    [FETCH_LAUNDRY_ROOM_SUCCESS]: (state: LocationStore, action) => {
        state.loading = false;
        state.errors = [];
        state.selectedLocation = action.payload.data;
    },
    [FETCH_LAUNDRY_ROOM_FAILURE]: (state: LocationStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [FILTER_MANSIONS]: (state: LocationStore, action) => {
        state.filteredMansions = filterMansions(
            state.allMansions,
            action.payload.searchValue
        );
    },
    [FILTER_LOCATIONS]: (state: LocationStore, action) => {
        state.filteredLocations = filterLocations(
            state.filteredMansions,
            action.payload.mansionId
        );
    },
    [FETCH_LAUNDRY_ROOM_QR_IDS]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = [];
    },
    [FETCH_LAUNDRY_ROOM_QR_IDS_SUCCESS]: (state: LocationStore, action) => {
        state.loading = false;
        state.qrIdsErrors = [];
        state.locationQrIds = action.payload.data;
    },
    [FETCH_LAUNDRY_ROOM_QR_IDS_FAILURE]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = action.payload.errors;
    },
    [DELETE_LAUNDRY_ROOM_QR_ID]: (state: LocationStore, action) => {
        state.loading = false;
        state.qrIdsErrors = [];
    },
    [DELETE_LAUNDRY_ROOM_QR_ID_SUCCESS]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = [];
    },
    [DELETE_LAUNDRY_ROOM_QR_ID_FAILURE]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = action.payload.errors;
    },
    [CREATE_LAUNDRY_ROOM_QR_ID]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = [];
    },
    [CREATE_LAUNDRY_ROOM_QR_ID_SUCCESS]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = [];
    },
    [CREATE_LAUNDRY_ROOM_QR_ID_FAILURE]: (state: LocationStore, action) => {
        state.loadingQrIds = false;
        state.qrIdsErrors = action.payload.errors;
        state.createLocationQrIdErrorMessage = action.payload.message;
    },
});

function filterMansions(
    allMansions: MansionWithLocations[],
    searchValue: string
): MansionWithLocations[] {
    const searchRegex = new RegExp(searchValue, 'i');

    const filteredMansions = allMansions.filter(
        (mansion) =>
            searchRegex.test(mansion.postalcode) ||
            searchRegex.test(mansion.city) ||
            searchRegex.test(mansion.street)
    );

    return sortMansionsByStreet(filteredMansions);
}

function filterLocations(
    filteredMansions: MansionWithLocations[],
    mansionId: string
): LocationForSelection[] {
    const mansion = filteredMansions.find(
        (mansion) => mansion.id === Number(mansionId)
    );
    if (mansion) {
        return mansion.locations;
    } else {
        return [];
    }
}

function sortMansionsByStreet(mansions: MansionWithLocations[]) {
    return mansions.sort((a, b) => (a.street > b.street ? 1 : -1));
}
