import React, { FunctionComponent } from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import {
    CreditNoteData,
    fileInternalCreditNote,
    FileInternalCreditNoteRequestValues,
} from '../../store/manage-credit-notes/manageCreditNotesActions';
import * as yup from 'yup';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    fileInternalCreditNote: (
        values: FileInternalCreditNoteRequestValues
    ) => void;
    creditNote: CreditNoteData;
}

interface FileInternalCreditNoteData {
    partnerGross: string;
}

const schemaFileInternalCreditNote: yup.ObjectSchema<FileInternalCreditNoteData> =
    yup.object({
        partnerGross: yup.string().required(),
    });

const FileInternalCreditNote: FunctionComponent<Props> = (props) => {
    const partnerGross =
        (props.creditNote && props.creditNote.partner_gross) || 0;

    const initialInternalPartnerCreditNoteValue: FileInternalCreditNoteData = {
        partnerGross: partnerGross.toFixed(2),
    };
    return (
        <div id={'FileInternalCreditNoteForm'}>
            <Formik
                onSubmit={handleInternalCreditNoteFormSubmit}
                initialValues={initialInternalPartnerCreditNoteValue}
                validationSchema={schemaFileInternalCreditNote}
                children={renderFileInternalCreditNoteForm}
            />
        </div>
    );

    async function handleInternalCreditNoteFormSubmit(
        values: FileInternalCreditNoteData,
        actions: FormikActions<FileInternalCreditNoteData>
    ) {
        if (!props.creditNote) {
            return;
        }
        const requestObj: FileInternalCreditNoteRequestValues = {
            credit_note_Id: props.creditNote.credit_note_id,
            user_id: props.creditNote.user_id,
            partner_gross: String(values.partnerGross),
        };
        props.fileInternalCreditNote(requestObj);
    }

    function renderFileInternalCreditNoteForm(
        formProps: FormikProps<FileInternalCreditNoteData>
    ) {
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <h3 style={{ marginTop: 10 }}>File Internal Credit Note</h3>
                <Form.Group controlId={'partnerGross'}>
                    <Form.Label>Partner Anteil/Partner Gross</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={formProps.handleChange}
                        value={formProps.values.partnerGross}
                    />
                </Form.Group>
                <ErrorFeedback apiErrors={props.apiErrors} />
                <Button type={'submit'} size={'sm'}>
                    File Internal Credit Note
                </Button>
            </Form>
        );
    }
};
export default connect(
    (store: RootStore) => ({
        apiErrors: store.manageCreditNotes.errors,
    }),
    { fileInternalCreditNote }
)(FileInternalCreditNote);
