import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { closeModal, ModalParameters } from '../../store/modal/modalActions';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { Formik, FormikProps } from 'formik';
import { Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import { updateLocationSetSupportsScan2Wash } from '../../store/manage-laundry-room/manageLaundryRoomActions';

export interface SetSupportsScan2WashParams {
    supportsScan2Wash: boolean;
    laundryRoomId: number;
}

interface Props {
    params: SetSupportsScan2WashParams;
    close: (params?: ModalParameters<SetSupportsScan2WashParams>) => void;
    data: { laundryRoomId?: number; prePaymentRequired?: boolean };
    manageLaundryRoomStore: ManageLaundryRoomStore;
    updateSupportsScan2Wash: (
        locationId: number,
        supportScan2Wash: boolean,
        successCallback?: () => void
    ) => void;
}

function SetSupportsScan2WashForLaundryRoomModal(props: Props) {
    const initialValues: InputFormData = {
        supportsScan2Wash: props.params.supportsScan2Wash,
    };
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Scan2Wash Einstellen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{renderScan2WashBody()}</Modal.Body>
        </Fragment>
    );

    function renderScan2WashBody() {
        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                children={renderForm}
            />
        );

        function renderForm(formProps: FormikProps<InputFormData>) {
            return (
                <Form noValidate onSubmit={formProps.handleSubmit}>
                    <Form.Group
                        as={Row}
                        controlId={'formGroupLaundryRoomId'}
                        className={'mx-0'}
                    >
                        <ErrorFeedback
                            apiErrors={props.manageLaundryRoomStore.errors}
                        />
                        Soll Scan2Wash für den Waschraum (ID:{' '}
                        {props.data.laundryRoomId}) aktiviert werden?
                        <InputGroup>
                            <InputGroup>
                                <Form.Check
                                    custom
                                    label="Scan2Wash erlaubt"
                                    name={'supportsScan2Wash'}
                                    id={'supportsScan2Wash'}
                                    onChange={formProps.handleChange}
                                    checked={formProps.values.supportsScan2Wash}
                                />
                            </InputGroup>
                        </InputGroup>
                        <Row className={'mx-0 mt-4'}>
                            <Button
                                onClick={handleCancel}
                                className={'mr-2'}
                                variant="outline-secondary"
                            >
                                Abbrechen
                            </Button>
                            <Button type={'submit'}>
                                {props.manageLaundryRoomStore.loading && (
                                    <Spinner animation={'grow'} />
                                )}
                                Setzen
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            );
        }

        function handleSubmit(value: InputFormData) {
            if (
                props.data &&
                props.data.laundryRoomId &&
                value.supportsScan2Wash !== undefined
            ) {
                props.updateSupportsScan2Wash(
                    props.data.laundryRoomId,
                    value.supportsScan2Wash,
                    () => props.close()
                );
            }
        }

        function handleCancel() {
            props.close();
        }
    }
}

interface InputFormData {
    supportsScan2Wash?: boolean;
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SetSupportsScan2WashParams,
        manageLaundryRoomStore: state.manageLaundryRooms,
    }),
    {
        close: closeModal,
        updateSupportsScan2Wash: updateLocationSetSupportsScan2Wash,
    }
)(SetSupportsScan2WashForLaundryRoomModal);
