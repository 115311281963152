import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { AxiosResponse } from 'axios';
import { extractErrorMessage, extractErrorTags } from '../../http/errors';
import {
    LaundryRoomOverviewDto,
    MansionWithLocations,
} from './locationReducer';

export const FETCH_ALL_LOCATIONS = 'FETCH_ALL_LOCATIONS';
export const FETCH_ALL_LOCATIONS_SUCCESS = 'FETCH_ALL_LOCATIONS_SUCCESS';
export const FETCH_ALL_LOCATIONS_FAILURE = 'FETCH_ALL_LOCATIONS_FAILURE';

export const FETCH_LAUNDRY_ROOM = 'FETCH_LAUNDRY_ROOM';
export const FETCH_LAUNDRY_ROOM_SUCCESS = 'FETCH_LAUNDRY_ROOM_SUCCESS';
export const FETCH_LAUNDRY_ROOM_FAILURE = 'FETCH_LAUNDRY_ROOM_FAILURE';

export const FILTER_MANSIONS = 'FILTER_MANSIONS';
export const FILTER_LOCATIONS = 'FILTER_LOCATIONS';

export const FETCH_LAUNDRY_ROOM_QR_IDS = 'FETCH_LAUNDRY_ROOM_QR_IDS';
export const FETCH_LAUNDRY_ROOM_QR_IDS_SUCCESS =
    'FETCH_LAUNDRY_ROOM_QR_IDS_SUCCESS';
export const FETCH_LAUNDRY_ROOM_QR_IDS_FAILURE =
    'FETCH_LAUNDRY_ROOM_QR_IDS_FAILURE';

export const CREATE_LAUNDRY_ROOM_QR_ID = 'CREATE_LAUNDRY_ROOM_QR_ID';

export const CREATE_LAUNDRY_ROOM_QR_ID_SUCCESS =
    'CREATE_LAUNDRY_ROOM_QR_ID_SUCCESS';

export const CREATE_LAUNDRY_ROOM_QR_ID_FAILURE =
    'CREATE_LAUNDRY_ROOM_QR_ID_FAILURE';

export const DELETE_LAUNDRY_ROOM_QR_ID = 'DELETE_LAUNDRY_ROOM_QR_ID';

export const DELETE_LAUNDRY_ROOM_QR_ID_SUCCESS =
    'DELETE_LAUNDRY_ROOM_QR_ID_SUCCESS';

export const DELETE_LAUNDRY_ROOM_QR_ID_FAILURE =
    'DELETE_LAUNDRY_ROOM_QR_ID_FAILURE';

export function fetchLaundryRoom(laundryRoomId: string | number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: FETCH_LAUNDRY_ROOM });
        try {
            const getLocationByIdResponse: AxiosResponse<LaundryRoomOverviewDto> =
                await RestClient.get(
                    `v1/callcenter/laundry_room/${laundryRoomId}`
                );
            dispatch({
                type: FETCH_LAUNDRY_ROOM_SUCCESS,
                payload: { data: getLocationByIdResponse.data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_LAUNDRY_ROOM_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function fetchAllMansions() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: FETCH_ALL_LOCATIONS });
        try {
            const allLocationsResponse: AxiosResponse<MansionWithLocations[]> =
                await RestClient.get('v1/callcenter/all_locations');
            dispatch({
                type: FETCH_ALL_LOCATIONS_SUCCESS,
                payload: { data: allLocationsResponse.data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_ALL_LOCATIONS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function filterMansions(searchValue: string) {
    return { type: FILTER_MANSIONS, payload: { searchValue } };
}

export function filterLocations(mansionId: string) {
    return { type: FILTER_LOCATIONS, payload: { mansionId } };
}

export function fetchLaundryRoomQrIds(locationId?: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: FETCH_LAUNDRY_ROOM_QR_IDS });
        try {
            const response: AxiosResponse<MansionWithLocations[]> =
                await RestClient.get(
                    `v1/callcenter/laundry_room/${locationId}/qr_id`
                );
            dispatch({
                type: FETCH_LAUNDRY_ROOM_QR_IDS_SUCCESS,
                payload: { data: response.data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_LAUNDRY_ROOM_QR_IDS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function deleteLaundryRoomQrId(
    id: number,
    successCallback?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DELETE_LAUNDRY_ROOM_QR_ID });
        try {
            const response: AxiosResponse<MansionWithLocations[]> =
                await RestClient.delete(
                    `v1/callcenter/laundry_room_qr_id/${id}`
                );
            dispatch({
                type: DELETE_LAUNDRY_ROOM_QR_ID_SUCCESS,
                payload: { data: response.data },
            });
            if (successCallback) {
                successCallback();
            }
        } catch (error) {
            dispatch({
                type: DELETE_LAUNDRY_ROOM_QR_ID_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function createLaundryRoomQrId(
    laundryRoomId: number,
    qrId: string,
    successCallBack?: () => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CREATE_LAUNDRY_ROOM_QR_ID });
        try {
            const response: AxiosResponse<MansionWithLocations[]> =
                await RestClient.post(
                    `v1/callcenter/laundry_room/${laundryRoomId}/qr_id`,
                    {
                        qr_id: qrId,
                    }
                );
            dispatch({
                type: CREATE_LAUNDRY_ROOM_QR_ID_SUCCESS,
                payload: { data: response.data },
            });
            if (successCallBack) {
                successCallBack();
            }
        } catch (error) {
            dispatch({
                type: CREATE_LAUNDRY_ROOM_QR_ID_FAILURE,
                payload: {
                    errors: extractErrorTags(error),
                    message: extractErrorMessage(error),
                },
            });
        }
    };
}
