import React, { FunctionComponent } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikActions, FormikProps } from 'formik';
import { WeWashApiErrorTag } from '../../http/errors';
import ErrorFeedback from '../../forms/ErrorFeedback';
import styles from './GroupedCreditNotes.module.css';
import { CreditNotesSummary } from '../../store/grouped-credit-notes/groupedCreditNotesReducer';
import {
    getCreditNotesSummary,
    resetGroupedCreditNotesStore,
} from '../../store/grouped-credit-notes/groupedCreditNotesAction';
import CreditNotesSummaryTable from './CreditNotesSummaryTable';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    creditNotesSummary: CreditNotesSummary | null;
    getCreditNotesSummary: (year: number, month: number) => void;
    resetGroupedCreditNotesStore: () => void;
}

interface FormInput {
    month: string;
    year: string;
}

const MONTHS: number[] = Array.from({ length: 12 }, (v, i) => i + 1);

const GroupedCreditNotes: FunctionComponent<Props> = (props) => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);

    const initialValues: FormInput = {
        month: String(date.getMonth() + 1),
        year: String(date.getFullYear()),
    };

    function renderForm(formProps: FormikProps<FormInput>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <ErrorFeedback apiErrors={props.apiErrors} />
                <Row>
                    <Col>
                        <Form.Group controlId={'month'}>
                            <Form.Label>Monat</Form.Label>
                            <Form.Control
                                as="select"
                                onChange={formProps.handleChange}
                                value={formProps.values.month}
                            >
                                {MONTHS.map((it) => (
                                    <option key={`month-${it}`} value={it}>
                                        {getMonthName(it)}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={'year'}>
                            <Form.Label>Jahr</Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'year'}
                                value={formProps.values.year}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {loadDetailsButton(formProps)}
                {props.creditNotesSummary &&
                    props.creditNotesSummary.entries && (
                        <CreditNotesSummaryTable
                            creditNotesSummary={props.creditNotesSummary}
                        />
                    )}
            </Form>
        );
    }

    async function handleFormSubmit(
        values: FormInput,
        actions: FormikActions<FormInput>
    ) {
        props.resetGroupedCreditNotesStore();
        actions.setSubmitting(true);
        await props.getCreditNotesSummary(
            Number(values.year),
            Number(values.month)
        );
        actions.setSubmitting(false);
    }

    function loadDetailsButton(formProps: any) {
        const buttonText = formProps.isSubmitting
            ? 'wird geladen...'
            : 'Details laden';
        return (
            <Button type="submit" disabled={formProps.isSubmitting}>
                {buttonText}
            </Button>
        );
    }

    return (
        <div id={'GetGroupedCreditNotes'} className={styles.groupedCreditNotes}>
            <Row className={styles.groupedCreditNotesForm}>
                <h2>Gutschriften herunterladen</h2>
                <p>Abrechnungszeitraum wählen.</p>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Row>
        </div>
    );
};

function getMonthName(monthNumber: number) {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('de', { month: 'long' });
}

export default connect(
    (store: RootStore) => ({
        apiErrors: store.groupedCreditNotes.errors,
        creditNotesSummary: store.groupedCreditNotes.creditNotesSummary,
    }),
    { getCreditNotesSummary, resetGroupedCreditNotesStore }
)(GroupedCreditNotes);
