import axios, {
    AxiosError,
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from 'axios';

const HTTP_STATUS_UNAUTHORIZED = 401;

export const BASE_URL = process.env.REACT_APP_API_URL;

type StoredAxiosRequestConfig = AxiosRequestConfig & {
    wewashRetryCount?: number;
};

const options: AxiosRequestConfig = {
    headers: { 'WW-App-Version': '1.6.0', 'WW-Client': 'CCUI' },
    withCredentials: true,
    baseURL: process.env.REACT_APP_API_URL,
};

class RestClient {
    private readonly _restService: AxiosInstance;
    private _refreshInterceptorId: number;

    constructor(options: AxiosRequestConfig) {
        this._restService = axios.create(options);
        this._refreshInterceptorId = this.createRefreshInterceptor();
    }

    createRefreshInterceptor(): number {
        return this._restService.interceptors.response.use(
            (response: AxiosResponse) => response,
            (error: AxiosError) => {
                if (
                    error.response &&
                    error.response.status === HTTP_STATUS_UNAUTHORIZED
                ) {
                    if (shouldNotBeRetried(error.response.config)) {
                        return Promise.reject(error);
                    }
                    const originalRequest: StoredAxiosRequestConfig =
                        error.config;
                    if (originalRequest.wewashRetryCount) {
                        return Promise.reject(error);
                    }

                    const modifiedRequest: StoredAxiosRequestConfig = {
                        ...originalRequest,
                        wewashRetryCount: 1,
                    };

                    this._restService.interceptors.response.eject(
                        this._refreshInterceptorId
                    );

                    return this._restService
                        .get('auth/refresh')
                        .then(
                            () => this._restService.request(modifiedRequest),
                            () => Promise.reject(error)
                        )
                        .finally(
                            () =>
                                (this._refreshInterceptorId =
                                    this.createRefreshInterceptor())
                        );
                }

                return Promise.reject(error);
            }
        );
    }

    get restService(): AxiosInstance {
        return this._restService;
    }
}

const URLS_TO_SKIP = ['auth/refresh', 'auth', 'logout'];
const URLS_TO_SKIP_CHECK = new RegExp('(^|/)(' + URLS_TO_SKIP.join('|') + ')$');

function shouldNotBeRetried(requestConfig: AxiosRequestConfig) {
    const url: string | undefined = requestConfig.url;
    if (!url) {
        return true;
    }
    return URLS_TO_SKIP_CHECK.test(url);
}

const restClient = new RestClient(options);

export default restClient.restService;
