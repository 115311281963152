import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';

export const BLACKLIST_IBAN = 'BLACKLIST_IBAN';
export const BLACKLIST_IBAN_SUCCESS = 'BLACKLIST_IBAN_SUCCESS';
export const BLACKLIST_IBAN_FAILURE = 'BLACKLIST_IBAN_FAILURE';

export function blacklistIban(
    paymentId: number | null,
    reason: string,
    iban: string
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: BLACKLIST_IBAN, payload: {} });
        try {
            await RestClient.post(`/v1/payments/blacklist_iban`, {
                payment_id: paymentId,
                reason,
                iban,
            });
            dispatch({ type: BLACKLIST_IBAN_SUCCESS });
        } catch (error) {
            dispatch({
                type: BLACKLIST_IBAN_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
