import React from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    LaundryLessonFrequency,
    LaundryRoomOverviewDto,
} from '../../store/location/locationReducer';
import * as yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';

import { updateLaundryLessonFrequencyInLocation } from '../../store/manage-laundry-room/manageLaundryRoomActions';

const FREQUENCIES_TO_TEXT: { [k in LaundryLessonFrequency]: string } = {
    [LaundryLessonFrequency.HIGH]: 'Hoch',
    [LaundryLessonFrequency.DEFAULT]: 'Normal',
    [LaundryLessonFrequency.OFF]: 'Aus',
};

interface Props {
    laundryRoomInfo: LaundryRoomOverviewDto;
    updateLaundryLessonFrequency: (
        locationId: string,
        frequency: LaundryLessonFrequency
    ) => void;
}

interface FrequencyFormValues {
    laundryRoomId: string;
    laundryLessonFrequency: string;
}

const schema: yup.ObjectSchema<FrequencyFormValues> = yup.object({
    laundryRoomId: yup.string().required(),
    laundryLessonFrequency: yup.string().required(),
});

function EditLaundryLessonFrequency({ laundryRoomInfo, ...props }: Props) {
    if (!laundryRoomInfo) {
        return null;
    }

    const initialValues: FrequencyFormValues = {
        laundryRoomId: String(laundryRoomInfo.id),
        laundryLessonFrequency: laundryRoomInfo.laundry_lesson_frequency,
    };

    return (
        <Formik
            enableReinitialize={true}
            onSubmit={handleSubmit}
            initialValues={initialValues}
            children={renderForm}
            validationSchema={schema}
        />
    );

    function handleSubmit(
        values: FrequencyFormValues,
        actions: FormikActions<FrequencyFormValues>
    ) {
        actions.setSubmitting(true);
        try {
            props.updateLaundryLessonFrequency(
                values.laundryRoomId,
                values.laundryLessonFrequency as LaundryLessonFrequency
            );
        } finally {
            actions.setSubmitting(false);
        }
    }

    function renderForm({
        values,
        ...formProps
    }: FormikProps<FrequencyFormValues>) {
        const valueIsDifferent =
            values.laundryLessonFrequency !==
            laundryRoomInfo.laundry_lesson_frequency;
        const mayNotSubmit = !valueIsDifferent || formProps.isSubmitting;
        const buttonText = valueIsDifferent
            ? 'Häufigkeit der Lektionen setzen'
            : 'Keine Änderung';
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'laundryRoomId'}>
                    <Form.Label>Waschraum ID</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={values.laundryRoomId}
                        disabled
                    />
                </Form.Group>
                <Form.Group controlId={'laundryLessonFrequency'}>
                    <Form.Label>Wäschelektionen-Frequenz</Form.Label>
                    <Form.Control
                        onChange={formProps.handleChange}
                        as={'select'}
                        value={values.laundryLessonFrequency}
                    >
                        {Object.keys(FREQUENCIES_TO_TEXT).map((frequency) => (
                            <option value={frequency}>
                                {
                                    FREQUENCIES_TO_TEXT[
                                        frequency as LaundryLessonFrequency
                                    ]
                                }
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                <Button
                    type={'submit'}
                    disabled={mayNotSubmit}
                    variant={valueIsDifferent ? 'primary' : 'outline-secondary'}
                >
                    {buttonText}
                </Button>
            </Form>
        );
    }
}

export default connect((store: RootStore) => ({}), {
    updateLaundryLessonFrequency: updateLaundryLessonFrequencyInLocation,
})(EditLaundryLessonFrequency);
