import React, { Fragment } from 'react';
import { ManagePartnerManagerStore } from '../../store/manage-partner-manager/managePartnerManagerReducer';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { RootStore } from '../../store/rootStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import styles from '../manage-user/elements/UserInformationCard.module.css';
import { Form, Row } from 'react-bootstrap';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { Formik, FormikActions, FormikProps } from 'formik';
import InputGroup from 'react-bootstrap/InputGroup';
import {
    searchB2BUser,
    sendActivationEmail,
} from '../../store/manage-partner-manager/managePartnerManagerActions';
import { CommunicationState } from '../../store/communicationState';

interface Props {
    data: {
        partnerManagerId: number;
        partnerEmail: string;
    };
    close: () => void;
    sendActivationEmail: (partnerId: number, email?: string) => void;
    searchPartnerManager: (email: string) => void;
    partnerManagerStore: ManagePartnerManagerStore;
}

export interface EmailText {
    emailText: string;
    partnerManagerId: number;
    isEmailChange: boolean;
    emailTextOld: string;
}

function validateEmail(values: EmailText) {
    const errors: { emailText?: string } = {};
    if (values.isEmailChange) {
        if (!values.emailText) {
            errors.emailText = 'Required';
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.emailText)
        ) {
            errors.emailText = 'Invalid email address';
        }
    }
    return errors;
}

function PartnerManagerSendActivationEmailCard({
    sendActivationEmail,
    searchPartnerManager,
    ...props
}: Props) {
    const initialValues: EmailText = {
        emailText: props.data.partnerEmail,
        emailTextOld: props.data.partnerEmail,
        isEmailChange: false,
        partnerManagerId: props.data.partnerManagerId,
    };

    return (
        <div>
            <Formik
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
                validate={validateEmail}
            >
                {(formProps: FormikProps<EmailText>) => (
                    <Form onSubmit={formProps.handleSubmit} className={'px-5'}>
                        <Fragment>
                            <Modal.Header closeButton>
                                <Modal.Title>
                                    Send Activation E-Mail
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                {renderEmailSendContent(formProps)}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={handleCancel}>Cancel</Button>
                                <Button type={'submit'}>
                                    Resend{' '}
                                    <FontAwesomeIcon
                                        icon={faEnvelope}
                                        className={styles.icon}
                                    />
                                </Button>
                            </Modal.Footer>
                        </Fragment>
                    </Form>
                )}
            </Formik>
        </div>
    );

    async function handleFormSubmit(
        values: EmailText,
        actions: FormikActions<EmailText>
    ) {
        sendActivationEmail(
            values.partnerManagerId,
            values.isEmailChange && values.emailTextOld != values.emailText
                ? values.emailText
                : ''
        );
        finalize(`${values.partnerManagerId}`);
    }

    function finalize(partnerManagerId: string) {
        if (
            props.partnerManagerStore.sendActivationEmail ===
            CommunicationState.SUCCESS
        ) {
            searchPartnerManager(partnerManagerId);
            props.close();
        } else if (
            props.partnerManagerStore.sendActivationEmail ===
            CommunicationState.LOADING
        ) {
            finalize(partnerManagerId);
        }
    }

    function renderEmailSendContent(formProps: FormikProps<EmailText>) {
        return (
            <div>
                <p>
                    This will trigger the resend of Activation E-Mail for the
                    partner board manager.Optionally, you can also change the
                    e-mail address
                </p>
                <Form.Group as={Row}>
                    <ErrorFeedback
                        apiErrors={props.partnerManagerStore.errors}
                    />
                    <InputGroup>
                        <Form.Check
                            type="checkbox"
                            name="isEmailChange"
                            label="Change E-Mail Address"
                            checked={formProps.values.isEmailChange}
                            onChange={formProps.handleChange}
                        />
                    </InputGroup>
                    <InputGroup hidden={!formProps.values.isEmailChange}>
                        <Form.Control
                            type="email"
                            name="emailText"
                            className={styles.input}
                            value={formProps.values.emailText}
                            placeholder={'E-Mail'}
                            onChange={formProps.handleChange}
                        />
                    </InputGroup>
                </Form.Group>
            </div>
        );
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({ partnerManagerStore: store.managePartnerManager }),
    { close: closeModal, sendActivationEmail, searchPartnerManager: searchB2BUser }
)(PartnerManagerSendActivationEmailCard);
