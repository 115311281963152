import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    CreditNoteData,
    downloadCreditNoteDatev,
    getPartnerCreditNotesFor,
} from '../../store/manage-credit-notes/manageCreditNotesActions';
import { Route } from '../../Router';
import { Row } from 'react-bootstrap';
import { CreditNoteTable } from './CreditNoteTable';
import { WeWashApiErrorTag } from '../../http/errors';
import FileInternalCreditNote from './FileInternalCreditNote';
import UploadCreditNoteForm from './UploadCreditNoteForm';
import Alert from 'react-bootstrap/Alert';
import CreditNoteCorrectionForm from './CreditNoteCorrectionForm';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import styles from '../manage-mansion/SearchMansionById.module.css';
import { DownloadCallback, downloadFile } from '../../util/downloadFileAsBlob';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    uploadSuccess: boolean;
    selectedCreditNoteId?: number;
    creditNote?: CreditNoteData;
    goTo: (route: Route) => void;
    fileInternalCreditNoteSuccess: boolean;
    getPartnerCreditNotesFor: (userId: string) => void;
    downloadCreditNoteDatev: (
        creditNoteNumber: string,
        callback: DownloadCallback
    ) => void;
}

const ManageCreditNote: FunctionComponent<Props> = (props) => {
    if (!props.creditNote) {
        return (
            <div id={'ManageCreditNote'}>
                <Row className={'mx-0 mb-4'}>
                    <p>
                        Credit Note Id '{props.selectedCreditNoteId}' kann nicht
                        gefunden werden.
                    </p>
                </Row>
            </div>
        );
    }
    const creditNote = props.creditNote;

    return (
        <div id={'ManageCreditNote'}>
            <Row className={'mx-0 mb-4'}>
                <CreditNoteTable
                    creditNotes={[creditNote]}
                    onDownloadCreditNoteXml={handleDownloadXml}
                />
                <Button onClick={handleRefresh}>
                    Refresh{' '}
                    <FontAwesomeIcon
                        icon={faRedo}
                        className={styles.iconWhite}
                    />
                </Button>
            </Row>
            <Row className={'mx-0 mb-4'}>{chooseAndRenderForm()}</Row>
            {props.fileInternalCreditNoteSuccess && (
                <Alert variant="success" className={'mt-2'}>
                    File Internal Credit Note erfolgreich
                </Alert>
            )}
        </div>
    );

    function chooseAndRenderForm() {
        const isInternalCreditNote: boolean =
            creditNote.accounting_status === 'INTERNAL';
        if (isInternalCreditNote) {
            return <FileInternalCreditNote creditNote={creditNote} />;
        }

        if (creditNote.pdf_status === 'SUCCESS') {
            return <CreditNoteCorrectionForm creditNote={creditNote} />;
        }

        return <UploadCreditNoteForm creditNote={creditNote} />;
    }

    function handleRefresh() {
        props.getPartnerCreditNotesFor(creditNote.user_id);
    }

    function handleDownloadXml(creditNoteNumber: string) {
        props.downloadCreditNoteDatev(creditNoteNumber, downloadFile);
    }
};

export default connect(
    (store: RootStore) => ({
        apiErrors: store.manageCreditNotes.errors,
        selectedCreditNoteId: store.manageCreditNotes.selectedCreditNoteId,
        creditNote: store.manageCreditNotes.creditNotes.find(
            (it) =>
                it.credit_note_id ===
                store.manageCreditNotes.selectedCreditNoteId
        ),
        fileInternalCreditNoteSuccess:
            store.manageCreditNotes.fileInternalCreditNoteSuccess,
    }),
    { getPartnerCreditNotesFor, downloadCreditNoteDatev }
)(ManageCreditNote);
