import React from 'react';

import { LaundryRoomForVipUsers } from '../../store/manage-vip-users/manageVipUsersActions';
import { formatMonetaryAmount } from '../../store/amountAndCurrency';
import styles from '../manage-user/elements/UserInformationCard.module.css';
import { KeyValueElement } from '../common/KeyValueElement';

interface Props {
    laundryRoom: LaundryRoomForVipUsers;
}

function VipUsersLaundryRoomCard({ laundryRoom, ...props }: Props) {
    return (
        <div className={styles.card}>
            <div className={styles.leftColumn}>
                <KeyValueElement name={'Waschraum ID'}>
                    {laundryRoom.id}
                </KeyValueElement>
                <KeyValueElement name={'Name'}>
                    {laundryRoom.name}
                </KeyValueElement>
                <KeyValueElement name={'Adresse'}>
                    {laundryRoom.address.street}{' '}
                    {laundryRoom.address.house_number}
                    <br />
                    {laundryRoom.address.city} <br />
                    {laundryRoom.address.postal_code}
                </KeyValueElement>
            </div>
            <div className={styles.rightColumn}>
                <KeyValueElement name={'Kosten Waschen'}>
                    Standard: {formatCost(laundryRoom.costs.amount_washer)}
                    <br />
                    VIP:{' '}
                    {formatCost(laundryRoom.costs.amount_washer_vip) ||
                        '-'}{' '}
                    <br />
                </KeyValueElement>
                <KeyValueElement name={'Kosten Trocknen'}>
                    Standard: {formatCost(laundryRoom.costs.amount_dryer)}
                    <br />
                    VIP: {formatCost(laundryRoom.costs.amount_dryer_vip) ||
                        '-'}{' '}
                    <br />
                </KeyValueElement>
            </div>
        </div>
    );

    function formatCost(amount?: number | null) {
        if (amount === undefined || amount === null) {
            return null;
        }
        return formatMonetaryAmount({
            amount,
            currency: laundryRoom.costs.currency_code,
        });
    }
}

export default VipUsersLaundryRoomCard;
