import { createReducer } from 'redux-starter-kit';
import {
    REGISTER,
    REGISTER_ERROR,
    REGISTER_SUCCESS,
} from './registrationActions';
import { WeWashApiErrorTag } from '../../http/errors';

export interface RegistrationStore {
    errors: WeWashApiErrorTag[];
}

const initialState: RegistrationStore = {
    errors: [],
};

export const registrationReducer = createReducer(initialState, {
    [REGISTER]: (state: RegistrationStore) => {
        state.errors = [];
    },
    [REGISTER_SUCCESS]: (state: RegistrationStore) => {
        state.errors = [];
    },
    [REGISTER_ERROR]: (state: RegistrationStore, action) => {
        state.errors = action.payload.errors;
    },
});
