import React, { ReactNode } from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import styles from './KeyValueProps.module.css';

interface KeyValueProps {
    name: string;
    children: ReactNode;
    hasEditButton?: boolean;
    onEdit?: () => void;
}

export function KeyValueElement(props: KeyValueProps) {
    return (
        <div className={styles.item}>
            <div className={styles.description}>
                <div className={styles.label}>{props.name}</div>
                <div className={styles.text}>{props.children}</div>
            </div>
            {maybeRenderEdit()}
        </div>
    );

    function maybeRenderEdit() {
        if (!props.hasEditButton) {
            return null;
        }

        return (
            <div className={styles.editContainer}>
                <Button onClick={props.onEdit} variant={'outline-light'}>
                    <FontAwesomeIcon
                        size={'1x'}
                        color="#00B3E6"
                        icon={faEdit}
                    />
                </Button>
            </div>
        );
    }
}
