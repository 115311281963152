export type DownloadCallback = typeof downloadFile;

export function downloadFile(filename: string, blob: Blob) {
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
}

export function parseContentDispositionForFilename(
    disposition: string
): string | null {
    const grouped = /filename="(.*)"/.exec(disposition);
    if (!grouped) {
        return null;
    }
    return grouped[1];
}
