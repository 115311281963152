import React, { FunctionComponent, ReactText } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    createTopUp,
    loadTopUpUserAndBalance,
} from '../../store/topup/topUpActions';
import { TopUpStore, TopUpUserDto } from '../../store/topup/topUpReducer';
import { Button, Form, FormControl } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import styles from './TopUpForm.module.css';
import * as yup from 'yup';
import ErrorFeedback from '../ErrorFeedback';
import SuccessFeedback from '../SuccessFeedback';
import { resetViews } from '../../store/genericActions';

interface Props extends TopUpStore {
    loadTopUpUserAndBalance: (userId: ReactText) => any;
    createTopUp: (data: TopUpCreationDto) => any;
    clearView: () => void;
}

interface UserParameters {
    userId: ReactText;
}

interface TopUpParameters {
    amount: string;
    countryId: string;
    comment: ReactText;
}

export interface TopUpCreationDto extends UserParameters, TopUpParameters {}

const schema: yup.ObjectSchema<{}> = yup.object({
    amount: yup
        .string()
        .trim()
        .required('please provide an amount')
        .matches(
            /^-?[0-9]{1,4}([,.][0-9]{1,2})?$/,
            'valid examples: 2 | 2.5 | 11.99'
        ),
    comment: yup.string().trim().required('please provide a comment'),
    countryId: yup.string().trim().required('please select a country'),
});

const TopUpForm: FunctionComponent<Props> = (props) => {
    const userParameters: UserParameters = {
        userId: props.topUpUser ? props.topUpUser.user_id : '',
    };

    const topUpParameters: TopUpParameters = {
        countryId: extractUserCountryIdAsString(props.topUpUser) || '',
        amount: Number(0).toFixed(),
        comment: '',
    };

    return (
        <div id="TopUpForm">
            <div>
                <Formik
                    onSubmit={handleUserIdSubmit}
                    initialValues={userParameters}
                >
                    {(formProps: FormikProps<UserParameters>) => {
                        function handleUserIdChange(
                            event: React.ChangeEvent<any>
                        ) {
                            if (props.topUpUser) {
                                props.clearView();
                            }
                            formProps.handleChange(event);
                        }

                        return (
                            <Form noValidate onSubmit={formProps.handleSubmit}>
                                <Form.Group controlId="userId">
                                    <Form.Label>User Id</Form.Label>
                                    <Form.Control
                                        type="text"
                                        onChange={handleUserIdChange}
                                        value={String(formProps.values.userId)}
                                    />
                                </Form.Group>

                                {loadDetailsButton()}
                            </Form>
                        );
                    }}
                </Formik>

                <div className={styles.infoContainer}>
                    {userInfoDisplay()}
                    {userUpdateForm()}
                </div>
                {props.currentSuccessfulTopUp && (
                    <SuccessFeedback
                        message={createTopUpSuccessMessage(
                            props.currentSuccessfulTopUp
                        )}
                    />
                )}
                <ErrorFeedback apiErrors={props.errors} />
            </div>
        </div>
    );

    function extractUserCountryIdAsString(
        topUpUser: TopUpUserDto | null
    ): string | null {
        if (!topUpUser) {
            return null;
        }
        const selectedCountry = topUpUser.balances.find(
            (it) => it.country_code === topUpUser.country_code
        );
        return selectedCountry ? String(selectedCountry.country_id) : null;
    }

    function handleUserIdSubmit(values: UserParameters) {
        props.loadTopUpUserAndBalance(values.userId);
    }

    function handleTopUpSubmit(values: TopUpParameters) {
        const { userId } = userParameters;
        props.createTopUp({ userId, ...values });
    }

    function loadDetailsButton() {
        const buttonText = props.loading ? 'Loading...' : 'Load Details';
        return (
            <Button type="submit" disabled={props.loading}>
                {buttonText}
            </Button>
        );
    }

    function userInfoDisplay() {
        return props.loading ? <div>...</div> : userInfoDetails();
    }

    function userInfoDetails() {
        if (!props.topUpUser) {
            return <div />;
        }
        const userCountryCode = props.topUpUser.country_code;

        return (
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Name</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {props.topUpUser.first_name +
                                ' ' +
                                props.topUpUser.last_name}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Current Country</strong>
                        </td>
                    </tr>
                    <tr>
                        <td>{userCountryCode}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Balances</strong>
                        </td>
                    </tr>
                    {props.topUpUser.balances
                        .filter(
                            (it) =>
                                it.amount || it.country_code === userCountryCode
                        )
                        .map((it) => (
                            <tr key={it.country_id}>
                                <td>
                                    {it.country_code +
                                        ': ' +
                                        it.currency_code +
                                        ' ' +
                                        Number(it.amount).toFixed(2)}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </table>
        );
    }

    function userUpdateForm() {
        if (!props.topUpUser || props.loading) {
            return <div />;
        } else {
            return userUpdateFormDetails();
        }
    }

    function userUpdateFormDetails() {
        return (
            <div className={styles.topUpForm}>
                <Formik
                    validationSchema={schema}
                    onSubmit={handleTopUpSubmit}
                    initialValues={topUpParameters}
                >
                    {(formProps: FormikProps<TopUpParameters>) => (
                        <Form onSubmit={formProps.handleSubmit}>
                            <Form.Group controlId="countryId">
                                <Form.Label>Land</Form.Label>
                                <Form.Control
                                    as="select"
                                    onChange={formProps.handleChange}
                                    value={formProps.values.countryId}
                                >
                                    {props.topUpUser &&
                                        props.topUpUser.balances.map((it) => (
                                            <option
                                                key={it.country_id}
                                                value={String(it.country_id)}
                                            >{`${it.country_code} (${it.currency_code})`}</option>
                                        ))}
                                </Form.Control>
                            </Form.Group>
                            <Form.Group controlId="amount">
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={formProps.handleChange}
                                    value={String(formProps.values.amount)}
                                    isInvalid={
                                        formProps.touched.amount &&
                                        !!formProps.errors.amount
                                    }
                                    isValid={
                                        formProps.touched.amount &&
                                        !formProps.errors.amount
                                    }
                                />
                                <FormControl.Feedback type="invalid">
                                    {formProps.errors.amount}
                                </FormControl.Feedback>
                            </Form.Group>
                            <Form.Group controlId="comment">
                                <Form.Label>Comment</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={formProps.handleChange}
                                    value={String(formProps.values.comment)}
                                    isInvalid={
                                        formProps.touched.comment &&
                                        !!formProps.errors.comment
                                    }
                                    isValid={
                                        formProps.touched.comment &&
                                        !formProps.errors.comment
                                    }
                                />
                                <FormControl.Feedback type="invalid">
                                    {formProps.errors.comment}
                                </FormControl.Feedback>
                            </Form.Group>

                            {topUpButton()}
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }

    function topUpButton() {
        const buttonText = props.loading ? '...' : 'Top Up Account';
        return (
            <Button type="submit" disabled={props.loading}>
                {buttonText}
            </Button>
        );
    }
};

function createTopUpSuccessMessage(dto: TopUpCreationDto) {
    if (dto) {
        return `Successfully executed top up of ${dto.amount} to the user ${dto.userId}`;
    } else {
        return null;
    }
}

export default connect((store: RootStore) => ({ ...store.topUp }), {
    loadTopUpUserAndBalance,
    createTopUp,
    clearView: resetViews,
})(TopUpForm);
