import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import { RESET_VIEWS } from '../genericActions';
import {
    ASSIGN_GATEWAY,
    ASSIGN_GATEWAY_FAILURE,
    ASSIGN_GATEWAY_SUCCESS,
    ASSIGN_SERIALNUMBERS,
    ASSIGN_SERIALNUMBERS_FAILURE,
    ASSIGN_SERIALNUMBERS_SUCCESS,
    LOAD_ASSIGNABLE_LOCATIONS,
    LOAD_ASSIGNABLE_LOCATIONS_FAILURE,
    LOAD_ASSIGNABLE_LOCATIONS_SUCCESS,
    LOAD_SERIALNUMBERS,
    LOAD_SERIALNUMBERS_FAILURE,
    LOAD_SERIALNUMBERS_SUCCESS,
    SET_MANAGED_PARTNER,
    UNASSIGN_GATEWAY,
    UNASSIGN_GATEWAY_FAILURE,
    UNASSIGN_GATEWAY_SUCCESS,
    UNASSIGN_SERIALNUMBER,
    UNASSIGN_SERIALNUMBER_FAILURE,
    UNASSIGN_SERIALNUMBER_SUCCESS,
} from './manageSerialnumbersActions';
import { ManagedPartner } from '../search-user/searchUserReducer';

export interface PartnerSerialnumberDto {
    serial_number: string;
    hub: boolean;
    has_appliance: boolean;
    location_id: number | null;
}

export interface LocationNameDto {
    id: number;
    name: string;
}

export interface PartnerLocationsDto {
    locations: LocationNameDto[];
}

export interface ManageSerialnumbersStore {
    managedPartner: ManagedPartner | null;
    serialnumbers: PartnerSerialnumberDto[];
    assignableLocations: LocationNameDto[];
    loading: boolean;
    errors: WeWashApiErrorTag[];
}

const initialStore: ManageSerialnumbersStore = {
    managedPartner: null,
    serialnumbers: [],
    assignableLocations: [],
    loading: false,
    errors: [],
};

export const manageSerialnumbersReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [SET_MANAGED_PARTNER]: (state: ManageSerialnumbersStore, action) => {
        state.managedPartner = action.payload.partner;
    },
    [LOAD_SERIALNUMBERS]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_SERIALNUMBERS_SUCCESS]: (state: ManageSerialnumbersStore, action) => {
        state.loading = false;
        state.errors = [];
        state.serialnumbers = action.payload.data;
    },
    [LOAD_SERIALNUMBERS_FAILURE]: (state: ManageSerialnumbersStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.serialnumbers = [];
    },
    [ASSIGN_SERIALNUMBERS]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [ASSIGN_SERIALNUMBERS_SUCCESS]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [ASSIGN_SERIALNUMBERS_FAILURE]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UNASSIGN_SERIALNUMBER]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [UNASSIGN_SERIALNUMBER_SUCCESS]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
    },
    [UNASSIGN_SERIALNUMBER_FAILURE]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [LOAD_ASSIGNABLE_LOCATIONS]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_ASSIGNABLE_LOCATIONS_SUCCESS]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.assignableLocations = action.payload.data.locations;
        state.errors = [];
    },
    [LOAD_ASSIGNABLE_LOCATIONS_FAILURE]: (
        state: ManageSerialnumbersStore,
        action
    ) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [ASSIGN_GATEWAY]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [ASSIGN_GATEWAY_SUCCESS]: (state: ManageSerialnumbersStore) => {
        state.loading = false;
        state.errors = [];
    },
    [ASSIGN_GATEWAY_FAILURE]: (state: ManageSerialnumbersStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
    [UNASSIGN_GATEWAY]: (state: ManageSerialnumbersStore) => {
        state.loading = true;
        state.errors = [];
    },
    [UNASSIGN_GATEWAY_SUCCESS]: (state: ManageSerialnumbersStore) => {
        state.loading = false;
        state.errors = [];
    },
    [UNASSIGN_GATEWAY_FAILURE]: (state: ManageSerialnumbersStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
});
