import { CommunicationState } from '../communicationState';
import { WeWashApiErrorTag } from '../../http/errors';
import { UserAccountState } from '../search-user/searchUserReducer';
import { createReducer } from 'redux-starter-kit';
import { RESET_VIEWS } from '../genericActions';
import {
    LOAD_PARTNER_MANAGER,
    LOAD_PARTNER_MANAGER_FAILURE,
    LOAD_PARTNER_MANAGER_SUCCESS,
    SEND_ACTIVATION_EMAIL,
    SEND_ACTIVATION_EMAIL_FAILURE,
    SEND_ACTIVATION_EMAIL_SUCCESS,
} from './managePartnerManagerActions';

export interface ManagePartnerManagerStore {
    partnerManager?: PartnerBoardManagerDto;
    fetchState: CommunicationState;
    sendActivationEmail: CommunicationState;
    errors: WeWashApiErrorTag[];
}

export interface PartnerBoardManagerDto {
    id: number;
    email: string;
    salesforce_id?: string;
    customer_id: string;
    name: string;
    login_state: UserAccountState;
    account_salesforce_id?: string;
    account_name?: string;
    roles: B2bUserRoleDto[];
    expiration_date_time?: number;
    expires?: boolean;
}

export enum B2bRoleType {
    B2B_LAUNDRY_USAGE = 'B2B_LAUNDRY_USAGE',
    TECHNICIAN = 'TECHNICIAN',
    OWNER = 'OWNER',
}

export enum B2bSubRoleType {
    RECEPTIONIST = 'RECEPTIONIST',
    INTERNAL = 'INTERNAL',
    HOUSEKEEPING = 'HOUSEKEEPING',
}

export interface B2bUserRoleDto {
    b2b_user_id: number;
    accounting_area: AccountingAreaInfoDto;
    role: B2bRoleType;
    sub_role?: B2bSubRoleType;
}

export interface AccountingAreaInfoDto {
    accounting_area_id: number;
    company_name: string;
    street_and_house_number: string;
    postal_code: string;
    city: string;
}

const initialStore: ManagePartnerManagerStore = {
    errors: [],
    fetchState: CommunicationState.INITIAL,
    sendActivationEmail: CommunicationState.INITIAL,
};

export const managePartnerManagerReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_PARTNER_MANAGER]: (state) => {
        state.fetchState = CommunicationState.LOADING;
        state.errors = [];
        state.partnerManager = undefined;
    },
    [LOAD_PARTNER_MANAGER_SUCCESS]: (state, action) => {
        state.fetchState = CommunicationState.SUCCESS;
        state.partnerManager = action.payload.data;
    },
    [LOAD_PARTNER_MANAGER_FAILURE]: (state, action) => {
        state.fetchState = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
    [SEND_ACTIVATION_EMAIL]: (state) => {
        state.sendActivationEmail = CommunicationState.LOADING;
        state.errors = [];
    },
    [SEND_ACTIVATION_EMAIL_SUCCESS]: (state, action) => {
        state.sendActivationEmail = CommunicationState.SUCCESS;
    },
    [SEND_ACTIVATION_EMAIL_FAILURE]: (state, action) => {
        state.sendActivationEmail = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
});
