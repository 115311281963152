import React, { FunctionComponent, memo } from 'react';
import Form from 'react-bootstrap/Form';
import { Formik, FormikActions, FormikProps } from 'formik';
import * as yup from 'yup';
import { ObjectSchema } from 'yup';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { addPaymentAction } from '../../store/payment/paymentActions';
import PaymentForm from './PaymentForm';
import { RootStore } from '../../store/rootStore';
import SuccessFeedback from '../SuccessFeedback';
import { PaymentStore } from '../../store/payment/paymentReducer';
import ErrorFeedback from '../ErrorFeedback';

export interface DirectDebitData {
    iban: string;
    accountHolder: string;
}

const schema: ObjectSchema<{}> = yup.object({
    iban: yup.string(),
    accountHolder: yup.string(),
});

const initialValues: DirectDebitData = {
    iban: '',
    accountHolder: '',
};

interface Props {
    userId: number;
    payment: PaymentStore;
    addPaymentAction: (userId: number, data: DirectDebitData) => any;
}

const PaymentFormStandalone: FunctionComponent<Props> = memo((props) => {
    async function handleFormSubmit(
        values: DirectDebitData,
        actions: FormikActions<DirectDebitData>
    ) {
        try {
            await props.addPaymentAction(props.userId, values);
            actions.setSubmitting(false);
        } catch (e) {
            console.error('Error during payment registration', e);
        }
    }

    function createSuccessMessage() {
        const { payment } = props;
        let lastCreatedPayment = payment.lastCreated.get(props.userId);
        if (!payment || !payment.lastCreated || !lastCreatedPayment) {
            return null;
        } else {
            return `"${lastCreatedPayment.accountHolder} - ${lastCreatedPayment.iban}" wurde erfolgreich hinzugefügt`;
        }
    }

    return (
        <div id="PaymentFormStandalone">
            <Formik
                validationSchema={schema}
                initialValues={initialValues}
                onSubmit={handleFormSubmit}
            >
                {(formProps: FormikProps<DirectDebitData>) => (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        <PaymentForm
                            handleChange={formProps.handleChange}
                            iban={formProps.values.iban}
                            accountHolder={formProps.values.accountHolder}
                        />
                        <Button
                            type="submit"
                            disabled={formProps.isSubmitting}
                            size="lg"
                        >
                            {formProps.isSubmitting
                                ? 'Bitte warten...'
                                : 'Absenden'}
                        </Button>
                    </Form>
                )}
            </Formik>
            <ErrorFeedback apiErrors={props.payment.errors} />
            <SuccessFeedback message={createSuccessMessage()} />
        </div>
    );
});

export default connect((store: RootStore) => ({ payment: store.payment }), {
    addPaymentAction,
})(PaymentFormStandalone);
