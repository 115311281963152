import React from 'react';
import Table from 'react-bootstrap/Table';
import { ManagedPartner } from '../../store/search-user/searchUserReducer';
import styles from './UserInformationTable.module.css';

interface Props {
    partners: ManagedPartner[];
    onSelect: (partner: ManagedPartner) => void;
}

const PartnerInformationByAnyIdTable = ({ partners, onSelect }: Props) => {
    return (
        <>
            <h3>Found Accounting Areas</h3>
            <Table bordered hover className={styles.table}>
                <thead>
                    <tr className={styles.header}>
                        <th>Partner ID</th>
                        <th>Customer ID</th>
                        <th>Name</th>
                        <th>Firmenname</th>
                        <th>Adresse</th>
                    </tr>
                </thead>
                <tbody>
                    {partners.map((partner) => (
                        <tr
                            key={partner.external_id}
                            onClick={() => onSelect(partner)}
                            className={styles.body}
                        >
                            <td>{partner.id}</td>
                            <td>{partner.customer_id}</td>
                            <td>{partner.name}</td>
                            <td>{partner.company_name}</td>
                            <td>
                                {partner.street_and_housenumber},{' '}
                                {partner.postalcode} {partner.city}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default PartnerInformationByAnyIdTable;
