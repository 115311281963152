import React, { useEffect } from 'react';
import { Button, Form, Table } from 'react-bootstrap';
import {
    LaundryRoomQrIdOverview,
    LocationQrIdStatus,
} from '../../store/location/locationReducer';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    createLaundryRoomQrId,
    deleteLaundryRoomQrId,
    fetchLaundryRoomQrIds,
} from '../../store/location/locationActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    locationId: number;
    locationQrIds: LaundryRoomQrIdOverview[];
    createLocationQrIdErrorMessage: string;
    qrIdsErrors: WeWashApiErrorTag[];
    fetchLaundryRoomQrIds: (id: number) => void;
    deleteLaundryRoomQrId: (id: number, successCallback?: () => void) => void;
    createLaundryRoomQrId: (
        laundryRoomId: number,
        qrId: string,
        successCallback?: () => void
    ) => void;
}

interface CreateLaundryRoomQrCodeValues {
    qrId: string;
}

function LaundryRoomQrIdOverviewTable(props: Props) {
    useEffect(() => {
        props.fetchLaundryRoomQrIds(props.locationId);
    }, [props.locationId]);

    const initialValues: CreateLaundryRoomQrCodeValues = {
        qrId: '',
    };
    const hideCursorStyle = {
        cursor: 'auto !important',
    };
    return (
        <>
            {maybeRenderCreateQrIdForm()}
            {maybeRenderTable()}
        </>
    );

    function maybeRenderTable() {
        if (!props.locationQrIds || props.locationQrIds.length === 0) {
            return <p>Dieser Waschraum hat keine QR IDs.</p>;
        }
        return (
            <Table bordered>
                <thead>
                    <tr style={hideCursorStyle}>
                        <th>QR ID</th>
                        <th>Anzahl der Verifizierungen</th>
                        <th>Verifizierungsstatus</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>{props.locationQrIds.map(renderEntry)}</tbody>
            </Table>
        );
    }

    function handleSubmit(
        values: CreateLaundryRoomQrCodeValues,
        actions: FormikActions<CreateLaundryRoomQrCodeValues>
    ) {
        props.createLaundryRoomQrId(props.locationId, values.qrId, () => {
            props.fetchLaundryRoomQrIds(props.locationId);
        });
    }

    function maybeRenderCreateQrIdForm() {
        function children({
            values,
            ...formProps
        }: FormikProps<CreateLaundryRoomQrCodeValues>) {
            return (
                <>
                    <Form onSubmit={formProps.handleSubmit}>
                        <p>QR ID</p>
                        <Form.Group controlId={'qrId'}>
                            <Form.Control
                                type={'text'}
                                value={values.qrId}
                                onChange={formProps.handleChange}
                            />
                            <Button type={'submit'}>Erstellen</Button>
                        </Form.Group>
                    </Form>
                    <ErrorFeedback
                        apiErrors={props.qrIdsErrors}
                        additionalInfoConstructor={
                            createAdditionalErrorInformation
                        }
                    ></ErrorFeedback>
                </>
            );
        }

        return (
            <Formik
                enableReinitialize={true}
                onSubmit={handleSubmit}
                initialValues={initialValues}
                children={children}
            ></Formik>
        );
    }

    function createAdditionalErrorInformation(tag: string): JSX.Element {
        if (tag === WeWashApiErrorTag.LOCATION_QR_ID_DUPLICATE) {
            return <>props.createLocationQrIdErrorMessage</>;
        }
        return <></>;
    }

    function renderEntry(locationQrId: LaundryRoomQrIdOverview) {
        return (
            <tr>
                <td>{locationQrId.qr_id}</td>
                <td>{locationQrId.verification_count}</td>
                <td>
                    {locationQrId.verification_status ===
                    LocationQrIdStatus.UNVERIFIED
                        ? 'Nicht verifiziert'
                        : 'Verifiziert'}
                </td>
                <td>
                    <button
                        type="button"
                        className={'btn btn-outline-secondary'}
                        onClick={() => handleEntryDelete(locationQrId)}
                    >
                        Löschen
                    </button>
                </td>
            </tr>
        );

        function handleEntryDelete(locationQrId: LaundryRoomQrIdOverview) {
            props.deleteLaundryRoomQrId(locationQrId.id, () => {
                props.fetchLaundryRoomQrIds(props.locationId);
            });
        }
    }
}

export default connect(
    (store: RootStore) => ({
        locationQrIds: store.location.locationQrIds,
        qrIdsErrors: store.location.qrIdsErrors,
        createLocationQrIdErrorMessage:
            store.location.createLocationQrIdErrorMessage,
    }),
    {
        fetchLaundryRoomQrIds,
        deleteLaundryRoomQrId,
        createLaundryRoomQrId,
    }
)(LaundryRoomQrIdOverviewTable);
