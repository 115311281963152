import React, {Fragment, FunctionComponent, useEffect} from 'react';
import {connect} from "react-redux";
import Router, {Route} from "../Router";
import {logoutAction, refreshAuthAction} from "../store/user/userActions";
import {RootStore} from "../store/rootStore";
import {goTo} from "../store/router/routerActions";
import RootModal from "../components/modal/RootModal";
import Background from "./Background";
import LoginForm from "../forms/login/LoginForm";

interface Props {
    refreshAuthAction: () => {}
    logoutAction: () => {}
    goTo: (route: Route) => any
    loggedIn: boolean
}

const App: FunctionComponent<Props> = (props) => {
    useEffect(() => {
        props.refreshAuthAction();
    }, []);

    return (
        <Fragment>
            {!props.loggedIn && <Background/>}
            {!props.loggedIn && <LoginForm/>}
            {props.loggedIn && <Router/>}
            <RootModal/>
        </Fragment>

    );
};

export default connect(
    (store: RootStore) => ({loggedIn: store.user.loggedIn}),
    {refreshAuthAction, logoutAction, goTo}
)(App);
