import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import {
    PartnerExtendedInformation,
    UserExtendedInformation,
} from './searchUserReducer';

export const SEARCH_USER = 'SEARCH_USER';
export const SEARCH_USER_SUCCESS = 'SEARCH_USER_SUCCESS';
export const SEARCH_USER_FAILURE = 'SEARCH_USER_FAILURE';

export const SEARCH_USER_ID = 'SEARCH_USER_ID';
export const SEARCH_USER_ID_SUCCESS = 'SEARCH_USER_ID_SUCCESS';
export const SEARCH_USER_ID_FAILURE = 'SEARCH_USER_ID_FAILURE';
export const REMEMBER_USER_BY_ID = 'REMEMBER_USER_BY_ID';

export const SEARCH_USER_CLEAR = 'SEARCH_USER_CLEAR';

export const SEARCH_PARTNER_MANAGER = 'SEARCH_PARTNER_MANAGER';
export const SEARCH_PARTNER_MANAGER_SUCCESS = 'SEARCH_PARTNER_MANAGER_SUCCESS';
export const SEARCH_PARTNER_MANAGER_FAILURE = 'SEARCH_PARTNER_MANAGER_FAILURE';

export const SEARCH_PARTNER_BY_ANY_ID = 'SEARCH_PARTNER_BY_ANY_ID';
export const SEARCH_PARTNER_BY_ANY_ID_SUCCESS =
    'SEARCH_PARTNER_BY_ANY_ID_SUCCESS';
export const SEARCH_PARTNER_BY_ANY_ID_FAILURE =
    'SEARCH_PARTNER_BY_ANY_ID_FAILURE';

export const SEARCH_PARTNER_CLEAR = 'SEARCH_PARTNER_CLEAR';

export function searchUser(searchTerm: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_USER, payload: { searchTerm } });
        try {
            const { data } = await RestClient.get<UserExtendedInformation[]>(
                `/v1/callcenter/user_information/${searchTerm}`
            );
            dispatch({ type: SEARCH_USER_SUCCESS, payload: { data } });
            dispatch({ type: REMEMBER_USER_BY_ID, payload: { data } });
        } catch (error) {
            dispatch({
                type: SEARCH_USER_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function searchUserById(searchUserId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_USER_ID, payload: { searchUserId } });
        try {
            const { data } = await RestClient.get<UserExtendedInformation[]>(
                `/v1/callcenter/user_information_by_id/${searchUserId}`
            );
            dispatch({ type: SEARCH_USER_ID_SUCCESS, payload: { data } });
            dispatch({ type: REMEMBER_USER_BY_ID, payload: { data } });
        } catch (error) {
            dispatch({
                type: SEARCH_USER_ID_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function clearCacheAndSearchUserById(searchUserId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_USER_CLEAR });
        await dispatch(searchUserById(searchUserId));
    };
}

export function searchPartnerManager(email: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_PARTNER_MANAGER });
        try {
            const { data } = await RestClient.get<PartnerExtendedInformation[]>(
                `/v1/callcenter/partner_manager_information/${email}`
            );
            dispatch({
                type: SEARCH_PARTNER_MANAGER_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: SEARCH_PARTNER_MANAGER_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function searchPartnerByAnyId(searchId: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_PARTNER_BY_ANY_ID });
        try {
            const { data } = await RestClient.get<PartnerExtendedInformation[]>(
                `/v1/callcenter/partner_manager_information/search_by_any_id/${searchId}`
            );
            dispatch({
                type: SEARCH_PARTNER_BY_ANY_ID_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: SEARCH_PARTNER_BY_ANY_ID_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function clearPartnerSearch() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_PARTNER_CLEAR });
    };
}
