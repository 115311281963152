import React, { Fragment, FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';

interface Props {
    data: {
        status: string;
    };
    close: () => any;
}

const FailedPaymentVerificationInformation: FunctionComponent<Props> = ({
    data,
    close,
}) => {
    let reason =
        'Die Zahlungsmethode kann nicht verifiziert werden, da sie sich nicht in Verifizierung befindet.';

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Zahlungsmethode kann nicht verifiziert werden
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{reason}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => close()}>Ok.</Button>
            </Modal.Footer>
        </Fragment>
    );
};

export default connect(null, { close: closeModal })(
    FailedPaymentVerificationInformation
);
