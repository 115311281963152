import React from 'react';
import { Formik, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './LaundryRoomCardAddLanguage.module.css';

interface Props {
    languagesToShow: string[];
    onAddNewLanguage: (language: string) => void;
}

interface LanguageFormikValue {
    language: string;
}

export function LaundryRoomCardAddLanguage(props: Props) {
    function handleAddNewLanguage(languageDto: LanguageFormikValue) {
        props.onAddNewLanguage(languageDto.language);
    }

    const languagesToAddCard: LanguageFormikValue = {
        language: props.languagesToShow[0],
    };
    return (
        <div>
            <Formik
                onSubmit={handleAddNewLanguage}
                initialValues={languagesToAddCard}
            >
                {(formProps: FormikProps<LanguageFormikValue>) => (
                    <Form noValidate onSubmit={formProps.handleSubmit}>
                        <Form.Group
                            controlId={'language'}
                            className={styles.selectLanguage}
                        >
                            <Form.Label>Sprache wählen</Form.Label>
                            <Form.Control
                                as="select"
                                className={styles.maxWidth}
                                onChange={formProps.handleChange}
                            >
                                {props.languagesToShow.map((language) => (
                                    <option key={language} value={language}>
                                        {language}
                                    </option>
                                ))}
                            </Form.Control>
                            <Button type="submit">
                                <FontAwesomeIcon icon={faPlus} />
                            </Button>
                        </Form.Group>
                    </Form>
                )}
            </Formik>
        </div>
    );
}
