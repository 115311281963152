import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    fetchAllMansions,
    filterLocations,
    filterMansions,
} from '../../store/location/locationActions';
import {
    LocationForSelection,
    MansionWithLocations,
} from '../../store/location/locationReducer';
import { ObjectSchema } from 'yup';
import * as yup from 'yup';
import { Formik, FormikActions, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Alert from 'react-bootstrap/Alert';

interface ReduxProps {
    allMansions: MansionWithLocations[];
    filteredMansions: MansionWithLocations[];
    filteredLocations: LocationForSelection[];

    fetchAllMansions: () => void;
    filterMansions: (postalcode: string) => void;
    filterLocations: (mansionId: string) => void;
}

interface Props extends ReduxProps {
    onChange: (laundryRoomId: number) => void;
    selectedLaundryRoomId?: number;
}

interface MansionAndLaundryRoom {
    smartSearchValue: string;
    mansionId: string;
    locationId: string;
}

const schema: ObjectSchema<MansionAndLaundryRoom> = yup.object({
    smartSearchValue: yup.string(),
    mansionId: yup.string().required(),
    locationId: yup.string().required(),
});

function SelectLaundryRoomFromList({ ...props }: Props) {
    const [initialValues, setInitialValues] = useState<MansionAndLaundryRoom>();

    useEffect(() => {
        if (props.allMansions && props.allMansions.length > 0) {
            props.filterMansions('');
            return;
        }
        props.fetchAllMansions();
    }, []);

    useEffect(() => {
        if (initialValues) {
            return;
        }
        if (!props.allMansions || props.allMansions.length === 0) {
            return;
        }
        const mansion = props.allMansions.find(
            (m) =>
                !!m.locations.find((l) => l.id === props.selectedLaundryRoomId)
        );
        const values: MansionAndLaundryRoom = {
            smartSearchValue: '',
            mansionId: mansion ? String(mansion.id) : '',
            locationId: mansion ? String(props.selectedLaundryRoomId) : '',
        };
        if (mansion) {
            props.filterLocations(String(mansion.id));
        }
        setInitialValues(values);
    }, [props.allMansions]);

    if (!initialValues) {
        return <div>Waschräume werden geladen...</div>;
    }

    return (
        <Formik
            validationSchema={schema}
            initialValues={initialValues}
            onSubmit={handleSubmit}
            children={renderForm}
        />
    );

    function handleSubmit(
        values: MansionAndLaundryRoom,
        actions: FormikActions<MansionAndLaundryRoom>
    ) {
        actions.setSubmitting(false);
    }

    function renderForm(formProps: FormikProps<MansionAndLaundryRoom>) {
        if (props.filteredMansions.length > 0) {
            if (
                !props.filteredMansions.find(
                    (it) => it.id.toString() === formProps.values.mansionId
                )
            ) {
                formProps.setFieldValue(
                    'mansionId',
                    props.filteredMansions[0].id.toString()
                );
                props.filterLocations(props.filteredMansions[0].id.toString());
            }
        } else {
            if (formProps.values.mansionId !== '') {
                formProps.setFieldValue('mansionId', '');
                props.filterLocations('');
            }
        }

        let selectedLocation: LocationForSelection | undefined;

        if (props.filteredLocations.length > 0) {
            selectedLocation = props.filteredLocations.find(
                (it) => it.id.toString() === formProps.values.locationId
            );
            if (!selectedLocation) {
                selectedLocation = props.filteredLocations[0];
                formProps.setFieldValue(
                    'locationId',
                    selectedLocation.id.toString()
                );
                handleChange(selectedLocation.id);
            }
        } else {
            selectedLocation = undefined;
            if (formProps.values.locationId !== '') {
                formProps.setFieldValue('locationId', '');
                handleChange(Number.NaN);
            }
        }

        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className={'ml-3'}>
                    <Form.Group controlId={'smartSearchValue'}>
                        <Form.Label column={false}>
                            Freie Waschraumsuche (Straße, Ort, PLZ)
                        </Form.Label>
                        <Form.Control
                            type={'text'}
                            onChange={handleChangeAndTriggerMansionSearch}
                            value={formProps.values.smartSearchValue}
                        />
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mr-3'}>
                    <Form.Group controlId={'mansionId'}>
                        <Form.Label column={false}>Objekt</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleMansionChange}
                            value={formProps.values.mansionId}
                        >
                            {props.filteredMansions.map((it) => (
                                <option
                                    key={it.id}
                                    value={it.id}
                                >{`${it.street} ${it.housenumber}, ${it.postalcode} ${it.city}`}</option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mr-3'}>
                    <Form.Group controlId="locationId">
                        <Form.Label column={false}>Waschraum</Form.Label>
                        <Form.Control
                            as="select"
                            onChange={handleLaundryRoomChange}
                            value={formProps.values.locationId}
                        >
                            {props.filteredLocations.map((it) => (
                                <option key={it.id} value={it.id}>
                                    {it.name}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                {selectedLocation && !selectedLocation.phone_users_allowed && (
                    <Row className={'ml-3 mr-3'}>
                        <Alert variant="warning">
                            {
                                'Der ausgewählte Waschraum unterstützt keine Telefonbuchung'
                            }
                        </Alert>
                    </Row>
                )}
            </Form>
        );

        function handleMansionChange(event: React.ChangeEvent<any>) {
            props.filterLocations(event.target.value);
            formProps.handleChange(event);
        }

        function handleLaundryRoomChange(event: React.ChangeEvent<any>) {
            formProps.handleChange(event);
            handleChange(Number(event.target.value));
        }

        function handleChangeAndTriggerMansionSearch(
            event: React.ChangeEvent<any>
        ) {
            props.filterMansions(event.target.value);
            formProps.handleChange(event);
        }
    }

    function handleChange(laundryRoomId: number) {
        props.onChange(laundryRoomId);
    }
}

export default connect(
    (store: RootStore) => ({
        allMansions: store.location.allMansions,
        filteredMansions: store.location.filteredMansions,
        filteredLocations: store.location.filteredLocations,
    }),
    { fetchAllMansions, filterMansions, filterLocations }
)(SelectLaundryRoomFromList);
