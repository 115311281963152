import React from 'react';
import Table from 'react-bootstrap/Table';
import { PartnerExtendedInformation } from '../../store/search-user/searchUserReducer';
import styles from './UserInformationTable.module.css';

interface Props {
    partnerManager: PartnerExtendedInformation;
}

const PartnerManagerInformationTable = ({ partnerManager }: Props) => {
    return (
        <>
            <h3>Partner Account Manager</h3>
            <Table borderless className={styles.table}>
                <thead>
                    <tr className={styles.header}>
                        <th>Name</th>
                        <th>Firmenname</th>
                        <th>Email</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className={styles.body}>
                        <td>
                            {partnerManager.first_name}{' '}
                            {partnerManager.last_name}
                        </td>
                        <td>{partnerManager.company_name}</td>
                        <td>{partnerManager.email}</td>
                    </tr>
                </tbody>
            </Table>
        </>
    );
};

export default PartnerManagerInformationTable;
