import React from 'react';
import {
    Button,
    OverlayTrigger,
    Spinner,
    Table,
    Tooltip,
} from 'react-bootstrap';
import styles from './CreditNotesSummaryTable.module.css';
import {
    CreditNotesSummary,
    CreditNotesSummaryEntry,
    PartnerGrossFilter,
} from '../../store/grouped-credit-notes/groupedCreditNotesReducer';
import {
    CreditNotesDownloadRequest,
    CreditNotesExportTypes,
    getCreditNotesContents,
} from '../../store/grouped-credit-notes/groupedCreditNotesAction';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { WeWashApiErrorTag } from '../../http/errors';
import { CommunicationState } from '../../store/communicationState';
import { DownloadCallback, downloadFile } from '../../util/downloadFileAsBlob';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Props {
    creditNotesSummary: CreditNotesSummary;
}

interface PropsWithConnect extends Props {
    apiErrors: WeWashApiErrorTag[];
    downloadingCreditNotesZip: {
        state: CommunicationState;
        request?: CreditNotesDownloadRequest;
    };

    downloadGroupedCreditNotes: (
        request: CreditNotesDownloadRequest,
        cb: DownloadCallback
    ) => void;
}

function CreditNotesSummaryTable(props: PropsWithConnect) {
    return (
        <div className={styles.creditNotesSummaryTable}>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Land</th>
                        <th>Zeitraum</th>
                        <th>Partnershare</th>
                        <th>Dokumente</th>
                        <th>PDFs</th>
                        <th>XMLs</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {props.creditNotesSummary.entries.map((entry) =>
                        renderEntry(entry)
                    )}
                </tbody>
            </Table>
        </div>
    );

    function renderEntry(entry: CreditNotesSummaryEntry) {
        const accountableTooltip = (
            <Tooltip id={'accountableTooltip'}>
                Davon korrigiert: {entry.corrected}; Intern: {entry.internal}
            </Tooltip>
        );
        const pdfTooltip = (
            <Tooltip id={'pdfTooltip'}>
                Fehler: {entry.pdf_error}; Initial: {entry.pdf_initial}
            </Tooltip>
        );
        const xmlTooltip = (
            <Tooltip id={'xmlTooltip'}>
                Fehler: {entry.xml_error}; Initial: {entry.xml_initial}
            </Tooltip>
        );
        return (
            <tr key={entry.country_code + entry.partner_gross_filter}>
                <td>
                    {entry.country_code} ({entry.country_id})
                </td>
                <td>{renderAccountingPeriod()}</td>
                <td>{mapPartnerGrossFilter(entry.partner_gross_filter)}</td>
                <td className={styles.numberCell}>
                    <OverlayTrigger
                        placement="top"
                        overlay={accountableTooltip}
                    >
                        <span className={styles.textWithTooltip}>
                            {String(entry.accountable)}
                        </span>
                    </OverlayTrigger>
                </td>
                <td className={styles.numberCell}>
                    <OverlayTrigger placement="top" overlay={pdfTooltip}>
                        <span className={styles.textWithTooltip}>
                            {String(entry.pdf_success)}
                        </span>
                    </OverlayTrigger>
                </td>
                <td className={styles.numberCell}>
                    {hasXmls() && (
                        <OverlayTrigger placement="top" overlay={xmlTooltip}>
                            <span className={styles.textWithTooltip}>
                                {String(entry.xml_success)}
                            </span>
                        </OverlayTrigger>
                    )}
                </td>
                <td className={styles.buttonCell}>{renderPdfButton()}</td>
                <td className={styles.buttonCell}>
                    {maybeRenderDatevButton(entry.partner_gross_filter)}
                </td>
            </tr>
        );

        function renderAccountingPeriod() {
            const monthStr = String(entry.month).replace(/^(\d)$/, '0$1');
            return `${entry.year}-${monthStr}`;
        }

        function renderPdfButton() {
            const buttonDisabled =
                props.downloadingCreditNotesZip.state ===
                CommunicationState.LOADING;
            return (
                <Button
                    variant={'outline-secondary'}
                    onClick={handleDownloadPdf}
                    disabled={buttonDisabled}
                >
                    {maybeRenderSpinnerOrDownloadIcon(
                        buttonDisabled,
                        translateToPathParam(entry.partner_gross_filter)
                    )}
                    PDFs
                </Button>
            );
        }

        function handleDownloadPdf() {
            const rq: CreditNotesDownloadRequest = {
                year: entry.year,
                month: entry.month,
                countryCode: entry.country_code,
                exportType: translateToPathParam(entry.partner_gross_filter),
            };
            props.downloadGroupedCreditNotes(rq, downloadFile);
        }

        function translateToPathParam(
            filter: PartnerGrossFilter
        ): CreditNotesExportTypes {
            switch (filter) {
                case PartnerGrossFilter.POSITIVE:
                    return CreditNotesExportTypes.PDFS_POSITIVE;
                case PartnerGrossFilter.NEGATIVE:
                    return CreditNotesExportTypes.PDFS_NEGATIVE;
                case PartnerGrossFilter.ZERO:
                    return CreditNotesExportTypes.PDFS_ZERO;
                case PartnerGrossFilter.ALL:
                default:
                    return CreditNotesExportTypes.PDFS_ALL;
            }
        }

        function translateToDatevExportPathParam(filter: PartnerGrossFilter) {
            switch (filter) {
                case PartnerGrossFilter.NEGATIVE:
                    return CreditNotesExportTypes.DATEV_NEGATIVE;
                default:
                    return CreditNotesExportTypes.DATEV_POSITIVE;
            }
        }

        function maybeRenderDatevButton(filter: PartnerGrossFilter) {
            if (!hasXmls()) {
                return null;
            }
            const buttonDisabled =
                props.downloadingCreditNotesZip.state ===
                CommunicationState.LOADING;
            const exportType: CreditNotesExportTypes =
                translateToDatevExportPathParam(filter);
            return (
                <Button
                    variant={'outline-secondary'}
                    onClick={() => handleDownloadDatev(exportType)}
                    disabled={buttonDisabled}
                >
                    {maybeRenderSpinnerOrDownloadIcon(
                        buttonDisabled,
                        exportType
                    )}
                    XMLs
                </Button>
            );
        }

        function handleDownloadDatev(exportType: CreditNotesExportTypes) {
            const rq: CreditNotesDownloadRequest = {
                year: entry.year,
                month: entry.month,
                countryCode: entry.country_code,
                exportType: exportType,
            };
            props.downloadGroupedCreditNotes(rq, downloadFile);
        }

        function maybeRenderSpinnerOrDownloadIcon(
            blocked: boolean,
            exportType: CreditNotesExportTypes
        ) {
            const request: CreditNotesDownloadRequest | undefined =
                props.downloadingCreditNotesZip.request;
            const shouldShowSpinner =
                request &&
                entry.year === request.year &&
                entry.month === request.month &&
                entry.country_code === request.countryCode &&
                exportType === request.exportType;

            if (blocked && shouldShowSpinner) {
                return <Spinner animation={'border'} size={'sm'} />;
            } else {
                return <FontAwesomeIcon size={'sm'} icon={faDownload} />;
            }
        }

        function hasXmls() {
            const filterTypesThatSupportXMLDownload = [
                PartnerGrossFilter.POSITIVE,
                PartnerGrossFilter.NEGATIVE,
            ];
            return filterTypesThatSupportXMLDownload.includes(
                entry.partner_gross_filter
            );
        }
    }

    function mapPartnerGrossFilter(filter: PartnerGrossFilter): string {
        switch (filter) {
            case PartnerGrossFilter.ALL:
                return 'beliebig';
            case PartnerGrossFilter.POSITIVE:
                return '>0';
            case PartnerGrossFilter.NEGATIVE:
                return '<0';
            case PartnerGrossFilter.ZERO:
                return '=0';
            default:
                return filter;
        }
    }
}

export default connect(
    (store: RootStore) => ({
        apiErrors: store.groupedCreditNotes.errors,
        downloadingCreditNotesZip:
            store.groupedCreditNotes.downloadingCreditNotesZip,
    }),
    { downloadGroupedCreditNotes: getCreditNotesContents }
)(CreditNotesSummaryTable);
