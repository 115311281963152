export function parseUrlParamsFromSearchString(
    searchString: string
): Map<string, string> {
    const result = new Map<string, string>();
    searchString
        .split('&')
        .map((val) => val.split('='))
        .forEach((variable) => {
            result.set(variable[0], variable[1]);
        });
    return result;
}

export function appendRequestParams(
    baseUrl: string,
    requestParams: Map<string, string>
): string {
    if (!requestParams) {
        return baseUrl;
    }
    let result = baseUrl + '?';
    const keys = Array.from(requestParams.keys());
    for (let i = 0; i < keys.length; i++) {
        const val = requestParams.get(keys[i]);
        result += keys[i] + '=' + val;
        if (i < keys.length - 1) {
            result += '&';
        }
    }
    return result;
}
