import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import InputGroup from 'react-bootstrap/InputGroup';
import {
    checkPartner,
    transferMansion,
} from '../../store/manage-mansion/manageMansionActions';
import {
    ManageMansionStore,
    Mansion,
    MansionTransfer,
    Partner,
} from '../../store/manage-mansion/manageMansionReducer';
import Table from 'react-bootstrap/Table';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';
import Row from 'react-bootstrap/Row';

interface Props {
    manageMansion: ManageMansionStore;
    transferMansion: (dto: MansionTransfer) => any;
    checkPartner: (partnerId: number) => any;
    goTo: (route: Route) => void;
}

interface InputFormData {
    newPartnerId: string;
}

const ManageMansion: FunctionComponent<Props> = (props) => {
    const initialValues: InputFormData = { newPartnerId: '' };

    async function handleSubmit(
        values: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        const mansion = props.manageMansion.mansion;
        if (!mansion) {
            actions.setSubmitting(false);
            return;
        }
        const newPartnerId: number = Number(values.newPartnerId);
        const confirmationMessage = `Objekt mit ID ${mansion.mansion_id} dem Partner mit ID ${newPartnerId} zuweisen?`;

        if (window.confirm(confirmationMessage)) {
            const dto: MansionTransfer = {
                mansion_id: mansion.mansion_id,
                current_owner_partner_id: mansion.partner.partner_id,
                partner_id: newPartnerId,
            };
            props.transferMansion(dto);
        }
        actions.setSubmitting(false);
    }

    function handleBack() {
        props.goTo(Route.SEARCH_MANSION);
    }

    function renderMansionTable(mansion: Mansion) {
        return (
            <Table bordered>
                <thead>
                    <tr>
                        <th>Mansion ID</th>
                        <th>Objektadresse</th>
                        <th>Partner ID</th>
                        <th>Vor-/Nachname</th>
                        <th>Firma</th>
                        <th>Customer ID</th>
                        <th>Account Manager ID</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{mansion.mansion_id}</td>
                        <td>{mansion.address}</td>
                        <td>{mansion.partner.partner_id}</td>
                        <td>{mansion.partner.name}</td>
                        <td>{mansion.partner.company_name}</td>
                        <td>{mansion.partner.customer_id}</td>
                        <td>{mansion.partner.partner_manager_id}</td>
                    </tr>
                </tbody>
            </Table>
        );
    }

    function renderPartnerCheckResult(partner: Partner) {
        return `Partner ID ${partner.partner_id}, ${partner.name}, ${partner.company_name}, Account Manager ID ${partner.partner_manager_id}`;
    }

    function renderForm(formProps: FormikProps<InputFormData>) {
        function handleCheckNewPartnerId() {
            const newPartnerId: number = Number(formProps.values.newPartnerId);
            props.checkPartner(newPartnerId);
        }

        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group
                    as={Row}
                    controlId={'formGroupTransferMansion'}
                    className={'mx-0'}
                >
                    <ErrorFeedback
                        apiErrors={props.manageMansion.transferMansionErrors}
                    />
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Neue Partner ID</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type="text"
                            name={'newPartnerId'}
                            value={formProps.values.newPartnerId}
                            onChange={formProps.handleChange}
                            placeholder={'0'}
                        />
                    </InputGroup>
                </Form.Group>
                <Row className={'mx-0 mt-4'}>
                    <ErrorFeedback
                        apiErrors={props.manageMansion.checkPartnerErrors}
                    />
                    {props.manageMansion.partnerCheckResult &&
                        renderPartnerCheckResult(
                            props.manageMansion.partnerCheckResult
                        )}
                </Row>
                <Row className={'mx-0 mt-4'}>
                    <Button
                        variant="outline-secondary"
                        className={'mr-2'}
                        onClick={handleCheckNewPartnerId}
                    >
                        Neue Partner ID prüfen
                    </Button>
                    <Button type={'submit'} disabled={formProps.isSubmitting}>
                        Partner ändern
                    </Button>
                </Row>
            </Form>
        );
    }

    return (
        <div id="ManageMansion">
            <Row className={'mx-0 mb-4'}>
                <Button onClick={handleBack}>Zurück</Button>
            </Row>
            {props.manageMansion.mansion &&
                renderMansionTable(props.manageMansion.mansion)}
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                children={renderForm}
            />
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        manageMansion: store.manageMansion,
    }),
    { transferMansion, checkPartner, goTo }
)(ManageMansion);
