import React from 'react';
import styles from './HorizontalApplianceSelect.module.css';
import {
    ApplianceWithIncidentType,
    mapIncidentTypeToShortText,
} from '../../store/manage-laundry-room/manageLaundryRoomUtils';

export interface HorizontalApplianceSelectProperties {
    applianceInfos: DisplayedApplianceInfo[];
    rowSize: number;
    cardSize: number;
    selectedLocation?: number;
    selectedApplianceId?: number | string;
    maxWidth?: string;
    maxHeight?: string;
    onApplianceSelectCallback?: (
        selectedAppliance: DisplayedApplianceInfo
    ) => void;
}

interface ApplianceInfoWidgetProperties {
    adjustedCardSize: number;
    selectedApplianceId?: number | string;
    onApplianceSelectCallback?: (
        selectedAppliance: DisplayedApplianceInfo
    ) => void;
}

export interface DisplayedApplianceInfo extends ApplianceWithIncidentType {
    id: number;
    active: boolean;
    name?: string;
}

const cardStyleActive = {
    width: '18rem',
    height: '18rem',
    textAlign: 'center' as const,
    borderStyle: 'solid',
    borderWidth: '2px',
    margin: '2px',
    color: '',
    backgroundColor: '',
};

const cardStyleInactive = {
    width: '18rem',
    height: '18rem',
    'text-align': 'center',
    borderStyle: 'dashed',
    borderWidth: '2px',
    borderColor: 'grey',
    margin: '2px',
    color: '',
    backgroundColor: '',
};

function HorizontalApplianceSelect(props: HorizontalApplianceSelectProperties) {
    const adjustedCardSize = Math.max(props.cardSize, 6);
    const containerStyle = {
        display: 'inline-block',
        'align-content': 'center',
        margin: '0.5rem',
    };
    return (
        <span>
            <h2>Maschinen im Waschraum</h2>
            <i>Eine Maschine anklicken um Details einzusehen</i>
            <br />
            <div
                className={styles.horizontalContainerFlex}
                style={containerStyle}
            >
                <div className="row">
                    {props.applianceInfos.map((applianceInfo) =>
                        ApplianceInfoWidget(
                            {
                                adjustedCardSize: adjustedCardSize,
                                onApplianceSelectCallback:
                                    props.onApplianceSelectCallback,
                                selectedApplianceId: props.selectedApplianceId,
                            },
                            applianceInfo
                        )
                    )}
                </div>
            </div>
        </span>
    );
}

function ApplianceInfoWidget(
    props: ApplianceInfoWidgetProperties,
    applianceInfo: DisplayedApplianceInfo
) {
    const activeText = applianceInfo.active ? 'Aktiv' : 'Nicht aktiv';
    const selectedAppliance =
        String(props.selectedApplianceId) === String(applianceInfo.id);

    function createActiveStyle() {
        const cardStyle = applianceInfo.active
            ? cardStyleActive
            : cardStyleInactive;
        const adjustedCardStyle = Object.assign({}, cardStyle);
        adjustedCardStyle.width = props.adjustedCardSize + 'rem';
        adjustedCardStyle.height = props.adjustedCardSize + 'rem';
        adjustedCardStyle.backgroundColor = selectedAppliance ? '#00B3E6' : '';
        adjustedCardStyle.color = selectedAppliance ? '#fff' : '';
        return adjustedCardStyle;
    }

    const adjustedCardStyle = createActiveStyle();
    const onClickCallback = (event: any) => {
        if (props.onApplianceSelectCallback) {
            props.onApplianceSelectCallback(applianceInfo);
        }
    };
    if (applianceInfo.name) {
        return (
            <div
                className="card"
                style={adjustedCardStyle}
                onClick={onClickCallback}
            >
                <div className={styles.card}>
                    <p className="card-text">
                        {applianceInfo.name}
                        <br />
                        <i>{applianceInfo.id}</i>
                        <br />
                        <i>
                            {mapIncidentTypeToShortText(
                                applianceInfo.incident_type
                            )}
                        </i>
                    </p>
                    <p className="card-text">{activeText}</p>
                </div>
            </div>
        );
    } else {
        return (
            <div
                className="card"
                style={adjustedCardStyle}
                onClick={onClickCallback}
            >
                <div className={styles.card}>
                    <p className="card-text">{applianceInfo.id}</p>
                    <p className="card-text">{activeText}</p>
                </div>
            </div>
        );
    }
}

export default HorizontalApplianceSelect;
