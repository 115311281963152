import {configureStore} from 'redux-starter-kit';
import {userReducer, UserStore} from './user/userReducer';
import {locationReducer, LocationStore} from './location/locationReducer';
import {routerReducer, RouterStore} from './router/routerReducer';
import {registrationReducer, RegistrationStore,} from './registration/registrationReducer';
import {blacklistIbanReducer, BlacklistIbanStore,} from './blacklist-iban/blacklistIbanReducer';
import {failedPaymentReducer, FailedPaymentStore,} from './failed-payment/failedPaymentReducer';
import {topUpReducer, TopUpStore} from './topup/topUpReducer';
import {refundReservationReducer, RefundReservationStore,} from './refund-reservation/refundReservationReducer';
import {searchUserReducer, SearchUserStore,} from './search-user/searchUserReducer';
import {manageUserReducer, ManageUserStore,} from './manage-user/manageUserReducer';
import {modalReducer, ModalStore} from './modal/modalStore';
import {paymentReducer, PaymentStore} from './payment/paymentReducer';
import {manageApplianceReducer, ManageApplianceStore,} from './manage-appliances/manageApplianceReducer';
import {manageSerialnumbersReducer, ManageSerialnumbersStore,} from './manage-serialnumbers/manageSerialnumbersReducer';
import {manageMansionReducer, ManageMansionStore,} from './manage-mansion/manageMansionReducer';
import {payInvoiceReducer, PayInvoiceStore,} from './pay-invoice/payInvoiceReducer';
import {manageHubsReducer, ManageHubsStore,} from './manage-hubs/manageHubsReducer';
import {manageLaundryRoomReducer, ManageLaundryRoomStore,} from './manage-laundry-room/manageLaundryRoomReducer';
import {manageCreditNotesReducer, ManageCreditNotesStore,} from './manage-credit-notes/manageCreditNotesReducer';
import {
    groupedUserInvoicesReducer,
    GroupedUserInvoicesStore,
} from './grouped-user-invoices/groupedUserInvoicesReducer';
import {
    manageReservationsOfUserReducer,
    ManageReservationsOfUserStore,
} from './manage-user/manageReservationsOfUserReducer';
import {manageInvoicesOfUserReducer, ManageInvoicesOfUserStore,} from './manage-user/manageInvoicesOfUserReducer';
import {groupedCreditNotesReducer, GroupedCreditNotesStore,} from './grouped-credit-notes/groupedCreditNotesReducer';
import {manageVipUsersReducer, ManageVipUsersStore,} from './manage-vip-users/manageVipUsersReducer';
import {
    managePartnerManagerReducer,
    ManagePartnerManagerStore,
} from './manage-partner-manager/managePartnerManagerReducer';
import {locationFeedbackModeReducer, LocationFeedbackStore} from "./location-feedback-mode/locationFeedbackReducer";

export interface RootStore {
    user: UserStore;
    location: LocationStore;
    locationFeedback: LocationFeedbackStore;
    router: RouterStore;
    registration: RegistrationStore;
    blacklistIban: BlacklistIbanStore;
    failedPayment: FailedPaymentStore;
    topUp: TopUpStore;
    refundReservation: RefundReservationStore;
    searchUser: SearchUserStore;
    manageUser: ManageUserStore;
    modal: ModalStore;
    payment: PaymentStore;
    manageAppliance: ManageApplianceStore;
    manageSerialnumbers: ManageSerialnumbersStore;
    manageMansion: ManageMansionStore;
    manageHubs: ManageHubsStore;
    payInvoice: PayInvoiceStore;
    manageLaundryRooms: ManageLaundryRoomStore;
    manageCreditNotes: ManageCreditNotesStore;
    groupedUserInvoices: GroupedUserInvoicesStore;
    groupedCreditNotes: GroupedCreditNotesStore;
    manageReservationsOfUser: ManageReservationsOfUserStore;
    manageInvoicesOfUser: ManageInvoicesOfUserStore;
    manageVipUsers: ManageVipUsersStore;
    managePartnerManager: ManagePartnerManagerStore;
}

export default configureStore({
    reducer: {
        user: userReducer,
        location: locationReducer,
        locationFeedback: locationFeedbackModeReducer,
        router: routerReducer,
        registration: registrationReducer,
        blacklistIban: blacklistIbanReducer,
        failedPayment: failedPaymentReducer,
        topUp: topUpReducer,
        refundReservation: refundReservationReducer,
        searchUser: searchUserReducer,
        manageUser: manageUserReducer,
        modal: modalReducer,
        payment: paymentReducer,
        manageAppliance: manageApplianceReducer,
        manageSerialnumbers: manageSerialnumbersReducer,
        manageMansion: manageMansionReducer,
        manageHubs: manageHubsReducer,
        payInvoice: payInvoiceReducer,
        manageLaundryRooms: manageLaundryRoomReducer,
        manageCreditNotes: manageCreditNotesReducer,
        groupedUserInvoices: groupedUserInvoicesReducer,
        manageReservationsOfUser: manageReservationsOfUserReducer,
        manageInvoicesOfUser: manageInvoicesOfUserReducer,
        groupedCreditNotes: groupedCreditNotesReducer,
        manageVipUsers: manageVipUsersReducer,
        managePartnerManager: managePartnerManagerReducer,
    },
});
