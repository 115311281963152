import React from 'react';
import { Redirect, Route as ReactRoute, RouteProps } from 'react-router-dom';
import { Route } from '../Router';
import { connect } from 'react-redux';
import { RootStore } from '../store/rootStore';

interface Props extends RouteProps {
    ccAgentsAllowed?: boolean;
    loggedIn: boolean;
    userIsAdmin: boolean;
}

function PrivateRoute({
    component,
    ccAgentsAllowed,
    userIsAdmin,
    loggedIn,
    ...rest
}: Props) {
    const authorized = ccAgentsAllowed ? loggedIn : loggedIn && userIsAdmin;

    if (authorized) {
        return <ReactRoute {...rest} component={component} />;
    } else {
        return (
            <ReactRoute {...rest}>
                <Redirect
                    to={{
                        pathname: Route.LOGIN,
                        state: { from: rest.location },
                    }}
                />
            </ReactRoute>
        );
    }
}

export default connect((store: RootStore) => ({
    loggedIn: store.user.loggedIn,
    userIsAdmin: store.user.userIsAdmin,
}))(PrivateRoute);
