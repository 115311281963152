import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { extractErrorTags } from '../../http/errors';
import RestClient from '../../http/RestClient';
import { RouterLiveData } from './manageHubsReducer';

export const HUBS_INSPECT = 'HUBS_INSPECT';
export const HUBS_INSPECT_SUCCESS = 'HUBS_INSPECT_SUCCESS';
export const HUBS_INSPECT_FAILURE = 'HUBS_INSPECT_FAILURE';

export const HUBS_MARK_SHIPPED = 'HUBS_MARK_SHIPPED';
export const HUBS_MARK_SHIPPED_SUCCESS = 'HUBS_MARK_SHIPPED_SUCCESS';
export const HUBS_MARK_SHIPPED_FAILURE = 'HUBS_MARK_SHIPPED_FAILURE';

export const HUBS_START_INSTALLATION_REPORT = 'HUBS_START_INSTALLATION_REPORT';
export const HUBS_START_INSTALLATION_REPORT_SUCCESS =
    'HUBS_START_INSTALLATION_REPORT_SUCCESS';
export const HUBS_START_INSTALLATION_REPORT_FAILURE =
    'HUBS_START_INSTALLATION_REPORT_FAILURE';

export function hubInspect(hubSerialNumber: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: HUBS_INSPECT });
        try {
            const { data } = await RestClient.get<RouterLiveData>(
                `/v1/callcenter/routers/${encodeURIComponent(hubSerialNumber)}`
            );
            dispatch({ type: HUBS_INSPECT_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: HUBS_INSPECT_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

interface RouterUpdateRequest {
    serial_number: string;
    installation_lifecycle: string;
}

export function hubMarkShipped(hubSerialNumber: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: HUBS_MARK_SHIPPED });
        try {
            const patchData: RouterUpdateRequest = {
                serial_number: hubSerialNumber,
                installation_lifecycle: 'INSTALLATION',
            };
            const { data } = await RestClient.patch<RouterLiveData>(
                `/v1/callcenter/routers/${encodeURIComponent(hubSerialNumber)}`,
                patchData
            );
            dispatch({ type: HUBS_MARK_SHIPPED_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: HUBS_MARK_SHIPPED_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function hubStartInstallationReport(hubSerialNumber: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: HUBS_START_INSTALLATION_REPORT });
        try {
            const { data } = await RestClient.post<RouterLiveData>(
                `/v1/callcenter/routers/${encodeURIComponent(
                    hubSerialNumber
                )}/start_installation_report`
            );
            dispatch({
                type: HUBS_START_INSTALLATION_REPORT_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: HUBS_START_INSTALLATION_REPORT_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
