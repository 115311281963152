import React, { FunctionComponent, ReactText } from 'react';
import {
    RouterInstallationLifecycle,
    RouterInstallationReport,
    RouterLiveData,
} from '../../store/manage-hubs/manageHubsReducer';
import { Button, Row, Table } from 'react-bootstrap';

interface Props {
    router: RouterLiveData;
    onSetShipped: (router: RouterLiveData) => void;
    onStartInstallationReport: (router: RouterLiveData) => void;
}

const RouterAndReportView: FunctionComponent<Props> = (props) => {
    const { router } = props;

    function handleStartInstallation() {
        props.onSetShipped(router);
    }

    function handleStartReport() {
        props.onStartInstallationReport(router);
    }

    return (
        <div>
            <Row className={'mx-0 mt-4 mt-4'}>
                <Table>
                    <thead>
                        <tr>
                            <th>Seriennummer</th>
                            <th>Installation</th>
                            <th>Go Live</th>
                            <th>IP Adresse</th>
                            <th>Laundry Room ID</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{router.serial_number}</td>
                            <td>{router.installation_lifecycle}</td>
                            <td>
                                {router.go_live_time &&
                                    new Date(
                                        router.go_live_time
                                    ).toLocaleString()}
                            </td>
                            <td>{router.ip_address}</td>
                            <td>{router.location_id}</td>
                            <td>{router.hub_state}</td>
                        </tr>
                    </tbody>
                </Table>
            </Row>
            <Row className={'mx-0 mt-4 mt-4'}>
                <h2>Installation Report</h2>
            </Row>
            <Row className={'mx-0 mt-4 mt-4'}>
                {maybeRenderReport(router.installation_report)}
            </Row>
            <Row className={'mx-0 mt-4 mt-4'}>{renderActions()}</Row>
        </div>
    );

    function renderActions() {
        const showStartInstallation =
            router.installation_lifecycle ===
            RouterInstallationLifecycle.PRODUCED;

        const showStartReport =
            router.installation_lifecycle === RouterInstallationLifecycle.LIVE;
        const reportInProgress = !!router.installation_report;

        return (
            <div>
                {showStartInstallation && (
                    <Button
                        variant={'outline-secondary'}
                        className={'mr-2'}
                        onClick={handleStartInstallation}
                    >
                        Hub als Verschickt markieren (INSTALLATION)
                    </Button>
                )}
                {showStartReport && (
                    <Button
                        variant={
                            reportInProgress
                                ? 'outline-danger'
                                : 'outline-secondary'
                        }
                        className={'mr-2'}
                        onClick={handleStartReport}
                    >
                        {reportInProgress
                            ? 'Installation Report neu starten'
                            : 'Installation Report starten'}
                    </Button>
                )}
            </div>
        );
    }

    function maybeRenderReport(report?: RouterInstallationReport) {
        if (!report) {
            return <div>Kein Installation Report verfügbar</div>;
        }
        return (
            <Table>
                <tbody>
                    {renderField('Overall Result', report.overall_result)}
                    {renderDate('Due', report.due_time)}
                    {renderField('Status', report.status)}
                    {renderDate('Status Updated', report.status_updated_time)}
                    {renderField('Average RSSI', report.average_rssi)}
                    {renderField('Gateway Firmware', report.gateway_firmware)}
                    {renderField('Switch Firmware', report.switch_firmware)}
                    {renderField('Default Antenna', report.default_antenna)}
                    {renderField('Antenna Mode', report.antenna_mode)}
                    {renderField(
                        'Hub State = Down',
                        report.count_hub_state_history_down
                    )}
                    {renderField(
                        'Hub State = Warning',
                        report.count_hub_state_history_warning
                    )}
                    {renderField(
                        'Hub State = Critical',
                        report.count_hub_state_history_critical
                    )}
                </tbody>
            </Table>
        );
    }

    function renderDate(title: string, timestamp?: number) {
        return renderField(
            title,
            timestamp ? new Date(timestamp).toLocaleString() : ''
        );
    }

    function renderField(title: string, value?: ReactText) {
        const valueOrQuestionMark =
            value !== null && value !== undefined ? value : '?';
        return (
            <tr>
                <th>{title}</th>
                <td>{valueOrQuestionMark}</td>
            </tr>
        );
    }
};

export default RouterAndReportView;
