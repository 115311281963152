import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import { fetchLaundryRoom } from '../location/locationActions';

export const SET_FEEDBACK_QUESTION = 'FETCH_ALL_FEEDBACK_QUESTION';
export const SET_FEEDBACK_QUESTION_SUCCESS =
    'FETCH_ALL_FEEDBACK_QUESTION_SUCCESS';
export const SET_FEEDBACK_QUESTION_FAILURE =
    'FETCH_ALL_FEEDBACK_QUESTION_FAILURE';

export function SetLaundryRoomFeedbackQuestion(
    laundryRoomId: number,
    feedbackQuestionId: number | null,
    closeOnSuccess: () => void,
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SET_FEEDBACK_QUESTION });
        try {
            await RestClient.put(
                `v1/callcenter/laundry_room/${laundryRoomId}/question/${feedbackQuestionId}`
            );
            dispatch({
                type: SET_FEEDBACK_QUESTION_SUCCESS,
            });
            await dispatch(fetchLaundryRoom(laundryRoomId));
            closeOnSuccess();
        } catch (error) {
            dispatch({
                type: SET_FEEDBACK_QUESTION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
