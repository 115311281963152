import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    cancelInvoiceForUser,
    loadInvoiceById,
    resetCancelInvoicePerformedAction,
} from '../../store/pay-invoice/payInvoiceActions';
import {
    CancelInvoicePerformedAction,
    PayInvoiceStore,
    ReservationForInvoiceDetails,
} from '../../store/pay-invoice/payInvoiceReducer';
import { useHistory, useParams } from 'react-router-dom';
import { CommunicationState } from '../../store/communicationState';
import { UserExtendedDetails } from '../../store/manage-user/manageUserReducer';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';
import Row from 'react-bootstrap/Row';
import { Button, Form } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import { formatMonetaryAmount } from '../../store/amountAndCurrency';
import Col from 'react-bootstrap/Col';
import ErrorFeedback from '../../forms/ErrorFeedback';
import styles from './CancelUserInvoice.module.css';
import SuccessFeedback from '../../forms/SuccessFeedback';
import { RefreshButton } from '../common/RefreshButton';
import { printInvoicingStatus } from '../../forms/refund-reservation/InvoiceItemUtil';
import { InvoiceItemStatusForRefund } from '../../store/refund-reservation/refundReservationReducer';

interface Props {
    invoiceStore: PayInvoiceStore;
    userDetails: UserExtendedDetails | null;

    loadInvoiceById: (invoiceId: number) => void;
    cancelInvoiceForUser: (
        invoiceId: number,
        userId: number,
        invoiceItemIds: number[]
    ) => void;
    resetCancelInvoicePerformedAction: () => void;
    goTo: (route: Route, param?: string | number) => void;
}

interface Params {
    invoiceId?: string;
}

interface FormValues {
    [invoiceItemId: string]: boolean;
}

const selectDeselectAllField = 'select_de_select_all';

function CancelUserInvoiceScreen({
    loadInvoiceById,
    cancelInvoiceForUser,
    ...props
}: Props) {
    const params = useParams<Params>();
    const invoiceId = Number(params.invoiceId);
    const history = useHistory();

    useEffect(() => {
        props.resetCancelInvoicePerformedAction();
        loadInvoiceById(invoiceId);
    }, [invoiceId, loadInvoiceById]);

    if (
        [CommunicationState.INITIAL, CommunicationState.LOADING].includes(
            props.invoiceStore.getCommunicationState
        )
    ) {
        return renderEmpty();
    }

    if (!props.invoiceStore.invoiceById) {
        return renderEmpty();
    }
    const invoice = props.invoiceStore.invoiceById;
    const invoiceItems = invoice.reservations;
    const invoiceAlreadyCanceled = invoice.status === 'CANCELED';

    const initialValues: FormValues = Object.fromEntries(
        invoiceItems.map((it) => [String(it.invoice_item_id), true])
    );
    initialValues[selectDeselectAllField] = true;

    return (
        <Row className={'flex-column justify-content-between'}>
            <Col>
                <Row>
                    <Button onClick={goBack} variant={'outline-secondary'}>
                        Zurück
                    </Button>
                    <RefreshButton
                        onRefresh={() => loadInvoiceById(invoiceId)}
                    ></RefreshButton>
                </Row>

                <Row>
                    <Formik
                        onSubmit={handleSubmit}
                        initialValues={initialValues}
                        children={renderForm}
                    />
                </Row>
            </Col>
        </Row>
    );

    function countInvoiceItemStatus() {
        if (!invoice) {
            return <></>;
        }
        let countRefunded = invoice.reservations.filter(
            (reservation) =>
                reservation.invoice_item_status ===
                InvoiceItemStatusForRefund.REFUNDED
        ).length;

        let countCancelled = invoice.reservations.filter(
            (reservation) =>
                reservation.invoice_item_status ===
                InvoiceItemStatusForRefund.CANCELED
        ).length;

        return (
            <>
                {countRefunded} rückerstattet, {countCancelled} abgebrochen
            </>
        );
    }

    function renderSelectUnSelectAll(formikProps: FormikProps<FormValues>) {
        return (
            <tr>
                <td className={styles.invoiceItemEntry}>
                    <Form.Control
                        type={'checkbox'}
                        name={selectDeselectAllField}
                        checked={formikProps.values[selectDeselectAllField]}
                        onChange={(e: any) => {
                            const { checked } = e.target;
                            invoiceItems.map(
                                (it) =>
                                    (formikProps.values[it.invoice_item_id] =
                                        checked)
                            );
                            formikProps.handleChange(e);
                        }}
                    />
                </td>
                <td>Alle auswählen/abwählen</td>
            </tr>
        );
    }

    function getCancellationSuccessMessage() {
        const cancelAction = props.invoiceStore.cancelInvoicePerformedAction;
        if (!cancelAction) {
            return 'Rechnung wurde storniert';
        }
        switch (cancelAction) {
            case CancelInvoicePerformedAction.INVOICE_CANCELLING:
                return 'Rechnung wird storniert';
            case CancelInvoicePerformedAction.INVOICE_ABORTED:
                return 'Rechnungserstellung wurde abgebrochen';
            case CancelInvoicePerformedAction.INVOICE_REFUNDING:
                return 'Rechnung wird erstattet';
            case CancelInvoicePerformedAction.CREDITS_GRANTED:
                return 'Credits wurden gutgeschrieben';
            case CancelInvoicePerformedAction.NONE:
                return 'Rechnung wurde nicht storniert';
        }
    }

    function renderForm(formikProps: FormikProps<FormValues>) {
        return (
            <Form onSubmit={formikProps.handleSubmit}>
                <h2 className={styles.spacerAbove}>Rechnung Stornieren</h2>
                <>
                    <h5>Rechnungsinformationen</h5>
                    <div>Rechnungsnummer: {invoice.invoice_number}</div>
                    <div>Rechnungsstatus: {invoice.status},</div>
                    <div>{countInvoiceItemStatus()}</div>
                    <div>Bezahlstatus: {invoice.payment_status}</div>
                    <div>Land: {invoice.invoice_country_id}</div>
                    <div>Zielstatus: {invoice.target_accounting_state}</div>
                    <div>Datum: {renderTime(invoice.timestamp)}</div>
                    <div>Rechnungssumme: {renderPrice(invoice.amount)}</div>
                </>
                {invoice.balance ? (
                    <div>
                        enthält verwendetes Guthaben:{' '}
                        {renderPrice(invoice.balance)}
                    </div>
                ) : undefined}
                {invoice.discount ? (
                    <div>enthält Discount: {renderPrice(invoice.discount)}</div>
                ) : undefined}
                {renderFinancialTransactionInformation()}
                <h3 className={styles.spacerAbove}>
                    {invoiceAlreadyCanceled
                        ? 'Reservierungen'
                        : 'Reservierungen auswählen:'}
                </h3>
                <table className={styles.invoiceItemList}>
                    <thead>{renderInvoiceItemHeader()}</thead>
                    <tbody>
                        {invoiceItems.map(renderInvoiceItem)}
                        {!invoiceAlreadyCanceled &&
                            invoiceItems.length > 0 &&
                            renderSelectUnSelectAll(formikProps)}
                    </tbody>
                </table>
                <div>
                    Nur ausgewählte Reservierungen werden aufgehoben. Die
                    komplette Rechnung wird storniert, aber die nicht
                    ausgewählten Reservierungen werden dem Nutzer separat in
                    Rechnung gestellt.
                </div>
                {props.invoiceStore.cancelInvoicePerformedAction && (
                    <SuccessFeedback
                        message={getCancellationSuccessMessage()}
                    />
                )}
                <ErrorFeedback apiErrors={props.invoiceStore.errors} />
                {!invoiceAlreadyCanceled && (
                    <Button type={'submit'}>
                        Rechnung Stornieren (nur ausgewählte Reservierungen)
                    </Button>
                )}
            </Form>
        );

        function renderFinancialTransactionInformation() {
            if (!invoice.financial_transaction) {
                return (
                    <h5 className={styles.spacerAbove}>
                        Keine Transaktionsinformationen verfügbar
                    </h5>
                );
            }
            return (
                <>
                    <h5 className={styles.spacerAbove}>
                        Transaktionsinformationen
                    </h5>
                    <div>
                        Transaktionsstatus:{' '}
                        {invoice.financial_transaction.status}
                    </div>
                    <div>
                        Datum:{' '}
                        {renderTime(
                            invoice.financial_transaction
                                .status_updated_timestamp
                        )}
                    </div>
                    <div>
                        Art der Mollie-Überweisung:{' '}
                        {invoice.financial_transaction.mollie_method_type}
                    </div>
                </>
            );
        }

        function renderInvoiceItemHeader() {
            return (
                <tr>
                    <td />
                    <td className={styles.invoiceItemEntry}>
                        Reservierungs-ID
                    </td>
                    <td className={styles.invoiceItemEntry}>Maschine</td>
                    <td className={styles.invoiceItemEntry}>Datum</td>
                    <td className={styles.invoiceItemEntry}>Preis</td>
                    <td className={styles.invoiceItemEntry}>Status</td>
                </tr>
            );
        }

        function renderInvoiceItem(it: ReservationForInvoiceDetails) {
            return (
                <tr key={`ii_${it.invoice_item_id}`}>
                    <td className={styles.invoiceItemEntry}>
                        {!invoiceAlreadyCanceled && (
                            <Form.Control
                                type={'checkbox'}
                                name={`${it.invoice_item_id}`}
                                checked={
                                    formikProps.values[
                                        String(it.invoice_item_id)
                                    ]
                                }
                                onChange={formikProps.handleChange}
                            />
                        )}
                    </td>
                    <td className={styles.invoiceItemEntry}>
                        {it.reservation_id}
                    </td>
                    <td className={styles.invoiceItemEntry}>{it.short_name}</td>
                    <td className={styles.invoiceItemEntry}>
                        {renderTime(it.timestamp)}
                    </td>
                    <td className={styles.invoiceItemEntry}>
                        {renderPrice(it.amount)}
                    </td>
                    <td className={styles.invoiceItemEntry}>
                        {printInvoicingStatus(it.invoice_item_status)}
                    </td>
                </tr>
            );
        }
    }

    function renderTime(timestamp: number) {
        return moment(timestamp).format('DD.MM.YYYY - HH:mm');
    }

    function renderPrice(amount?: number) {
        if (amount === undefined) {
            return undefined;
        }
        return formatMonetaryAmount({
            amount: amount,
            currency: invoice.currency,
        });
    }

    function handleSubmit(values: FormValues) {
        const selectedIds: number[] = Object.keys(values)
            .filter((key) => key !== selectDeselectAllField && values[key])
            .map((it) => Number(it));

        cancelInvoiceForUser(invoiceId, invoice.user_id, selectedIds);
    }

    function renderEmpty() {
        return (
            <Row className={'flex-column justify-content-between'}>
                <Row>Lade...</Row>
                <Row>
                    <Button onClick={goBack} variant={'outline-secondary'}>
                        Zurück
                    </Button>
                </Row>
            </Row>
        );
    }

    function goBack() {
        history.goBack();
    }
}

export default connect(
    (state: RootStore) => ({
        invoiceStore: state.payInvoice,
        userDetails: state.manageUser.details,
    }),
    {
        cancelInvoiceForUser,
        resetCancelInvoicePerformedAction,
        loadInvoiceById,
        goTo,
    }
)(CancelUserInvoiceScreen);
