import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    CHECK_PARTNER,
    CHECK_PARTNER_FAILURE,
    CHECK_PARTNER_SUCCESS,
    SEARCH_MANSION,
    SEARCH_MANSION_FAILURE,
    SEARCH_MANSION_SUCCESS,
    TRANSFER_MANSION,
    TRANSFER_MANSION_FAILURE,
    TRANSFER_MANSION_SUCCESS,
} from './manageMansionActions';
import { RESET_VIEWS } from '../genericActions';

export interface Mansion {
    mansion_id: number;
    address: string;
    partner: Partner;
}

export interface MansionTransfer {
    current_owner_partner_id: number;
    partner_id: number;
    mansion_id: number;
}

export interface Partner {
    partner_id: number;
    name: string;
    company_name: string;
    customer_id: number;
    partner_manager_id?: number;
}

export interface ManageMansionStore {
    mansion: Mansion | null;
    partnerCheckResult: Partner | null;
    loading: boolean;
    searchMansionErrors: WeWashApiErrorTag[];
    transferMansionErrors: WeWashApiErrorTag[];
    checkPartnerErrors: WeWashApiErrorTag[];
}

const initialStore: ManageMansionStore = {
    mansion: null,
    partnerCheckResult: null,
    loading: false,
    searchMansionErrors: [],
    transferMansionErrors: [],
    checkPartnerErrors: [],
};

export const manageMansionReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [SEARCH_MANSION]: (state: ManageMansionStore) => {
        state.loading = true;
        state.searchMansionErrors = [];
    },
    [SEARCH_MANSION_SUCCESS]: (state: ManageMansionStore, action) => {
        state.mansion = action.payload.data;
        state.loading = false;
        state.searchMansionErrors = [];
    },
    [SEARCH_MANSION_FAILURE]: (state: ManageMansionStore, action) => {
        state.mansion = null;
        state.loading = false;
        state.searchMansionErrors = action.payload.errors;
    },
    [TRANSFER_MANSION]: (state: ManageMansionStore) => {
        state.loading = true;
        state.transferMansionErrors = [];
    },
    [TRANSFER_MANSION_SUCCESS]: (state: ManageMansionStore) => {
        state.loading = false;
        state.transferMansionErrors = [];
    },
    [TRANSFER_MANSION_FAILURE]: (state: ManageMansionStore, action) => {
        state.loading = false;
        state.transferMansionErrors = action.payload.errors;
    },
    [CHECK_PARTNER]: (state: ManageMansionStore) => {
        state.loading = true;
        state.checkPartnerErrors = [];
        state.partnerCheckResult = null;
    },
    [CHECK_PARTNER_SUCCESS]: (state: ManageMansionStore, action) => {
        state.loading = false;
        state.checkPartnerErrors = [];
        state.partnerCheckResult = action.payload.data;
    },
    [CHECK_PARTNER_FAILURE]: (state: ManageMansionStore, action) => {
        state.loading = false;
        state.checkPartnerErrors = action.payload.errors;
        state.partnerCheckResult = null;
    },
});
