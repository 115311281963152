import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import {
    PartnerLocationsDto,
    PartnerSerialnumberDto,
} from './manageSerialnumbersReducer';
import { ManagedPartner } from '../search-user/searchUserReducer';

export const LOAD_SERIALNUMBERS = 'LOAD_SERIALNUMBERS';
export const LOAD_SERIALNUMBERS_SUCCESS = 'LOAD_SERIALNUMBERS_SUCCESS';
export const LOAD_SERIALNUMBERS_FAILURE = 'LOAD_SERIALNUMBERS_FAILURE';

export const ASSIGN_SERIALNUMBERS = 'ASSIGN_SERIALNUMBERS';
export const ASSIGN_SERIALNUMBERS_SUCCESS = 'ASSIGN_SERIALNUMBERS_SUCCESS';
export const ASSIGN_SERIALNUMBERS_FAILURE = 'ASSIGN_SERIALNUMBERS_FAILURE';

export const UNASSIGN_SERIALNUMBER = 'UNASSIGN_SERIALNUMBER';
export const UNASSIGN_SERIALNUMBER_SUCCESS = 'UNASSIGN_SERIALNUMBER_SUCCESS';
export const UNASSIGN_SERIALNUMBER_FAILURE = 'UNASSIGN_SERIALNUMBER_FAILURE';

export const SET_MANAGED_PARTNER = 'SET_MANAGED_PARTNER';

export const LOAD_ASSIGNABLE_LOCATIONS = 'LOAD_ASSIGNABLE_LOCATIONS';
export const LOAD_ASSIGNABLE_LOCATIONS_SUCCESS =
    'LOAD_ASSIGNABLE_LOCATIONS_SUCCESS';
export const LOAD_ASSIGNABLE_LOCATIONS_FAILURE =
    'LOAD_ASSIGNABLE_LOCATIONS_FAILURE';

export const ASSIGN_GATEWAY = 'ASSIGN_GATEWAY';
export const ASSIGN_GATEWAY_SUCCESS = 'ASSIGN_GATEWAY_SUCCESS';
export const ASSIGN_GATEWAY_FAILURE = 'ASSIGN_GATEWAY_FAILURE';

export const UNASSIGN_GATEWAY = 'UNASSIGN_GATEWAY';
export const UNASSIGN_GATEWAY_SUCCESS = 'UNASSIGN_GATEWAY_SUCCESS';
export const UNASSIGN_GATEWAY_FAILURE = 'UNASSIGN_GATEWAY_FAILURE';

export function setManagedPartner(partner: ManagedPartner) {
    return { type: SET_MANAGED_PARTNER, payload: { partner } };
}

export function loadSerialnumbersForPartner(externalId: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_SERIALNUMBERS });
        try {
            const { data } = await RestClient.get<PartnerSerialnumberDto[]>(
                `/v1/callcenter/partners/${externalId}/serialnumbers`
            );
            dispatch({ type: LOAD_SERIALNUMBERS_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: LOAD_SERIALNUMBERS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function assignSerialnumbersToPartner(
    externalId: string,
    serialnumbers: string[]
) {
    const data = {
        external_id: externalId,
        serial_numbers: serialnumbers,
    };

    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: ASSIGN_SERIALNUMBERS });
        try {
            await RestClient.post(`/v1/callcenter/serialnumbers/assign`, data);
            dispatch({ type: ASSIGN_SERIALNUMBERS_SUCCESS });
            dispatch(loadSerialnumbersForPartner(externalId));
        } catch (error) {
            dispatch({
                type: ASSIGN_SERIALNUMBERS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function unassignSerialnumberFromPartner(
    externalId: string,
    serialnumber: string
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UNASSIGN_SERIALNUMBER });
        try {
            await RestClient.post(`/v1/callcenter/serialnumbers/unassign`, {
                serial_number: serialnumber,
            });
            dispatch({ type: UNASSIGN_SERIALNUMBER_SUCCESS });
            dispatch(loadSerialnumbersForPartner(externalId));
        } catch (error) {
            dispatch({
                type: UNASSIGN_SERIALNUMBER_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function loadAssignableLocationsForPartner(externalId: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_ASSIGNABLE_LOCATIONS });
        try {
            const { data } = await RestClient.get<PartnerLocationsDto>(
                `/v1/callcenter/partners/${externalId}/assignable-laundry-rooms`
            );
            dispatch({
                type: LOAD_ASSIGNABLE_LOCATIONS_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: LOAD_ASSIGNABLE_LOCATIONS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function assignGatewayToLocation(
    externalId: string,
    serialnumber: string,
    locationId: number
) {
    const data = {
        partner_external_id: externalId,
        serial_number: serialnumber,
        location_id: locationId,
    };

    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: ASSIGN_GATEWAY });
        try {
            await RestClient.post('/v1/callcenter/gateway/assign', data);
            dispatch({ type: ASSIGN_GATEWAY_SUCCESS });
            dispatch(loadSerialnumbersForPartner(externalId));
            dispatch(loadAssignableLocationsForPartner(externalId));
        } catch (error) {
            dispatch({
                type: ASSIGN_GATEWAY_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function unassignGatewayFromLocation(
    externalId: string,
    serialnumber: string
) {
    const data = {
        serial_number: serialnumber,
    };

    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: UNASSIGN_GATEWAY });
        try {
            await RestClient.post('/v1/callcenter/gateway/unassign', data);
            dispatch({ type: UNASSIGN_GATEWAY_SUCCESS });
            dispatch(loadSerialnumbersForPartner(externalId));
            dispatch(loadAssignableLocationsForPartner(externalId));
        } catch (error) {
            dispatch({
                type: UNASSIGN_GATEWAY_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
