import { WeWashApiErrorTag } from '../../http/errors';
import { createReducer } from 'redux-starter-kit';
import {
    GET_RESERVATION_DATA,
    GET_RESERVATION_DATA_FAILURE,
    GET_RESERVATION_DATA_SUCCESS,
    REFUND_RESERVATION,
    REFUND_RESERVATION_FAILURE,
    REFUND_RESERVATION_SUCCESS,
} from './refundReservationActions';
import { RESET_VIEWS } from '../genericActions';
import { Currency } from '../amountAndCurrency';

export enum InvoiceItemStatusForRefund {
    NEW = 'NEW',
    INVOICED = 'INVOICED',
    CANCELED = 'CANCELED',
    REFUNDED = 'REFUNDED',
}

export enum ReservationPerformedAction {
    TOP_UP = 'TOP_UP',
    REFUNDING = 'REFUNDING',
    NONE = 'NONE',
    CANCELLED = 'CANCELLED',
}

export interface ReservationForRefundDto {
    reservation_id: number;
    user_id: number;
    contract_closed_at?: number;
    reservation_status: string;
    cycle_conducted?: string;
    amount?: number;
    currency: Currency;
    invoicing_status?: InvoiceItemStatusForRefund;
    invoice_item_updated_at?: number;
    already_refunded?: boolean;
    already_refunded_comment?: string;
    admissible_status?: string;
    appliance_short_name?: string;
    performed_action?: ReservationPerformedAction;
    invoice?: ReservationForRefundInvoiceDto;
}

export interface ReservationForRefundInvoiceDto {
    invoice_id: number;
    invoice_status: string;
    payment_status: string;
    accounting_status: string;
    target_accounting_state: string;
    country_id: number;
}

export interface RefundReservationStore {
    loading: boolean;
    reservationForRefund: ReservationForRefundDto | null;
    errors: WeWashApiErrorTag[];
    refundReservationSuccess: boolean;
}

const initialStore: RefundReservationStore = {
    loading: false,
    reservationForRefund: null,
    errors: [],
    refundReservationSuccess: false,
};

export const refundReservationReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [GET_RESERVATION_DATA]: (state: RefundReservationStore) => {
        state.loading = true;
        state.errors = [];
        state.refundReservationSuccess = false;
    },
    [GET_RESERVATION_DATA_SUCCESS]: (state: RefundReservationStore, action) => {
        state.loading = false;
        state.errors = [];
        state.reservationForRefund = action.payload.data;
        state.refundReservationSuccess = false;
    },
    [GET_RESERVATION_DATA_FAILURE]: (state: RefundReservationStore, action) => {
        state.loading = false;
        state.reservationForRefund = null;
        state.errors = action.payload.errors;
    },
    [REFUND_RESERVATION]: (state: RefundReservationStore) => {
        state.loading = true;
        state.errors = [];
    },
    [REFUND_RESERVATION_SUCCESS]: (state: RefundReservationStore, action) => {
        state.loading = false;
        state.errors = [];
        state.reservationForRefund = action.payload.data;
        state.refundReservationSuccess = true;
    },
    [REFUND_RESERVATION_FAILURE]: (state: RefundReservationStore, action) => {
        state.loading = false;
        state.reservationForRefund = null;
        state.errors = action.payload.errors;
        state.refundReservationSuccess = false;
    },
});
