import React from 'react';
import { CommunicationState } from '../../store/communicationState';
import { Button, Spinner } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBan,
    faCheckCircle,
    faCircle,
} from '@fortawesome/free-solid-svg-icons';

const SUCCESS_COLOR = '#27A730';

interface Props {
    onClick: () => void;
    status: CommunicationState;
    disabled?: boolean;
    children: string;
    successText?: string;
}

function UserActionButton(props: Props) {
    const disabled =
        props.disabled || props.status === CommunicationState.LOADING;

    if (props.successText && props.status === CommunicationState.SUCCESS) {
        return <div style={{ color: SUCCESS_COLOR }}>{renderContent()}</div>;
    }

    return (
        <Button
            disabled={disabled}
            onClick={props.onClick}
            variant={'outline-primary'}
        >
            {renderContent()}
        </Button>
    );

    function renderContent() {
        if (props.status === CommunicationState.INITIAL) {
            return (
                <>
                    <FontAwesomeIcon icon={faCircle} /> {props.children}
                </>
            );
        }
        if (props.status === CommunicationState.FAILURE) {
            return (
                <>
                    <FontAwesomeIcon icon={faBan} color={'#E20015'} />{' '}
                    {props.children}
                </>
            );
        }
        if (props.status === CommunicationState.SUCCESS) {
            return (
                <>
                    <FontAwesomeIcon icon={faCheckCircle} />{' '}
                    {props.successText || props.children}
                </>
            );
        }
        if (props.status === CommunicationState.LOADING) {
            return (
                <>
                    <Spinner animation={'border'} size={'sm'} /> Lade...
                </>
            );
        }

        return props.children;
    }
}

export default UserActionButton;
