import { WeWashApiErrorTag } from '../../http/errors';
import { createReducer } from 'redux-starter-kit';
import {
    CREATE_TOP_UP,
    CREATE_TOP_UP_FAILURE,
    CREATE_TOP_UP_SUCCESS,
    LOAD_TOP_UP_DATA,
    LOAD_TOP_UP_DATA_FAILURE,
    LOAD_TOP_UP_DATA_SUCCESS,
} from './topUpActions';
import { TopUpCreationDto } from '../../forms/topup/TopUpForm';
import { RESET_VIEWS } from '../genericActions';

interface CountryBalanceDto {
    country_id: number;
    country_code: string;
    amount: number;
    currency_code: string;
}

export interface TopUpUserDto {
    user_id: number;
    email: string;
    balances: CountryBalanceDto[];
    first_name: number;
    last_name: number;
    country_code: string;
}

export interface TopUpStore {
    loading: boolean;
    topUpUser: TopUpUserDto | null;
    errors: WeWashApiErrorTag[];
    currentSuccessfulTopUp: TopUpCreationDto | null;
}

const initialStore: TopUpStore = {
    loading: false,
    topUpUser: null,
    errors: [],
    currentSuccessfulTopUp: null,
};

export const topUpReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [LOAD_TOP_UP_DATA]: (state: TopUpStore) => {
        state.loading = true;
        state.errors = [];
    },
    [LOAD_TOP_UP_DATA_SUCCESS]: (state: TopUpStore, action) => {
        state.loading = false;
        state.errors = [];
        state.topUpUser = action.payload.data;
    },
    [LOAD_TOP_UP_DATA_FAILURE]: (state: TopUpStore, action) => {
        state.loading = false;
        state.topUpUser = null;
        state.errors = action.payload.errors;
    },
    [CREATE_TOP_UP]: (state: TopUpStore) => {
        state.loading = true;
        state.errors = [];
        state.currentSuccessfulTopUp = null;
    },
    [CREATE_TOP_UP_SUCCESS]: (state: TopUpStore, action) => {
        state.loading = false;
        state.errors = [];
        state.currentSuccessfulTopUp = action.payload.data;
    },
    [CREATE_TOP_UP_FAILURE]: (state: TopUpStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
        state.currentSuccessfulTopUp = null;
    },
});
