import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { UserStore } from '../../store/user/userReducer';
import { loginAction } from '../../store/user/userActions';
import Row from 'react-bootstrap/Row';
import ErrorFeedback from '../ErrorFeedback';
import Logo from '../../App/logo.png';
import styles from './LoginForm.module.css';
import classnames from 'classnames';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faUser } from '@fortawesome/free-solid-svg-icons';
import InputGroup from 'react-bootstrap/InputGroup';

export interface LoginCredentials {
    username: string;
    password: string;
}

interface Props {
    user: UserStore;
    loginAction: (credentials: LoginCredentials) => any;
}

const LoginForm: FunctionComponent<Props> = (props) => {
    const initialValues: LoginCredentials = { username: '', password: '' };

    async function handleFormSubmit(
        values: LoginCredentials,
        actions: FormikActions<LoginCredentials>
    ) {
        await props.loginAction(values);
        actions.setSubmitting(false);
    }

    return (
        <Card id="LoginForm" className={styles.card}>
            <ListGroup variant="flush">
                <ListGroup.Item>
                    <div id="wewash-logo">
                        <img
                            alt="WeWash Logo"
                            src={Logo}
                            className={styles.logo}
                        />
                    </div>
                </ListGroup.Item>
                <ListGroup.Item>
                    <p className={classnames('pb-4', styles.heading)}>
                        Kundendienst UI
                    </p>
                    <Formik
                        initialValues={initialValues}
                        onSubmit={handleFormSubmit}
                    >
                        {(formProps: FormikProps<LoginCredentials>) => (
                            <Form
                                noValidate
                                onSubmit={formProps.handleSubmit}
                                className={'px-5'}
                            >
                                <ErrorFeedback apiErrors={props.user.errors} />
                                <Form.Group as={Row} controlId="formGroupEmail">
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="email"
                                            name="username"
                                            value={formProps.values.username}
                                            onChange={formProps.handleChange}
                                            placeholder="Email eingeben"
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group
                                    as={Row}
                                    controlId="formGroupPassword"
                                >
                                    <InputGroup>
                                        <InputGroup.Prepend>
                                            <InputGroup.Text id="inputGroupPrepend">
                                                <FontAwesomeIcon
                                                    icon={faLock}
                                                />
                                            </InputGroup.Text>
                                        </InputGroup.Prepend>
                                        <Form.Control
                                            type="password"
                                            name="password"
                                            value={formProps.values.password}
                                            onChange={formProps.handleChange}
                                            placeholder="Passwort eingeben"
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Row>
                                    <Button
                                        type="submit"
                                        className={styles.signInButton}
                                        disabled={formProps.isSubmitting}
                                    >
                                        Anmelden
                                    </Button>
                                </Row>
                            </Form>
                        )}
                    </Formik>
                </ListGroup.Item>
            </ListGroup>
        </Card>
    );
};

export default connect((store: RootStore) => ({ user: store.user }), {
    loginAction,
})(LoginForm);
