import React, { useEffect } from 'react';
import ManageUser from './ManageUser';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import { loadManageUserById } from '../../store/manage-user/manageUserActions';
import { RootStore } from '../../store/rootStore';
import { UserExtendedDetails } from '../../store/manage-user/manageUserReducer';
import { UserExtendedInformation } from '../../store/search-user/searchUserReducer';

interface Props {
    loadUserById: (userId: number) => void;
    userDetails?: UserExtendedDetails | null;
    searchResults: UserExtendedInformation[];
    searchResultsById: UserExtendedInformation[];
}

interface Params {
    userId?: string;
}

function ManageUserForId({ loadUserById, ...props }: Props) {
    const params = useParams<Params>();
    const userId = Number(params.userId);
    useEffect(() => {
        if (userId !== undefined) {
            loadUserById(userId);
        }
    }, [userId, loadUserById]);

    const detailsLoaded =
        props.userDetails && props.userDetails.user_id === userId;
    const searchResultAvailable =
        props.searchResultsById.filter((it) => it.user_id === userId).length >
        0;

    if (detailsLoaded && searchResultAvailable) {
        return <ManageUser />;
    } else {
        return null;
    }
}

export default connect(
    (store: RootStore) => ({
        userDetails: store.manageUser.details,
        searchResults: store.searchUser.resultsByEmail,
        searchResultsById: store.searchUser.resultsById,
    }),
    { loadUserById: loadManageUserById }
)(ManageUserForId);
