import React, { FunctionComponent } from 'react';

interface Props {}

const ManagePayments: FunctionComponent<Props> = (props) => {
    return (
        <div id="ManagePayments">
            <h1>Manage Payments</h1>
        </div>
    );
};

export default ManagePayments;
