import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import { Mansion, MansionTransfer, Partner } from './manageMansionReducer';

export const SEARCH_MANSION = 'SEARCH_MANSION';
export const SEARCH_MANSION_SUCCESS = 'SEARCH_MANSION_SUCCESS';
export const SEARCH_MANSION_FAILURE = 'SEARCH_MANSION_FAILURE';

export const CHECK_PARTNER = 'CHECK_PARTNER';
export const CHECK_PARTNER_SUCCESS = 'CHECK_PARTNER_SUCCESS';
export const CHECK_PARTNER_FAILURE = 'CHECK_PARTNER_FAILURE';

export const TRANSFER_MANSION = 'TRANSFER_MANSION';
export const TRANSFER_MANSION_SUCCESS = 'TRANSFER_MANSION_SUCCESS';
export const TRANSFER_MANSION_FAILURE = 'TRANSFER_MANSION_FAILURE';

export function searchMansion(searchMansionId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_MANSION });
        try {
            const { data } = await RestClient.get<Mansion>(
                `/v1/callcenter/mansions/${searchMansionId}`
            );
            dispatch({ type: SEARCH_MANSION_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: SEARCH_MANSION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function transferMansion(transferDto: MansionTransfer) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: TRANSFER_MANSION });
        try {
            await RestClient.post(
                `/v1/callcenter/mansions/transfer`,
                transferDto
            );
            dispatch({ type: TRANSFER_MANSION_SUCCESS });
            dispatch(searchMansion(transferDto.mansion_id));
        } catch (error) {
            dispatch({
                type: TRANSFER_MANSION_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function checkPartner(partnerId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHECK_PARTNER });
        try {
            const { data } = await RestClient.get<Partner>(
                `/v1/callcenter/partner/${partnerId}`
            );
            dispatch({ type: CHECK_PARTNER_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: CHECK_PARTNER_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
