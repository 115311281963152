import React, { Fragment } from 'react';
import Modal from 'react-bootstrap/Modal';
import { closeModal, ModalParameters } from '../../store/modal/modalActions';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { SetLaundryRoomFeedbackQuestion } from '../../store/location-feedback-mode/locationFeedbackModeActions';
import { LocationFeedbackStore } from '../../store/location-feedback-mode/locationFeedbackReducer';

export interface SelectFeedbackQuestionModalParams {
    feedbackQuestionId?: number;
    laundryRoomId?: number;
}

interface Props {
    params?: SelectFeedbackQuestionModalParams;
    close: (
        params?: ModalParameters<SelectFeedbackQuestionModalParams>
    ) => void;
    locationFeedbackStore: LocationFeedbackStore;
    changeLaundryRoomFeedbackQuestion: (
        laundryRoomId: number,
        feedbackQuestionId: number | null,
        closeOnSuccess: () => void
    ) => void;
    data: { laundryRoomId?: number; questionId?: number };
}

function SelectFeedbackModeQuestionModal(props: Props) {
    const feedbackQuestionId = props.params
        ? String(props.params.feedbackQuestionId)
        : String(null);
    const initialValues: InputFormData = {
        questionId: feedbackQuestionId,
        removeFeedbackMode: !Boolean(feedbackQuestionId),
    };

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Waschraum Feedback Frage setzen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{SetFeedbackQuestion()}</Modal.Body>
        </Fragment>
    );

    function SetFeedbackQuestion() {
        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                children={renderForm}
            />
        );

        function renderForm(formProps: FormikProps<InputFormData>) {
            return (
                <Form noValidate onSubmit={formProps.handleSubmit}>
                    <Form.Group
                        as={Row}
                        controlId={'formGroupLaundryRoomId'}
                        className={'mx-0'}
                    >
                        <ErrorFeedback
                            apiErrors={props.locationFeedbackStore.errors}
                        />
                        <InputGroup>
                            <InputGroup.Prepend>
                                <InputGroup.Text>Question ID</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                                type={'number'}
                                name={'questionId'}
                                value={formProps.values.questionId}
                                onChange={formProps.handleChange}
                                disabled={formProps.values.removeFeedbackMode}
                                placeholder={'Question ID'}
                            />
                            <InputGroup>
                                <Form.Check
                                    custom
                                    label="Remove Feedback Mode"
                                    name={'removeFeedbackMode'}
                                    id={'removeFeedbackMode'}
                                    onChange={formProps.handleChange}
                                    checked={
                                        formProps.values.removeFeedbackMode
                                    }
                                ></Form.Check>
                            </InputGroup>
                        </InputGroup>
                        <Row className={'mx-0 mt-4'}>
                            <Button onClick={handleCancel} className={'mr-2'}>
                                Abbrechen
                            </Button>
                            <Button
                                type={'submit'}
                                disabled={formProps.isSubmitting}
                            >
                                {props.locationFeedbackStore.loading && (
                                    <Spinner animation={'grow'} />
                                )}
                                Setzen
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            );
        }

        function handleSubmit(
            value: InputFormData,
            actions: FormikActions<InputFormData>
        ) {
            if (!props.data.laundryRoomId) {
                console.error('laundry room id is null');
                actions.setSubmitting(false);
                return;
            }
            const questionId = value.removeFeedbackMode
                ? null
                : Number(value.questionId);
            props.changeLaundryRoomFeedbackQuestion(
                props.data.laundryRoomId,
                questionId,
                props.close
            );
            actions.setSubmitting(false);
        }

        function handleCancel() {
            props.close();
        }
    }
}

interface InputFormData {
    questionId: string;
    removeFeedbackMode: boolean;
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SelectFeedbackQuestionModalParams,
        locationFeedbackStore: state.locationFeedback,
    }),
    {
        close: closeModal,
        changeLaundryRoomFeedbackQuestion: SetLaundryRoomFeedbackQuestion,
    }
)(SelectFeedbackModeQuestionModal);
