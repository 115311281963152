import { Route } from '../../Router';

export const GO_TO = 'GO_TO';
export const UPDATE_URL = 'UPDATE_URL';

export function goTo(
    route: Route,
    param?: string | number,
    requestParams?: Map<string, string>
) {
    return { type: GO_TO, payload: { route, param, requestParams } };
}
