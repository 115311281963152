import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../store/rootStore';
import styles from './Background.module.css';

interface Props {
    loggedIn: boolean;
}

const Background: FunctionComponent<Props> = (props) => {
    return <div id="Background" className={styles.background}></div>;
};

export default connect((store: RootStore) => ({
    loggedIn: store.user.loggedIn,
}))(Background);
