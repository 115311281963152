import React from 'react';
import { PartnerBoardManagerDto } from '../../store/manage-partner-manager/managePartnerManagerReducer';
import styles from '../manage-user/elements/UserInformationCard.module.css';
import { KeyValueElement } from '../common/KeyValueElement';
import { translateUserAccountState } from '../user-information-table/UserInformationTable';
import { ModalParameters, openModal } from '../../store/modal/modalActions';
import { ModalType } from '../modal/RootModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import Button from 'react-bootstrap/Button';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { UserAccountState } from '../../store/search-user/searchUserReducer';

interface Props {
    partnerManager: PartnerBoardManagerDto;
    openModal: (params: ModalParameters<any>) => any;
}

const PARTNER_MANAGER_BACKGROUNDS = [
    { backgroundColor: '#ab3954' },
    { backgroundColor: '#930986' },
];

function PartnerManagerCard({ partnerManager, openModal, ...props }: Props) {
    function handleOpen() {
        openModal({
            type: ModalType.SEND_ACTIVATION_EMAIL,
            data: {
                partnerManagerId: partnerManager.id,
                partnerEmail: partnerManager.email,
            },
        });
    }

    return (
        <div>
            <div className={styles.card}>
                <div className={styles.leftColumn}>
                    <div
                        className={styles.ersatzImage}
                        style={
                            PARTNER_MANAGER_BACKGROUNDS[
                                partnerManager.id %
                                    PARTNER_MANAGER_BACKGROUNDS.length
                            ]
                        }
                    >
                        {partnerManager.name.substring(0, 2)}
                    </div>
                    <KeyValueElement name={'Name'}>
                        {partnerManager.name}
                    </KeyValueElement>
                    <KeyValueElement name={'E-Mail-Adresse'}>
                        {partnerManager.email}
                    </KeyValueElement>
                </div>
                <div className={styles.rightColumn}>
                    <KeyValueElement name={'Customer ID'}>
                        {partnerManager.customer_id}
                    </KeyValueElement>
                    <KeyValueElement name={'Salesforce ID'}>
                        {partnerManager.salesforce_id}
                    </KeyValueElement>
                    <KeyValueElement name={'Login Status'}>
                        {translateUserAccountState(partnerManager.login_state)}
                        {partnerManager.login_state ===
                            UserAccountState.ACTIVATING && (
                            <>
                                <br />
                                Ablaufdatum:{' '}
                                {partnerManager.expires
                                    ? getExpirationDate()
                                    : 'Nie'}
                            </>
                        )}
                    </KeyValueElement>
                    <KeyValueElement name={'Account'}>
                        {formatAccount()}
                    </KeyValueElement>
                </div>
            </div>
            {partnerManager.login_state === UserAccountState.ACTIVATING && (
                <div className={styles.row}>
                    <Button onClick={() => handleOpen()}>
                        <span className={styles.buttontext}>
                            Aktivierungs E-Mail erneut senden
                        </span>
                        <FontAwesomeIcon
                            icon={faEnvelope}
                            className={styles.icon}
                        />
                    </Button>
                </div>
            )}
        </div>
    );

    function getExpirationDate() {
        if (!partnerManager.expiration_date_time) {
            return '--';
        }
        return new Date(partnerManager.expiration_date_time).toLocaleString();
    }

    function formatAccount() {
        if (!partnerManager.account_salesforce_id) {
            return '-';
        }
        return `${partnerManager.account_salesforce_id} (${partnerManager.account_name})`;
    }
}

export default connect((store: RootStore) => ({}), {
    openModal,
})(PartnerManagerCard);
