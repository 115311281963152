import React, { Fragment, FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { deletePaymentMethod } from '../../store/manage-user/manageUserActions';
import { closeModal } from '../../store/modal/modalActions';

interface Props {
    data: {
        paymentMethodId: number;
        userId: number;
    };
    close: () => any;
    deletePaymentMethod: (paymentMethodId: number, userId: number) => any;
}

const DeletePaymentConfirmation: FunctionComponent<Props> = ({
    data,
    close,
    deletePaymentMethod,
}) => {
    async function handleConfirm() {
        await deletePaymentMethod(data.paymentMethodId, data.userId);
        close();
    }

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Zahlungsmethode löschen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Sicher, dass diese Zahlungsmethode gelöscht werden soll?
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleConfirm}>Ich bin sicher!</Button>
            </Modal.Footer>
        </Fragment>
    );
};

export default connect(null, { close: closeModal, deletePaymentMethod })(
    DeletePaymentConfirmation
);
