import React, {
    FunctionComponent,
    ReactText,
    useEffect,
    useState,
} from 'react';
import { RootStore } from '../../store/rootStore';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import { resetViews } from '../../store/genericActions';
import {
    createLaundryRoomCard,
    LaundryRoomCardDto,
    LaundryRoomCardsDto,
    LaundryRoomCardWriteDto,
    loadLaundryRoomCards,
    setCardAsFirst,
    setLaundryRoomCard,
    unsetLaundryRoomCard,
} from '../../store/manage-laundry-room/manageLaundryRoomActions';
import {
    appendRequestParams,
    parseUrlParamsFromSearchString,
} from '../../util/url';
import { useHistory, useLocation } from 'react-router-dom';
import { laundryRoomIdReqParam } from '../../util/constants';
import { Route } from '../../Router';
import LaundryRoomCardSelectOrAdd from './cards/LaundryRoomCardSelect';
import { LaundryRoomCardForm } from './cards/LaundryRoomCardForm';
import { LaundryRoomCardAddLanguage } from './cards/LaundryRoomCardAddLanguage';
import { AddNewLaundryRoomCard } from './cards/AddNewLaundryRoomCardForm';
import {
    LaundryRoomParameters,
    LaundryRoomSearchForm,
} from './search/LaundryRoomSearchForm';

interface Props {
    cardsDto: LaundryRoomCardsDto | null;
    cardInfoLoading: boolean;
    clearLaundryRoomView: () => void;
    loadLaundryRoomCards: (laundryRoomId: ReactText) => void;
    setLaundryRoomCard: (
        laundryRoomId: number,
        cardId: number,
        writeDto: LaundryRoomCardWriteDto
    ) => void;
    createLaundryRoomCard: (
        laundryRoomId: number,
        writeDto: LaundryRoomCardWriteDto
    ) => void;
    unsetLaundryRoomCard: (laundryRoomId: number, cardId: number) => void;
    setCardAsFirst: (laundryRoomId: number, cardId: number) => void;
}

const LaundryRoomCardScreen: FunctionComponent<Props> = (props) => {
    const requestParams = parseUrlParamsFromSearchString(
        useLocation().search.substring(1)
    );
    const locationId = requestParams.get(laundryRoomIdReqParam);
    const history = useHistory();

    useEffect(() => {
        if (locationId) {
            props.loadLaundryRoomCards(locationId);
        }
    }, [locationId]);

    function getConfiguredLanguages() {
        return selectedCard ? Object.keys(selectedCard.title) : [];
    }

    const [selectedCard, setSelectedCard] = useState<
        LaundryRoomCardDto | undefined
    >(undefined);

    const [configuredLanguages, setConfiguredLanguages] = useState<string[]>(
        getConfiguredLanguages()
    );

    const [addCardAction, setAddCardAction] = useState(false);

    function handleCardSelect(card: LaundryRoomCardDto) {
        setSelectedCard(card);
        setAddCardAction(false);
        setConfiguredLanguages(Object.keys(card.title));
    }

    const languagesToShow = props.cardsDto
        ? props.cardsDto.languages.filter(
              (it) => !configuredLanguages.includes(it)
          )
        : [];

    function onAddNewLanguage(language: string) {
        if (!configuredLanguages.includes(language)) {
            setConfiguredLanguages([...configuredLanguages, language]);
        }
    }

    if (props.cardInfoLoading) {
        return <>Loading</>;
    }

    function onAddNewCardTileClick() {
        setSelectedCard(undefined);
        setAddCardAction(true);
    }

    function onPatchLaundryRoomCard(
        laundryRoomId: number,
        cardId: number,
        writeDto: LaundryRoomCardWriteDto
    ) {
        props.setLaundryRoomCard(laundryRoomId, cardId, writeDto);
        setSelectedCard(undefined);
    }

    function onDeleteLaundryRoomCard(laundryRoomId: number, cardId: number) {
        props.unsetLaundryRoomCard(laundryRoomId, cardId);
        setSelectedCard(undefined);
    }

    function onCreateNewLaundryRoomCard(
        laundryRoomId: number,
        writeDto: LaundryRoomCardWriteDto
    ) {
        props.createLaundryRoomCard(laundryRoomId, writeDto);
        setAddCardAction(false);
    }

    return (
        <div id={'LaundryRoomCard'}>
            <h2>Spezielle Waschraum Karte</h2>
            <LaundryRoomSearchForm
                locationId={locationId ? String(locationId) : undefined}
                handleLaundryRoomSearch={handleLaundryRoomSearch}
                isLoading={props.cardInfoLoading}
            />
            {!!locationId && props.cardsDto && (
                <div>
                    <LaundryRoomCardSelectOrAdd
                        selectedLaundryRoomCard={selectedCard}
                        cards={props.cardsDto.cards}
                        defaultLanguage={props.cardsDto.default_language}
                        laundryRoomDefaultLanguage={
                            props.cardsDto.default_language
                        }
                        onCardSelect={handleCardSelect}
                        onAddNewCard={onAddNewCardTileClick}
                    />
                </div>
            )}
            {renderEitherSelectedCardSectionsOrAddNewCard()}
        </div>
    );

    function renderEitherSelectedCardSectionsOrAddNewCard() {
        if (selectedCard) {
            return (
                <>
                    <LaundryRoomCardAddLanguage
                        languagesToShow={languagesToShow}
                        onAddNewLanguage={onAddNewLanguage}
                    />
                    {renderBasicInfo(configuredLanguages, selectedCard)}
                    {!addCardAction && (
                        <LaundryRoomCardForm
                            cardData={selectedCard}
                            onDelete={onDeleteLaundryRoomCard}
                            configuredLanguages={configuredLanguages}
                            onPatchLaundryRoomCard={onPatchLaundryRoomCard}
                        />
                    )}
                </>
            );
        } else if (addCardAction) {
            return (
                props.cardsDto &&
                locationId && (
                    <AddNewLaundryRoomCard
                        laundryRoomId={parseInt(locationId)}
                        onCreateNewCard={onCreateNewLaundryRoomCard}
                        defaultLanguage={props.cardsDto.default_language}
                    />
                )
            );
        }
    }

    function handleLaundryRoomSearch(params: LaundryRoomParameters) {
        history.replace({
            pathname: String(Route.MANAGE_LAUNDRY_ROOM_CARDS),
            search: appendRequestParams(
                '',
                new Map<string, any>([
                    [laundryRoomIdReqParam, params.locationId],
                ])
            ),
        });
        props.loadLaundryRoomCards(params.locationId);
    }

    function renderBasicInfo(
        configuredLanguages: string[],
        card?: LaundryRoomCardDto
    ) {
        if (!card) {
            return;
        }

        return (
            <table>
                <tbody>
                    <tr>
                        <th>Waschraum:</th>
                        <td>{card.laundry_room_id}</td>
                    </tr>
                    <tr>
                        <th>Karte:</th>
                        <td>{card.title['de']}</td>
                    </tr>
                    <tr>
                        <th>Konfigurierte Sprachen:</th>
                        <td>{configuredLanguages.join(',')}</td>
                    </tr>
                    <tr>
                        <th>Sortierung:</th>
                        <td>
                            {
                                <Button
                                    onClick={() => {
                                        props.setCardAsFirst(
                                            card.laundry_room_id,
                                            card.card_id
                                        );
                                    }}
                                >
                                    Als erste Waschraumkarte anzeigen
                                </Button>
                            }
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }
};

export default connect(
    (store: RootStore) => ({
        cardInfoLoading: store.manageLaundryRooms.loading,
        cardsDto: store.manageLaundryRooms.laundryRoomCards,
    }),
    {
        clearLaundryRoomView: resetViews,
        loadLaundryRoomCards: loadLaundryRoomCards,
        setLaundryRoomCard,
        unsetLaundryRoomCard,
        setCardAsFirst,
        createLaundryRoomCard,
    }
)(LaundryRoomCardScreen);
