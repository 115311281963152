import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { Button, Form, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { Formik, FormikProps } from 'formik';
import {
    ManageHubsStore,
    RouterLiveData,
} from '../../store/manage-hubs/manageHubsReducer';
import {
    hubInspect,
    hubMarkShipped,
    hubStartInstallationReport,
} from '../../store/manage-hubs/manageHubsActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import RouterAndReportView from './RouterAndReportView';

interface Props {
    manageHubs: ManageHubsStore;

    hubInspect: (serialNumber: string) => any;
    hubMarkShipped: (serialNumber: string) => any;
    hubStartInstallationReport: (serialNumber: string) => any;
}

interface InputSerialNumberData {
    serialNumber: string;
}

const ManageHub: FunctionComponent<Props> = (props: Props) => {
    const initialValues: InputSerialNumberData = {
        serialNumber: '',
    };

    function handleCheckHub(values: InputSerialNumberData) {
        props.hubInspect(values.serialNumber);
    }

    function handleMarkShipped(router: RouterLiveData) {
        props.hubMarkShipped(router.serial_number);
    }

    function handleStartInstallationReport(router: RouterLiveData) {
        props.hubStartInstallationReport(router.serial_number);
    }

    function renderForm(formProps: FormikProps<InputSerialNumberData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <FormGroup as={Row} className={'mx-0'}>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Seriennummer</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type={'text'}
                            name={'serialNumber'}
                            value={formProps.values.serialNumber}
                            onChange={formProps.handleChange}
                            placeholder={
                                'Seriennummer oder Waschraum ID oder IP Addresse'
                            }
                        />
                    </InputGroup>
                </FormGroup>
                <Row className={'mx-0 mt-4'}>
                    <ErrorFeedback apiErrors={props.manageHubs.hubRestErrors} />
                </Row>
                <Row className={'mx-0 mt-4'}>
                    <Button
                        type={'submit'}
                        variant={'outline-secondary'}
                        className={'mr-2'}
                    >
                        Hub prüfen
                    </Button>
                </Row>
            </Form>
        );
    }

    function renderRouter(router: RouterLiveData) {
        return (
            <RouterAndReportView
                router={router}
                onSetShipped={handleMarkShipped}
                onStartInstallationReport={handleStartInstallationReport}
            />
        );
    }

    return (
        <div id={'ManageHub'}>
            <Formik
                onSubmit={handleCheckHub}
                initialValues={initialValues}
                children={renderForm}
            />
            {props.manageHubs.router && renderRouter(props.manageHubs.router)}
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        manageHubs: store.manageHubs,
    }),
    { hubInspect, hubMarkShipped, hubStartInstallationReport }
)(ManageHub);
