import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    LaundryRoomNotesDto,
    loadLaundryRoomNotes,
    setLaundryRoomNotes,
} from '../../store/manage-laundry-room/manageLaundryRoomActions';
import { Formik, FormikProps } from 'formik';
import { Button, Form } from 'react-bootstrap';
import styles from '../manage-appliance/ManageApplianceForm.module.css';
import SuccessFeedback from '../SuccessFeedback';
import ErrorFeedback from '../ErrorFeedback';
import { resetViews } from '../../store/genericActions';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import Alert from 'react-bootstrap/Alert';

export const MAX_LENGTH_ALLOWED_FOR_NOTE = 250;

interface Props extends ManageLaundryRoomStore {
    loadLaundryRoomNotes: (locationId: number) => any;
    setLaundryRoomNotes: (
        locationId: number,
        notes: LaundryRoomNotesDto
    ) => any;
    locationId: number;
    hideNotesInfo?: boolean;
    clearView: () => void;
}

const ManageLaundryRoomNotesForm: FunctionComponent<Props> = (props) => {
    const setLaundryRoomNotesParameters: LaundryRoomNotesDto = {
        laundry_room_id: props.locationId,
        note: '',
        show_critical_note: false,
        critical_note: undefined,
        ...props.laundryRoomNotes,
    };

    useEffect(() => {
        props.loadLaundryRoomNotes(props.locationId);
    }, [props.locationId]);

    function renderNotesDataDisplay() {
        if (props.hideNotesInfo) {
            return null;
        } else {
            return laundryRoomNotesDataDetails();
        }
    }

    return (
        <div id="ManageLaundryRoomNotesForm">
            <div>
                <div className={styles.infoContainer}>
                    {renderNotesDataDisplay()}
                    {laundryRoomNotesUpdateForm()}
                </div>
                {maybeRenderSuccessMessage()}
                <ErrorFeedback apiErrors={props.errors} />
            </div>
        </div>
    );

    function handleLaundryRoomNotesSubmit(values: LaundryRoomNotesDto) {
        if (!values.laundry_room_id) {
            return;
        }
        props.setLaundryRoomNotes(values.laundry_room_id, values);
    }

    function handleUnsetCriticalLaundryRoomNotes() {
        if (!props.laundryRoomNotes) {
            return;
        }
        const notes: LaundryRoomNotesDto = {
            ...props.laundryRoomNotes,
            show_critical_note: false,
            critical_note: undefined,
        };
        props.setLaundryRoomNotes(Number(notes.laundry_room_id), notes);
    }

    function laundryRoomNotesDataDisplay() {
        return props.loading ? <div>...</div> : laundryRoomNotesDataDetails();
    }

    function laundryRoomNotesDataDetails() {
        if (!props.laundryRoomNotes) {
            return <div />;
        }
        return (
            <table>
                <tbody>
                    <tr>
                        <td>
                            <strong>Waschraum Id</strong>
                        </td>
                        <td>{props.laundryRoomNotes.laundry_room_id}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Waschraum Hinweis</strong>
                        </td>
                        <td>{props.laundryRoomNotes.note}</td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Kritischen Anzeigen</strong>
                        </td>
                        <td>
                            {String(props.laundryRoomNotes.show_critical_note)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <strong>Kritischer Hinweis</strong>
                        </td>
                        <td>{props.laundryRoomNotes.critical_note}</td>
                    </tr>
                </tbody>
            </table>
        );
    }

    function laundryRoomNotesUpdateForm() {
        if (!props.laundryRoomNotes || props.loading) {
            return <div />;
        } else {
            return (
                <div className={styles.manageApplianceForm}>
                    <Formik
                        onSubmit={handleLaundryRoomNotesSubmit}
                        validate={validateLaundryRoomNotesUpdate}
                        initialValues={setLaundryRoomNotesParameters}
                        children={laundryRoomNotesUpdateFormDetails}
                    />
                </div>
            );
        }
    }

    function validateLaundryRoomNotesUpdate(values: LaundryRoomNotesDto) {
        const errors: {
            critical_note?: string;
            show_critical_note?: string;
            note?: string;
        } = {};
        if (values.show_critical_note && !values.critical_note) {
            errors.critical_note = 'Leerer kritischer Hinweis.';
        }
        if (
            values.critical_note &&
            values.critical_note.length > MAX_LENGTH_ALLOWED_FOR_NOTE
        ) {
            errors.critical_note = `Text zu lang - maximal ${MAX_LENGTH_ALLOWED_FOR_NOTE} Zeichen`;
        }
        if (values.note && values.note.length > MAX_LENGTH_ALLOWED_FOR_NOTE) {
            errors.note = `Text zu lang - maximal ${MAX_LENGTH_ALLOWED_FOR_NOTE} Zeichen`;
        }
        return errors;
    }

    function laundryRoomNotesUpdateFormDetails(
        formProps: FormikProps<LaundryRoomNotesDto>
    ) {
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <Form.Group controlId="note">
                    <Form.Label>Waschraum Hinweis</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={formProps.handleChange}
                        value={formProps.values.note}
                    />
                    <Alert
                        show={!!formProps.errors.note}
                        variant="danger"
                        children={formProps.errors.note}
                    />
                </Form.Group>
                <Form.Group controlId="show_critical_note">
                    <Form.Check
                        custom
                        type="checkbox"
                        label="Kritischen Hinweis anzeigen"
                        checked={formProps.values.show_critical_note}
                        onChange={formProps.handleChange}
                    />
                    <Alert
                        show={!!formProps.errors.show_critical_note}
                        variant="danger"
                        children={formProps.errors.show_critical_note}
                    />
                </Form.Group>
                <Form.Group controlId="critical_note">
                    <Form.Label>Kritischer Hinweis</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={formProps.handleChange}
                        isInvalid={!!formProps.errors.critical_note}
                        value={formProps.values.critical_note}
                    />
                    <Alert
                        show={!!formProps.errors.critical_note}
                        variant="danger"
                        children={formProps.errors.critical_note}
                    />
                </Form.Group>

                {renderSetLaundryRoomNotesButton()}
            </Form>
        );
    }

    function renderSetLaundryRoomNotesButton() {
        const buttonText = props.loading ? '...' : 'Waschraum Hinweise setzen';
        return (
            <Button type="submit" disabled={props.loading}>
                {buttonText}
            </Button>
        );
    }

    function maybeRenderSuccessMessage() {
        if (props.updatedLaundryRoomNotes) {
            const message = `Waschraum Hinweise für Id ${props.updatedLaundryRoomNotes.laundry_room_id} erfolgreich gesetzt.`;
            return <SuccessFeedback message={message} />;
        } else {
            return null;
        }
    }
};

export default connect(
    (store: RootStore) => ({
        ...store.manageLaundryRooms,
    }),
    { loadLaundryRoomNotes, setLaundryRoomNotes, clearView: resetViews }
)(ManageLaundryRoomNotesForm);
