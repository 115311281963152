import React, { FunctionComponent } from 'react';
import { Button, Spinner, Table } from 'react-bootstrap';
import {
    downloadGroupedUserInvoicesCsv,
    downloadGroupedUserInvoicesPdf,
    GroupedUserInvoiceRequestDto,
} from '../../store/grouped-user-invoices/groupedUserInvoicesAction';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { WeWashApiErrorTag } from '../../http/errors';
import styles from './GroupedUserInvoiceInfoTable.module.css';
import { CommunicationState } from '../../store/communicationState';
import { downloadFile } from '../../util/downloadFileAsBlob';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    countByStatus: { [key: string]: number };
    groupedUserInvoiceRequestDto: GroupedUserInvoiceRequestDto;
    groupedInvoicesDownloadPdf: CommunicationState;
    groupedInvoicesDownloadCsv: CommunicationState;
    downloadGroupedUserInvoicesPdf: (
        dto: GroupedUserInvoiceRequestDto,
        downloadAction: (filename: string, data: Blob) => void
    ) => void;
    downloadGroupedUserInvoicesCsv: (
        dto: GroupedUserInvoiceRequestDto,
        downloadAction: (filename: string, data: Blob) => void
    ) => void;
}

const GroupedUserInvoiceInfoTable: FunctionComponent<Props> = (
    props: Props
) => {
    const showPdfSpinner =
        props.groupedInvoicesDownloadPdf === CommunicationState.LOADING;
    const showCsvSpinner =
        props.groupedInvoicesDownloadCsv === CommunicationState.LOADING;

    function handlePdfDownload() {
        props.downloadGroupedUserInvoicesPdf(
            props.groupedUserInvoiceRequestDto,
            downloadFile
        );
    }

    function handleCsvDownload() {
        props.downloadGroupedUserInvoicesCsv(
            props.groupedUserInvoiceRequestDto,
            downloadFile
        );
    }

    return (
        <div className={styles.groupedUserInvoiceInfoTable}>
            <Table bordered>
                <thead>
                    <tr>
                        <th>Invoice Status</th>
                        <th>Invoice Count</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.entries(props.countByStatus).map((entry) =>
                        renderEntry(entry)
                    )}
                </tbody>
            </Table>
            <Button onClick={handlePdfDownload} disabled={showPdfSpinner}>
                PDFs herunterladen
                {showPdfSpinner && <Spinner animation={'border'} />}
            </Button>
            <Button onClick={handleCsvDownload} disabled={showCsvSpinner}>
                CSV herunterladen
                {showCsvSpinner && <Spinner animation={'border'} />}
            </Button>
        </div>
    );

    function renderEntry(entry: [string, number]) {
        return (
            <tr key={entry[0]}>
                <td>{entry[0]}</td>
                <td>{entry[1]}</td>
            </tr>
        );
    }
};

export default connect(
    (store: RootStore) => ({
        apiErrors: store.groupedUserInvoices.errors,
        groupedInvoicesDownloadPdf:
            store.groupedUserInvoices.groupedInvoicesPdfDownload,
        groupedInvoicesDownloadCsv:
            store.groupedUserInvoices.groupedInvoicesCsvDownload,
    }),
    { downloadGroupedUserInvoicesPdf, downloadGroupedUserInvoicesCsv }
)(GroupedUserInvoiceInfoTable);
