import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { RootStore } from '../../store/rootStore';
import {
    loadLaundryRoomForVipScreen,
    loadVipUsers,
    removeVipUsers,
} from '../../store/manage-vip-users/manageVipUsersActions';
import { ManageVipUsersStore } from '../../store/manage-vip-users/manageVipUsersReducer';
import { CommunicationState } from '../../store/communicationState';
import { Link, useParams } from 'react-router-dom';
import VipUsersListDisplay from './VipUsersListDisplay';
import VipUsersLaundryRoomCard from './VipUsersLaundryRoomCard';
import { Route } from '../../Router';
import { LAUNDRY_ROOM_VIP_SEARCH_QUERY } from '../../util/constants';

interface Props {
    loadLaundryRoomForVipScreen: (laundryRoomId: number) => void;
    loadVipUsers: (laundryRoomId: number) => void;
    removeVipUsers: (laundryRoomId: number, userIds: number[]) => void;
    manageVipUsers: ManageVipUsersStore;
}

interface Params {
    laundryRoomId?: string;
}

function VipUsersList({
    loadLaundryRoomForVipScreen,
    loadVipUsers,
    ...props
}: Props) {
    const params = useParams<Params>();
    const laundryRoomId = Number(params.laundryRoomId);
    const { getLaundryRoomStatus, getVipUsersStatus, removeVipUsersStatus } =
        props.manageVipUsers;

    const loadedLaundryRoomId =
        props.manageVipUsers.laundryRoom && props.manageVipUsers.laundryRoom.id;
    const laundryRoomIdFromUsers =
        props.manageVipUsers.vipUsers &&
        props.manageVipUsers.vipUsers.location_id;

    const removeInProgress =
        getVipUsersStatus === CommunicationState.LOADING ||
        removeVipUsersStatus === CommunicationState.LOADING;

    useEffect(() => {
        if (!laundryRoomId) {
            return;
        }
        if (
            isInitialOrSuccess(getLaundryRoomStatus) &&
            loadedLaundryRoomId !== laundryRoomId
        ) {
            loadLaundryRoomForVipScreen(laundryRoomId);
        }
        if (
            isInitialOrSuccess(getVipUsersStatus) &&
            laundryRoomIdFromUsers !== laundryRoomId
        ) {
            loadVipUsers(laundryRoomId);
        }
    }, [
        laundryRoomId,
        getLaundryRoomStatus,
        loadLaundryRoomForVipScreen,
        getVipUsersStatus,
        loadVipUsers,
        loadedLaundryRoomId,
        laundryRoomIdFromUsers,
    ]);

    return (
        <div>
            <Link
                to={`${Route.MANAGE_VIP_USERS}?${LAUNDRY_ROOM_VIP_SEARCH_QUERY}=${laundryRoomId}`}
            >
                Zurück
            </Link>
            <h1>Bestehende VIP Nutzer</h1>
            {props.manageVipUsers.laundryRoom &&
                loadedLaundryRoomId === laundryRoomId && (
                    <>
                        <h3>Informationen zum Waschraum</h3>
                        <VipUsersLaundryRoomCard
                            laundryRoom={props.manageVipUsers.laundryRoom}
                        />
                    </>
                )}
            {props.manageVipUsers.vipUsers &&
                laundryRoomIdFromUsers === laundryRoomId && (
                    <VipUsersListDisplay
                        onRemove={props.removeVipUsers}
                        removeInProgress={removeInProgress}
                        removalReport={
                            props.manageVipUsers.removeVipUsersReport
                        }
                        errors={props.manageVipUsers.updateErrors}
                        vipUsers={props.manageVipUsers.vipUsers}
                    />
                )}
        </div>
    );

    function isInitialOrSuccess(status: CommunicationState) {
        return [
            CommunicationState.INITIAL,
            CommunicationState.SUCCESS,
        ].includes(status);
    }
}

export default connect(
    (store: RootStore) => ({
        manageVipUsers: store.manageVipUsers,
    }),
    { loadLaundryRoomForVipScreen, loadVipUsers, removeVipUsers }
)(VipUsersList);
