import React, { FunctionComponent, ReactText, useEffect } from 'react';
import { connect } from 'react-redux';
import {
    RefundReservationStore,
    ReservationForRefundDto,
    ReservationPerformedAction,
} from '../../store/refund-reservation/refundReservationReducer';
import { Formik, FormikProps } from 'formik';
import { Alert, Button, Form, FormControl } from 'react-bootstrap';
import styles from './RefundReservationForm.module.css';
import { RootStore } from '../../store/rootStore';
import {
    loadReservationForRefund,
    refundReservation,
} from '../../store/refund-reservation/refundReservationActions';
import SuccessFeedback from '../SuccessFeedback';
import ErrorFeedback from '../ErrorFeedback';
import { resetViews } from '../../store/genericActions';
import { formatMonetaryAmount } from '../../store/amountAndCurrency';
import { RouterStore } from '../../store/router/routerReducer';
import { useHistory, useParams } from 'react-router-dom';
import { getErrorTagFromMessage } from '../../http/errors';
import { printInvoicingStatus } from './InvoiceItemUtil';

interface Props {
    refundReservationStore: RefundReservationStore;
    routerStore: RouterStore;

    loadReservationForRefund: (reservationId: ReactText) => any;
    refundReservation: (
        reservationId: React.ReactText,
        refundDto: RefundParameters
    ) => any;
    clearView: () => void;
}

interface ReservationParameters {
    reservationId: ReactText;
}

interface Params {
    reservationId?: string;
}

export interface RefundParameters {
    nml: boolean;
    comment: string;
}

const RefundReservationForm: FunctionComponent<Props> = (props) => {
    const params = useParams<Params>();
    const reservationId = Number(params.reservationId);
    const history = useHistory();
    const { loadReservationForRefund } = props;
    useEffect(() => {
        if (reservationId) {
            loadReservationForRefund(reservationId);
        }
    }, [reservationId, loadReservationForRefund]);

    const reservationDto: ReservationForRefundDto | null =
        props.refundReservationStore.reservationForRefund;

    return (
        <div id="RefundReservationForm">
            <h2>Buchung</h2>
            {renderLoadReservationForm()}
            <div className={styles.columns}>
                {reservationDto && renderReservationInfo(reservationDto)}
                {reservationDto && renderRefundForm(reservationDto)}
                <ErrorFeedback
                    apiErrors={props.refundReservationStore.errors}
                />
            </div>
        </div>
    );

    function renderLoadReservationForm() {
        const buttonText = props.refundReservationStore.loading
            ? '...'
            : 'Buchungsdetails laden';

        return (
            <Formik
                initialValues={{ reservationId: reservationId || '' }}
                onSubmit={({ reservationId }) =>
                    props.loadReservationForRefund(reservationId)
                }
            >
                {(formProps: FormikProps<ReservationParameters>) => {
                    function handleChange(event: React.ChangeEvent<any>) {
                        if (props.refundReservationStore.reservationForRefund) {
                            props.clearView();
                        }
                        formProps.handleChange(event);
                    }

                    return (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <Form.Group controlId="reservationId">
                                <Form.Label>ID der Buchung</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={handleChange}
                                    value={String(
                                        formProps.values.reservationId
                                    )}
                                />
                            </Form.Group>
                            <Button
                                type="submit"
                                disabled={props.refundReservationStore.loading}
                            >
                                {buttonText}
                            </Button>
                        </Form>
                    );
                }}
            </Formik>
        );
    }

    function renderRefundForm(reservationDto: ReservationForRefundDto) {
        const refundReservationSuccess =
            props.refundReservationStore.refundReservationSuccess;
        const successMessage = refundReservationSuccess
            ? `${getSuccessMessage()} Erstattung von ${renderReservationCost(
                  reservationDto
              )} an Nutzer ID ${reservationDto.user_id} für Buchung ID ${
                  reservationDto.reservation_id
              }. ${printInvoicingStatus(reservationDto.invoicing_status)}`
            : null;
        const buttonText = props.refundReservationStore.loading
            ? '...'
            : 'Zykluskosten erstatten';

        return (
            <Formik
                onSubmit={({ nml, comment }) =>
                    props.refundReservation(reservationDto.reservation_id, {
                        nml,
                        comment,
                    })
                }
                initialValues={{ nml: false, comment: '' }}
            >
                {(formProps: FormikProps<RefundParameters>) => (
                    <Form
                        noValidate
                        onSubmit={formProps.handleSubmit}
                        className={styles.column1}
                    >
                        <p className={styles.headline}>
                            Rückerstattung der Buchung
                        </p>
                        <Form.Group controlId="comment">
                            <Form.Label>Kommentar</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={formProps.handleChange}
                                value={String(formProps.values.comment)}
                                isInvalid={
                                    formProps.touched.comment &&
                                    !!formProps.errors.comment
                                }
                                isValid={
                                    formProps.touched.comment &&
                                    !formProps.errors.comment
                                }
                            />
                            <FormControl.Feedback type="invalid">
                                {formProps.errors.comment}
                            </FormControl.Feedback>
                        </Form.Group>
                        <Form.Group controlId="nml">
                            <Form.Check
                                custom
                                type="checkbox"
                                label="Als 'Nicht Meine Wäsche' markieren"
                                checked={formProps.values.nml}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                        <Button
                            className={styles.refundButton}
                            type="submit"
                            disabled={
                                reservationDto.admissible_status !==
                                    'REFUNDABLE' ||
                                props.refundReservationStore.loading
                            }
                        >
                            {buttonText}
                        </Button>
                        {renderMaybeAdmissibleStatusMsg(
                            reservationDto.admissible_status
                        )}
                        <SuccessFeedback message={successMessage} />
                    </Form>
                )}
            </Formik>
        );
    }

    function renderMaybeAdmissibleStatusMsg(admissibleStatus?: string) {
        if (props.refundReservationStore.refundReservationSuccess) {
            return null;
        }

        return admissibleStatus != null && admissibleStatus !== 'REFUNDABLE' ? (
            <div>
                <Alert show={!!admissibleStatus} variant="danger">
                    {getErrorTagFromMessage(admissibleStatus)}
                </Alert>
            </div>
        ) : null;
    }

    function renderReservationInfo(reservationDto: ReservationForRefundDto) {
        return (
            <div className={styles.column1}>
                <p className={styles.headline}>Details</p>
                <div className={styles.infoContainer}>
                    <table>
                        <tbody>
                            <tr>
                                <td>ID der Buchung</td>
                                <td>{reservationDto.reservation_id}</td>
                            </tr>
                            <tr>
                                <td>Interne Nutzer ID</td>
                                <td>{reservationDto.user_id}</td>
                            </tr>
                            <tr>
                                <td>Gerätekurzname</td>
                                <td>{reservationDto.appliance_short_name}</td>
                            </tr>
                            <tr>
                                <td>Datum</td>
                                <td>
                                    {renderDate(
                                        reservationDto.contract_closed_at
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Status</td>
                                <td>{reservationDto.reservation_status}</td>
                            </tr>
                            <tr>
                                <td>Zyklus durchgeführt</td>
                                <td>{reservationDto.cycle_conducted}</td>
                            </tr>
                            <tr>
                                <td>Preis</td>
                                <td>{renderReservationCost(reservationDto)}</td>
                            </tr>
                            <tr>
                                <td>Rechnungsstatus</td>
                                <td>
                                    {printInvoicingStatus(
                                        reservationDto.invoicing_status
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Datum (Rechnungsstatus)</td>
                                <td>
                                    {renderDate(
                                        reservationDto.invoice_item_updated_at
                                    )}
                                </td>
                            </tr>
                            <tr>
                                <td>Rückerstattungsstatus</td>
                                <td>
                                    {reservationDto.already_refunded
                                        ? `Erstattet - Anmerkung: ${reservationDto.already_refunded_comment}`
                                        : '-'}
                                </td>
                            </tr>
                            <tr>{renderLoadReservationForm()}</tr>
                        </tbody>
                    </table>
                </div>
                {getInvoiceInformation()}
            </div>
        );
    }

    function getInvoiceInformation() {
        if (!reservationDto || !reservationDto.invoice) {
            return <></>;
        }
        const invoice = reservationDto.invoice;
        return (
            <div
                className={styles.card}
                onClick={(event) => {
                    history.push(`/cancel_user_invoice/${invoice.invoice_id}`);
                }}
            >
                <h4>Rechnungsinformationen</h4>
                <table>
                    <tbody>
                        <tr>
                            <td>Invoice ID:</td>
                            <td>{reservationDto.invoice.invoice_id}</td>
                        </tr>
                        <tr>
                            <td>Invoice Status:</td>
                            <td>{reservationDto.invoice.invoice_status}</td>
                        </tr>
                        <tr>
                            <td>Invoice Payment Status:</td>
                            <td>{reservationDto.invoice.payment_status}</td>
                        </tr>
                        <tr>
                            <td>Invoice Accounting Status:</td>
                            <td>{reservationDto.invoice.accounting_status}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    function getSuccessMessage(): string {
        if (!reservationDto) {
            return '';
        }
        const performedAction: ReservationPerformedAction | undefined =
            reservationDto.performed_action;
        if (!performedAction) {
            return '';
        }

        switch (performedAction) {
            case ReservationPerformedAction.CANCELLED:
                return 'Reservierung wurde storniert.';
            case ReservationPerformedAction.TOP_UP:
                return 'User hat credits erhalten';
            case ReservationPerformedAction.REFUNDING:
                return 'Reservationskosten wurden auf die Bezahlmethode zurückerstattet';
            case ReservationPerformedAction.NONE:
                return '...';
            default:
                return 'Erfolgreich.';
        }
    }

    function renderReservationCost({
        amount,
        currency,
    }: ReservationForRefundDto) {
        if (!amount) {
            return 'n/a';
        }
        return formatMonetaryAmount({ amount, currency });
    }

    function renderDate(timestamp: number | null | undefined) {
        if (!timestamp) {
            return <span />;
        }

        return <span>{new Date(timestamp).toLocaleDateString('de-DE')}</span>;
    }
};

export default connect(
    (store: RootStore) => ({
        refundReservationStore: store.refundReservation,
        routerStore: store.router,
    }),
    { loadReservationForRefund, refundReservation, clearView: resetViews }
)(RefundReservationForm);
