import React, { FunctionComponent, ReactText } from 'react';
import { Route } from '../../Router';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { goTo } from '../../store/router/routerActions';
import {
    LaundryRoomOverviewDto,
    LocationStore,
} from '../../store/location/locationReducer';
import styles from '../../forms/refund-reservation/RefundReservationForm.module.css';
import { ApplianceOverviewTable } from './ApplianceOverviewTable';
import {
    LAUNDRY_ROOM_VIP_SEARCH_QUERY,
    laundryRoomIdReqParam,
} from '../../util/constants';
import { Col } from 'react-bootstrap';
import ManageLaundryRoomNotesForm from '../../forms/manage-laundry-room/ManageLaundryRoomNotesForm';
import LaundryRoomAnchorCard, {
    DisplayedLaundryRoomInfo,
} from '../../forms/manage-appliance/LaundryRoomAnchorCard';
import EditLaundryLessonFrequency from './EditLaundryLessonFrequency';
import { ModalType } from '../modal/RootModal';
import { ModalParameters, openModal } from '../../store/modal/modalActions';
import { Link } from 'react-router-dom';
import {
    LaundryRoomNotesDto,
    setLaundryRoomNotes,
} from '../../store/manage-laundry-room/manageLaundryRoomActions';
import {
    createLaundryRoomQrId,
    deleteLaundryRoomQrId,
    fetchLaundryRoom,
    fetchLaundryRoomQrIds,
} from '../../store/location/locationActions';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import LaundryRoomQrIdOverviewTable from './LaundryRoomQrIdOverviewTable';
import { SetSupportsScan2WashParams } from '../modal/SetSupportsScan2WashForLaundryRoomModal';

interface Props {
    goTo: (route: Route, path?: string, requestParams?: any) => any;
    openModal: (params: ModalParameters<any>) => any;
    laundryRoomStore: LocationStore;
    manageLaundryRoomsStore: ManageLaundryRoomStore;
    setLaundryRoomNotes: (
        laundryRoomId: ReactText,
        notes: LaundryRoomNotesDto,
        onDone?: () => void
    ) => void;
    fetchLaundryRoom: (laundryRoomId: ReactText) => unknown;
}

const ManageLaundryRoom: FunctionComponent<Props> = ({
    goTo,
    laundryRoomStore,
    openModal,
    ...props
}) => {
    const selectedLaundryRoom = laundryRoomStore.selectedLocation;

    function createDisplayedLaundryRoomInfo(): DisplayedLaundryRoomInfo {
        if (!selectedLaundryRoom) {
            return {};
        }
        return {
            id: selectedLaundryRoom.id,
            name: selectedLaundryRoom.name,
            note: selectedLaundryRoom.note,
            criticalNote: selectedLaundryRoom.critical_note,
            houseNumber: selectedLaundryRoom.address.house_number,
            postalCode: selectedLaundryRoom.address.postal_code,
            city: selectedLaundryRoom.address.city,
            street: selectedLaundryRoom.address.street,
            needs_monitoring: selectedLaundryRoom.needs_monitoring,
            visibility_distance: selectedLaundryRoom.visibility_distance,
            metadata: selectedLaundryRoom.location_meta_data_dto,
            lifecycleStatus: selectedLaundryRoom.lifecycle_status,
            feedback_question_id: selectedLaundryRoom.feedback_question_id,
            feedback_question_response_count:
                selectedLaundryRoom.feedback_question_response_count,
            desired_feedback_count: selectedLaundryRoom.desired_feedback_count,
            pre_payment_required: selectedLaundryRoom.pre_payment_required,
            supports_scan2_wash: selectedLaundryRoom.supports_scan2_wash,
        };
    }

    function handleCleaningInformationModel() {
        if (selectedLaundryRoom) {
            openModal({
                type: ModalType.CHANGE_LOCATION_META_INFORMATION,
                data: {
                    metadata: selectedLaundryRoom.location_meta_data_dto,
                    locationId: selectedLaundryRoom.id,
                },
            });
        }
    }

    function handleNeedsMonitoringOptionEdit() {
        if (selectedLaundryRoom) {
            openModal({
                type: ModalType.CHANGE_MONITORING_FLAG_INFORMATION,
                data: {
                    needs_monitoring: selectedLaundryRoom.needs_monitoring,
                    locationId: selectedLaundryRoom.id,
                },
            });
        }
    }

    function handleVisibilityDistanceEdit() {
        if (selectedLaundryRoom) {
            openModal({
                type: ModalType.CHANGE_LOCATION_VISIBILITY_DISTANCE,
                data: {
                    distance: selectedLaundryRoom.visibility_distance,
                    locationId: selectedLaundryRoom.id,
                },
            });
        }
    }

    function handleFeedbackModeEdit() {
        if (selectedLaundryRoom) {
            openModal({
                type: ModalType.SELECT_FEEDBACK_MODE_QUESTION,
                data: {
                    laundryRoomId: selectedLaundryRoom.id,
                    feedbackQuestionId:
                        selectedLaundryRoom.feedback_question_id,
                },
            });
        }
    }

    function handlePrepaymentModeEdit() {
        if (selectedLaundryRoom) {
            openModal({
                type: ModalType.CHANGE_PREPAYMENT_ALLOWED,
                data: {
                    prePaymentRequired:
                        selectedLaundryRoom.pre_payment_required,
                    laundryRoomId: selectedLaundryRoom.id,
                },
            });
        }
    }

    function handleScan2WashEdit() {
        if (selectedLaundryRoom) {
            const data: SetSupportsScan2WashParams = {
                supportsScan2Wash: !!selectedLaundryRoom.supports_scan2_wash,
                laundryRoomId: selectedLaundryRoom.id,
            };
            openModal({
                type: ModalType.CHANGE_SCAN_2_WASH_SUPPORTED,
                data,
            });
        }
    }

    function handleSetLaundryRoomNote(notes: LaundryRoomNotesDto) {
        if (notes.laundry_room_id) {
            const locationId = notes.laundry_room_id;
            props.setLaundryRoomNotes(locationId, notes, () =>
                props.fetchLaundryRoom(locationId)
            );
        }
    }

    function renderSelectedLaundryRoom() {
        if (!selectedLaundryRoom) {
            return;
        }
        const laundryRoomInfo = createDisplayedLaundryRoomInfo();
        const infoContainerStyle = {
            display: 'flex' as const,
            justifyContent: 'space-between' as const,
            alignItems: 'flex-start' as const,
            marginBottom: '2rem',
        };

        const widthStyle = {
            maxWidth: '50%',
            minWidth: '50%',
        };
        if (laundryRoomStore.errors && laundryRoomStore.errors.length > 0) {
            return <></>;
        }
        return (
            <>
                <p className={styles.headline}>Waschrauminformationen</p>
                <div style={infoContainerStyle}>
                    <div style={widthStyle}>
                        <LaundryRoomAnchorCard
                            handleCleaningInformationModel={
                                handleCleaningInformationModel
                            }
                            handleNeedsMonitoringOptionEdit={
                                handleNeedsMonitoringOptionEdit
                            }
                            onVisibilityDistanceEdit={
                                handleVisibilityDistanceEdit
                            }
                            onFeedbackModeEdit={handleFeedbackModeEdit}
                            onPrepaymentModeEdit={handlePrepaymentModeEdit}
                            onScan2WashEdit={handleScan2WashEdit}
                            laundryRoomInfo={laundryRoomInfo}
                            criticalNoteActive={
                                selectedLaundryRoom &&
                                selectedLaundryRoom.show_critical_note
                            }
                            onSetLaundryRoomNotes={handleSetLaundryRoomNote}
                            setLaundryRoomNotesErrors={
                                props.manageLaundryRoomsStore.errors
                            }
                        />
                    </div>
                    <div style={widthStyle}>
                        {renderManageLaundryRoomNotes(selectedLaundryRoom)}
                    </div>
                </div>
                {selectedLaundryRoom && (
                    <Row className="spacer-tb">
                        <Col xs={6} sm={6} md={6} lg={6}>
                            <p className={styles.headline}>Wäschelektionen</p>
                            <EditLaundryLessonFrequency
                                laundryRoomInfo={selectedLaundryRoom}
                            />
                        </Col>
                    </Row>
                )}

                <p className={styles.headline}>Waschraum Kachel</p>
                {renderManageLaundryRoomCard(selectedLaundryRoom)}
                <br />
                <p className={styles.headline}>VIP Nutzer</p>
                <Link
                    to={`${Route.MANAGE_VIP_USERS}?${LAUNDRY_ROOM_VIP_SEARCH_QUERY}=${selectedLaundryRoom.id}`}
                >
                    Zu den VIP-Nutzer Einstellungen
                </Link>
                <br />
                <p className={styles.headline}>Maschinen</p>
                <ApplianceOverviewTable
                    appliances={selectedLaundryRoom.appliances}
                    goTo={goTo}
                />
                <p className={styles.headline}>Laundry Room QR IDs</p>
                <LaundryRoomQrIdOverviewTable
                    locationId={selectedLaundryRoom.id}
                />
            </>
        );
    }

    function renderManageLaundryRoomCard(laundryRoom: LaundryRoomOverviewDto) {
        const text = laundryRoom.has_laundry_room_card
            ? 'Der Waschraum hat eine eigene Karte in der User-App.'
            : 'Der Waschraum hat keine angepasste Karte.';

        return (
            <Row className="spacer-tb">
                <Col xs={6} sm={6} md={6} lg={6}>
                    <p>{text}</p>
                    <p>
                        <Button
                            variant="outline-secondary"
                            onClick={handleManageLaundryRoomCard}
                        >
                            &gt; zur Konfiguration
                        </Button>
                    </p>
                </Col>
            </Row>
        );

        function handleManageLaundryRoomCard() {
            const paramsMap = new Map();
            paramsMap.set(
                laundryRoomIdReqParam,
                selectedLaundryRoom && selectedLaundryRoom.id
                    ? selectedLaundryRoom.id
                    : ''
            );
            goTo(Route.MANAGE_LAUNDRY_ROOM_CARDS, undefined, paramsMap);
        }
    }

    function renderManageLaundryRoomNotes(laundryRoom: LaundryRoomOverviewDto) {
        if (selectedLaundryRoom) {
            return (
                <ManageLaundryRoomNotesForm
                    locationId={selectedLaundryRoom.id}
                    hideNotesInfo
                />
            );
        } else {
            return null;
        }
    }

    return <div id="ManageLaundryRoom">{renderSelectedLaundryRoom()}</div>;
};

export default connect(
    (store: RootStore) => ({
        laundryRoomStore: store.location,
        manageLaundryRoomsStore: store.manageLaundryRooms,
        locationQrIds: store.location.locationQrIds,
    }),
    {
        goTo,
        openModal,
        setLaundryRoomNotes,
        fetchLaundryRoom,
        fetchLaundryRoomQrIds,
        deleteLaundryRoomQrId,
        createLaundryRoomQrId,
    }
)(ManageLaundryRoom);
