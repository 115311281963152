import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import { AxiosResponse } from 'axios';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import { ThunkDispatch } from 'redux-thunk';
import { CreditNotesSummary } from './groupedCreditNotesReducer';
import {
    DownloadCallback,
    parseContentDispositionForFilename,
} from '../../util/downloadFileAsBlob';

export const FETCH_CREDIT_NOTES_SUMMARY = 'FETCH_CREDIT_NOTES_SUMMARY';
export const FETCH_CREDIT_NOTES_SUMMARY_SUCCESS =
    'FETCH_CREDIT_NOTES_SUMMARY_SUCCESS';
export const FETCH_CREDIT_NOTES_SUMMARY_FAILURE =
    'FETCH_CREDIT_NOTES_SUMMARY_FAILURE';
export const RESET_GROUPED_CREDIT_NOTES = 'RESET_GROUPED_CREDIT_NOTES';

export const DOWNLOAD_CREDIT_NOTES = 'DOWNLOAD_CREDIT_NOTES';
export const DOWNLOAD_CREDIT_NOTES_SUCCESS = 'DOWNLOAD_CREDIT_NOTES_SUCCESS';
export const DOWNLOAD_CREDIT_NOTES_FAILURE = 'DOWNLOAD_CREDIT_NOTES_FAILURE';

export enum CreditNotesExportTypes {
    PDFS_ALL = 'pdfs',
    PDFS_POSITIVE = 'pdfs_positive',
    PDFS_NEGATIVE = 'pdfs_negative',
    PDFS_ZERO = 'pdfs_zero',
    DATEV_POSITIVE = 'datev_positive',
    DATEV_NEGATIVE = 'datev_negative',
}

export interface CreditNotesDownloadRequest {
    countryCode: string;
    year: number;
    month: number;
    exportType: CreditNotesExportTypes;
}

export function resetGroupedCreditNotesStore() {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: RESET_GROUPED_CREDIT_NOTES });
    };
}

export function getCreditNotesSummary(year: number, month: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: FETCH_CREDIT_NOTES_SUMMARY });
        try {
            const { data }: AxiosResponse<CreditNotesSummary> =
                await RestClient.get(
                    `v1/callcenter/credit_notes/summary?year=${year}&month=${month}`
                );
            dispatch({
                type: FETCH_CREDIT_NOTES_SUMMARY_SUCCESS,
                payload: { data },
            });
        } catch (error) {
            dispatch({
                type: FETCH_CREDIT_NOTES_SUMMARY_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function getCreditNotesContents(
    request: CreditNotesDownloadRequest,
    download: DownloadCallback
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DOWNLOAD_CREDIT_NOTES, payload: { ...request } });
        try {
            const downloadZipResponse: AxiosResponse = await RestClient.get(
                `/v1/callcenter/credit_notes/download/${request.countryCode}/${request.exportType}?month=${request.month}&year=${request.year}`,
                { responseType: 'blob' }
            );
            dispatch({ type: DOWNLOAD_CREDIT_NOTES_SUCCESS });
            const filename =
                parseContentDispositionForFilename(
                    downloadZipResponse.headers['content-disposition']
                ) || getFilenameFromRequest(request);
            download(filename, downloadZipResponse.data);
        } catch (e) {
            dispatch({
                type: DOWNLOAD_CREDIT_NOTES_FAILURE,
                payload: { errors: extractErrorTags(e) },
            });
        }
    };
}

function getFilenameFromRequest(request: CreditNotesDownloadRequest) {
    return `${request.year}-${request.month}-${request.countryCode}-${request.exportType}.zip`;
}
