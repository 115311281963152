import { createReducer } from 'redux-starter-kit';
import { GO_TO } from './routerActions';
import { Route } from '../../Router';
import history from '../../App/history';
import { appendRequestParams } from '../../util/url';

export interface RouterStore {
    currentRoute: Route;
    param?: string | number;
}

const initialState: RouterStore = {
    currentRoute: Route.LOGIN,
};

export const routerReducer = createReducer(initialState, {
    [GO_TO]: (state: RouterStore, action) => {
        state.currentRoute = action.payload.route;
        state.param = action.payload.param;
        let fullUrl = action.payload.param
            ? `${state.currentRoute}/${action.payload.param}`
            : state.currentRoute;
        fullUrl = appendRequestParams(fullUrl, action.payload.requestParams);
        history.push(fullUrl);
    },
});
