import React, {Fragment} from 'react';
import Modal from 'react-bootstrap/Modal';
import {closeModal, ModalParameters} from '../../store/modal/modalActions';
import {connect} from 'react-redux';
import {RootStore} from '../../store/rootStore';
import ErrorFeedback from '../../forms/ErrorFeedback';
import {Formik, FormikActions, FormikProps} from 'formik';
import {Form, InputGroup, Row, Spinner} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import {ManageLaundryRoomStore} from '../../store/manage-laundry-room/manageLaundryRoomReducer';
import {updatePrePaymentForLocation} from '../../store/manage-laundry-room/manageLaundryRoomActions';

export interface SetPrepaymentAllowedParams {
    prePaymentRequired: boolean;
    locationId: number;
}

interface Props {
    params: SetPrepaymentAllowedParams;
    close: (params?: ModalParameters<SetPrepaymentAllowedParams>) => void;
    data: { laundryRoomId?: number; prePaymentRequired?: boolean };
    manageLaundryRoomStore: ManageLaundryRoomStore;
    updatePrePaymentForLocation: (
        locationId: number,
        requirePrePayment: boolean,
        successCallback?: () => void
    ) => void;
}

function SetPrepaymentForLocationModal(props: Props) {
    const initialValues: InputFormData = {
        prePaymentRequired: props.params.prePaymentRequired,
    };
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Prepayment Einstellungen</Modal.Title>
            </Modal.Header>
            <Modal.Body>{SetPrepaymentAllowed()}</Modal.Body>
        </Fragment>
    );

    function SetPrepaymentAllowed() {
        return (
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                children={renderForm}
            />
        );

        function renderForm(formProps: FormikProps<InputFormData>) {
            return (
                <Form noValidate onSubmit={formProps.handleSubmit}>
                    <Form.Group
                        as={Row}
                        controlId={'formGroupLaundryRoomId'}
                        className={'mx-0'}
                    >
                        <ErrorFeedback
                            apiErrors={props.manageLaundryRoomStore.errors}
                        />
                        Soll Pre-Payment für diesen Waschraum aktiviert werden?
                        <InputGroup>
                            <InputGroup>
                                <Form.Check
                                    custom
                                    label="Pre-Payment"
                                    name={'prePaymentRequired'}
                                    id={'prePaymentRequired'}
                                    onChange={formProps.handleChange}
                                    checked={
                                        formProps.values.prePaymentRequired
                                    }
                                ></Form.Check>
                            </InputGroup>
                        </InputGroup>
                        <Row className={'mx-0 mt-4'}>
                            <Button onClick={handleCancel} className={'mr-2'}>
                                Abbrechen
                            </Button>
                            <Button type={'submit'}>
                                {props.manageLaundryRoomStore.loading && (
                                    <Spinner animation={'grow'}/>
                                )}
                                Setzen
                            </Button>
                        </Row>
                    </Form.Group>
                </Form>
            );
        }

        function handleSubmit(
            value: InputFormData,
            actions: FormikActions<InputFormData>
        ) {
            if (
                props.data &&
                props.data.laundryRoomId &&
                value.prePaymentRequired !== undefined
            ) {
                props.updatePrePaymentForLocation(
                    props.data.laundryRoomId,
                    value.prePaymentRequired,
                    () => props.close()
                );
            }
        }

        function handleCancel() {
            props.close();
        }
    }
}

interface InputFormData {
    prePaymentRequired?: boolean;
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SetPrepaymentAllowedParams,
        manageLaundryRoomStore: state.manageLaundryRooms,
    }),
    {
        close: closeModal,
        updatePrePaymentForLocation: updatePrePaymentForLocation,
    }
)(SetPrepaymentForLocationModal);
