import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux-starter-kit';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import RestClient from '../../http/RestClient';
import { PartnerBoardManagerDto } from './managePartnerManagerReducer';

export const LOAD_PARTNER_MANAGER = 'LOAD_PARTNER_MANAGER';
export const LOAD_PARTNER_MANAGER_SUCCESS = 'LOAD_PARTNER_MANAGER_SUCCESS';
export const LOAD_PARTNER_MANAGER_FAILURE = 'LOAD_PARTNER_MANAGER_FAILURE';

export const SEND_ACTIVATION_EMAIL = 'SEND_ACTIVATION_EMAIL';
export const SEND_ACTIVATION_EMAIL_SUCCESS = 'SEND_ACTIVATION_EMAIL_SUCCESS';
export const SEND_ACTIVATION_EMAIL_FAILURE = 'SEND_ACTIVATION_EMAIL_FAILURE';

export function searchB2BUser(searchStr: string) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: LOAD_PARTNER_MANAGER });
        try {
            const { data } = await RestClient.get<PartnerBoardManagerDto>(
                `/v1/callcenter/partner_manager_search?search=${encodeURIComponent(
                    searchStr
                )}`
            );
            dispatch({ type: LOAD_PARTNER_MANAGER_SUCCESS, payload: { data } });
        } catch (e) {
            dispatch({
                type: LOAD_PARTNER_MANAGER_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function sendActivationEmail(partnerId: number, email?: string) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: SEND_ACTIVATION_EMAIL });
        try {
            const { data } = await RestClient.put(
                `/v1/callcenter/send_activation_email/${encodeURIComponent(
                    partnerId
                )}`,
                {
                    email: email,
                }
            );
            dispatch({
                type: SEND_ACTIVATION_EMAIL_SUCCESS,
                payload: { data },
            });
        } catch (e) {
            dispatch({
                type: SEND_ACTIVATION_EMAIL_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}
