import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface UseUrlSearchParamConfig {
    name: string;
}

export function useUrlSearchParam(config: UseUrlSearchParamConfig) {
    const location = useLocation();
    const currentSearch = location.search;
    const history = useHistory();
    const initialValue = new URLSearchParams(location.search).get(config.name);

    const [currentValue, setCurrentValue] = useState<string | null>(
        initialValue
    );

    useEffect(() => {
        const valueFromSearch = new URLSearchParams(currentSearch).get(
            config.name
        );
        if (valueFromSearch !== currentValue) {
            setCurrentValue(valueFromSearch);
        }
    }, [currentSearch]);

    return {
        currentValue,
        updateValue,
    };

    function updateValue(newValue: string) {
        if (currentValue === newValue) {
            return;
        }

        const searchParams = new URLSearchParams(location.search);
        searchParams.set(config.name, newValue);
        history.replace({
            ...location,
            search: '?' + searchParams.toString(),
        });
        setCurrentValue(newValue);
    }
}
