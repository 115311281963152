import React, { ChangeEvent, useState } from 'react';
import Table from 'react-bootstrap/Table';
import {
    ManagedPartner,
    SearchUserStore,
} from '../../store/search-user/searchUserReducer';
import styles from './UserInformationTable.module.css';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';

interface Props {
    search: SearchUserStore;
    partners: ManagedPartner[];
    onSelect: (partner: ManagedPartner) => void;
}

const PartnerInformationTable = ({ partners, onSelect, search }: Props) => {
    const [filteredPartners, setFilteredPartners] = useState(partners);
    const [searchString, setSearchString] = useState('');

    function filter(event: ChangeEvent<HTMLInputElement>) {
        const searchString = event.target.value;
        setSearchString(searchString);

        if (!partners) return null;

        const filteredPartners = partners.filter(
            (partner) =>
                String(partner.id)
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                String(partner.customer_id).includes(searchString) ||
                (partner.street_and_housenumber &&
                    partner.street_and_housenumber
                        .toLowerCase()
                        .includes(searchString.toLowerCase())) ||
                (partner.postalcode &&
                    partner.postalcode
                        .toLowerCase()
                        .includes(searchString.toLowerCase())) ||
                (partner.city &&
                    partner.city
                        .toLowerCase()
                        .includes(searchString.toLowerCase())) ||
                partner.company_name
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
        );

        setFilteredPartners(filteredPartners);
    }

    return (
        <>
            <h3>Partner Accounts</h3>
            <input
                type="text"
                onChange={filter}
                value={searchString}
                placeholder="Filtern nach PLZ, Stadt, Firmenname, Partner ID, Customer ID"
                className={styles.bigInputForSearchingOrFiltering}
            />

            <Table bordered hover className={styles.table}>
                <thead>
                    <tr className={styles.header}>
                        <th>Partner ID</th>
                        <th>Customer ID</th>
                        <th>Name</th>
                        <th>Firmenname</th>
                        <th>Adresse</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredPartners.map((it) => (
                        <tr
                            key={it.external_id}
                            onClick={() => onSelect(it)}
                            className={styles.body}
                        >
                            <td>{it.id}</td>
                            <td>{it.customer_id}</td>
                            <td>{it.name}</td>
                            <td>{it.company_name}</td>
                            <td>
                                {it.street_and_housenumber}, {it.postalcode}{' '}
                                {it.city}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </>
    );
};

export default connect(
    (store: RootStore) => ({
        search: store.searchUser,
    }),
    {}
)(PartnerInformationTable);
