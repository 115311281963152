import React from 'react';
import {
    B2bRoleType,
    B2bUserRoleDto,
    PartnerBoardManagerDto,
} from '../../store/manage-partner-manager/managePartnerManagerReducer';
import B2BUserCard from './PartnerManagerCard';
import { Table } from 'react-bootstrap';
import styles from '../manage-user/elements/UserInformationCard.module.css';
import Button from 'react-bootstrap/Button';
import { Route } from '../../Router';

interface Props {
    b2bUser: PartnerBoardManagerDto;
    goTo: (route: Route, param?: string | number) => void;
}

function PartnerManagerDisplay({ b2bUser, ...props }: Props) {
    return (
        <div>
            <h3 className={'mt-5 mb-1'}>Informationen zum B2B User</h3>
            <B2BUserCard partnerManager={b2bUser} />
            {b2bUser.roles && renderRoles(b2bUser.roles)}
        </div>
    );

    function goToB2bUserDetails() {
        props.goTo(Route.MANAGE_USER, b2bUser.id);
    }

    function renderRoles(roles: B2bUserRoleDto[]) {
        return (
            <>
                <h3 className={'mt-5 mb-1'}>verknüpfte Verwaltungsbereiche</h3>
                <div className={styles.table}>
                    <Table bordered>
                        <thead>
                            <tr>
                                <th>Verwaltungsbereich ID</th>
                                <th>Firma</th>
                                <th>Firmen Adresse</th>
                                <th>Rolle</th>
                                <th>Sub-Rolle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {roles.map((item, index) => (
                                <tr
                                    key={
                                        item.accounting_area.accounting_area_id
                                    }
                                >
                                    <td>
                                        {
                                            item.accounting_area
                                                .accounting_area_id
                                        }
                                    </td>
                                    <td>{item.accounting_area.company_name}</td>
                                    <td>
                                        {
                                            item.accounting_area
                                                .street_and_house_number
                                        }
                                        <br />
                                        {item.accounting_area.city}{' '}
                                        {item.accounting_area.postal_code}
                                    </td>
                                    <td>{item.role}</td>
                                    <td>
                                        {item.sub_role || '-'}
                                        {item.role ===
                                            B2bRoleType.B2B_LAUNDRY_USAGE && (
                                            <Button
                                                className="mt-4"
                                                onClick={goToB2bUserDetails}
                                            >
                                                Details
                                            </Button>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </>
        );
    }
}

export default PartnerManagerDisplay;
