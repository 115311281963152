export interface LaundryRoomWithApplianceStates {
    incidents_of_appliances?: ApplianceIncidentType[];
}

export interface ApplianceWithIncidentType {
    incident_type: ApplianceIncidentType;
}

export enum ApplianceIncidentType {
    NONE = 'NONE',
    DISCHARGE_PUMP_INCIDENT = 'DISCHARGE_PUMP_INCIDENT',
    WARNING = 'WARNING',
    DRYER_INCIDENT = 'DRYER_INCIDENT',
}

export enum ApplianceConnectivityWarning {
    NONE = 'NONE',
    LONG_TERM_DISCONNECT = 'LONG_TERM_DISCONNECT',
}

export function hasDischargePumpIncident(
    laundryRoomWithApplianceStates: LaundryRoomWithApplianceStates
) {
    if (laundryRoomWithApplianceStates.incidents_of_appliances) {
        for (let type of laundryRoomWithApplianceStates.incidents_of_appliances) {
            if (type === ApplianceIncidentType.DISCHARGE_PUMP_INCIDENT) {
                return true;
            }
        }
    }
    return false;
}

export function mapIncidentTypeToShortText(
    incidentType: ApplianceIncidentType
) {
    switch (incidentType) {
        case ApplianceIncidentType.DISCHARGE_PUMP_INCIDENT:
            return 'Laugenpumpe';
        case ApplianceIncidentType.NONE:
            return '-';
        case ApplianceIncidentType.WARNING:
            return 'Warnung';
        case ApplianceIncidentType.DRYER_INCIDENT:
            return 'Kurzabbrecher';
        default:
            return incidentType;
    }
}
