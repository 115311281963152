import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../ErrorFeedback';
import {
    clearPartnerSearch,
    searchPartnerByAnyId,
    searchPartnerManager,
} from '../../store/search-user/searchUserActions';
import {
    ManagedPartner,
    SearchUserStore,
} from '../../store/search-user/searchUserReducer';
import { setManagedPartner } from '../../store/manage-serialnumbers/manageSerialnumbersActions';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchPartner.module.css';
import GreyLogo from '../../App/WeWash_LightGrey.png';
import Alert from 'react-bootstrap/Alert';
import PartnerManagerInformationTable from '../../components/user-information-table/PartnerManagerInformationTable';
import PartnerInformationTable from '../../components/user-information-table/PartnerInformationTable';
import { goTo } from '../../store/router/routerActions';
import { Route } from '../../Router';
import PartnerInformationByAnyIdTable from '../../components/user-information-table/PartnerInformationByAnyIdTable';

export interface SearchTerm {
    searchTerm: string;
}

interface Props {
    search: SearchUserStore;
    searchPartnerManager: (searchTerm: string) => any;
    searchPartnerByAnyId: (searchId: string) => any;
    clearPartnerSearch: () => any;
    setManagedPartner: (partner: ManagedPartner) => void;
    goTo: (route: Route) => void;
}

const SearchPartner: FunctionComponent<Props> = (props) => {
    const initialValues: SearchTerm = { searchTerm: '' };

    async function handleFormSubmit(
        values: SearchTerm,
        actions: FormikActions<SearchTerm>
    ) {
        await props.clearPartnerSearch();
        if (isNaN(parseInt(values.searchTerm))) {
            await props.searchPartnerManager(values.searchTerm);
        } else {
            await props.searchPartnerByAnyId(values.searchTerm);
        }

        actions.setSubmitting(false);
    }

    function handleSelect(partner: ManagedPartner) {
        props.setManagedPartner(partner);
        props.goTo(Route.MANAGE_SERIALNUMBERS);
    }

    const hasPartnersFromAnyId =
        props.search.resultByAnyId &&
        props.search.resultByAnyId.partners.length > 0;

    return (
        <div id="SearchPartner" className="p-1">
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {(formProps: FormikProps<SearchTerm>) => (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <ErrorFeedback apiErrors={props.search.errors} />

                            <Form.Group
                                controlId="formGroupSearch"
                                className={styles.searchBar}
                            >
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        name="searchTerm"
                                        className={styles.input}
                                        value={formProps.values.searchTerm}
                                        placeholder="Partner Suche mit Partner ID, Location ID, Customer ID oder Partner Manager E-Mail"
                                        onChange={formProps.handleChange}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-dark"
                                            type="submit"
                                            disabled={
                                                formProps.isSubmitting ||
                                                formProps.values.searchTerm
                                                    .length === 0
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className={styles.icon}
                                            />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <div className="mt-5">
                                {props.search
                                    .resultForPartnerManagerByEmail && (
                                    <div>
                                        <PartnerManagerInformationTable
                                            partnerManager={
                                                props.search
                                                    .resultForPartnerManagerByEmail
                                            }
                                        />
                                        <PartnerInformationTable
                                            partners={
                                                props.search
                                                    .resultForPartnerManagerByEmail
                                                    .partners
                                            }
                                            onSelect={handleSelect}
                                        />
                                    </div>
                                )}
                                {hasPartnersFromAnyId &&
                                    props.search.resultByAnyId && (
                                        <div>
                                            <PartnerInformationByAnyIdTable
                                                partners={
                                                    props.search.resultByAnyId
                                                        .partners
                                                }
                                                onSelect={handleSelect}
                                            />
                                        </div>
                                    )}
                                {!props.search.resultForPartnerManagerByEmail &&
                                    !hasPartnersFromAnyId &&
                                    formProps.submitCount > 0 && (
                                        <div>
                                            <img
                                                alt="WeWash Logo"
                                                src={GreyLogo}
                                                className={styles.logo}
                                            />
                                            <Alert
                                                variant="info"
                                                className={styles.text}
                                            >
                                                Deine Suche ergab keine
                                                Ergebnisse
                                            </Alert>
                                        </div>
                                    )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        search: store.searchUser,
    }),
    {
        searchPartnerManager,
        searchPartnerByAnyId,
        clearPartnerSearch,
        setManagedPartner,
        goTo,
    }
)(SearchPartner);
