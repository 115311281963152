import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    SearchUserStore,
    UserExtendedInformation,
} from '../../store/search-user/searchUserReducer';
import UserInformationTable from '../../components/user-information-table/UserInformationTable';
import { loadUserDetailsAndGoToManage } from '../../store/manage-user/manageUserActions';
import styles from './SearchUser.module.css';
import GreyLogo from '../../App/WeWash_LightGrey.png';
import Alert from 'react-bootstrap/Alert';
import useFavoriteUsers from './useFavoriteUsers';
import { searchUserById } from '../../store/search-user/searchUserActions';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';

interface Props {
    search: SearchUserStore;
    searchUserById: (userId: number) => void;
    loadUserDetailsAndGoToManage: (userId: number) => void;
    goTo: (route: Route, param?: string | number) => void;
    isAdmin: boolean;
}

const FavoriteUsers: FunctionComponent<Props> = (props) => {
    const loadFromBackend = props.searchUserById;
    const memorizedUsersById = props.search.memoryUsersBydId;

    const { favoriteUsers, handleSelectUser, handleResetFavoriteUsers } =
        useFavoriteUsers();

    const [displayedUsers, setDisplayedUsers] = useState<
        UserExtendedInformation[]
    >([]);

    useEffect(() => {
        favoriteUsers.forEach((it) => loadFromBackend(it.userId));
    }, [favoriteUsers, loadFromBackend]);

    useEffect(() => {
        const newList: UserExtendedInformation[] = [];
        favoriteUsers.forEach((it) => {
            if (memorizedUsersById[it.userId]) {
                newList.push(memorizedUsersById[it.userId]);
            }
        });
        setDisplayedUsers(newList);
    }, [favoriteUsers, memorizedUsersById]);

    function handleSelect(it: UserExtendedInformation) {
        handleSelectUser(it.user_id);
        props.goTo(Route.MANAGE_USER, it.user_id);
    }

    function handleReset() {
        handleResetFavoriteUsers();
        setDisplayedUsers([]);
    }

    return (
        <div id="FavoriteUsers" className="p-1">
            <div className="mt-5">
                {displayedUsers.length > 0 && (
                    <UserInformationTable
                        users={displayedUsers}
                        onSelect={handleSelect}
                        isAdmin={props.isAdmin}
                    />
                )}
                {displayedUsers.length === 0 && (
                    <div>
                        <img
                            alt="WeWash Logo"
                            src={GreyLogo}
                            className={styles.logo}
                        />
                        {favoriteUsers.length === 0 && (
                            <Alert variant="info" className={styles.text}>
                                Bisher in diesem Browser noch keine Favoriten
                                gesammelt. Benutzen Sie bitte die normale Suche
                                um Nutzer zu finden.
                            </Alert>
                        )}
                    </div>
                )}

                {displayedUsers.length > 0 && (
                    <Button
                        variant="outline-dark"
                        type="submit"
                        onClick={handleReset}
                    >
                        <FontAwesomeIcon
                            icon={faTrash}
                            className={styles.icon}
                        />{' '}
                        Liste von Favoriten leeren
                    </Button>
                )}
            </div>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        search: store.searchUser,
        isAdmin: store.user.userIsAdmin,
    }),
    { loadUserDetailsAndGoToManage, searchUserById, goTo }
)(FavoriteUsers);
