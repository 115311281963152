import React, { Fragment, FunctionComponent, memo } from 'react';
import Form from 'react-bootstrap/Form';

interface Props {
    handleChange: (e: React.ChangeEvent<any>) => void;
    iban: string;
    accountHolder: string;
}

const PaymentForm: FunctionComponent<Props> = memo((props) => {
    return (
        <Fragment>
            <Form.Group controlId="iban">
                <Form.Label>IBAN</Form.Label>
                <Form.Control
                    type="text"
                    onChange={props.handleChange}
                    value={props.iban}
                />
            </Form.Group>
            <Form.Group controlId="accountHolder">
                <Form.Label>Kontoinhaber</Form.Label>
                <Form.Control
                    type="text"
                    onChange={props.handleChange}
                    value={props.accountHolder}
                />
            </Form.Group>
        </Fragment>
    );
});

export default PaymentForm;
