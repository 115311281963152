import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { updateLocationVisibilityDistance } from '../../store/manage-laundry-room/manageLaundryRoomActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { ManageLaundryRoomStore } from '../../store/manage-laundry-room/manageLaundryRoomReducer';

interface Props {
    data: { distance?: number; locationId: number };
    laundryRoomStore: ManageLaundryRoomStore;
    close: () => void;
    updateLocationVisibilityDistance: (
        locationId: number,
        distance: number
    ) => void;
}

interface InputFormData {
    distance: string;
}

function ChangeLocationVisibilityDistance({
    data,
    close,
    updateLocationVisibilityDistance,
    laundryRoomStore,
}: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const isLoading = patchTriggered && laundryRoomStore.loading;

    useEffect(() => {
        if (
            patchTriggered &&
            !isLoading &&
            laundryRoomStore.errors.length === 0
        ) {
            close();
        }
    }, [patchTriggered, isLoading, laundryRoomStore.errors, close]);

    const initialValues: InputFormData = {
        distance: String(data.distance ? data.distance : 1000),
    };
    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Sichtbarkeit für Waschraumzuweisung</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Aus welcher Distanz [in Meter] soll der Waschraum für Nutzer
                sichtbar sein? Die Distanz kann zwischen 200 Metern und 5000
                Metern sein.
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    validate={validateValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<InputFormData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group
                    as={Row}
                    controlId={'formGroupLocationVisibilityDistance'}
                    className={'mx-0'}
                >
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Distanz [m]</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type={'number'}
                            name={'distance'}
                            value={formProps.values.distance}
                            onChange={formProps.handleChange}
                            placeholder={'Distanz'}
                        />
                    </InputGroup>
                </Form.Group>
                <Row className={'mx-0 mt-4'}>
                    <ErrorFeedback apiErrors={laundryRoomStore.errors} />
                </Row>
                <Row className={'mx-0 mt-4'}>
                    <Button
                        variant="outline-primary"
                        onClick={handleCancel}
                        className={'mr-2'}
                    >
                        Abbrechen
                    </Button>
                    <Button type={'submit'} disabled={formProps.isSubmitting}>
                        {isLoading && <Spinner animation={'grow'} />}
                        Speichern
                    </Button>
                </Row>
            </Form>
        );
    }

    function handleCancel() {
        close();
    }

    function handleSubmit(
        value: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        updateLocationVisibilityDistance(
            data.locationId,
            Number(value.distance)
        );
        setPatchTriggered(true);
        actions.setSubmitting(false);
    }
}

function validateValues(values: InputFormData) {
    if (Number.isNaN(Number(values.distance))) {
        return { distance: 'NaN' };
    }
    return {};
}

export default connect(
    (state: RootStore) => ({ laundryRoomStore: state.manageLaundryRooms }),
    { close: closeModal, updateLocationVisibilityDistance }
)(ChangeLocationVisibilityDistance);
