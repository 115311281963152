import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import { UserExtendedDetails } from './manageUserReducer';
import { goTo } from '../router/routerActions';
import { Route } from '../../Router';
import {
    SEARCH_USER_CLEAR,
    searchUserById,
} from '../search-user/searchUserActions';
import {
    InvoiceDeliveryMethod,
    UserParallelReservationsState,
} from '../search-user/searchUserReducer';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const LOAD_USER_DETAILS = 'LOAD_USER_DETAILS';
export const LOAD_USER_DETAILS_SUCCESS = 'LOAD_USER_DETAILS_SUCCESS';
export const LOAD_USER_DETAILS_FAILURE = 'LOAD_USER_DETAILS_FAILURE';

export const SELECT_PAYMENT_METHOD = 'SELECT_PAYMENT_METHOD';
export const SELECT_PAYMENT_METHOD_SUCCESS = 'SELECT_PAYMENT_METHOD_SUCCESS';
export const SELECT_PAYMENT_METHOD_FAILURE = 'SELECT_PAYMENT_METHOD_FAILURE';

export const DELETE_PAYMENT_METHOD = 'DELETE_PAYMENT_METHOD';
export const DELETE_PAYMENT_METHOD_SUCCESS = 'DELETE_PAYMENT_METHOD_SUCCESS';
export const DELETE_PAYMENT_METHOD_FAILURE = 'DELETE_PAYMENT_METHOD_FAILURE';

export const REVALIDATE_PAYMENT_METHOD = 'REVALIDATE_PAYMENT_METHOD';
export const REVALIDATE_PAYMENT_METHOD_SUCCESS =
    'REVALIDATE_PAYMENT_METHOD_SUCCESS';
export const REVALIDATE_PAYMENT_METHOD_FAILURE =
    'REVALIDATE_PAYMENT_METHOD_FAILURE';

export const CHANGE_LAUNDRY_ROOM_METHOD = 'CHANGE_LAUNDRY_ROOM_METHOD';
export const CHANGE_LAUNDRY_ROOM_METHOD_SUCCESS =
    'CHANGE_LAUNDRY_ROOM_METHOD_SUCCESS';
export const CHANGE_LAUNDRY_ROOM_METHOD_FAILURE =
    'CHANGE_LAUNDRY_ROOM_METHOD_FAILURE';

export const CHANGE_ALLOW_PLAIN_SEPA_MANDATE =
    'CHANGE_ALLOW_PLAIN_SEPA_MANDATE';
export const CHANGE_ALLOW_PLAIN_SEPA_MANDATE_SUCCESS =
    'CHANGE_ALLOW_PLAIN_SEPA_MANDATE_SUCCESS';
export const CHANGE_ALLOW_PLAIN_SEPA_MANDATE_FAILURE =
    'CHANGE_ALLOW_PLAIN_SEPA_MANDATE_FAILURE';

export const CHANGE_USER_PARALLEL_RESERVATIONS =
    'CHANGE_USER_PARALLEL_RESERVATIONS';
export const CHANGE_USER_PARALLEL_RESERVATIONS_SUCCESS =
    'CHANGE_USER_PARALLEL_RESERVATIONS_SUCCESS';
export const CHANGE_USER_PARALLEL_RESERVATIONS_FAILURE =
    'CHANGE_USER_PARALLEL_RESERVATIONS_FAILURE';

export const CHANGE_INVOICE_DELIVERY = 'CHANGE_INVOICE_DELIVERY';
export const CHANGE_INVOICE_DELIVERY_SUCCESS =
    'CHANGE_INVOICE_DELIVERY_SUCCESS';
export const CHANGE_INVOICE_DELIVERY_FAILURE =
    'CHANGE_INVOICE_DELIVERY_FAILURE';

export const USER_ACTION_RESET = 'USER_ACTION_RESET';
export const USER_ACTION_STARTED = 'USER_ACTION_STARTED';
export const USER_ACTION_SUCCESS = 'USER_ACTION_SUCCESS';
export const USER_ACTION_FAILURE = 'USER_ACTION_FAILURE';

export function loadManageUserById(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        await dispatch(searchUserById(userId));
        await dispatch(loadUserDetails(userId));
    };
}

export function loadUserDetailsAndGoToManage(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        try {
            await dispatch(searchUserById(userId));
            await dispatch(loadUserDetails(userId));
            dispatch(goTo(Route.MANAGE_USER, userId));
        } catch {}
    };
}

export function loadUserDetails(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_USER_DETAILS });
        try {
            const { data } = await RestClient.get<UserExtendedDetails>(
                `/v1/callcenter/user_details/${userId}`
            );
            dispatch({ type: LOAD_USER_DETAILS_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: LOAD_USER_DETAILS_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function selectPaymentMethod(paymentMethodId: number, userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SELECT_PAYMENT_METHOD });
        try {
            await RestClient.post(
                `/v1/callcenter/default_payments/${paymentMethodId}`
            );
            dispatch({ type: SELECT_PAYMENT_METHOD_SUCCESS });
            dispatch(loadUserDetails(userId));
        } catch (error) {
            dispatch({
                type: SELECT_PAYMENT_METHOD_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function deletePaymentMethod(paymentMethodId: number, userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: DELETE_PAYMENT_METHOD });
        try {
            await RestClient.delete(
                `/v1/callcenter/payments/${paymentMethodId}`
            );
            dispatch({ type: DELETE_PAYMENT_METHOD_SUCCESS });
            dispatch(loadUserDetails(userId));
        } catch (error) {
            dispatch({
                type: DELETE_PAYMENT_METHOD_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function revalidatePaymentMethod(
    paymentMethodId: number,
    userId: number
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REVALIDATE_PAYMENT_METHOD });
        try {
            await RestClient.post(
                `/v1/callcenter/payments/${paymentMethodId}/revalidate`
            );
            dispatch({ type: REVALIDATE_PAYMENT_METHOD_SUCCESS });
            dispatch(loadUserDetails(userId));
        } catch (error) {
            dispatch({
                type: REVALIDATE_PAYMENT_METHOD_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

interface UserExtendedDetailsPatchDto {
    laundry_room_id: number;
    email: string;
}

interface PatchAllowPlainSepaMandateCreation {
    allow_plain_sepa_mandate_creation: boolean;
}

interface PatchUserParallelReservations {
    parallel_reservations_state: UserParallelReservationsState;
    parallel_reservations_limit: number;
}

export function changeAllowPlainSepaMandateCreation(
    userId: number,
    value: boolean
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHANGE_ALLOW_PLAIN_SEPA_MANDATE });
        try {
            const patchData: PatchAllowPlainSepaMandateCreation = {
                allow_plain_sepa_mandate_creation: value,
            };
            await RestClient.patch(
                `/v1/callcenter/user_details/${userId}/allow_plain_sepa_mandate_creation`,
                patchData
            );

            dispatch({ type: CHANGE_ALLOW_PLAIN_SEPA_MANDATE_SUCCESS });
        } catch (error) {
            dispatch({
                type: CHANGE_ALLOW_PLAIN_SEPA_MANDATE_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
        await dispatch(searchUserById(userId));
    };
}

export function changeUserParallelReservations(
    userId: number,
    state: UserParallelReservationsState,
    limit: number
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHANGE_USER_PARALLEL_RESERVATIONS });
        try {
            const patchData: PatchUserParallelReservations = {
                parallel_reservations_state: state,
                parallel_reservations_limit: limit,
            };
            await RestClient.patch(
                `/v1/callcenter/user_details/${userId}/set_parallel_reservations`,
                patchData
            );

            dispatch({ type: CHANGE_USER_PARALLEL_RESERVATIONS_SUCCESS });
        } catch (error) {
            dispatch({
                type: CHANGE_USER_PARALLEL_RESERVATIONS_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
        await dispatch(searchUserById(userId));
    };
}

export function changeLaundryRoomMethod(userId: number, laundryRoomId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHANGE_LAUNDRY_ROOM_METHOD });
        try {
            const patchData: Partial<UserExtendedDetailsPatchDto> = {
                laundry_room_id: laundryRoomId,
            };
            await RestClient.patch(
                `/v1/callcenter/user_details/${userId}/selected_laundry_room`,
                patchData
            );
            dispatch({ type: CHANGE_LAUNDRY_ROOM_METHOD_SUCCESS });
        } catch (error) {
            dispatch({
                type: CHANGE_LAUNDRY_ROOM_METHOD_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
        await dispatch(searchUserById(userId));
    };
}

export function forceUpdateUserRealtimeDataMethod(userId: number) {
    return async function () {
        try {
            await RestClient.post(
                `/v1/callcenter/user_details/${userId}/realtime_updates`
            );
        } finally {
        }
    };
}

export function deleteUser(
    userId: number,
    callback?: (success: boolean) => void
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        let success = false;
        dispatch({ type: SEARCH_USER_CLEAR });
        dispatch({ type: DELETE_USER });
        try {
            await RestClient.delete(`/v1/callcenter/users/${userId}`);
            dispatch({ type: DELETE_USER_SUCCESS });
            success = true;
        } catch (error) {
            dispatch({
                type: DELETE_USER_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
        callback && callback(success);
    };
}

export function undeleteUser(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SEARCH_USER_CLEAR });
        dispatch({ type: USER_ACTION_STARTED });
        try {
            await RestClient.post(
                `/v1/callcenter/users/${userId}/cancel-deletion-request`
            );
            dispatch({ type: USER_ACTION_SUCCESS });
            await dispatch(loadUserDetailsAndGoToManage(userId));
        } catch (e) {
            dispatch({
                type: USER_ACTION_FAILURE,
                payload: { errors: extractErrorTags(e) },
            });
        }
    };
}

export function resetUserActionStatus() {
    return { type: USER_ACTION_RESET };
}

export function resendActivationCode(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        try {
            dispatch({ type: USER_ACTION_STARTED });
            await RestClient.post(
                `/v1/callcenter/user_details/${userId}/new_activation_code`
            );
            dispatch({ type: USER_ACTION_SUCCESS });
        } catch (e) {
            dispatch({
                type: USER_ACTION_FAILURE,
                payload: { errors: extractErrorTags(e) },
            });
        }
    };
}

export function sendPasswordResetEmail(userId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        try {
            dispatch({ type: USER_ACTION_STARTED });
            await RestClient.post(
                `/v1/callcenter/user_details/${userId}/forgot_password`
            );
            dispatch({ type: USER_ACTION_SUCCESS });
        } catch (e) {
            dispatch({
                type: USER_ACTION_FAILURE,
                payload: { errors: extractErrorTags(e) },
            });
        }
    };
}

export function updatePhoneUserEmail(userId: number, userEmail: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        try {
            dispatch({ type: USER_ACTION_STARTED });
            const patchData: Partial<UserExtendedDetailsPatchDto> = {
                email: userEmail,
            };
            await RestClient.patch(
                `/v1/callcenter/user_details/${userId}/email`,
                patchData
            );
            dispatch({ type: USER_ACTION_SUCCESS });
        } catch (e) {
            dispatch({
                type: USER_ACTION_FAILURE,
                payload: { errors: extractErrorTags(e) },
            });
        } finally {
            dispatch(searchUserById(userId));
        }
    };
}

export function changeInvoiceDelivery(
    userId: number,
    invoiceDeliveryMethod: InvoiceDeliveryMethod
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: CHANGE_INVOICE_DELIVERY });
        try {
            const patchData = {
                invoice_delivery_method: invoiceDeliveryMethod,
            };
            await RestClient.patch(
                `/v1/callcenter/user_details/${userId}/invoice_delivery`,
                patchData
            );
            dispatch({ type: CHANGE_INVOICE_DELIVERY_SUCCESS });
        } catch (error) {
            dispatch({
                type: CHANGE_INVOICE_DELIVERY_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
        await dispatch(searchUserById(userId));
    };
}
