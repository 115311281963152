import React, { FunctionComponent, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { RootStore } from '../../store/rootStore';
import { connect } from 'react-redux';
import { WeWashApiErrorTag } from '../../http/errors';
import { BlacklistIbanRequestState } from '../../store/blacklist-iban/blacklistIbanReducer';
import { blacklistIban } from '../../store/blacklist-iban/blacklistIbanActions';

interface Props {
    blacklistIban: (
        paymentId: number | null,
        reason: string,
        iban: string
    ) => any;
    requestState: BlacklistIbanRequestState;
    errors: WeWashApiErrorTag[];
}

const BlacklistIbanForm: FunctionComponent<Props> = (props) => {
    const [iban, setIban] = useState('');
    const [reason, setReason] = useState('');
    const [paymentId, setPaymentId] = useState(null);

    let requestStateDisplay = <span />;
    switch (props.requestState) {
        case BlacklistIbanRequestState.LOADING:
            requestStateDisplay = <div>...</div>;
            break;
        case BlacklistIbanRequestState.FAILURE:
            requestStateDisplay = (
                <div>
                    <strong>Blacklisting gescheitert</strong>, {props.errors}
                </div>
            );
            break;
        case BlacklistIbanRequestState.SUCCESS:
            requestStateDisplay = (
                <div>IBAN wurde erfolgreich geblacklisted</div>
            );
            break;
    }

    return (
        <div id="BlacklistIbanForm">
            <Form>
                <Form.Group controlId="iban">
                    <Form.Label>IBAN</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(event: any) => setIban(event.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="paymentId">
                    <Form.Label>Payment Method Id</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(event: any) =>
                            setPaymentId(event.target.value)
                        }
                    />
                </Form.Group>
                <Form.Group controlId="reason">
                    <Form.Label>Grund</Form.Label>
                    <Form.Control
                        type="text"
                        onChange={(event: any) => setReason(event.target.value)}
                    />
                </Form.Group>
                <Button
                    onClick={() => props.blacklistIban(paymentId, reason, iban)}
                >
                    Blacklist
                </Button>

                {requestStateDisplay}
            </Form>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        requestState: store.blacklistIban.requestState,
        errors: store.blacklistIban.errors,
    }),
    { blacklistIban }
)(BlacklistIbanForm);
