import React, { FunctionComponent } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { Formik, FormikActions, FormikProps } from 'formik';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import ErrorFeedback from '../ErrorFeedback';
import { clearCacheAndSearchUserById } from '../../store/search-user/searchUserActions';
import {
    SearchUserStore,
    UserExtendedInformation,
} from '../../store/search-user/searchUserReducer';
import UserInformationTable from '../../components/user-information-table/UserInformationTable';
import { loadUserDetailsAndGoToManage } from '../../store/manage-user/manageUserActions';
import InputGroup from 'react-bootstrap/InputGroup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import styles from './SearchUser.module.css';
import GreyLogo from '../../App/WeWash_LightGrey.png';
import Alert from 'react-bootstrap/Alert';
import useFavoriteUsers from './useFavoriteUsers';
import { Route } from '../../Router';
import { goTo } from '../../store/router/routerActions';

export interface SearchTerm {
    searchTerm: string;
}

interface Props {
    search: SearchUserStore;
    clearCacheAndSearchUserById: (searchTerm: number) => any;
    loadUserDetailsAndGoToManage: (userId: number) => any;
    goTo: (route: Route, param?: string | number) => void;
    isAdmin: boolean;
}

const SearchUserById: FunctionComponent<Props> = (props) => {
    const initialValues: SearchTerm = { searchTerm: '' };
    const { handleSelectUser } = useFavoriteUsers();

    async function handleFormSubmit(
        values: SearchTerm,
        actions: FormikActions<SearchTerm>
    ) {
        await props.clearCacheAndSearchUserById(Number(values.searchTerm));
        actions.setSubmitting(false);
    }

    function handleSelect(it: UserExtendedInformation) {
        handleSelectUser(it.user_id);
        props.goTo(Route.MANAGE_USER, it.user_id);
    }

    return (
        <div id="SearchUserId" className="p-1">
            <div>
                <Formik
                    initialValues={initialValues}
                    onSubmit={handleFormSubmit}
                >
                    {(formProps: FormikProps<SearchTerm>) => (
                        <Form noValidate onSubmit={formProps.handleSubmit}>
                            <ErrorFeedback apiErrors={props.search.errors} />

                            <Form.Group
                                controlId="formGroupSearch"
                                className={styles.searchUserIdBar}
                            >
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        name="searchTerm"
                                        className={styles.input}
                                        value={formProps.values.searchTerm}
                                        placeholder={'Benutzersuche mit Id'}
                                        onChange={formProps.handleChange}
                                    />
                                    <InputGroup.Append>
                                        <Button
                                            variant="outline-dark"
                                            type="submit"
                                            disabled={
                                                formProps.isSubmitting ||
                                                formProps.values.searchTerm
                                                    .length === 0
                                            }
                                        >
                                            <FontAwesomeIcon
                                                icon={faSearch}
                                                className={styles.icon}
                                            />
                                        </Button>
                                    </InputGroup.Append>
                                </InputGroup>
                            </Form.Group>

                            <div className="mt-5">
                                {props.search.resultsById.length > 0 && (
                                    <UserInformationTable
                                        users={props.search.resultsById}
                                        onSelect={handleSelect}
                                        isAdmin={props.isAdmin}
                                    />
                                )}
                                {props.search.resultsById.length === 0 &&
                                    formProps.submitCount > 0 && (
                                        <div>
                                            <img
                                                alt="WeWash Logo"
                                                src={GreyLogo}
                                                className={styles.logo}
                                            />
                                            <Alert
                                                variant="info"
                                                className={styles.text}
                                            >
                                                Deine Suche ergab keine
                                                Ergebnisse
                                            </Alert>
                                        </div>
                                    )}
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        search: store.searchUser,
        isAdmin: store.user.userIsAdmin,
    }),
    { clearCacheAndSearchUserById, loadUserDetailsAndGoToManage, goTo }
)(SearchUserById);
