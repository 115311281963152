import React, { FunctionComponent } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import PaymentFormStandalone from '../payment/PaymentFormStandalone';

interface Props {
    userId: number;
}

const AddPayment: FunctionComponent<Props> = (props) => {
    return (
        <div id="AddPayment">
            <Accordion>
                <Card>
                    <Card.Header>
                        <Accordion.Toggle
                            as={Button}
                            variant="link"
                            eventKey="0"
                        >
                            <FontAwesomeIcon icon={faPlusSquare} /> Neue
                            Zahlungsmethode hinzufügen
                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <PaymentFormStandalone userId={props.userId} />
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};

export default AddPayment;
