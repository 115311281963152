import React, { Fragment, FunctionComponent } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { PaymentMethod } from '../../store/manage-user/manageUserReducer';

interface Props {
    data: {
        paymentMethod: PaymentMethod;
    };
    close: () => any;
}

const FailedPaymentDeletionInformation: FunctionComponent<Props> = ({
    data,
    close,
}) => {
    let reason;

    if (data.paymentMethod.selected) {
        reason =
            'Die Zahlungsmethode kann nicht gelöscht werden, da sie die ausgewählte Zahlungsmethode ist.';
    } else {
        switch (data.paymentMethod.status) {
            case 'TRIAL':
            case 'UNVERIFIED':
                reason =
                    'Die Zahlungsmethode kann nicht gelöscht werden, da sie sich in Verifizierung befindet.';
                break;
            case 'INVALID':
                reason =
                    'Die Zahlungsmethode kann nicht gelöscht werden, da sie ungültig ist.';
                break;
            default:
                reason = 'Die Zahlungsmethode kann nicht gelöscht werden.';
                break;
        }
    }

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>
                    Zahlungsmethode kann nicht gelöscht werden
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>{reason}</Modal.Body>
            <Modal.Footer>
                <Button onClick={() => close()}>Ok.</Button>
            </Modal.Footer>
        </Fragment>
    );
};

export default connect(null, { close: closeModal })(
    FailedPaymentDeletionInformation
);
