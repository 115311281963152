import React, { FunctionComponent, useEffect, useState } from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import {
    Dropdown,
    DropdownButton,
    Form,
    Spinner,
    Table,
} from 'react-bootstrap';
import {
    LocationNameDto,
    ManageSerialnumbersStore,
    PartnerSerialnumberDto,
} from '../../store/manage-serialnumbers/manageSerialnumbersReducer';
import {
    assignGatewayToLocation,
    assignSerialnumbersToPartner,
    loadAssignableLocationsForPartner,
    loadSerialnumbersForPartner,
    setManagedPartner,
    unassignGatewayFromLocation,
    unassignSerialnumberFromPartner,
} from '../../store/manage-serialnumbers/manageSerialnumbersActions';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import ErrorFeedback from '../ErrorFeedback';
import SuccessFeedback from '../SuccessFeedback';
import { Route } from '../../Router';
import styles from './ManageSerialnumbersForm.module.css';
import { goTo } from '../../store/router/routerActions';
import { RootStore } from '../../store/rootStore';
import { connect } from 'react-redux';

interface Props {
    serialnumbersStore: ManageSerialnumbersStore;
    loadSerialnumbersForPartner: (externalId: string) => void;
    loadAssignableLocationsForPartner: (externalId: string) => void;
    assignSerialnumbersToPartner: (
        externalId: string,
        serialnumbers: string[]
    ) => Promise<any>;
    unassignSerialnumberFromPartner: (
        externalId: string,
        serialnumber: string
    ) => void;
    assignGatewayToLocation: (
        externalId: string,
        serialnumber: string,
        locationId: number
    ) => void;
    unassignGatewayFromLocation: (
        externalId: string,
        serialnumber: string
    ) => void;
    goTo: (route: Route) => void;
}

interface SerialnumbersList {
    serialnumbers: string;
}

const initialValues: SerialnumbersList = {
    serialnumbers: '',
};

const ManageSerialnumbersForm: FunctionComponent<Props> = (props) => {
    const { managedPartner, serialnumbers, loading, errors } =
        props.serialnumbersStore;
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        if (managedPartner && managedPartner.external_id) {
            props.loadSerialnumbersForPartner(managedPartner.external_id);
            props.loadAssignableLocationsForPartner(managedPartner.external_id);
        }
    }, [managedPartner]);

    if (!managedPartner || loading) {
        return <Spinner animation="border" />;
    }

    return (
        <div id="ManageSerialnumbersForm">
            <Row className={styles.back}>
                <Button onClick={() => props.goTo(Route.MANAGE_PARTNER)}>
                    Zurück
                </Button>
            </Row>
            <Row>
                <Table bordered>
                    <thead>
                        <tr>
                            <th>Seriennummer</th>
                            <th>Hub</th>
                            <th>Maschine zugewiesen</th>
                            <th>Waschraum Gateway</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {serialnumbers.map((it) => (
                            <tr key={it.serial_number}>
                                <td>{it.serial_number}</td>
                                <td>{it.hub ? 'Ja' : 'Nein'}</td>
                                <td>{it.has_appliance ? 'Ja' : 'Nein'}</td>
                                <td>{renderGatewayAssignment(it)}</td>
                                <td>
                                    {renderUnassignment(it)}{' '}
                                    {maybeRenderGatewayUnassign(it)}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
                <div>
                    <Formik
                        onSubmit={handleInputSubmit}
                        initialValues={initialValues}
                    >
                        {(formProps: FormikProps<SerialnumbersList>) => {
                            return (
                                <Form
                                    noValidate
                                    onSubmit={formProps.handleSubmit}
                                >
                                    <Form.Group controlId="serialnumbers">
                                        <Form.Label>Seriennummern</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows="10"
                                            onChange={formProps.handleChange}
                                            value={
                                                formProps.values.serialnumbers
                                            }
                                        />
                                    </Form.Group>
                                    {errors.length < 1 && success && (
                                        <SuccessFeedback message="Zuweisung erfolgreich" />
                                    )}
                                    <ErrorFeedback apiErrors={errors} />
                                    <Button
                                        type="submit"
                                        disabled={formProps.isSubmitting}
                                    >
                                        Zuweisen
                                    </Button>
                                </Form>
                            );
                        }}
                    </Formik>
                </div>
            </Row>
        </div>
    );

    function renderGatewayAssignment(serialnumber: PartnerSerialnumberDto) {
        if (serialnumber.location_id) {
            return <span>{serialnumber.location_id}</span>;
        } else if (serialnumber.has_appliance || !serialnumber.hub) {
            return;
        }

        const { assignableLocations } = props.serialnumbersStore;
        return (
            <DropdownButton
                size="sm"
                id="dropdown-item-button"
                title="Als Gateway zuweisen"
            >
                {assignableLocations.map((it) => (
                    <Dropdown.Item
                        key={it.id}
                        as="button"
                        onClick={() =>
                            handleAssignGateway(serialnumber.serial_number, it)
                        }
                    >
                        {it.id} | {it.name}
                    </Dropdown.Item>
                ))}
            </DropdownButton>
        );
    }

    function handleAssignGateway(
        serialnumber: string,
        location: LocationNameDto
    ) {
        if (!managedPartner) {
            return;
        }

        const confirmationMessage = `Hub mit der Seriennummer ${serialnumber} als Gateway dem Waschraum "${location.name}" zuweisen?`;

        if (window.confirm(confirmationMessage)) {
            props.assignGatewayToLocation(
                managedPartner.external_id,
                serialnumber,
                location.id
            );
        }
    }

    function maybeRenderGatewayUnassign(serialnumber: PartnerSerialnumberDto) {
        if (
            !serialnumber.hub ||
            serialnumber.has_appliance ||
            !serialnumber.location_id
        ) {
            return;
        }

        return (
            <Button
                variant="outline-danger"
                size="sm"
                onClick={() =>
                    handleUnassignGateway(serialnumber.serial_number)
                }
            >
                Gateway trennen
            </Button>
        );
    }

    function handleUnassignGateway(serialnumber: string) {
        if (!managedPartner) {
            return;
        }

        const confirmationMessage = `Gateway trennen? Hub mit der Seriennummer ${serialnumber} nicht mehr als Gateway verwenden?`;
        if (window.confirm(confirmationMessage)) {
            props.unassignGatewayFromLocation(
                managedPartner.external_id,
                serialnumber
            );
        }
    }

    function renderUnassignment(serialnumber: PartnerSerialnumberDto) {
        if (serialnumber.has_appliance || serialnumber.location_id) {
            return;
        }

        return (
            <Button
                variant="outline-danger"
                size="sm"
                onClick={() => handleUnassign(serialnumber.serial_number)}
            >
                Seriennummer entfernen
            </Button>
        );
    }

    function handleUnassign(serialnumber: string) {
        if (!managedPartner) {
            return;
        }

        setSuccess(false);

        const confirmationMessage = `Zuweisung dieser Seriennummer aufheben?\n\n${serialnumber}`;

        if (window.confirm(confirmationMessage)) {
            props.unassignSerialnumberFromPartner(
                managedPartner.external_id,
                serialnumber
            );
        }
    }

    function handleInputSubmit(
        values: SerialnumbersList,
        actions: FormikActions<SerialnumbersList>
    ) {
        if (!managedPartner) {
            return;
        }

        setSuccess(false);

        const splitNumbers: string[] = values.serialnumbers
            .trim()
            .split(/[\s,]+/)
            .filter((it) => it);

        if (new Set(splitNumbers).size !== splitNumbers.length) {
            window.alert('Die Eingabe enthält Duplikate. Bitte prüfen.');
        } else {
            const confirmationMessage = `Diese ${
                splitNumbers.length
            } Seriennummern zuweisen?\n\n${splitNumbers.join('\n')}`;

            if (window.confirm(confirmationMessage)) {
                props
                    .assignSerialnumbersToPartner(
                        managedPartner.external_id,
                        splitNumbers
                    )
                    .then(() => setSuccess(true));
            }
        }

        actions.setSubmitting(false);
    }
};

export default connect(
    (store: RootStore) => ({
        serialnumbersStore: store.manageSerialnumbers,
    }),
    {
        loadSerialnumbersForPartner,
        loadAssignableLocationsForPartner,
        assignGatewayToLocation,
        unassignGatewayFromLocation,
        setManagedPartner,
        assignSerialnumbersToPartner,
        unassignSerialnumberFromPartner,
        goTo,
    }
)(ManageSerialnumbersForm);
