import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import {
    LaundryRoomForVipUsers,
    updateLaundryRoomVipCosts,
    UpdateVipCostsForLaundryRoomDto,
} from '../../store/manage-vip-users/manageVipUsersActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';
import { CommunicationState } from '../../store/communicationState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SuccessFeedback from '../../forms/SuccessFeedback';

interface Props {
    laundryRoom: LaundryRoomForVipUsers;
    updateStatus: CommunicationState;
    errors: WeWashApiErrorTag[];

    updateLaundryRoomVipCosts: (
        laundryRoomId: number,
        vipPrices: UpdateVipCostsForLaundryRoomDto
    ) => void;
}

interface VipPricesValues {
    drying: string;
    washing: string;
}

function isNullOrUndefined(value?: number | null): boolean {
    return value === null || value === undefined;
}

function VipPricingForm(props: Props) {
    const [laundryRoomId, setLaundryRoomId] = useState(props.laundryRoom.id);
    useEffect(() => {
        setLaundryRoomId(props.laundryRoom.id);
    }, [props.laundryRoom.id]);

    const initialValues: VipPricesValues = {
        drying: String(
            isNullOrUndefined(props.laundryRoom.costs.amount_dryer_vip)
                ? ''
                : props.laundryRoom.costs.amount_dryer_vip
        ),
        washing: String(
            isNullOrUndefined(props.laundryRoom.costs.amount_washer_vip)
                ? ''
                : props.laundryRoom.costs.amount_washer_vip
        ),
    };

    return (
        <Row>
            <Col xs={6}>
                <Formik
                    enableReinitialize={laundryRoomId !== props.laundryRoom.id}
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Col>
        </Row>
    );

    function renderForm(formProps: FormikProps<VipPricesValues>) {
        const unchangedValues = isUnchanged(formProps.values);
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'formGroupWashing'}>
                    <Form.Label>
                        Preis Waschen (in{' '}
                        {props.laundryRoom.costs.currency_code})
                    </Form.Label>
                    <Form.Control
                        type={'text'}
                        name={'washing'}
                        value={formProps.values.washing}
                        onChange={formProps.handleChange}
                    />
                </Form.Group>

                <Form.Group controlId={'formGroupDrying'}>
                    <Form.Label>
                        Preis Trocknen (in{' '}
                        {props.laundryRoom.costs.currency_code})
                    </Form.Label>
                    <Form.Control
                        type={'text'}
                        name={'drying'}
                        value={formProps.values.drying}
                        onChange={formProps.handleChange}
                    />
                </Form.Group>

                <Button
                    variant={unchangedValues ? 'outline-dark' : 'primary'}
                    type={'submit'}
                    disabled={
                        formProps.isSubmitting ||
                        unchangedValues ||
                        CommunicationState.LOADING === props.updateStatus
                    }
                >
                    {unchangedValues ? 'Keine Änderung' : 'VIP Preise setzen'}
                </Button>
                {props.updateStatus === CommunicationState.SUCCESS && (
                    <SuccessFeedback message={'Preise aktualisiert.'} />
                )}

                <ErrorFeedback apiErrors={props.errors} />
            </Form>
        );
    }

    function isUnchanged(values: VipPricesValues) {
        const dryingEqual =
            parseAsPrice(values.drying) ===
            props.laundryRoom.costs.amount_dryer_vip;
        const washingEqual =
            parseAsPrice(values.washing) ===
            props.laundryRoom.costs.amount_washer_vip;

        return dryingEqual && washingEqual;
    }

    function handleSubmit(
        values: VipPricesValues,
        actions: FormikActions<VipPricesValues>
    ) {
        const updatePrices: UpdateVipCostsForLaundryRoomDto = {
            cost_dryer_vip: parseAsPrice(values.drying),
            cost_washing_machine_vip: parseAsPrice(values.washing),
        };
        props.updateLaundryRoomVipCosts(props.laundryRoom.id, updatePrices);
        actions.setSubmitting(false);
    }
}

function parseAsPrice(str: string): number {
    // WARNING: this ignores the possibility of dot used as thousands separator - i.e. when 1.453 means 1453
    return parseFloat(str.trim().replace(',', '.'));
}

export default connect(
    (store: RootStore) => ({
        updateStatus: store.manageVipUsers.updateLaundryRoomVipCostStatus,
        errors: store.manageVipUsers.updateErrors,
    }),
    { updateLaundryRoomVipCosts }
)(VipPricingForm);
