import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { closeModal, ModalParameters } from '../../store/modal/modalActions';
import Modal from 'react-bootstrap/Modal';
import SelectLaundryRoomFromList from '../manage-vip-users/SelectLaundryRoomFromList';
import Button from 'react-bootstrap/Button';
import { ModalType } from './RootModal';

export interface SelectLaundryRoomModalParams {
    laundryRoomId: number;
}

interface Props {
    params?: SelectLaundryRoomModalParams;

    close: (params?: ModalParameters<SelectLaundryRoomModalParams>) => void;
}

function SelectLaundryRoomModal(props: Props) {
    const [laundryRoomId, setLaundryRoomId] = useState<number | undefined>(
        props.params && props.params.laundryRoomId
    );

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Waschraum wählen</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SelectLaundryRoomFromList
                    selectedLaundryRoomId={
                        props.params && props.params.laundryRoomId
                    }
                    onChange={handleChange}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleCancel} variant={'outline-primary'}>
                    ABBRECHEN
                </Button>
                <Button onClick={handleSubmit} disabled={!laundryRoomId}>
                    WASCHRAUM WÄHLEN
                </Button>
            </Modal.Footer>
        </Fragment>
    );

    function handleChange(laundryRoomId: number) {
        setLaundryRoomId(laundryRoomId);
    }

    function handleCancel() {
        props.close();
    }

    function handleSubmit() {
        if (!laundryRoomId) {
            return;
        }
        props.close({
            type: ModalType.SELECT_LAUNDRY_ROOM,
            data: { laundryRoomId },
        });
    }
}

export default connect(
    (state: RootStore) => ({
        params: state.modal.modal.data as SelectLaundryRoomModalParams,
    }),
    { close: closeModal }
)(SelectLaundryRoomModal);
