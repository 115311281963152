import { createReducer } from 'redux-starter-kit';
import {
    SET_FEEDBACK_QUESTION,
    SET_FEEDBACK_QUESTION_FAILURE,
    SET_FEEDBACK_QUESTION_SUCCESS,
} from './locationFeedbackModeActions';
import { WeWashApiErrorTag } from '../../http/errors';

export interface LocationFeedbackStore {
    loading: boolean;
    errors: WeWashApiErrorTag[];
}

const initialStore: LocationFeedbackStore = {
    loading: false,
    errors: [],
};

export const locationFeedbackModeReducer = createReducer(initialStore, {
    [SET_FEEDBACK_QUESTION]: (state: LocationFeedbackStore) => {
        state.loading = false;
        state.errors = [];
    },
    [SET_FEEDBACK_QUESTION_SUCCESS]: (state: LocationFeedbackStore, action) => {
        state.loading = false;
        state.errors = [];
    },
    [SET_FEEDBACK_QUESTION_FAILURE]: (state: LocationFeedbackStore, action) => {
        state.loading = false;
        state.errors = action.payload.errors;
    },
});
