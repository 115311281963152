import { ReactText } from 'react';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootStore } from '../rootStore';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import RestClient from '../../http/RestClient';
import {
    DownloadCallback,
    parseContentDispositionForFilename,
} from '../../util/downloadFileAsBlob';

export const GET_PARTNER_CREDIT_NOTES = 'GET_PARTNER_CREDIT_NOTES';
export const GET_PARTNER_CREDIT_NOTES_SUCCESS =
    'GET_PARTNER_CREDIT_NOTES_SUCCESS';
export const GET_PARTNER_CREDIT_NOTES_FAILURE =
    'GET_PARTNER_CREDIT_NOTES_FAILURE';
export const SELECT_PARTNER_CREDIT_NOTE = 'SELECT_PARTNER_CREDIT_NOTE';
export const UPLOAD_PARTNER_CREDIT_NOTE = 'UPLOAD_PARTNER_CREDIT_NOTE';
export const UPLOAD_PARTNER_CREDIT_NOTE_SUCCESS =
    'UPLOAD_PARTNER_CREDIT_NOTE_SUCCESS';
export const UPLOAD_PARTNER_CREDIT_NOTE_FAILURE =
    'UPLOAD_PARTNER_CREDIT_NOTE_FAILURE';
export const FILE_PARTNER_CREDIT_NOTE = 'FILE_PARTNER_CREDIT_NOTE';
export const FILE_PARTNER_CREDIT_NOTE_SUCCESS =
    'FILE_PARTNER_CREDIT_NOTE_SUCCESS';
export const FILE_PARTNER_CREDIT_NOTE_FAILURE =
    'FILE_PARTNER_CREDIT_NOTE_FAILURE';
export const CORRECT_PARTNER_CREDIT_NOTE = 'CORRECT_PARTNER_CREDIT_NOTE';
export const CORRECT_PARTNER_CREDIT_NOTE_RESET =
    'CORRECT_PARTNER_CREDIT_NOTE_RESET';
export const CORRECT_PARTNER_CREDIT_NOTE_SUCCESS =
    'CORRECT_PARTNER_CREDIT_NOTE_SUCCESS';
export const CORRECT_PARTNER_CREDIT_NOTE_FAILURE =
    'CORRECT_PARTNER_CREDIT_NOTE_FAILURE';

export interface CreditNoteData {
    credit_note_id: number;
    user_id: string;
    user_customer_id: number;
    credit_note_number?: string;
    month: number;
    year: number;
    country: string;
    countryId: number;
    total_gross: number;
    partner_gross: number;
    calculated_partner_gross: number;
    accounting_status: string;
    email_status: string;
    xml_status: string;
    pdf_status: string;
    download_token?: string;
}

export interface CreditNoteList {
    credit_notes: CreditNoteData[];
}

export interface CreditNoteForUpload {
    user_id: string;
    credit_note_number: string;
    reset_email_status: boolean;
    file?: Blob;
    partnerGross: string;
    upload: boolean;
}

export enum CorrectionMode {
    NONE = 'none',
    UPLOAD = 'upload',
    REGENERATE = 'regenerate',
}

export interface CreditNoteCorrection {
    userId: string;
    creditNoteId: number;
    creditNoteNumber: string;
    partnerGross: string;
    resetEmailStatus: boolean;
    pdfMode: CorrectionMode;
    pdfFile?: Blob;
    xmlMode: CorrectionMode;
    xmlFile?: Blob;
}

export function getPartnerCreditNotesFor(accountingAreaUserId: ReactText) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: GET_PARTNER_CREDIT_NOTES });
        try {
            const { data } = await RestClient.get<CreditNoteList>(
                `/v1/callcenter/turnover/${accountingAreaUserId}`
            );
            dispatch({
                type: GET_PARTNER_CREDIT_NOTES_SUCCESS,
                payload: { data: data.credit_notes },
            });
        } catch (e) {
            dispatch({
                type: GET_PARTNER_CREDIT_NOTES_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
    };
}

export function selectPartnerCreditNote(creditNoteId: number) {
    return { type: SELECT_PARTNER_CREDIT_NOTE, payload: { creditNoteId } };
}

export function uploadCreditNote(creditNote: CreditNoteForUpload) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: UPLOAD_PARTNER_CREDIT_NOTE });
        try {
            const formData = new FormData();
            formData.append('creditNoteNumber', creditNote.credit_note_number);
            formData.append('userId', creditNote.user_id);
            formData.append(
                'resetEmailStatus',
                String(creditNote.reset_email_status)
            );
            formData.append(
                'mode',
                creditNote.upload
                    ? CorrectionMode.UPLOAD
                    : CorrectionMode.REGENERATE
            );
            if (creditNote.upload && creditNote.file) {
                formData.append('file', creditNote.file);
            }
            formData.append('partnerGross', creditNote.partnerGross);

            await RestClient.post(`/v1/callcenter/turnover`, formData);

            dispatch({ type: UPLOAD_PARTNER_CREDIT_NOTE_SUCCESS });
        } catch (e) {
            dispatch({
                type: UPLOAD_PARTNER_CREDIT_NOTE_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }

        dispatch(getPartnerCreditNotesFor(creditNote.user_id));
    };
}

export function downloadCreditNoteDatev(
    creditNoteNumber: string,
    downloadCallback: DownloadCallback
) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        try {
            const response = await RestClient.get(
                `/v1/callcenter/credit_note/${creditNoteNumber}/datev_zip`,
                { responseType: 'blob' }
            );
            const filename =
                parseContentDispositionForFilename(
                    response.headers['content-disposition']
                ) || `${creditNoteNumber}.zip`;
            downloadCallback(filename, response.data);
        } catch (e) {
            console.error(
                `Failed to download creditNoteNumber/${creditNoteNumber}`,
                e
            );
        }
    };
}

interface FileCreditNoteRequestBody {
    partner_gross: string;
}

export interface FileInternalCreditNoteRequestValues {
    credit_note_Id: number;
    partner_gross: string;
    user_id: string;
}

export function fileInternalCreditNote(
    fileInternalCreditNoteRequestValues: FileInternalCreditNoteRequestValues
) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: FILE_PARTNER_CREDIT_NOTE });

        const body: FileCreditNoteRequestBody = {
            partner_gross: fileInternalCreditNoteRequestValues.partner_gross,
        };

        try {
            await RestClient.post(
                `/v1/callcenter/credit_note/${fileInternalCreditNoteRequestValues.credit_note_Id}`,
                body
            );

            dispatch({ type: FILE_PARTNER_CREDIT_NOTE_SUCCESS });
        } catch (e) {
            dispatch({
                type: FILE_PARTNER_CREDIT_NOTE_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
        dispatch(
            getPartnerCreditNotesFor(
                fileInternalCreditNoteRequestValues.user_id
            )
        );
    };
}

export function resetCorrectCreditNote() {
    return { type: CORRECT_PARTNER_CREDIT_NOTE_RESET };
}

export function correctCreditNote(correction: CreditNoteCorrection) {
    return async (dispatch: ThunkDispatch<RootStore, void, AnyAction>) => {
        dispatch({ type: CORRECT_PARTNER_CREDIT_NOTE });
        try {
            const formData = new FormData();
            formData.append('creditNoteNumber', correction.creditNoteNumber);
            formData.append('partnerGross', correction.partnerGross);
            formData.append(
                'resetEmailStatus',
                String(correction.resetEmailStatus)
            );
            formData.append('pdfMode', correction.pdfMode);
            if (
                correction.pdfMode === CorrectionMode.UPLOAD &&
                correction.pdfFile
            ) {
                formData.append('pdfFile', correction.pdfFile);
            }
            formData.append('xmlMode', correction.xmlMode);
            if (
                correction.xmlMode === CorrectionMode.UPLOAD &&
                correction.xmlFile
            ) {
                formData.append('xmlFile', correction.xmlFile);
            }
            await RestClient.post(
                `/v1/callcenter/credit_note/correct`,
                formData
            );
            dispatch({ type: CORRECT_PARTNER_CREDIT_NOTE_SUCCESS });
        } catch (e) {
            dispatch({
                type: CORRECT_PARTNER_CREDIT_NOTE_FAILURE,
                payload: { errors: extractErrorTags(e as WeWashApiError) },
            });
        }
        dispatch(getPartnerCreditNotesFor(correction.userId));
    };
}
