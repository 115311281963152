import {
    CORRECT_PARTNER_CREDIT_NOTE,
    CORRECT_PARTNER_CREDIT_NOTE_FAILURE,
    CORRECT_PARTNER_CREDIT_NOTE_RESET,
    CORRECT_PARTNER_CREDIT_NOTE_SUCCESS,
    CreditNoteData,
    FILE_PARTNER_CREDIT_NOTE_FAILURE,
    FILE_PARTNER_CREDIT_NOTE_SUCCESS,
    GET_PARTNER_CREDIT_NOTES,
    GET_PARTNER_CREDIT_NOTES_FAILURE,
    GET_PARTNER_CREDIT_NOTES_SUCCESS,
    SELECT_PARTNER_CREDIT_NOTE,
    UPLOAD_PARTNER_CREDIT_NOTE,
    UPLOAD_PARTNER_CREDIT_NOTE_FAILURE,
    UPLOAD_PARTNER_CREDIT_NOTE_SUCCESS,
} from './manageCreditNotesActions';
import { WeWashApiErrorTag } from '../../http/errors';
import { createReducer } from 'redux-starter-kit';
import { RESET_VIEWS } from '../genericActions';
import { CommunicationState } from '../communicationState';

export interface ManageCreditNotesStore {
    loading: boolean;
    creditNotes: CreditNoteData[];
    errors: WeWashApiErrorTag[];
    selectedCreditNoteId?: number;
    uploading: boolean;
    uploadSuccess: boolean;
    fileInternalCreditNoteSuccess: boolean;
    correctCreditNoteStatus: CommunicationState;
}

const initialStore: ManageCreditNotesStore = {
    loading: false,
    creditNotes: [],
    errors: [],
    uploading: false,
    uploadSuccess: false,
    fileInternalCreditNoteSuccess: false,
    correctCreditNoteStatus: CommunicationState.INITIAL,
};

export const manageCreditNotesReducer = createReducer(initialStore, {
    [RESET_VIEWS]: (state: ManageCreditNotesStore) => {
        state.loading = false;
        state.uploading = false;
        state.uploadSuccess = false;
        state.fileInternalCreditNoteSuccess = false;
        state.creditNotes = [];
        state.errors = [];
        state.selectedCreditNoteId = undefined;
        state.correctCreditNoteStatus = CommunicationState.INITIAL;
    },
    [GET_PARTNER_CREDIT_NOTES]: (state: ManageCreditNotesStore) => {
        state.loading = true;
        state.errors = [];
    },
    [GET_PARTNER_CREDIT_NOTES_SUCCESS]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.loading = false;
        state.errors = [];
        state.creditNotes = action.payload.data;
    },
    [GET_PARTNER_CREDIT_NOTES_FAILURE]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.loading = false;
        state.creditNotes = [];
        state.errors = action.payload.errors;
    },
    [SELECT_PARTNER_CREDIT_NOTE]: (state: ManageCreditNotesStore, action) => {
        state.selectedCreditNoteId = action.payload.creditNoteId;
        state.uploadSuccess = false;
        state.fileInternalCreditNoteSuccess = false;
    },
    [UPLOAD_PARTNER_CREDIT_NOTE]: (state: ManageCreditNotesStore) => {
        state.uploading = true;
        state.uploadSuccess = false;
        state.errors = [];
    },
    [UPLOAD_PARTNER_CREDIT_NOTE_SUCCESS]: (state: ManageCreditNotesStore) => {
        state.uploading = false;
        state.uploadSuccess = true;
    },
    [UPLOAD_PARTNER_CREDIT_NOTE_FAILURE]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.uploading = false;
        state.uploadSuccess = false;
        state.errors = action.payload.errors;
    },
    [FILE_PARTNER_CREDIT_NOTE_FAILURE]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.fileInternalCreditNoteSuccess = false;
        state.errors = action.payload.errors;
    },
    [FILE_PARTNER_CREDIT_NOTE_SUCCESS]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.fileInternalCreditNoteSuccess = true;
    },
    [CORRECT_PARTNER_CREDIT_NOTE_RESET]: (state: ManageCreditNotesStore) => {
        state.correctCreditNoteStatus = CommunicationState.INITIAL;
    },
    [CORRECT_PARTNER_CREDIT_NOTE]: (state: ManageCreditNotesStore) => {
        state.correctCreditNoteStatus = CommunicationState.LOADING;
    },
    [CORRECT_PARTNER_CREDIT_NOTE_SUCCESS]: (state: ManageCreditNotesStore) => {
        state.correctCreditNoteStatus = CommunicationState.SUCCESS;
    },
    [CORRECT_PARTNER_CREDIT_NOTE_FAILURE]: (
        state: ManageCreditNotesStore,
        action
    ) => {
        state.correctCreditNoteStatus = CommunicationState.FAILURE;
        state.errors = action.payload.errors;
    },
});
