import Button from 'react-bootstrap/Button';
import {
    FontAwesomeIcon,
    Props as FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import React, { MouseEventHandler } from 'react';

interface Props extends FontAwesomeIconProps {
    onClick: MouseEventHandler<HTMLButtonElement>;
}

function FontAwesomeButton({ onClick, ...props }: Props) {
    return (
        <Button onClick={onClick} variant={'outline-light'}>
            <FontAwesomeIcon {...props} />
        </Button>
    );
}

export default FontAwesomeButton;
