import { ModalType } from '../../components/modal/RootModal';

export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const CLEAR_MODAL_RESPONSE = 'CLEAR_MODAL_RESPONSE';

export interface ModalParameters<T> {
    type: ModalType;
    data?: T;
}

export function openModal(params: ModalParameters<any>) {
    return { type: OPEN_MODAL, payload: { params } };
}

export function closeModal(params?: ModalParameters<any>) {
    return { type: CLOSE_MODAL, payload: { params } };
}

export function clearModalResponse() {
    return { type: CLEAR_MODAL_RESPONSE };
}
