import React, { Fragment, useEffect, useState } from 'react';
import { UserExtendedInformation } from '../../store/search-user/searchUserReducer';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikProps } from 'formik';
import { changeAllowPlainSepaMandateCreation } from '../../store/manage-user/manageUserActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    data: {
        userId: number;
    };

    searchResultsById: UserExtendedInformation[];
    errorTags: WeWashApiErrorTag[];
    isPatching: boolean;
    changeAllowPlainSepaMandateCreation: (
        userId: number,
        allowPlainSepaMandateCreation: boolean
    ) => void;
    close: () => void;
}

interface Data {
    userId: number;
    sepaMandateCreation: SepaMandateCreation;
}

enum SepaMandateCreation {
    CHECKOUT_ONLY = 'CHECKOUT_ONLY',
    CHECKOUT_OR_DIRECT_ENTRY = 'CHECKOUT_OR_DIRECT_ENTRY',
}

function sepaMandateCreationFromBoolean(value: boolean): SepaMandateCreation {
    return value
        ? SepaMandateCreation.CHECKOUT_OR_DIRECT_ENTRY
        : SepaMandateCreation.CHECKOUT_ONLY;
}

function sepaMandateCreationToBoolean(value: SepaMandateCreation): boolean {
    return value === SepaMandateCreation.CHECKOUT_OR_DIRECT_ENTRY;
}

const TRANSLATIONS_FOR_SEPA_MANDATE_CREATION: {
    [key in SepaMandateCreation]: string;
} = {
    [SepaMandateCreation.CHECKOUT_ONLY]: 'Nur via Mollie Checkout',
    [SepaMandateCreation.CHECKOUT_OR_DIRECT_ENTRY]:
        'Via Mollie Checkout oder direkte Eingabe',
};

export function translateAllowPlainSepaMandateCreation(value: boolean): string {
    if (value == null) {
        return 'Daten konnten nicht geladen werden';
    }
    const sepaMandateCreation = sepaMandateCreationFromBoolean(value);
    return TRANSLATIONS_FOR_SEPA_MANDATE_CREATION[sepaMandateCreation];
}

function ChangeAllowPlainSepaMandateCreation(props: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const information =
        props.searchResultsById
            .filter((it) => it.user_id === props.data.userId)
            .reduce((info) => info) || {};

    const initialValues: Data = {
        userId: props.data.userId,
        sepaMandateCreation: sepaMandateCreationFromBoolean(
            information.allow_plain_sepa_mandate_creation
        ),
    };

    useEffect(() => {
        if (patchTriggered && !props.isPatching) {
            if (props.errorTags.length > 0) {
                setPatchTriggered(false);
            } else {
                props.close();
            }
        }
    }, [patchTriggered, props.isPatching, props.errorTags, props.close]);

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>SEPA Mandat Erstellung ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<Data>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className={'ml-3 mb-3'}>
                    Den Erstellungsprozess für das SEPA Mandat für Nutzer{' '}
                    <strong>{information.email}</strong> (#
                    {props.data.userId}) anpassen.
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Form.Group controlId={'sepaMandateCreation'}>
                        <Form.Label>SEPA Mandaterstellung:</Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={formProps.handleChange}
                            value={formProps.values.sepaMandateCreation}
                        >
                            {Object.values(SepaMandateCreation).map((value) => (
                                <option key={value} value={value}>
                                    {
                                        TRANSLATIONS_FOR_SEPA_MANDATE_CREATION[
                                            value
                                        ]
                                    }
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Button
                        className={'mr-2'}
                        type="button"
                        onClick={handleCancel}
                        variant={'outline-secondary'}
                    >
                        ABBRECHEN
                    </Button>
                    <Button type={'submit'} disabled={formProps.isSubmitting}>
                        SEPA MANDAT ERSTELLUNG ÄNDERN
                    </Button>
                </Row>
                <ErrorFeedback apiErrors={props.errorTags} />
            </Form>
        );
    }

    async function handleSubmit(values: Data) {
        const appliedValue: boolean = sepaMandateCreationToBoolean(
            values.sepaMandateCreation
        );
        props.changeAllowPlainSepaMandateCreation(values.userId, appliedValue);
        setPatchTriggered(true);
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({
        searchResultsById: store.searchUser.resultsById,
        errorTags: store.manageUser.patchErrors,
        isPatching: store.manageUser.patching,
    }),
    {
        close: closeModal,
        changeAllowPlainSepaMandateCreation:
            changeAllowPlainSepaMandateCreation,
    }
)(ChangeAllowPlainSepaMandateCreation);
