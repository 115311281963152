import React, { FunctionComponent, useEffect, useState } from 'react';
import { Button, Row } from 'react-bootstrap';
import classnames from 'classnames';
import styles from './UserPaysDebt.module.css';
import genericStyles from '../../App/Generic.module.css';
import { Route } from '../../Router';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { goTo } from '../../store/router/routerActions';
import { loadInvoicesOfUserById } from '../../store/manage-user/manageInvoicesOfUserActions';
import { UserExtendedInformation } from '../../store/search-user/searchUserReducer';
import { useParams } from 'react-router-dom';
import { loadManageUserById } from '../../store/manage-user/manageUserActions';
import { ManageInvoicesOfUserStore } from '../../store/manage-user/manageInvoicesOfUserReducer';
import UserInvoiceList from './UserInvoiceList';
import { RefreshButton } from '../common/RefreshButton';

const LIMITS = [25, 50, 100];

interface Props {
    searchResultsById: UserExtendedInformation[];
    manageInvoices: ManageInvoicesOfUserStore;
}

interface PropsWithFunctions extends Props {
    goTo: (route: Route, param?: string | number) => void;
    loadInvoicesOfUserById: (userId: number, limit?: number) => void;
    loadUserById: (userId: number) => void;
}

interface Params {
    userId?: string;
}

const ManageInvoicesOfUser: FunctionComponent<PropsWithFunctions> = ({
    goTo,
    loadUserById,
    loadInvoicesOfUserById,
    ...props
}) => {
    const params = useParams<Params>();
    const userId = Number(params.userId);
    const information = props.searchResultsById.find(
        (it) => it.user_id === userId
    );
    const [limit, setLimit] = useState<number>(LIMITS[0]);
    useEffect(() => {
        if (userId && !information) {
            loadUserById(userId);
        }
    }, [userId, information, loadUserById]);
    useEffect(() => {
        if (userId) {
            loadInvoicesOfUserById(userId, limit);
        }
    }, [userId, limit, loadInvoicesOfUserById]);

    const invoices = props.manageInvoices.invoices;

    if (!information || invoices.length === 0) {
        return renderEmpty();
    }

    return (
        <Row
            className={classnames(
                'flex-column justify-content-between',
                styles.container
            )}
        >
            <Row>
                <Button onClick={() => goBack()} variant={'outline-primary'}>
                    Zurück
                </Button>
                <RefreshButton
                    onRefresh={() => loadInvoicesOfUserById(userId, limit)}
                ></RefreshButton>
            </Row>
            <Row className={styles.spacer}>
                <h3 className={genericStyles.wewashHeading}>
                    Letzte Rechnungen
                </h3>
            </Row>
            <Row className={styles.spacer}>
                <strong>Nutzer:</strong>{' '}
                <span className={styles.value}>{information.name}</span>
            </Row>
            <Row>
                <strong>E-Mail:</strong>{' '}
                <span className={styles.value}>{information.email}</span>
            </Row>
            <Row className={styles.spacer}>
                <p>Anzeige {LIMITS.map(renderLimitOption)}</p>
                <UserInvoiceList invoices={invoices} loading={false} />
            </Row>
            <Row className={styles.spacer}>
                <Button onClick={() => goBack()} variant={'outline-primary'}>
                    Zurück
                </Button>
            </Row>
        </Row>
    );

    function renderLimitOption(n: number, index: number) {
        const prefix = index === 0 ? '' : ' - ';
        if (limit === n) {
            return (
                <span>
                    {prefix}
                    <strong>{n}</strong>
                </span>
            );
        } else {
            return (
                <span>
                    {prefix}
                    <span
                        style={{
                            opacity: 0.8,
                            cursor: 'pointer',
                            textDecoration: 'underline',
                        }}
                        onClick={() => setLimit(n)}
                    >
                        {n}
                    </span>
                </span>
            );
        }
    }

    function renderEmpty() {
        return (
            <Row
                className={classnames(
                    'flex-column justify-content-between',
                    styles.container
                )}
            >
                <Row className={styles.spacer}>
                    <Button
                        onClick={() => goBack()}
                        variant={'outline-primary'}
                    >
                        Zurück
                    </Button>
                </Row>
                <Row className={styles.spacer}>
                    <Button
                        onClick={() => goTo(Route.SEARCH_USER)}
                        variant={'outline-primary'}
                    >
                        Nutzer suchen
                    </Button>
                </Row>
            </Row>
        );
    }

    function goBack() {
        if (userId) {
            goTo(Route.MANAGE_USER, userId);
        } else {
            goTo(Route.MANAGE_USER);
        }
    }
};

export default connect(
    (store: RootStore) => ({
        searchResultsById: store.searchUser.resultsById,
        manageInvoices: store.manageInvoicesOfUser,
    }),
    { goTo, loadInvoicesOfUserById, loadUserById: loadManageUserById }
)(ManageInvoicesOfUser);
