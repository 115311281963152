import { InvoiceDeliveryMethod } from '../store/search-user/searchUserReducer';

export interface PhoneUserRegistrationData {
    smartSearchValue: string;
    agbAccepted: boolean;
    userInfoAvailable: boolean;
    language: Language;
    salutation: Salutation;
    firstName: string;
    lastName: string;
    phoneCountryCode: string;
    phoneNumber: string;
    email: string;
    user_provides_email: boolean;
    invoice_delivery_method: InvoiceDeliveryMethod;
    street: string;
    housenumber: string;
    postalcode: string;
    city: string;
    country: Country;
    mansionId: string;
    locationId: string;
    iban: string;
    accountHolder: string;
}

export enum Language {
    GERMAN = 'de',
    ENGLISH = 'en',
}

export enum Salutation {
    MALE = 'M',
    FEMALE = 'F',
}

// Needs to match the countryIds in the country table
export enum Country {
    GERMANY = '1',
    SINGAPORE = '2',
    AUSTRIA = '3',
}
