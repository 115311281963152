import React, {useState} from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button} from "react-bootstrap";
import {faRedo} from "@fortawesome/free-solid-svg-icons";
import styles from './RefreshButton.module.css';

export interface RefreshButtonProps {
    disabled?: boolean;
    onRefresh: () => void;
}

export function RefreshButton(props: RefreshButtonProps) {
    const [rotate, setRotate] = useState(false);

    function handleRefresh() {
        setRotate(true);
        props.onRefresh();
        setTimeout(() => setRotate(false), 500);
    }

    return <Button onClick={handleRefresh}
                   disabled={props.disabled ? props.disabled : false}>
        <FontAwesomeIcon icon={faRedo} className={rotate ? styles.rotate : ''}></FontAwesomeIcon>
    </Button>;
}