import React, { FunctionComponent, useEffect } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikActions, FormikProps } from 'formik';
import { WeWashApiErrorTag } from '../../http/errors';
import ErrorFeedback from '../../forms/ErrorFeedback';
import {
    getCountries,
    getGroupedUserInvoicesInfoFor,
    GroupedUserInvoiceRequestDto,
    resetDownloadInvoiceStore,
} from '../../store/grouped-user-invoices/groupedUserInvoicesAction';
import { GroupedUserInvoicesInfoResponse } from '../../store/grouped-user-invoices/groupedUserInvoicesReducer';
import GroupedUserInvoiceInfoTable from './GroupedUserInvoiceInfoTable';
import styles from './GroupedUserInvoice.module.css';
import { CountryDto } from '../../store/country';

interface Props {
    apiErrors: WeWashApiErrorTag[];
    loading: boolean;
    groupedUserInvoicesInfo: GroupedUserInvoicesInfoResponse | null;
    getGroupedUserInvoicesInfoFor: (dto: GroupedUserInvoiceRequestDto) => void;
    resetDownloadInvoiceStore: () => void;
    countries: CountryDto[];
    getCountries: () => void;
}

export interface GroupedUserInvoicesInfo {
    month: string;
    year: string;
    countryIso1Code: string;
}

const GroupedUserInvoices: FunctionComponent<Props> = (props) => {
    const date = new Date();
    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    const getCountries = props.getCountries;

    const initialValues: GroupedUserInvoicesInfo = {
        month: String(date.getMonth() + 1),
        year: String(date.getFullYear()),
        countryIso1Code: 'AT',
    };

    useEffect(() => {
        getCountries();
    }, [getCountries]);

    function renderForm(formProps: FormikProps<GroupedUserInvoicesInfo>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <ErrorFeedback apiErrors={props.apiErrors} />
                <Row>
                    <Col>
                        <Form.Group controlId={'month'}>
                            <Form.Label>Monat</Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'month'}
                                value={formProps.values.month}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                    </Col>
                    <Col>
                        <Form.Group controlId={'year'}>
                            <Form.Label>Jahr</Form.Label>
                            <Form.Control
                                type={'text'}
                                name={'year'}
                                value={formProps.values.year}
                                onChange={formProps.handleChange}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Form.Group controlId="countryIso1Code">
                    <Form.Label>Land</Form.Label>
                    <Form.Control
                        as="select"
                        onChange={formProps.handleChange}
                        value={formProps.values.countryIso1Code}
                    >
                        {props.countries.map((it) => (
                            <option key={it.isocode1} value={it.isocode1}>
                                {it.isocode1}
                            </option>
                        ))}
                    </Form.Control>
                </Form.Group>
                {loadDetailsButton(formProps)}
                <p>
                    <small>
                        *Nutzerrechnungen in Status ARCHIVED werden nicht
                        heruntergeladen. Sie sind nur aus dem Backup verfügbar.
                    </small>
                </p>
                {props.groupedUserInvoicesInfo &&
                    props.groupedUserInvoicesInfo.count_by_status && (
                        <GroupedUserInvoiceInfoTable
                            groupedUserInvoiceRequestDto={{
                                month: Number(formProps.values.month),
                                year: Number(formProps.values.year),
                                country_code: formProps.values.countryIso1Code,
                            }}
                            countByStatus={
                                props.groupedUserInvoicesInfo.count_by_status
                            }
                        />
                    )}
            </Form>
        );
    }

    async function handleFormSubmit(
        values: GroupedUserInvoicesInfo,
        actions: FormikActions<GroupedUserInvoicesInfo>
    ) {
        props.resetDownloadInvoiceStore();
        actions.setSubmitting(true);
        await props.getGroupedUserInvoicesInfoFor({
            month: Number(values.month),
            year: Number(values.year),
            country_code: values.countryIso1Code,
        });
        actions.setSubmitting(false);
    }

    function loadDetailsButton(formProps: any) {
        const buttonText = formProps.isSubmitting
            ? 'wird geladen...'
            : 'Details laden';
        return (
            <Button type="submit" disabled={formProps.isSubmitting}>
                {buttonText}
            </Button>
        );
    }

    return (
        <div
            id={'GetGroupedUserInvoices'}
            className={styles.groupedUserInvoices}
        >
            <Row className={styles.groupedUserInvoicesForm}>
                <h2>Nutzerrechnungen herunterladen</h2>
                <p>Abrechnungszeitraum und Land wählen.</p>
                <Formik
                    onSubmit={handleFormSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Row>
        </div>
    );
};

export default connect(
    (store: RootStore) => ({
        apiErrors: store.groupedUserInvoices.errors,
        loading: store.groupedUserInvoices.loading,
        countries: store.groupedUserInvoices.countries,
        groupedUserInvoicesInfo:
            store.groupedUserInvoices.groupedUserInvoicesInfoResponse,
    }),
    { getGroupedUserInvoicesInfoFor, resetDownloadInvoiceStore, getCountries }
)(GroupedUserInvoices);
