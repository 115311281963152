import React, { FunctionComponent } from 'react';
import Alert from 'react-bootstrap/Alert';

interface Props {
    message: string | null;
}

const SuccessFeedback: FunctionComponent<Props> = ({ message }) => {
    if (!message) {
        return null;
    }

    return (
        <Alert show={!!message} variant="success">
            {message}
        </Alert>
    );
};

export default SuccessFeedback;
