import { AnyAction } from 'redux';
import { RootStore } from '../rootStore';
import { ThunkDispatch } from 'redux-thunk';
import RestClient from '../../http/RestClient';
import { extractErrorTags } from '../../http/errors';
import { FinancialTransactionDto } from './failedPaymentReducer';

export const LOAD_PAYMENT_DATA = 'LOAD_PAYMENT_DATA';
export const LOAD_PAYMENT_DATA_SUCCESS = 'LOAD_PAYMENT_DATA_SUCCESS';
export const LOAD_PAYMENT_DATA_FAILURE = 'LOAD_PAYMENT_DATA_FAILURE';
export const SET_PAYMENT_FAILED = 'SET_PAYMENT_FAILED';
export const SET_PAYMENT_FAILED_SUCCESS = 'SET_PAYMENT_FAILED_SUCCESS';
export const SET_PAYMENT_FAILED_FAILURE = 'SET_PAYMENT_FAILED_FAILURE';

export function loadPaymentData(financialDocumentNumber: string) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: LOAD_PAYMENT_DATA });
        try {
            const { data } = await RestClient.get<FinancialTransactionDto>(
                `/v1/financial_transaction`,
                { params: { financialDocumentNumber } }
            );
            dispatch({ type: LOAD_PAYMENT_DATA_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: LOAD_PAYMENT_DATA_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}

export function setPaymentFailed(financialTransactionId: number) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: SET_PAYMENT_FAILED });
        try {
            await RestClient.post(
                `/v1/financial_transaction/${financialTransactionId}/fail`
            );
            dispatch({ type: SET_PAYMENT_FAILED_SUCCESS });
        } catch (error) {
            dispatch({
                type: SET_PAYMENT_FAILED_FAILURE,
                payload: { errors: extractErrorTags(error) },
            });
        }
    };
}
