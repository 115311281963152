import React, { Fragment, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { changeLaundryRoomMethod } from '../../store/manage-user/manageUserActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Form, InputGroup, Row, Spinner } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { ManageUserStore } from '../../store/manage-user/manageUserReducer';
import ErrorFeedback from '../../forms/ErrorFeedback';

interface Props {
    manageUserStore: ManageUserStore;
    data: { userId: number; email: string };
    close: () => void;
    changeLaundryRoomMethod: (userId: number, laundryRoomId: number) => void;
}

interface InputFormData {
    laundryRoomId: string;
}

function ChangeLaundryRoom({
    manageUserStore,
    data,
    close,
    changeLaundryRoomMethod,
}: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const isPatching = patchTriggered && manageUserStore.patching;

    useEffect(() => {
        if (
            patchTriggered &&
            !isPatching &&
            manageUserStore.patchErrors.length === 0
        ) {
            close();
        }
    }, [patchTriggered, isPatching, manageUserStore.patchErrors, close]);

    const initialValues: InputFormData = { laundryRoomId: '' };

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Waschraum ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Den Waschraum für Nutzer <strong>{data.email}</strong> (#
                {data.userId}) anpassen.
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<InputFormData>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Form.Group
                    as={Row}
                    controlId={'formGroupLaundryRoomId'}
                    className={'mx-0'}
                >
                    <ErrorFeedback apiErrors={manageUserStore.patchErrors} />
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Waschraum ID</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                            type={'number'}
                            name={'laundryRoomId'}
                            value={formProps.values.laundryRoomId}
                            onChange={formProps.handleChange}
                            placeholder={'Waschraum ID'}
                        />
                    </InputGroup>
                    <Row className={'mx-0 mt-4'}>
                        <Button onClick={handleCancel} className={'mr-2'}>
                            Abbrechen
                        </Button>
                        <Button
                            type={'submit'}
                            disabled={formProps.isSubmitting}
                        >
                            {isPatching && <Spinner animation={'grow'} />}
                            Waschraum setzen
                        </Button>
                    </Row>
                </Form.Group>
            </Form>
        );
    }

    function handleCancel() {
        close();
    }

    function handleSubmit(
        value: InputFormData,
        actions: FormikActions<InputFormData>
    ) {
        changeLaundryRoomMethod(data.userId, Number(value.laundryRoomId));
        setPatchTriggered(true);
        actions.setSubmitting(false);
    }
}

export default connect(
    (state: RootStore) => ({ manageUserStore: state.manageUser }),
    {
        close: closeModal,
        changeLaundryRoomMethod,
    }
)(ChangeLaundryRoom);
