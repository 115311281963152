import { InvoiceItemStatusForRefund } from '../../store/refund-reservation/refundReservationReducer';

export function printInvoicingStatus(
    invoicingStatus?: InvoiceItemStatusForRefund
): string {
    switch (invoicingStatus) {
        case InvoiceItemStatusForRefund.NEW:
            return `${invoicingStatus}`;
        case InvoiceItemStatusForRefund.CANCELED:
            return `${invoicingStatus} - Betrag wird nicht in Rechnung gestellt.`;
        case InvoiceItemStatusForRefund.REFUNDED:
            return `${invoicingStatus} - Betrag wurde als Credits gutgeschrieben.`;
        case InvoiceItemStatusForRefund.INVOICED:
            return `${invoicingStatus} - In Rechnung gestellt.`;
    }
    return invoicingStatus || '-';
}
