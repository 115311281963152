import React, {
    ChangeEvent,
    KeyboardEvent,
    SyntheticEvent,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { faCheck, faPen, faBan } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeButton from './FontAwesomeButton';
import styles from './MultilineInlineEdit.module.css';
import Alert from 'react-bootstrap/Alert';
import ErrorFeedback from '../ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    value: string;
    onSetValue: (v: string) => void;
    validate?: (v: string) => boolean;
    errorText?: string;
    errors?: WeWashApiErrorTag[];
}

function MultilineInlineEdit({ value, onSetValue, ...props }: Props) {
    const [isEditing, setIsEditing] = useState<boolean>(false);
    const [editingValue, setEditingValue] = useState(value);

    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (isEditing && textareaRef.current) {
            textareaRef.current.select();
        }
    }, [isEditing, textareaRef]);

    const showError = useMemo(() => {
        if (!props.validate) {
            return false;
        }
        return !props.validate(editingValue);
    }, [editingValue, props.validate]);

    return (
        <div>
            <div hidden={!isEditing} className={styles.container}>
                <FontAwesomeButton
                    onClick={saveValue}
                    icon={faCheck}
                    color="#00B3E6"
                />
                <FontAwesomeButton
                    onClick={cancelEdit}
                    icon={faBan}
                    color="#3B3B3A"
                />
                <textarea
                    style={{ flex: 1 }}
                    rows={1}
                    onChange={handleChange}
                    onFocus={handleAutoGrow}
                    onKeyDown={handleKeyDown}
                    onKeyUp={handleAutoGrow}
                    value={editingValue}
                    ref={textareaRef}
                />
            </div>
            <div hidden={isEditing}>
                {renderEditButton()}
                {value}
            </div>

            <Alert
                show={showError}
                variant="danger"
                children={props.errorText}
            />
            <ErrorFeedback apiErrors={props.errors} />
        </div>
    );

    function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
        setEditingValue(event.target.value);
    }

    function handleKeyDown(event: KeyboardEvent<HTMLTextAreaElement>) {
        if (event.key === 'Enter') {
            saveValue();
        } else if (event.key === 'Escape') {
            cancelEdit();
        }
    }

    function handleAutoGrow(event: SyntheticEvent<HTMLTextAreaElement>) {
        const textarea = event.currentTarget;
        if (textarea.scrollHeight > textarea.clientHeight) {
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    function renderEditButton() {
        return (
            <FontAwesomeButton
                onClick={startEdit}
                icon={faPen}
                color={'#00B3E6'}
            />
        );
    }

    function startEdit() {
        setIsEditing(true);
        const textarea = textareaRef.current;
        if (textarea && textarea.scrollHeight > textarea.clientHeight) {
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    }

    function saveValue() {
        onSetValue(editingValue);
        setIsEditing(false);
    }

    function cancelEdit() {
        setEditingValue(value);
        setIsEditing(false);
    }
}

export default MultilineInlineEdit;
