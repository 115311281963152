import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import {
    correctCreditNote,
    CorrectionMode,
    CreditNoteCorrection,
    CreditNoteData,
    resetCorrectCreditNote,
} from '../../store/manage-credit-notes/manageCreditNotesActions';
import { Formik, FormikActions, FormikProps } from 'formik';
import { Button, Form, FormControl } from 'react-bootstrap';
import * as yup from 'yup';
import { RootStore } from '../../store/rootStore';
import { CommunicationState } from '../../store/communicationState';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';
import Alert from 'react-bootstrap/Alert';

const STYLE_INACTIVE: React.CSSProperties = {
    background: '#eee',
};

interface Props {
    creditNote: CreditNoteData;

    apiErrors: WeWashApiErrorTag[];
    correctionStatus: CommunicationState;
    resetCorrectCreditNote: () => void;
    correctCreditNote: (correction: CreditNoteCorrection) => void;
}

interface CorrectionFormValues {
    creditNoteId: number;
    creditNoteNumber: string;
    resetEmailStatus: boolean;
    partnerGross: string;
    upload: boolean;
    pdfFileName?: string;
    pdfFile?: Blob;
    uploadXml: boolean;
    xmlFileName?: string;
    xmlFile?: Blob;
    recreateXml: boolean;
}

const schema: yup.ObjectSchema<CorrectionFormValues> = yup.object({
    creditNoteId: yup.number().required(),
    creditNoteNumber: yup.string().required(),
    resetEmailStatus: yup.boolean().required(),
    partnerGross: yup.string().required(),
    upload: yup.boolean().required(),
    recreateXml: yup.boolean().required(),
    pdfFileName: yup.string().when('upload', {
        is: true,
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    pdfFile: yup.mixed().notRequired(),
    uploadXml: yup.boolean().required(),
    xmlFileName: yup.string().when(['upload', 'uploadXml'], {
        is: (...fields) => fields.every((x) => !!x),
        then: yup.string().required(),
        otherwise: yup.string().notRequired(),
    }),
    xmlFile: yup.mixed().notRequired(),
});

function CreditNoteCorrectionForm(props: Props) {
    const initialReset = props.resetCorrectCreditNote;
    useEffect(() => {
        initialReset && initialReset();
    }, [initialReset]);

    if (!props.creditNote.credit_note_number) {
        return (
            <div>
                Unzureichende Daten für Korrektur: Keine Credit Note Number
                vorhanden.
            </div>
        );
    }
    const partnerGrossIsDifferentNow =
        props.creditNote.partner_gross !==
        props.creditNote.calculated_partner_gross;

    const initialValues: CorrectionFormValues = {
        creditNoteId: props.creditNote.credit_note_id,
        creditNoteNumber: props.creditNote.credit_note_number,
        resetEmailStatus: true,
        partnerGross: props.creditNote.partner_gross.toFixed(2),
        recreateXml: true,
        upload: false,
        pdfFile: undefined,
        pdfFileName: '',
        uploadXml: false,
        xmlFile: undefined,
        xmlFileName: '',
    };

    return (
        <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={schema}
            children={renderForm}
        />
    );

    function handleSubmit(
        values: CorrectionFormValues,
        actions: FormikActions<CorrectionFormValues>
    ) {
        actions.setSubmitting(true);
        try {
            const xmlMode =
                values.upload && values.uploadXml
                    ? CorrectionMode.UPLOAD
                    : !values.upload && values.recreateXml
                    ? CorrectionMode.REGENERATE
                    : CorrectionMode.NONE;
            const creditNoteCorrection: CreditNoteCorrection = {
                creditNoteId: props.creditNote.credit_note_id,
                creditNoteNumber: values.creditNoteNumber,
                userId: props.creditNote.user_id,
                partnerGross: values.partnerGross,
                resetEmailStatus: values.resetEmailStatus,
                pdfMode: values.upload
                    ? CorrectionMode.UPLOAD
                    : CorrectionMode.REGENERATE,
                pdfFile: values.upload ? values.pdfFile : undefined,
                xmlMode,
                xmlFile:
                    xmlMode === CorrectionMode.UPLOAD
                        ? values.xmlFile
                        : undefined,
            };
            props.correctCreditNote(creditNoteCorrection);
        } catch (e) {
            console.error('Credit Note Correction failed', e);
        } finally {
            actions.setSubmitting(false);
        }
    }

    function renderForm(formProps: FormikProps<CorrectionFormValues>) {
        const successfulCorrection =
            props.correctionStatus === CommunicationState.SUCCESS;
        const waiting =
            formProps.isSubmitting ||
            props.correctionStatus === CommunicationState.LOADING;
        return (
            <Form onSubmit={formProps.handleSubmit}>
                <Form.Group controlId={'creditNoteNumber'}>
                    <Form.Label>Credit Note Number</Form.Label>
                    <Form.Control
                        type={'text'}
                        value={formProps.values.creditNoteNumber}
                        disabled
                    />
                </Form.Group>

                <Form.Group
                    controlId={'resetEmailStatus'}
                    className={'mb-3 mt-2'}
                >
                    <Form.Check
                        checked={formProps.values.resetEmailStatus}
                        onChange={formProps.handleChange}
                        label={
                            'E-Mail Status zurücksetzen (E-Mail mit Korrektur senden)'
                        }
                    />
                </Form.Group>

                <Form.Group controlId={'partnerGross'}>
                    <Form.Label>Partner Anteil/Partner Gross</Form.Label>
                    <Form.Control
                        type={'text'}
                        onChange={formProps.handleChange}
                        value={formProps.values.partnerGross}
                    />
                    {partnerGrossIsDifferentNow && (
                        <Button
                            type={'button'}
                            variant={'outline-dark'}
                            size={'sm'}
                            onClick={() =>
                                formProps.setFieldValue(
                                    'partnerGross',
                                    props.creditNote.calculated_partner_gross.toFixed(
                                        2
                                    )
                                )
                            }
                        >
                            Auf berechneten Partner Anteil (
                            {props.creditNote.calculated_partner_gross.toFixed(
                                2
                            )}
                            ) korrigieren
                        </Button>
                    )}
                </Form.Group>

                <Form.Check
                    type={'radio'}
                    id={'recreate'}
                    name={'group'}
                    label={'Dokumente neu erstellen'}
                    checked={!formProps.values.upload}
                    onChange={handleRecreateChange}
                ></Form.Check>
                {maybeRenderRecreate()}
                <Form.Check
                    type={'radio'}
                    id={'upload'}
                    name={'group'}
                    label={'Eigene Korrektur-Dokument hochladen'}
                    checked={formProps.values.upload}
                    onChange={handleUploadChange}
                ></Form.Check>
                {maybeRenderUpload()}

                <ErrorFeedback apiErrors={props.apiErrors} />
                <Button type={'submit'} disabled={waiting} size={'lg'}>
                    {waiting ? 'Bitte warten...' : 'Korrektur'}
                </Button>

                {successfulCorrection && (
                    <Alert variant="success" className={'mt-2'}>
                        Korrektur erfolgreich
                    </Alert>
                )}
            </Form>
        );

        function handleUploadChange() {
            formProps.setFieldValue('upload', true);
        }

        function handleRecreateChange() {
            formProps.setFieldValue('upload', false);
        }

        function maybeRenderRecreate() {
            const active = !formProps.values.upload;

            return (
                <div
                    className={'ml-5'}
                    style={active ? undefined : STYLE_INACTIVE}
                >
                    <Form.Group
                        controlId={'recreatePdf'}
                        className={'mb-3 mt-2'}
                    >
                        <Form.Check
                            checked={!formProps.values.upload}
                            label={'PDF Dokument neu erstellen'}
                            disabled
                        />
                    </Form.Group>

                    <Form.Group
                        controlId={'recreateXml'}
                        className={'mb-3 mt-2'}
                    >
                        <Form.Check
                            checked={
                                active ? formProps.values.recreateXml : false
                            }
                            onChange={formProps.handleChange}
                            label={'XML Dokument neu erstellen'}
                            disabled={!active}
                        />
                    </Form.Group>
                </div>
            );
        }

        function maybeRenderUpload() {
            const active = formProps.values.upload;

            return (
                <div
                    className={'ml-5'}
                    style={active ? undefined : STYLE_INACTIVE}
                >
                    <Form.Group controlId={'uploadPdf'} className={'mb-3 mt-2'}>
                        <Form.Check
                            checked={formProps.values.upload}
                            label={'PDF Dokument hochladen'}
                            disabled
                        />
                    </Form.Group>
                    <Form.Group controlId={'pdfFileName'}>
                        <Form.Label>PDF Dokument</Form.Label>
                        <Form.Control
                            type={'file'}
                            value={formProps.values.pdfFileName}
                            onChange={handlePdfFileUpload}
                            accept={'.pdf, application/pdf'}
                            isInvalid={
                                formProps.touched.pdfFileName &&
                                !!formProps.errors.pdfFileName
                            }
                            isValid={
                                formProps.touched.pdfFileName &&
                                !formProps.errors.pdfFileName
                            }
                            disabled={!active}
                        />
                        <FormControl.Feedback type={'invalid'}>
                            {formProps.errors.pdfFileName}
                        </FormControl.Feedback>
                    </Form.Group>

                    <Form.Group controlId={'uploadXml'} className={'mb-3 mt-2'}>
                        <Form.Check
                            checked={
                                active ? formProps.values.uploadXml : false
                            }
                            onChange={formProps.handleChange}
                            label={'XML Dokument hochladen'}
                            disabled={!active}
                        />
                    </Form.Group>

                    {maybeRenderXmlUpload(active)}
                </div>
            );
        }

        function maybeRenderXmlUpload(formActive: boolean) {
            const active = formProps.values.uploadXml && formActive;
            return (
                <Form.Group controlId={'xmlFileName'}>
                    <Form.Label>XML Dokument</Form.Label>
                    <Form.Control
                        type={'file'}
                        value={formProps.values.xmlFileName}
                        onChange={handleXmlFileUpload}
                        accept={'.xml, application/xml'}
                        isInvalid={
                            formProps.touched.xmlFileName &&
                            !!formProps.errors.xmlFileName
                        }
                        isValid={
                            formProps.touched.xmlFileName &&
                            !formProps.errors.xmlFileName
                        }
                        disabled={!active}
                    />
                    <FormControl.Feedback type={'invalid'}>
                        {formProps.errors.xmlFileName}
                    </FormControl.Feedback>
                </Form.Group>
            );
        }

        function handlePdfFileUpload(event: React.ChangeEvent<any>) {
            formProps.setFieldValue('pdfFile', event.target.files[0]);
            formProps.handleChange(event);
        }

        function handleXmlFileUpload(event: React.ChangeEvent<any>) {
            formProps.setFieldValue('xmlFile', event.target.files[0]);
            formProps.handleChange(event);
        }
    }
}

export default connect(
    (store: RootStore) => ({
        apiErrors: store.manageCreditNotes.errors,
        correctionStatus: store.manageCreditNotes.correctCreditNoteStatus,
    }),
    { resetCorrectCreditNote, correctCreditNote }
)(CreditNoteCorrectionForm);
