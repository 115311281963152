import { createReducer } from 'redux-starter-kit';
import { WeWashApiErrorTag } from '../../http/errors';
import {
    BLACKLIST_IBAN,
    BLACKLIST_IBAN_FAILURE,
    BLACKLIST_IBAN_SUCCESS,
} from './blacklistIbanActions';
import { RESET_VIEWS } from '../genericActions';

export enum BlacklistIbanRequestState {
    INITIAL = 'initial',
    LOADING = 'loading',
    SUCCESS = 'success',
    FAILURE = 'failure',
}

export interface BlacklistIbanStore {
    requestState: BlacklistIbanRequestState;
    errors: WeWashApiErrorTag[];
}

const initialStore: BlacklistIbanStore = {
    requestState: BlacklistIbanRequestState.INITIAL,
    errors: [],
};

export const blacklistIbanReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [BLACKLIST_IBAN]: (state: BlacklistIbanStore, action) => {
        state.requestState = BlacklistIbanRequestState.LOADING;
        state.errors = [];
    },
    [BLACKLIST_IBAN_SUCCESS]: (state: BlacklistIbanStore) => {
        state.requestState = BlacklistIbanRequestState.SUCCESS;
        state.errors = [];
    },
    [BLACKLIST_IBAN_FAILURE]: (state: BlacklistIbanStore, action) => {
        state.requestState = BlacklistIbanRequestState.FAILURE;
        state.errors = action.payload.errors;
    },
});
