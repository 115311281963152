import { ReactText } from 'react';
import { RootStore } from '../rootStore';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ReservationForRefundDto } from './refundReservationReducer';
import RestClient from '../../http/RestClient';
import { extractErrorTags, WeWashApiError } from '../../http/errors';
import { RefundParameters } from '../../forms/refund-reservation/RefundReservationForm';

export const GET_RESERVATION_DATA = 'GET_RESERVATION_DATA';
export const GET_RESERVATION_DATA_SUCCESS = 'GET_RESERVATION_DATA_SUCCESS';
export const GET_RESERVATION_DATA_FAILURE = 'GET_RESERVATION_DATA_FAILURE';

export const REFUND_RESERVATION = 'REFUND_RESERVATION';
export const REFUND_RESERVATION_SUCCESS = 'REFUND_RESERVATION_SUCCESS';
export const REFUND_RESERVATION_FAILURE = 'REFUND_RESERVATION_FAILURE';

export function loadReservationForRefund(reservationId: ReactText) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: GET_RESERVATION_DATA });
        try {
            const { data } = await RestClient.get<ReservationForRefundDto>(
                '/v1/refund/' + reservationId
            );
            dispatch({ type: GET_RESERVATION_DATA_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: GET_RESERVATION_DATA_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}

export function refundReservation(
    reservationId: ReactText,
    refundDto: RefundParameters
) {
    return async function (
        dispatch: ThunkDispatch<RootStore, void, AnyAction>
    ) {
        dispatch({ type: REFUND_RESERVATION });
        try {
            const { data } = await RestClient.post(
                '/v1/refund/' + reservationId,
                refundDto
            );
            dispatch({ type: REFUND_RESERVATION_SUCCESS, payload: { data } });
        } catch (error) {
            dispatch({
                type: REFUND_RESERVATION_FAILURE,
                payload: { errors: extractErrorTags(error as WeWashApiError) },
            });
        }
    };
}
