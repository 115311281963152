import React, { Fragment, useEffect, useState } from 'react';
import {
    InvoiceDeliveryMethod,
    UserExtendedInformation,
    UserType,
} from '../../store/search-user/searchUserReducer';
import { connect } from 'react-redux';
import { closeModal } from '../../store/modal/modalActions';
import { Button, Form, Modal, Row } from 'react-bootstrap';
import { RootStore } from '../../store/rootStore';
import { Formik, FormikProps } from 'formik';
import { changeInvoiceDelivery } from '../../store/manage-user/manageUserActions';
import ErrorFeedback from '../../forms/ErrorFeedback';
import { WeWashApiErrorTag } from '../../http/errors';

interface Props {
    data: {
        userId: number;
    };

    searchResultsById: UserExtendedInformation[];
    errorTags: WeWashApiErrorTag[];
    isPatching: boolean;
    close: () => void;
    updateInvoiceDelivery: (
        userId: number,
        invoiceDelivery: InvoiceDeliveryMethod
    ) => void;
}

interface Data {
    userId: number;
    invoiceDelivery: InvoiceDeliveryMethod;
}

export const ALLOWED_INVOICE_DELIVERY_BY_USER_TYPE = {
    [UserType.APP]: [InvoiceDeliveryMethod.E_MAIL, InvoiceDeliveryMethod.NONE],
    [UserType.PHONE]: [
        InvoiceDeliveryMethod.E_MAIL,
        InvoiceDeliveryMethod.LETTER,
        InvoiceDeliveryMethod.NONE,
    ],
};

export const TRANSLATIONS_FOR_INVOICE_DELIVERY: {
    [key in InvoiceDeliveryMethod]: string;
} = {
    [InvoiceDeliveryMethod.E_MAIL]: 'via E-Mail',
    [InvoiceDeliveryMethod.LETTER]: 'via Post (kostenpflichtig)',
    [InvoiceDeliveryMethod.NONE]: 'deaktiviert',
};

function ChangeInvoiceDelivery(props: Props) {
    const [patchTriggered, setPatchTriggered] = useState(false);
    const information =
        props.searchResultsById
            .filter((it) => it.user_id === props.data.userId)
            .reduce((info) => info) || {};

    const currentInvoiceDelivery: InvoiceDeliveryMethod =
        information.invoice_delivery_method || InvoiceDeliveryMethod.NONE;

    const initialValues: Data = {
        userId: props.data.userId,
        invoiceDelivery: currentInvoiceDelivery,
    };
    const allowedValues =
        ALLOWED_INVOICE_DELIVERY_BY_USER_TYPE[information.user_type] || [];

    useEffect(() => {
        if (patchTriggered && !props.isPatching) {
            if (props.errorTags.length > 0) {
                setPatchTriggered(false);
            } else {
                props.close();
            }
        }
    }, [patchTriggered, props.isPatching, props.errorTags, props.close]);

    return (
        <Fragment>
            <Modal.Header closeButton>
                <Modal.Title>Rechungszustellung ändern</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Formik
                    onSubmit={handleSubmit}
                    initialValues={initialValues}
                    children={renderForm}
                />
            </Modal.Body>
        </Fragment>
    );

    function renderForm(formProps: FormikProps<Data>) {
        return (
            <Form noValidate onSubmit={formProps.handleSubmit}>
                <Row className={'ml-3 mb-3'}>
                    Die Rechnungszustellung für den Nutzer
                    <strong> {information.email} </strong>
                    ändern.
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Form.Group controlId={'invoiceDelivery'}>
                        <Form.Label>Rechungszustellung:</Form.Label>
                        <Form.Control
                            as={'select'}
                            onChange={formProps.handleChange}
                            value={formProps.values.invoiceDelivery}
                        >
                            {allowedValues.map((it) => (
                                <option key={it} value={it}>
                                    {TRANSLATIONS_FOR_INVOICE_DELIVERY[it]}
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>
                </Row>
                <Row className={'ml-3 mb-3'}>
                    <Button
                        className={'mr-2'}
                        type="button"
                        onClick={handleCancel}
                        variant={'outline-secondary'}
                    >
                        ABBRECHEN
                    </Button>
                    <Button type={'submit'} disabled={formProps.isSubmitting}>
                        RECHUNGSZUSTELLUNG ÄNDERN
                    </Button>
                </Row>
                <ErrorFeedback apiErrors={props.errorTags} />
            </Form>
        );
    }

    async function handleSubmit(values: Data) {
        props.updateInvoiceDelivery(values.userId, values.invoiceDelivery);
        setPatchTriggered(true);
    }

    function handleCancel() {
        props.close();
    }
}

export default connect(
    (store: RootStore) => ({
        searchResultsById: store.searchUser.resultsById,
        errorTags: store.manageUser.patchErrors,
        isPatching: store.manageUser.patching,
    }),
    { close: closeModal, updateInvoiceDelivery: changeInvoiceDelivery }
)(ChangeInvoiceDelivery);
