import { WeWashApiErrorTag } from '../../http/errors';
import { RESET_VIEWS } from '../genericActions';
import {
    CANCEL_INVOICE,
    CANCEL_INVOICE_FAILURE,
    CANCEL_INVOICE_SUCCESS,
    GET_INVOICE_BY_ID,
    GET_INVOICE_BY_ID_FAILURE,
    GET_INVOICE_BY_ID_SUCCESS,
    MANUALLY_PAY_INVOICE,
    MANUALLY_PAY_INVOICE_FAILURE,
    MANUALLY_PAY_INVOICE_SUCCESS,
    RESET_CANCEL_INVOICE_PERFORMED_ACTION,
    RESET_PAY_INVOICE_REQUESTS,
    RESET_SEARCH_INVOICES_BY_SEARCH_TEXT,
    SEARCH_INVOICES_BY_SEARCH_TEXT,
    SEARCH_INVOICES_BY_SEARCH_TEXT_FAILURE,
    SEARCH_INVOICES_BY_SEARCH_TEXT_SUCCESS,
    TRIGGER_DEFAULT_INVOICE_PAYMENT,
    TRIGGER_DEFAULT_INVOICE_PAYMENT_FAILURE,
    TRIGGER_DEFAULT_INVOICE_PAYMENT_SUCCESS,
} from './payInvoiceActions';
import { createReducer } from 'redux-starter-kit';
import { CommunicationState } from '../communicationState';
import { Currency } from '../amountAndCurrency';
import { InvoiceItemStatusForRefund } from "../refund-reservation/refundReservationReducer";

export interface ReservationForInvoiceDetails {
    reservation_id: number;
    invoice_item_id: number;
    timestamp: number;
    amount: number;
    short_name: string;
    type: string;
    invoice_item_status: InvoiceItemStatusForRefund;
}

export interface FinancialTransactionDetails {
    mollie_method_type: string;
    status: string;
    status_updated_timestamp: number;
}

export interface InvoiceDetails {
    user_id: number;
    invoice_id: number;
    reservations: ReservationForInvoiceDetails[];
    amount: number;
    status: string;
    target_accounting_state: string;
    payment_status: string;
    timestamp: number;
    currency: Currency;
    balance?: number;
    discount?: number;
    invoice_number?: string;
    invoice_country_id: number;
    document_status: string;
    financial_transaction: FinancialTransactionDetails;
}

export interface InvoiceListEntry {
    id: number;
    user_id: number;
    customer_id: number;
    invoice_number: string;
    invoice_time: number;
    status: string;
    payment_status: string;
    accounting_status: string;
    invoice_country_id: number;
    target_accounting_state: string;
    type: string;
    billing_type: string;
    financial_transaction?: FinancialTransactionDetails;
}

export interface InvoiceList {
    invoices: InvoiceListEntry[];
}

export enum CancelInvoicePerformedAction {
    INVOICE_ABORTED = 'INVOICE_ABORTED',
    INVOICE_CANCELLING = 'INVOICE_CANCELLING',
    INVOICE_REFUNDING = 'INVOICE_REFUNDING',
    CREDITS_GRANTED = 'CREDITS_GRANTED',
    NONE = 'NONE',
}

export interface PayInvoiceStore {
    errors: WeWashApiErrorTag[];
    loading: boolean;
    communicationState: CommunicationState;
    getErrors: WeWashApiErrorTag[];
    getCommunicationState: CommunicationState;
    cancelInvoicePerformedAction?: CancelInvoicePerformedAction;
    invoiceById?: InvoiceDetails;
    invoiceList?: InvoiceList;
}

const initialStore: PayInvoiceStore = {
    errors: [],
    loading: false,
    communicationState: CommunicationState.INITIAL,
    getErrors: [],
    getCommunicationState: CommunicationState.INITIAL,
    cancelInvoicePerformedAction: undefined,
    invoiceById: undefined,
    invoiceList: undefined,
};

export const payInvoiceReducer = createReducer(initialStore, {
    [RESET_VIEWS]: () => initialStore,
    [RESET_PAY_INVOICE_REQUESTS]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.loading = false;
        state.communicationState = CommunicationState.INITIAL;
    },
    [MANUALLY_PAY_INVOICE]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.loading = true;
    },
    [MANUALLY_PAY_INVOICE_SUCCESS]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.loading = false;
    },
    [MANUALLY_PAY_INVOICE_FAILURE]: (state: PayInvoiceStore, action) => {
        state.errors = action.payload.errors;
        state.loading = false;
    },
    [TRIGGER_DEFAULT_INVOICE_PAYMENT]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.communicationState = CommunicationState.LOADING;
    },
    [TRIGGER_DEFAULT_INVOICE_PAYMENT_SUCCESS]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.communicationState = CommunicationState.SUCCESS;
    },
    [TRIGGER_DEFAULT_INVOICE_PAYMENT_FAILURE]: (
        state: PayInvoiceStore,
        action
    ) => {
        state.errors = action.payload.errors;
        state.communicationState = CommunicationState.FAILURE;
    },
    [CANCEL_INVOICE]: (state: PayInvoiceStore) => {
        state.errors = [];
        state.loading = true;
        state.cancelInvoicePerformedAction = CancelInvoicePerformedAction.NONE;
    },
    [CANCEL_INVOICE_SUCCESS]: (state: PayInvoiceStore, action) => {
        state.errors = [];
        state.loading = false;
        state.cancelInvoicePerformedAction = action.payload.data.message;
    },
    [CANCEL_INVOICE_FAILURE]: (state: PayInvoiceStore, action) => {
        state.errors = action.payload.errors;
        state.loading = false;
        state.cancelInvoicePerformedAction = CancelInvoicePerformedAction.NONE;
    },
    [GET_INVOICE_BY_ID]: (state: PayInvoiceStore, action) => {
        state.getErrors = [];
        state.getCommunicationState = CommunicationState.LOADING;
    },
    [GET_INVOICE_BY_ID_SUCCESS]: (state: PayInvoiceStore, action) => {
        state.getErrors = [];
        state.invoiceById = action.payload.data;
        state.getCommunicationState = CommunicationState.SUCCESS;
    },
    [GET_INVOICE_BY_ID_FAILURE]: (state: PayInvoiceStore, action) => {
        state.getErrors = action.payload.errors;
        state.getCommunicationState = CommunicationState.FAILURE;
    },
    [SEARCH_INVOICES_BY_SEARCH_TEXT]: (state: PayInvoiceStore, action) => {
        state.getErrors = [];
        state.getCommunicationState = CommunicationState.LOADING;
    },
    [RESET_SEARCH_INVOICES_BY_SEARCH_TEXT]: (state: PayInvoiceStore) => {
        state.getErrors = [];
        state.invoiceList = undefined;
        state.getCommunicationState = CommunicationState.LOADING;
    },
    [SEARCH_INVOICES_BY_SEARCH_TEXT_SUCCESS]: (
        state: PayInvoiceStore,
        action
    ) => {
        state.getErrors = [];
        state.invoiceList = action.payload.data;
        state.getCommunicationState = CommunicationState.SUCCESS;
    },
    [SEARCH_INVOICES_BY_SEARCH_TEXT_FAILURE]: (
        state: PayInvoiceStore,
        action
    ) => {
        state.getErrors = action.payload.errors;
        state.getCommunicationState = CommunicationState.FAILURE;
    },
    [RESET_CANCEL_INVOICE_PERFORMED_ACTION]: (
        state: PayInvoiceStore,
        action
    ) => {
        state.cancelInvoicePerformedAction = undefined;
    },
});
